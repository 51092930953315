
.side-link {
  display: inline-block;
  position: fixed;
  text-decoration: none;
  top: 50%;
  z-index: 9999;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
      transform: translateY(-50%); }

.left.side-link {
  left: 10px; }

.right.side-link {
  right: 10px; }

.side-link .side-title {
  font-family: 'Montserrat';
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  width: 1em;
  font-size: 18px;
  line-height: 1.2;
  transition: all .5s ease;
  -webkit-font-smoothing: antialiased; }

.left.side-link .side-title {
  margin-left: 10px; }

.right.side-link .side-title {
  margin-right: 10px; }

.side-arrow:before,
.side-arrow:after {
  content: "";
  position: absolute;
  width: 3px;
  height: 50%;
  background-color: #000;
  transition: all .5s ease; }

.left .side-arrow:before,
.left .side-arrow:after {
  left: 0px; }

.right .side-arrow:before,
.right .side-arrow:after {
  right: 0px; }

.left .side-arrow:before {
  bottom: 50%;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
      transform-origin: bottom left; }

.left .side-arrow:after {
  top: 50%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
      transform-origin: top left; }

.right .side-arrow:before {
  bottom: 50%;
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
      transform-origin: bottom right; }

.right .side-arrow:after {
  top: 50%;
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
      transform-origin: top right; }

.left.side-link:hover .side-arrow:before {
  height: 30px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

.left.side-link:hover .side-arrow:after {
  height: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }

.left.side-link:hover .side-title {
  margin-left: 40px;
  opacity: 0; }

.right.side-link:hover .side-arrow:before {
  height: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
      transform: rotate(-45deg); }

.right.side-link:hover .side-arrow:after {
  height: 30px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
      transform: rotate(45deg); }

.right.side-link:hover .side-title {
  margin-right: 40px;
  opacity: 0; }