header {
  position: relative;
  width: 100%;
  z-index: 999;
  text-align: center;
}

@media (max-width: 767px) {
  header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

header.absolute {
  position: absolute;
  margin-bottom: 0;
}

header .logo {
  margin-top: 20px;
  margin-bottom: 15px;
}

header a.logo {
  text-decoration: none;
}

iframe[name="advpp_manager"] {
  position: absolute;
  left: -9999px;
}

.header_top_bg {
  background-color: #ffffff;
  @extend %main-bg-color;
}

.header_top_bg.enable_fixed.fixed {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.header_trans-fixed.header_top_bg {
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  padding-left: 15px;padding-right: 15px;
  top: 0;
  width: 100%;
}

.header_trans-fixed.header_top_bg.open {
  background-color: #fff;
  @extend %main-bg-color;
}

@media (min-width: 768px) {
  .header_trans-fixed #topmenu {
    background-color: transparent;
  }
}

/*Text logo*/
.top-menu {
  padding-bottom: 10px;
}
.top-menu .logo {
  display: inline-block;
}

.top-menu .logo span {
  font-family: "BebasNeueRegular";
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 1.76px;
  min-width: 170px;
  text-align: center;
  background-color: transparent;
  color: #131313;
  display: inline-block;
  @extend %base-color;
}

@media (min-width: 992px) {
  .top-menu .logo span {
    padding: 24px 10px;
  }
}

@media (max-width: 991px) {
  .top-menu .logo span {
    padding: 15px 10px;
  }
}
@media (min-width: 767px) {
  .top-menu {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .right-menu .logo {
    float: left;
    margin: 0;
  }
  .right-menu .logo img {
    margin-top: 13px;
  }
}

.right-menu .logo span {
  vertical-align: middle;
  text-align: left;
  margin-bottom: 13px;
  font-family: "BebasNeueRegular";
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 1.76px;
  padding-top: 12px;
  padding-bottom: 0;
  margin: 0;
  color: #131313;
  @extend %base-color;
}

.header_trans-fixed.header_top_bg .logo span {
  color: #fff;
  @extend %base-color;
}

.header_trans-fixed.header_top_bg.open .logo span {
  color: #131313;
  @extend %base-color;
}

@media (min-width: 992px) {
  .right-menu .logo span {
    float: left;
  }
}