.post {
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    body .banner-gallery .content-wrap {
        width: 100%;
        margin-left: 0;
    }
    .post {
        width: 100%;
    }
}

body .post a {
    text-decoration: none;
    display: block;
    position: relative;
    opacity: 1;
    transition: all 0.5s ease;
    width: 100%;
    background-color: #343434;
}

.post a .post-content .post-media {
    display: none;
}

.post a .post-media {
    display: block;
    width: 100%;
    height: 240px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.post a:before {
    display: block;
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: all 0.5s ease;
}

.post-content {
    margin: 0;
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: all 0.5s ease;
}

.post-content.sticky .title {
    position: relative;
}

.post-content.sticky .title::before {
    content: "\f249";
    font-family: "FontAwesome";
    margin-right: 8px;
}

.post-content h5 {
    font-size: 24px;
    line-height: 30px;
    font-family: "BebasNeueRegular", sans-serif;
    color: #ffffff;
    display: inline-block;
    font-weight: 400;
    text-transform: none;
    text-align: center;
    letter-spacing: 1.92px;
    margin: 0;
    margin-bottom: 13px;
    @extend %base-color;
}

body .post-content .date {
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.1px;
    font-family: "Montserrat", sans-serif;
    color: #ffffff;
    text-align: center;
    line-height: normal;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    @extend %base-color;
}

.post iframe {
    border: none;
    display: block;
}

.post a:hover {
    opacity: 1;
    transition: all 0.5s ease;
}

.post a:hover:before {
    opacity: 0;
    transition: all 0.5s ease;
}

.post a:hover .date {
    opacity: 0;
    transition: all 0.5s ease;
}

.post a:hover h5 {
    opacity: 0;
    transition: all 0.5s ease;
}


/*Detail post*/

.single-post .date-post {
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1.1px;
    color: #c0af94;
    text-transform: uppercase;
    padding-top: 35px;
    padding-bottom: 17px;
    -webkit-font-smoothing: antialiased;
    @extend %subtitle-color;
}

@media (max-width: 767px) {
    .single-post .date-post {
        padding-top: 30px;
        text-align: center;
    }
}

.single-post .title {
    font-family: "BebasNeueBook";
    font-size: 70px;
    line-height: 80px;
    letter-spacing: 5.6px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 15px;
    -ms-word-wrap: break-word;
    word-wrap: break-word;
    color: #131313;
    @extend %base-color;
}

@media (max-width: 767px) {
    .single-post .title {
        font-size: 40px;
        line-height: 50px;
        text-align: center;
    }
}

.single-post p {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    font-family: "Libre Baskerville";
    color: #888888;
    margin-bottom: 30px;
    @extend %desc-color;
}

.single-post .single-content blockquote {
    margin: 30px 0px;
    overflow: hidden;
    text-align: center;
    padding: 20px 30px 20px 25px;
    border-left: 4px solid #c0af94;
    background-color: #ebebeb;
    @extend %dark-black-bg-color;
    @extend %subtitle-border-color;
}

@media (max-width: 767px) {
    .single-post .single-content blockquote {
        padding: 15px;
    }
}

.single-post .single-content blockquote p {
    font-size: 16px;
    line-height: 30px;
    font-family: "Libre Baskerville";
    color: #131313;
    text-align: left;
    font-style: normal;
    margin: 0;
    letter-spacing: 1.28px;
    @extend %base-color;
}


/*Recent posts*/

.recent-post-single .recent-title {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 2.88px;
    font-family: "BebasNeueRegular";
    font-weight: 400;
    margin: 0;
    margin-top: 34px;
    margin-bottom: 34px;
    color: #131313;
    @extend %base-color;
}

.sm-wrap-post a {
    display: block;
    background-color: #343434;
    position: relative;
    min-height: 240px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: all 0.5s ease;
    @extend %dark-black-bg-color;
}

.sm-wrap-post a:hover {
    opacity: 1;
    transition: all 0.5s ease;
}

.sm-wrap-post .content {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    text-align: center;
    opacity: 1;
    transition: all 0.5s ease;
}

.sm-wrap-post a:hover .content {
    opacity: 0;
    transition: all 0.5s ease;
}

.sm-wrap-post .content .title {
    font-size: 24px;
    line-height: 30px;
    font-family: "BebasNeueRegular";
    letter-spacing: 1.92px;
    font-weight: 400;
    color: #ffffff;
    margin-bottom: 12px;
    @extend %base-color;
}

.sm-wrap-post .content .post-date {
    display: block;
    height: auto;
    line-height: 20px;
}

.sm-wrap-post .content .post-date .date {
    font-size: 11px;
    line-height: 20px;
    font-family: "Montserrat";
    letter-spacing: 1.1px;
    font-weight: 400;
    color: #ffffff;
    margin: 0;
    display: inline-block;
    vertical-align: top;
    -webkit-font-smoothing: antialiased;
    @extend %base-color;
}

.sm-wrap-post a:before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: all 0.5s ease;
}

.sm-wrap-post a:hover:before {
    opacity: 0;
    transition: all 0.5s ease;
}