.single-content ul.comments {
    margin-left: 0;
}

.comments {
    margin-top: 73px;
    margin-bottom: 20px;
}

.comments .comment {
    margin-bottom: 3px;
}

.comments .content {
    position: relative;
    padding-right: 40px;
}

.comments .content .comment-reply-link {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.4px;
    font-family: "Montserrat", sans-serif;
    color: #bababa;
    text-decoration: none;
    font-weight: 400;
    transition: all 300ms ease;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    @extend %desc-color;
}

.comments .comment-reply-title {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 2.88px;
    font-family: "BebasNeueRegular";
    font-weight: 400;
    padding-top: 44px;
    margin-bottom: 13px;
    color: #131313;
    @extend %base-color;
}

.comments .comment-reply-title strong {
    font-weight: 400;
}

.comments .comment-reply-title a {
    text-decoration: none;
}

@media (max-width: 767px) {
    .comments .comment-reply-title {
        padding-top: 20px;
    }
}

.comments .content .comment-reply-link:hover {
    color: #131313;
    @extend %base-color;
}

.comments .content .text {
    font-size: 12px;
    font-family: "Montserrat", sans-serif;
    color: #585858;
    overflow: hidden;
    line-height: 22px;
    margin-top: 6px;
    letter-spacing: .6px;
    @extend %desc-color;
}

.comments .content .text p {
    margin-bottom: 16px;
}

.comments .person img {
    vertical-align: top;
    float: left;
    margin-right: 20px;
    margin-top: 6px;
}

.comments .person .author {
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.92px;
    font-family: "BebasNeueRegular";
    font-weight: 400;
    margin: 0;
    text-decoration: none;
    color: #131313;
    @extend %base-color;
}

.comments .person .author:hover {
    opacity: .7;
}

.comments .person .comment-date {
    font-size: 12px;
    line-height: 20px;
    font-family: "Montserrat", sans-serif;
    color: #c0af94;
    margin-left: 5px;
    font-weight: 400;
    letter-spacing: 2.4px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
    @extend %subtitle-color;
}

.comments-title,
.comments-title span {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 2.88px;
    font-family: "BebasNeueRegular";
    font-weight: 400;
    margin-bottom: 26px;
    color: #131313;
    @extend %base-color;
}

.comment-title {
    border-bottom: 1px dashed #a6a6a6;
    padding-bottom: 6px;
}

.comments li.comment {
    list-style: none;
}

.comments ul.children {
    margin-top: 30px;
}

.comments-form {
    padding-top: 2px;
    margin-bottom: 80px;
    clear: both;
}

body .comments-form input[type=submit]:hover {
    opacity: 1  ;
}

#contactform h3,
.comments-form h3 {
    display: block;
    font-size: 16px;
    font-family: "Libre Baskerville", sans-serif;
    color: #131313;
    font-weight: bold;
    letter-spacing: 1.25px;
    margin-bottom: 20px;
    @extend %base-color;
}

#contactform textarea,
#contactform input,
.comments-form textarea,
.comments-form input {
    font-size: 14px;
    line-height: 28px;
    font-family: "Libre Baskerville", sans-serif;
    background: none;
    width: 100%;
    color: #888888;
    border: 0;
    border-bottom: 2px solid #f2f2f2;
    padding: 10px 0px;
    letter-spacing: 1.12px;
    margin-bottom: 13px;
    transition: all .5s ease;
    @extend %desc-color;
    @extend %form-border-color;
}

#contactform textarea:focus,
#contactform input:focus,
.comments-form textarea:focus,
.comments-form input:focus {
    border-bottom: 2px solid #c0af94;
    transition: all .5s ease;
    @extend %base-color;
    @extend %subtitle-border-color;
}

#contactform textarea::-moz-placeholder,
#contactform input::-moz-placeholder,
.comments-form textarea::-moz-placeholder,
.comments-form input::-moz-placeholder {
    color: #888888;
}

#contactform textarea::-webkit-input-placeholder,
#contactform input::-webkit-input-placeholder,
.comments-form textarea::-webkit-input-placeholder,
.comments-form input::-webkit-input-placeholder {
    color: #888888;
}

#contactform textarea:-moz-placeholder,
#contactform input:-moz-placeholder,
.comments-form textarea:-moz-placeholder,
.comments-form input:-moz-placeholder {
    color: #888888;
}

#contactform textarea:-ms-input-placeholder,
#contactform input:-ms-input-placeholder,
.comments-form textarea:-ms-input-placeholder,
.comments-form input:-ms-input-placeholder {
    color: #888888;
}

#contactform textarea,
.comments-form textarea {
    min-height: 50px;
    max-height: 50px;
    resize: none;
}

#contactform #submit,
.comments-form #submit {
    font-size: 11px;
    line-height: 20px;
    display: inline-block;
    letter-spacing: 1.1px;
    font-family: "Montserrat";
    font-weight: bold;
    box-sizing: border-box;
    padding: 8px 20px;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    outline: none;
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    border-left: none;
    border-right: none;
    color: #131313;
    background-color: transparent;
    margin-top: 25px;
    margin-bottom: 0;
    width: auto;
    -webkit-font-smoothing: antialiased;
    border-radius: 0px;
    @extend %base-light-bottom-color;
    @extend %base-color;
}

#contactform #submit:hover,
.comments-form #submit:hover {
    border-top: 2px solid #131313;
    border-bottom: 2px solid #131313;
    border-left: none;
    border-right: none;
    color: #ffffff;
    background-color: #131313;
    opacity: 1;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}

#contactform {
    display: block;
}

.empty-post-list {
    padding-top: 130px;
    text-align: center;
    display: block;
    width: 100%;
}

.wpb_column>.wpb_wrapper>p:last-child {
    margin-bottom: 10px;
}

.vc_column_container .wpb_wrapper .vc_btn3.vc_btn3-style-outline {
    border-width: 1px;
    padding: 3px 22px;
    font-family: 'Montserrat', sans-serif;
    font-size: 13px;
    line-height: 26px;
    text-transform: uppercase;
    -webkit-font-smoothing: antialiased;
}

.black p.separator {
    background-color: #ffffff;
}

.portfolio-gallery a.gallery-item {
    float: none;
    padding: 0;
}

.blog .sidebar-item {
    margin-left: 15px;
}

.error404 .main-wrapper {
    min-height: 100vh;
    overflow: hidden;
}

body .container .ytbg iframe,
body .hero .vc_row-fluid .vc_column_container .ytbg iframe {
    margin-left: 0  ;
}

body .mb_OnlyYT,
body .mb_YTPTime,
body span.mb_YTPMuteUnmute {
    font-size: 0  ;
    display: none;
}

.mb_YTPPlaypause {
    display: block;
    width: 50px;
    height: 50px;
    font-size: 0;
}

.mb_YTPPlaypause:before {
    font-family: 'FontAwesome';
    content: "\f04c";
    font-size: 25px;
    color: #ffffff;
    height: 50px;
    line-height: 50px;
    text-shadow: 1px 1px 2px black;
}

.mb_YTPPlaypause.active:before {
    content: "\f04b";
}

.mb_YTPBar {
    bottom: 0;
    left: 25px;
}

.mb_YTPBar .buttonBar {
    height: 60px;
}

.single blockquote,
.about-details .content blockquote,
.about-section .content blockquote,
.portfolio-single-content blockquote {
    padding: 26px 26px 26px 64px;
    border: none;
    border-left: 4px solid #131313;
    font-style: italic;
    font-family: "Libre Baskerville", sans-serif;
    background-color: #F4F4F4;
    font-size: 18px;
    letter-spacing: 1.38px;
    color: #131313;
    margin-top: -4px;
    margin-bottom: 25px;
    @extend %dark-black-bg-color;
    @extend %subtitle-border-color;
}

blockquote cite {
    line-height: 1.42857;
    color: #777;
    font-style: normal;
    display: block;
    font-size: 14px;
    margin-top: 15px;
    @extend %desc-color;
}

blockquote cite:before {
    content: '- ';
    margin-right: 7px;
}

body #topmenu ul>li>ul>li>ul {
    display: none ;
}

.single table td,
.single table th,
table td,
table th {
    padding: 7px;
}

.post-info {
    margin: 10px 0 15px;
}

.post-info span a,
.post-info span {
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1.1px;
    text-decoration: none;
    color: #c0af94;
    text-transform: uppercase;
}

.post-info span a,
.post-info span {
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1.1px;
    text-decoration: none;
    color: #c0af94;
    text-transform: uppercase;
}

.post-info span {
    padding-right: 16px;
    border-right: 1px solid #E8E8E8;
}

.post-info span:last-child {
    border: 0 none;
}

div.wpcf7-mail-sent-ok {
    left: 0;
    right: 0;
}

body .wpcf7-not-valid {
    border-bottom: 2px solid red  ;
}

.wpcf7-form-control-wrap {
    position: relative;
    display: block;
}

.wpcf7-form-control-wrap .wpcf7-not-valid-tip {
    position: absolute;
    top: 50px;
}

div.wpcf7-mail-sent-ok,
div.wpcf7-mail-sent-ng,
div.wpcf7-validation-errors {
    border: none;
}

.ajax-loader {
    display: none;
}

div.wpcf7 {
    position: relative;
}

body div.wpcf7-response-output {
    position: absolute;
    bottom: 20px;
    margin: 0  ;
}

@media (min-width: 1200px) {
    div.wpcf7-response-output {
        left: -15px;
    }
}

@media (max-width: 767px) {
    div.wpcf7-response-output {
        position: relative;
        text-align: center;
        bottom: 20px;
    }
}

.empty-post-list form {
    padding-top: 20px;
    width: 270px;
    margin: auto;
    display: block;
}

.empty-post-list input {
    display: inline-block;
    margin: 0 auto;
    height: 36px;
}

.empty-post-list input[type=search] {
    border: 1px solid #ccc;
    padding-left: 7px;
}

.empty-post-list label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    float: left;
    width: 182px;
}

.abs * {
    color: inherit;
}

.tabs ul,
.hero-slider ol,
.tabs ol {
    margin-left: 0;
}


/*.container .wpb_row {
  margin-bottom: 20px;
}*/

@media (max-width: 767px) {
    .slide-caption {
        left: 20px;
        bottom: 20px;
        right: 20px;
    }
    .vc_column_container.vc_col-sm-12 {
        padding-left: 0;
        padding-right: 0;
    }
    .page .wpb_row,
    .page .wpb_content_element,
    .page ul.wpb_thumbnails-fluid>li,
    .page .last_toggle_el_margin,
    .page .wpb_button {
        margin-bottom: 0;
    }
    body .pad-top-120 {
        padding-top: 110px ;
    }
    [class*="col-sm"] {
        margin-bottom: 0;
    }
    #footer .copyright,
    #footer .social-links {
        float: none;
        text-align: center;
    }
    #footer .copyright a,
    #footer .social-links a {
        padding: 0 4px;
    }
}

@media screen and (max-width: 600px) {
    html {
        margin-top: 0;
    }
}

@media (max-width: 500px) {
  body  .hero-slider .slides .slide {
        height: 400px  ;
    }
    .side-link .side-title {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
  body  .hero-slider .slides .slide {
        height: 300px ;
    }
}

.social-links.single-post {
    width: 100%;
    text-align: center;
    font-size: 20px;
}

.social-links.single-post a {
    padding-left: 20px;
}

.click-on-this {
    text-decoration: none;
}

.social-list-portfolio:hover a {
    opacity: .6;
}

.social-list-portfolio a:hover {
    opacity: 1;
}


/* nn. ABOUT DETAILS
-----------------*/

.about-details-section {
    background-color: #f2f2f2;
    @extend %dark-black-bg-color;
}

.about-details {
    padding: 60px 70px 20px;
}

.about-details.style2 {
    padding: 77px 0 71px 0;
}

@media (max-width: 1199px) {
    .about-details {
        padding: 30px 40px 10px;
    }
}

@media (max-width: 991px) {
    .about-details {
        padding: 30px;
    }
}

@media (max-width: 767px) {
    .about-details {
        padding: 30px 15px;
    }
    .about-details.style2 {
        padding: 60px 0;
    }
}

.simple-details {
    margin-bottom: 30px;
}

@media (max-width: 767px) {
    .simple-details {
        text-align: center;
        padding: 0px 15px;
    }
}

.about-details .content .subtitle,
.simple-details .content .subtitle {
    font-family: "Montserrat", sans-serif;
    color: #c0af94;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 0;
    letter-spacing: 2.4px;
    margin-bottom: 30px;
    -webkit-font-smoothing: antialiased;
    @extend %subtitle-color;
}

@media (max-width: 1199px) {
    .about-details .content .subtitle,
    .simple-details .content .subtitle {
        margin-bottom: 20px;
    }
}

.simple-details .content .subtitle {
    margin-top: -3px;
    margin-bottom: 28px;
}

@media (max-width: 1199px) {
    .simple-details .content .subtitle {
        margin-bottom: 15px;
    }
}

.about-details .content .title,
.simple-details .content .title {
    font-family: "BebasNeueRegular";
    font-size: 36px;
    line-height: 40px;
    color: #131313;
    letter-spacing: 2.88px;
    font-weight: 400;
    margin: 0;
    margin-bottom: 20px;
    @extend %base-color;
}

@media (max-width: 1199px) {
    .about-details .content .title,
    .simple-details .content .title {
        font-size: 30px;
        line-height: 30px;
    }
}

.simple-details .content .title {
    line-height: 36px;
    margin-bottom: 25px;
}

@media (max-width: 1199px) {
    .simple-details .content .title {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 15px;
    }
}

.about-details .content .text,
.simple-details .content .text {
    font-size: 14px;
    line-height: 28px;
    font-family: "Libre Baskerville", sans-serif;
    color: #888888;
    letter-spacing: 1.12px;
    @extend %desc-color;
}

.about-details .content .text p,
.simple-details .content .text p {
    margin-top: 28px;
}

.about-details.style2 .content .text p {
    margin-top: 0;
    margin-bottom: 5px;
}

.simple-details .content .text {
    margin-bottom: 30px;
}

.about-details .content .text blockquote {
    margin-top: 25px;
    overflow: hidden;
    text-align: center;
    padding: 20px 30px 20px 25px;
    border-left: 4px solid #c0af94;
    background-color: #ebebeb;
    @extend %main-bg-color;
    @extend %subtitle-border-color;
}

@media (max-width: 1199px) {
    .about-details .content .text blockquote {
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {
    .about-details .content .text blockquote {
        padding: 15px;
    }
}

.about-details .content .text blockquote p {
    font-size: 16px;
    line-height: 30px;
    font-family: "Libre Baskerville";
    color: #131313;
    text-align: left;
    font-style: normal;
    margin: 0;
    letter-spacing: 1.28px;
    @extend %base-color;
}

.about-details .content .button {
    margin-top: 14px;
    margin-left: 0;
}


/* nn. TITLES
-----------------*/

.titles {
    max-width: 770px;
    margin: auto;
    &.left {
        max-width: none;
        .title,
        .subtitle {
            text-align: left;
        }
    }
}

.titles .title {
    font-size: 36px;
    font-family: "BebasNeueRegular", sans-serif;
    color: #131313;
    font-weight: 400;
    line-height: 1.3;
    text-align: center;
    letter-spacing: 2px;
    @extend %base-color;
}

.titles .subtitle {
    font-size: 14px;
    font-family: "Libre Baskerville", sans-serif;
    color: #888888;
    font-weight: 400;
    line-height: 28px;
    text-align: center;
    letter-spacing: .73px;
    @extend %desc-color;
}


/* nn. INSTAGRAM
-----------------*/

.insta-box .insta-box-follow {
    font-size: 36px;
    font-family: "BebasNeueRegular", sans-serif;
    color: #131313;
    font-weight: 400;
    float: left;
    width: 20.5%;
    letter-spacing: 2.88px;
    padding-top: 35px;
    word-break: break-all;
    line-height: 1.2;
    @extend %base-color;
}

@media (max-width: 1199px) {
    .insta-box .insta-box-follow {
        font-size: 30px;
    }
}

.insta-box .insta-box-follow a {
    font-size: 14px;
    display: inline-block;
    font-family: "Libre Baskerville", sans-serif;
    color: #888888;
    display: block;
    font-weight: 400;
    letter-spacing: 1.12px;
    margin-top: 5px;
    text-decoration: none;
    transition: all 300ms ease;
    @extend %desc-color;
}

.insta-box .insta-box-follow a:hover {
    color: #131313;
    @extend %base-color;
}

.insta-box .insta-img-wrap {
    overflow: hidden;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.insta-box .insta-img-wrap a {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.insta-box .insta-img-wrap .img {
    display: block;
    width: 125px;
    height: 125px;
    margin: 0;
    margin-bottom: 40px;
}

@media (max-width: 1199px) {
    .insta-box .insta-img-wrap .img {
        width: 115px;
        height: 115px;
    }
}

@media (max-width: 991px) {
    .insta-box .insta-box-follow {
        font-size: 24px;
        float: none;
        width: 100%;
        text-align: center;
        padding: 10px 0px 40px;
    }
    .insta-box .insta-img-wrap .img {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 767px) {
    .insta-box {
        padding: 0px 15px;
    }
    .insta-box.col-xs-12 {
        padding: 0px 30px;
    }
    .insta-box .insta-img-wrap {
        -ms-flex-wrap: wrap;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -ms-flex-pack: distribute;
        -webkit-justify-content: space-around;
        justify-content: space-around;
    }
    .insta-box .insta-img-wrap a {
        -ms-flex-preferred-size: 50%;
        -webkit-flex-basis: 50%;
        flex-basis: 50%;
    }
    .insta-box .insta-img-wrap a .img {
        margin-left: auto;
        margin-right: auto;
    }
}

.portfolio-single-content {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: #585858;
    line-height: 1.7;
    letter-spacing: .7px;
}

body .no-padd {
   padding-left: 0;padding-right: 0;
}

@media (min-width: 1200px) {
   body .hero>.container {
        padding: 0  ;
    }
   body .no-padd-md {
        padding: 0  ;
    }
}

@media (max-width: 992px) {
   body  #google-map {
        min-height: 310px  ;
    }
    .top-banner .banner-text {
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 991px) {
    .banner-gallery .content-wrap .content {
        padding: 15px 30px;
    }
    .banner-gallery .content-wrap .title {
        font-size: 35px;
        letter-spacing: 3px;
        line-height: 50px;
    }
    .about-details {
        text-align: center;
    }
}

@media (min-width: 768px) {
  body  .pad-sm-l-0 {
        padding-left: 0 ;
    }
  body  .pad-sm-r-0 {
        padding-right: 0 ;
    }
    #contactform input,
    .comments-form input {
        width: 48.5%;
        float: left;
    }
    #contactform input[name="author"],
    .comments-form input[name="author"] {
        margin-right: 3%;
    }
}

@media (max-width: 768px) {
    .post-details .title {
        line-height: 1.2;
    }
    .top-banner {
        height: 100vmin;
        min-height: 500px;
    }
    .top-banner.center_content {
        min-height: 300px;
    }
    .top-banner .banner-text {
        position: relative;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
}

@media (max-width: 767px) {
    .wpcf7 form input,
    form.wpcf7-form input {
        margin-bottom: 10px;
    }
  body  .no-pad-xs {
        padding: 0 ;
    }
    .action {
        padding: 27px 30px;
        text-align: center;
    }
    .action .button {
        position: static;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        margin-top: 20px;
        padding: 1px 39px;
    }
    .banner-gallery::before {
        content: '';
        background-color: rgba(255, 255, 255, 0.9);
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
    }
  body  .banner-gallery .content-wrap {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        background-repeat: no-repeat  ;
        background-size: cover  ;
        background-position: center  ;
        padding: 30px 15px;
        margin-left: 0;
    }
    .banner-gallery .content-wrap .content {
        padding: 0;
    }
}

@media (max-width: 767px) {
 body   #google-map,
  body  .contact-info {
        float: none;
        width: 100%;
        height: auto  ;
    }
  body  #google-map {
        min-height: 300px  ;
        height: 300px  ;
    }
    .contact-info .details {
        margin-left: 0;
    }
    .contact-info {
        margin-top: 30px;
        text-align: center;
        padding: 30px 15px;
    }
    .top-banner .banner-text {
        max-width: 90%;
        padding: 0 15px;
    }
    .top-banner .banner-text .title {
        font-size: 28px;
    }
    .hero {
        padding: 0;
    }
    .top-banner .subtitle {
        letter-spacing: 5px;
    }
    .about-details .content .text blockquote,
    .about-section .content blockquote,
    .portfolio-single-content blockquote {
        padding: 23px 25px 14px;
    }
    .about-details .content .text p {
        margin-bottom: 15px;
    }
    .about-details .content .text blockquote,
    .about-section .content blockquote,
    .portfolio-single-content blockquote {
        margin-top: 13px;
    }
    .about-details .content .button {
        margin-top: 5px;
    }
    .about-details .content .subtitle {
        letter-spacing: 5px;
    }
    .team-member .avatar {
        height: 300px;
    }
    .team-member .info .title {
        font-size: 22px;
    }
    .banner-gallery .content-wrap .title {
        font-size: 30px;
        margin-bottom: 30px;
        line-height: 40px;
    }
}

@media (max-width: 480px) {
    .single blockquote,
    .about-details .content blockquote,
    .about-section .content blockquote,
    .portfolio-single-content blockquote {
        padding: 26px 26px 26px 36px;
    }
    .pagination {
        margin: 15px 0 0;
    }
    .comments {
        margin-top: 28px;
        text-align: center;
    }
    .comments .person .comment-date {
        display: block;
    }
    .comments .content {
        padding-right: 0;
    }
    .comments .person img {
        float: none;
        display: block;
        margin: auto auto 15px;
    }
    .comments .content .comment-reply-link {
        top: auto;
        bottom: -15px;
    }
    .comments .comment {
        margin-bottom: 30px;
    }
    .comments-title {
        margin-bottom: 20px;
    }
    .comments-form {
        margin-bottom: 60px;
    }
    .post-details,
    .single-content {
        text-align: center;
    }
    .post-details .title {
        font-size: 30px;
    }
    .single blockquote,
    .about-details .content blockquote,
    .about-section .content blockquote,
    .portfolio-single-content blockquote {
        font-size: 16px;
        padding: 26px;
    }
    .single-content p {
        margin-bottom: 15px;
    }
    .about-section {
        padding: 30px 15px;
    }
    .about-section .title {
        font-size: 26px;
    }
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 1.75em;
    table-layout: fixed;
    width: 100%;
}

table,
th,
td {
    border: 1px solid #d1d1d1;
}

dd {
    margin: 0 0 1.75em;
}

address {
    font-style: italic;
}

abbr,
acronym {
    border-bottom: 1px dotted #d1d1d1;
    cursor: help;
}

code {
    background-color: #d1d1d1;
    color: #131313;
}

mark,
ins {
    background: #007acc;
    color: #ffffff;
    padding: 0.125em 0.25em;
    text-decoration: none;
}

.price ins {
    background-color: transparent;
}

kbd {
    background-color: transparent;
    color: #131313;
    font-weight: 100;
    box-shadow: none;
    opacity: .9;
}

.alignleft {
    float: left;
    margin: 0.375em 1.75em 1.75em 0;
}

.alignright {
    float: right;
    margin: 0.375em 0 1.75em 1.75em;
}

.wp-caption {
    margin-bottom: 1.75em;
    max-width: 100%;
}

.wp-caption .wp-caption-text {
    font-style: italic;
    opacity: .9;
    font-size: 13px;
}


/*PAGE NAVIGATION */

.post-nav,
.pager-pagination {
    text-align: center;
    clear: both;
    font-family: "Montserrat", sans-serif;
    //margin: 0 0 1.75em;
    padding: 50px 0;
}

.post-nav a,
.pages,
.page-numbers:not('.next, .prev') {
    border: 1px solid #131313;
    border-radius: 2px;
    display: inline-block;
    font-size: 13px;
    height: 24px;
    line-height: 22px;
    margin-right: 4px;
    text-align: center;
    width: 24px;
    color: #ffffff;
    text-decoration: none;
    background-color: #131313;
    @extend %dark-black-bg-color;
    @extend %base-color;
}

.post-nav a:hover,
.post-nav a:focus,
.page-numbers:hover,
.page-numbers:focus {
    opacity: .7;
    border-color: transparent;
    color: #ffffff;
    text-decoration: none;
    @extend %base-color;
}

body .next.page-numbers,
body .prev.page-numbers {
    font-family: "Montserrat", sans-serif;
    text-decoration: none;
    background-color: transparent  ;
    color: #131313;
    @extend %base-color;
}

.prev.page-numbers {
    padding-right: 10px;
}

.next.page-numbers {
    padding-left: 10px;
}

.next.page-numbers:hover,
.prev.page-numbers:hover {
    color: #131313;
    @extend %base-color;
}

.post-nav .pages,
.post-nav .current,
.pager-pagination .pages,
.pager-pagination .current {
    opacity: .7;
    border-color: transparent;
    color: #ffffff;
    @extend %base-color;
}

.post-nav a .pages {
    opacity: 1;
}

.post-nav a .pages {
    opacity: 1;
}

@media screen and (max-width: 991px) {
    .post-nav,
    .pager-pagination {
        padding: 30px 0;
    }
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
    padding: 0 10px;
    background: #131313;
    border-color: #131313;
    color: #ffffff;
    transition: all 300ms ease;
    border-radius: 0px;
}

button:hover,
html input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover {
    opacity: .8;
}

figure.gallery-item {
    display: inline-block;
    max-width: 33.33%;
    text-align: center;
    overflow: visible;
    vertical-align: top;
    width: 100%;
    height: auto;
    padding: 0 1.1400652% 2.2801304%;
}

.gallery-caption {
    padding-top: 7px;
}

.gallery-columns-1 .gallery-item {
    max-width: 100%;
}

.gallery-columns-2 .gallery-item {
    max-width: 50%;
}

.gallery-columns-4 .gallery-item {
    max-width: 25%;
}

.gallery-columns-5 .gallery-item {
    max-width: 20%;
}

.gallery-columns-6 .gallery-item {
    max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
    max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
    max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
    max-width: 11.11%;
}

caption {
    color: #131313;
    opacity: .7;
}

div.wpcf7-response-output {
    margin: auto auto 20px;
    padding: 0;
}

.main-wrapper {
    overflow-x: hidden;
    position: relative;
}

body .display {
    display: block ;
}

.portfolio-single-content .social-list {
    text-align: right;
}

.portfolio-single-content .social-list li {
    margin: 0px 1px 0 11px;
}

.single-pagination {
    padding: 50px 0;
}

.single-pagination>div {
    display: inline-block;
}

.single-pagination>div.pag-prev {
    float: left;
}

.single-pagination>div.pag-prev a {
    padding-left: 20px;
}

.single-pagination>div.pag-prev a:hover i {
    left: 5px;
}

.single-pagination>div.pag-prev i {
    left: 0;
    transition: left 300ms ease;
}

.single-pagination>div.pag-next {
    float: right;
}

.single-pagination>div.pag-next a {
    padding-right: 20px;
}

.single-pagination>div.pag-next a:hover i {
    right: 5px;
}

.single-pagination>div.pag-next i {
    right: 0;
    transition: right 300ms ease;
}

.single-pagination>div a.content {
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
    color: #585858;
    line-height: 1.7;
    letter-spacing: .7px;
    text-decoration: none;
    position: relative;
    display: block;
    @extend %desc-color;
}

.single-pagination>div a.content:hover {
    color: #c0af94;
    @extend %subtitle-color;
}

.single-pagination>div a.content i {
    font-size: 18px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #c0af94;
    @extend %subtitle-color;
}

@media screen and (max-width: 991px) {
    .single-pagination {
        padding: 30px 0;
    }
}

@media screen and (max-width: 767px) {
    .single-pagination>div.pag-prev {
        float: left;
        margin-left: 15px;
    }
    .single-pagination>div.pag-next {
        float: right;
        margin-right: 15px;
    }
}


/*Another styles*/

.bypostauthor {
    font-size: inherit;
}

.aligncenter,
div.aligncenter {
    display: block;
    margin: 5px auto 5px auto;
}

.top-banner {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: auto;
}

.top-banner .subtitle {
    padding-top: 0;
}

.top-banner .content {
    margin-top: 150px;
    margin-bottom: 150px;
    z-index: 30;
}

.top-banner.left_content .content {
    margin-top: 213px;
    margin-bottom: 213px;
}

.top-banner.center_content .content {
    margin-top: 75px;
    margin-bottom: 60px;
}

.top-banner.full-height .content {
    margin-top: auto;
    margin-bottom: auto;
}

.protected-page form {
    padding-bottom: 280px;
}

.sm-wrap-post .content .title {
    padding-right: 15px;
    padding-left: 15px;
}

.post-content h5 {
    padding-right: 15px;
    padding-left: 15px;
}

@media screen and (max-width: 1200px) {
    .protected-page .protected-title {
        padding-top: 150px;
    }
    .protected-page form {
        padding-bottom: 150px;
    }
    .post-banner {
        height: 300px;
    }
}

@media (min-height: 600px) and (min-width: 1280px) {
    .protected-page .protected-title {
        padding-top: 100px;
    }
    .protected-page form {
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 992px) {
    .top-banner .content {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .top-banner.left_content .content {
        margin-top: 150px;
        margin-bottom: 150px;
    }
    .protected-page .protected-title {
        padding-top: 100px;
    }
    .protected-page form {
        padding-bottom: 100px;
    }
    .select-for-hovers .content {
        text-align: center;
    }
    .services {
        min-height: 280px;
    }
    .post-banner {
        height: 250px;
    }
    .single-post .title {
        font-size: 48px;
        line-height: 1.2;
    }
    .comments .comment-reply-title {
        padding-top: 0;
        font-size: 25px;
        line-height: 1.2;
    }
    .recent-post-single .recent-title {
        font-size: 25px;
        line-height: 1.2;
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

.top-banner .content {
    max-width: 1170px;
    position: relative;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
}

.top-banner.center_content {
    border-top: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    @extend %table-border-color;
}

.top-banner.center_content .content {
    text-align: center;
}

@media (max-width: 1199px) {
    .top-banner .content {
        max-width: 970px;
    }
}

@media (max-width: 991px) {
    .top-banner .content {
        max-width: 750px;
    }
}

@media (max-width: 767px) {
    .top-banner .content {
        padding: 0px 15px;
    }
}

.top-banner .title {
    line-height: 1.1;
}

@media screen and (max-width: 768px) {
    .single-post .title {
        font-size: 40px;
    }
    .single-post p {
        margin-bottom: 15px;
    }
    .contact-info {
        margin-top: 0px;
    }
    .contact-info .details h5,
    .contact-form h2 {
        font-size: 28px;
    }
    .wpcf7 form input[type="submit"],
    form.wpcf7-form #submit {
        margin-bottom: 0px;
    }
}

@media screen and (max-width: 767px) {
    .sm-wrap-post a {
        min-height: 150px;
    }
    .blog .sidebar-item {
        margin-right: 15px;
    }
}

@media screen and (max-width: 600px) {
    .top-banner .content,
    .top-banner.center_content .content {
        margin-top: 70px;
        margin-bottom: 70px;
    }
    .top-banner.left_content .content {
        margin-top: 100px;
        margin-bottom: 100px;
    }
    .protected-page .protected-title {
        padding-top: 60px;
        font-size: 26px;
        padding-bottom: 0;
    }
    .protected-page form {
        padding-bottom: 60px;
    }
    .protected-page input[type="submit"] {
        margin-top: 30px;
        margin-bottom: 10px;
    }
    .single-post .title {
        font-size: 30px;
    }
}

.error-height {
    height: 100vh;
}

body .top-banner.top_align .content {
    top: 0 ;
    margin-top: 0  ;
}


/* change */

body .post.no-image a:hover,
body .no-image.recent-simple-post a:hover {
    opacity: 1  ;
    transition: all 0.5s ease;
}

.post.no-image a:hover:before {
    opacity: 1;
    transition: all 0.5s ease;
}

.post.no-image a:hover .date {
    opacity: 1;
    transition: all 0.5s ease;
}

.post.no-image a:hover h5,
.no-image.recent-simple-post .sm-wrap-post a:hover .content {
    opacity: 1;
    transition: all 0.5s ease;
}

.empty-post-list {
    padding-bottom: 130px;
}

.search-no-results .main-wrapper {
    height: auto;
}

body .blog {
    height: auto  ;
}

.banner-list li span {
    background-size: cover;
}

.container.about,
.about-us .hero>.row.about-us,
.about-section {
    background-color: #f2f2f2;
}

.wpb_single_image img {
    height: auto;
    max-width: 100%;
    vertical-align: top;
}

.wpb_single_image {
    text-align: center;
}

.custom-row {
    margin-left: -30px;
    margin-right: -30px;
}

.just-gallery img {
    display: initial;
    max-width: none;
}

.pswp__caption__center {
    max-width: none;
    text-align: center;
    padding: 10px 40px;
}

.item-desc {
    letter-spacing: .8px;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.4px;
    font-family: "Montserrat", sans-serif;
    color: #888;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
}

.pswp__caption h4 {
    font-family: "BebasNeueRegular";
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    letter-spacing: 1.92px;
    margin-bottom: 3px;
}

.pswp__img {
    top: 23px;
}

.pswp__img:hover {
    cursor: -webkit-grab;
    cursor: -moz-grab;
    cursor: -o-grab;
    cursor: -ms-grab;
    cursor: grab;
}


/* 10 hovers animation */

.select-for-hovers h5 {
    display: inline-block;
    padding: 2px 8px;
    font-weight: 400;
    text-decoration: none;
    font-size: 12px;
    line-height: 28px;
    font-family: "Libre Baskerville", sans-serif;
    color: #888888;
    margin: auto;
    letter-spacing: 1.12px;
}

.select-for-hovers .hover>span {
    position: relative;
    padding: 1px 20px 1px 10px;
    display: inline-block;
    font-weight: 400;
    border: 1px solid #888888;
    text-decoration: none;
    min-width: 200px;
    text-align: left;
    cursor: pointer;
    font-size: 12px;
    line-height: 28px;
    font-family: "Libre Baskerville", sans-serif;
    color: #888888;
    margin: auto;
    letter-spacing: 1.12px;
}

.select-for-hovers .hover>span::after {
    content: "\f107";
    font-family: 'FontAwesome';
    position: absolute;
    right: 5px;
}

.select-for-hovers {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
    text-align: right;
    margin-top: 20px;
}

.select-for-hovers .content {
    position: relative;
    text-align: right;
    width: 100%;
}

.select-for-hovers .hover {
    display: inline-block;
    position: relative;
}

.select-for-hovers .hover .list {
    text-align: left;
    position: absolute;
    background-color: #fff;
    margin-left: 0;
    z-index: 10;
    width: 100%;
    border: 1px solid #131313;
    display: none;
}

.select-for-hovers .hover .list li {
    list-style: none;
    font-weight: 400;
    padding: 1px 8px;
    cursor: pointer;
    background-color: transparent;
    transition: all 350ms ease;
    font-size: 12px;
    line-height: 28px;
    font-family: "Libre Baskerville", sans-serif;
    color: #888888;
    margin: auto;
    letter-spacing: 1.12px;
}

.select-for-hovers .hover .list li:hover {
    background-color: rgba(0, 0, 0, .1);
}


/* end 10 hovers animation */

.sliphover-container {
    font-family: "BebasNeueRegular";
    font-size: 24px;
    line-height: 30px;
    color: #fff;
    font-weight: 400;
    letter-spacing: 1.92px;
}