/* 26. SIDEBAR
-----------------*/
.sidebar-item {
  margin-bottom: 50px;
}
.sidebar-item > h5,
.sidebar-item .screen-reader-text {
  letter-spacing: .75px;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 21px;
  line-height: 21px;
  display: block;
}
.sidebar-item .screen-reader-text,
.screen-reader-text{
  display: none;
}
.widget_search label {
  display: block;
  width: 100%;
}
.widget_search .searchform {
  position: relative;
}
.widget_search input[type="text"] {
  background-color: #ffffff;
  border: 1px solid #131313;
  border-radius: 2px;
  color: #131313;
  padding: 5px 70px 5px 10px;
  font-weight: 400;
  width: 100%;
  @extend %base-border-color;
  @extend %base-color;
}
.widget_search input[type="submit"] {
  background: #131313;
  border: 1px solid #131313;
  border-radius: 2px;
  color: #ffffff;
  padding: 5px 10px;
  font-weight: 400;
  transition: all 300ms ease;
  position: absolute;
  right: 0;
  bottom: 0;
  @extend %base-bg-color;
  @extend %base-border-color;
  @extend %font-color;
}
.widget_search input[type="submit"]:hover {
  color: #131313;
  background: #ffffff;
}
.widget_rss .rss-date {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  opacity: .7;
}
.widget_rss ul li {
  margin-bottom: 15px;
}
.widget_tag_cloud .tagcloud a {
  border: 1px solid #131313;
  padding: 2px 5px;
  text-decoration: none;
  margin: 3px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  vertical-align: top;
  font-weight: 600;
  @extend %base-border-color;
}
.widget_calendar table td,
.widget_calendar table th {
  text-align: center;
}
.sidebar-item:first-child {
  margin-top: 0;
}
.sidebar-item h5 {
  padding: 0px 7px 10px 3px;
  width: 100%;
  border-bottom: 1px solid #131313;
  font-family: "Montserrat", sans-serif;
  @extend %base-border-color;
}
.page ol,
.page ul,
.page ul,
.single-content ol,
.single-content ul,
.sidebar-item ul {
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
}
.sidebar-item ul li {
  color: rgba(19, 19, 19, 0.7);
  @extend %base-color;
}
.sidebar-item ul li a {
  text-decoration: none;
  color: #131313;
  @extend %base-color;
}
.sidebar-item select {
  max-width: 100%;
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
  @extend %base-color;
}
.sidebar-item select option {
  max-width: 255px;
  width: 100%;
  @extend %font-color;
}
.sidebar-item input {
  padding: 4px 5px 6px 9px;
  border: 1px solid #a6a6a6;
  font-family: "Montserrat", sans-serif;
  @extend %base-border-color;
  @extend %font-color;
}
.page ul.slides {
  margin-left: 0;
}
.post-banner {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.post-details .date-post {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  margin-top: 20px;
  letter-spacing: .6px; }

.post-details .title {
  font-size: 44px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 12px;
  letter-spacing: 3px;
}
.pagination a.img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 150px;
  width: 100%;
  display: block;
  opacity: 1;
  margin: 0;
  background-color: #000;
  text-decoration: none;
  padding-top: 87px;
  margin-bottom: 5px;
}
