
.about-section {
  position: relative;
  min-height: 420px;
  background-color: #f2f2f2;
  @extend %dark-black-bg-color;
}
@media (max-width: 767px) {
  .about-section {
    padding: 15px;
    padding-bottom: 30px;
    text-align: center;
  }
}
.about-section .person-wrap {
  float: left;
  height: 420px;
  width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 70px;
}
@media (max-width: 1199px) {
  .about-section .person-wrap {
    width: 400px;
    margin-right: 60px;
  }
}
@media (max-width: 991px) {
  .about-section .person-wrap {
    width: 345px;
    margin-right: 30px;
  }
}
@media (max-width: 767px) {
  .about-section .person-wrap {
    float: none;
    width: 100%;
    max-width: 480px;
    height: 300px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}
.about-section .content {
  padding-top: 60px;
  padding-right: 70px;
}
@media (max-width: 991px) {
  .about-section .content {
    padding-top: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .about-section .content {
    padding: 0px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}
.about-section .subtitle {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  color: #c0af94;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
  -webkit-font-smoothing: antialiased;
  @extend %subtitle-color;
}
.about-section .title {
  font-size: 36px;
  font-family: "BebasNeueRegular", sans-serif;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 2.88px;
  margin-bottom: 20px;
  color: #131313;
  @extend %base-color;
}
.about-section .descr {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1.12px;
  color: #888888;
  margin-bottom: 30px;
  @extend %desc-color;
}
.about-section .a-btn-2 {
  color: #131313;
  @extend %base-color;
}
.about-section .a-btn-2:hover {
  color: #ffffff;
  @extend %font-color;
}