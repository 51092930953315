.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select {
    border-color: #984f4f;
}

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
    border-color: #364e24;
}


/*================================================*/


/* PRODUCT PRICE */


/*================================================*/

.woocommerce .napoli_product_detail div.product p.price,
.woocommerce .napoli_product_detail div.product span.price,
.woocommerce ul.products.default li.product .price,
.napoli_cart.shop_table ul .cart_item ul .product-price,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price,
.woocommerce table.shop_table .cart_item .product-total {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.40px;
    font-weight: 600;
    color: #c0af94;
    margin-bottom: 0;
    @extend %subtitle-color;
}

.woocommerce .napoli_product_detail div.product p.price del,
.woocommerce .napoli_product_detail div.product span.price del,
.woocommerce ul.products.default li.product .price del,
.napoli_cart.shop_table ul .cart_item ul .product-price del,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal del,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price del,
.woocommerce table.shop_table .cart_item .product-total del {
    color: #d4d4d4;
    opacity: 1;
    display: inline-block;
    padding-right: 10px;
    @extend %desc-color;
}

.woocommerce .napoli_product_detail div.product p.price ins,
.woocommerce .napoli_product_detail div.product span.price ins,
.woocommerce ul.products.default li.product .price ins,
.napoli_cart.shop_table ul .cart_item ul .product-price ins,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal ins,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price ins,
.woocommerce table.shop_table .cart_item .product-total ins {
    padding: 0;
    color: #131313;
    font-weight: 600;
    @extend %base-color;
}


/*================================================*/


/* BUTTONS */


/*================================================*/

.woocommerce-page.woocommerce-cart .woocommerce input.button,
.woocommerce-page.woocommerce-checkout .woocommerce input.button {
    display: inline-block;
    width: auto;
    min-width: 144px;
    border-radius: 0;
    box-sizing: border-box;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.1px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px 20px;
    background-color: transparent;
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    color: #131313;
    transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;
    right: auto;
    top: auto;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    @extend %base-light-bottom-color;
    @extend %base-color;
}

.woocommerce-page.woocommerce-cart .woocommerce input.button:hover,
.woocommerce-page.woocommerce-checkout .woocommerce input.button:hover {
    border-top: 2px solid #131313;
    border-bottom: 2px solid #131313;
    background-color: #131313;
    color: #fff;
    opacity: 1;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}

.woocommerce-page.woocommerce-cart a.button,
.woocommerce-page.woocommerce-checkout a.button,
.woocommerce-page.woocommerce a.button,
.woocommerce-page.woocommerce button.button.alt,
a.button.checkout {
    display: inline-block;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.1px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 8px 20px;
    text-transform: uppercase;
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    color: #131313;
    border-radius: 0;
    background-color: transparent;
    margin-top: 0;
    opacity: 1;
    @extend %base-light-bottom-color;
    @extend %base-color;
}

.woocommerce-page.woocommerce-cart a.button:hover,
.woocommerce-page.woocommerce-checkout a.button:hover,
.woocommerce-page.woocommerce a.button:hover,
.woocommerce-page.woocommerce button.button.alt:hover,
a.button.checkout:hover {
    border-top: 2px solid #131313;
    border-bottom: 2px solid #131313;
    background-color: #131313;
    color: #fff;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a,
.woocommerce-page.woocommerce .woocommerce-message a.button {
    display: inline-block;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.1px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 8px 20px;
    text-transform: uppercase;
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    color: #ffffff;
    border-radius: 0;
    background-color: transparent;
    margin-top: 0;
    opacity: 1;
    @extend %base-light-bottom-color;
    @extend %base-color;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a:hover,
.woocommerce-page.woocommerce .woocommerce-message a.button:hover {
    opacity: 1;
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    color: #000;
    background-color: #ffffff;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart .added_to_cart {
    display: none;
}


/*================================================*/


/* PRODUCT RATING */


/*================================================*/

.woocommerce .napoli_product_detail .star-rating {
    font-size: 12px;
    height: 20px;
    color: #131313;
    @extend %base-color;
}

.woocommerce .napoli_product_detail .star-rating:before {
    color: #e6e6e6;
}


/*================================================*/


/* PRODUCT ONSALE */


/*================================================*/

.woocommerce .napoli_images span.onsale,
.woocommerce ul.products li.product .napoli-prod-list-image .onsale {
    position: absolute;
    top: 30px;
    left: 30px;
    bottom: auto;
    right: auto;
    border-radius: 0;
    display: inline-block;
    margin: 0;
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.40px;
    font-weight: 400;
    background-color: #c0af94;
    color: #fff;
    text-transform: uppercase;
    padding: 8px 20px;
    min-height: auto;
    min-width: auto;
    @extend %subtitle-bg-color;
    @extend %base-color;
}


/*================================================*/


/* PRODUCT QUANTITY */


/*================================================*/

.woocommerce .quantity .qty {
    width: 80px;
    height: 40px;
    margin-right: 22px;
    border: 2px solid #f2f2f2;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    font-weight: 700;
    color: #131313;
    padding-right: 7px;
    background-color: transparent;
    @extend %table-border-color;
    @extend %base-color;
}


/*================================================*/


/* PRODUCT MESSAGE */


/*================================================*/

.woocommerce .woocommerce-message,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-error {
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 40px;
    border-top: 4px solid #c0af94;
    background-color: #c0af94;
    color: #f9f5ee;
    @extend %subtitle-border-color;
    @extend %subtitle-bg-color;
}

.woocommerce .woocommerce-error {
    background-color: #984f4f;
    border-top: 4px solid #984f4f;
}

.woocommerce .woocommerce-message:before,
.woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-error:before {
    color: #f9f5ee;
}

.woocommerce .woocommerce-message .showcoupon,
.woocommerce .woocommerce-message a,
.woocommerce .woocommerce-info .showcoupon,
.woocommerce .woocommerce-info .showlogin {
    color: #fff;
    @extend %base-color;
}

@media only screen and (max-width: 767px) {
    body .woocommerce .woocommerce-message,
    body .woocommerce .woocommerce-info,
    body .woocommerce .woocommerce-error {
        margin-left: 15px;
        margin-right: 15px;
    }
    .woocommerce .woocommerce-message,
    .woocommerce .woocommerce-info,
    .woocommerce .woocommerce-error {
        line-height: 20px;
    }
}


/*================================================*/


/* PRODUCT DETAIL PAGE */


/*================================================*/

.napoli_product_detail {
    margin-top: 0;
    margin-bottom: 20px;
}


/* PRODUCT DETAILS PAGE - IMAGES */

.napoli_product_detail .product .napoli_images {
    width: 52%;
    float: left;
    max-width: 600px;
    margin-top: 5px;
    position: relative;
}

.napoli_product_detail .product .napoli_images a {
    display: block;
}


/* PRODUCT DETAILS PAGE - SLIDER */

.napoli_images .swiper-slide a {
    display: block;
    min-height: 600px;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
}

.napoli_images .pagination {
    position: absolute;
    bottom: 22px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    margin: 0;
    width: auto;
}

.napoli_images .pagination .swiper-pagination-switch {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0 5px;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0.4;
    cursor: pointer;
    transition: opacity 0.3s ease;
}

.napoli_images .pagination .swiper-pagination-switch:hover {
    opacity: 0.7;
}

.napoli_images .pagination .swiper-active-switch {
    opacity: 1;
}


/* PRODUCT DETAILS PAGE - INFO */

.napoli_product_detail .product .summary.entry-summary {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    max-width: 500px;
    margin-bottom: 0;
}


/* PRODUCT DETAILS PAGE - PRICE */

.napoli_product_detail .product_price {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    width: 40%;
    margin-bottom: 27px;
}


/* PRODUCT DETAILS PAGE - RATING */

.napoli_product_detail .product .summary .woocommerce-product-rating {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    width: 60%;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 0;
}

.napoli_product_detail .product .summary .woocommerce-product-rating .star-rating {
    margin-top: 3px;
    margin-right: 5px;
}

.napoli_product_detail .product .summary .woocommerce-product-rating .woocommerce-review-link {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.40px;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    color: #888888;
    @extend %desc-color;
}


/* PRODUCT DETAILS PAGE - TITLE */

.napoli_product_detail .product .summary .product_title {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 3;
    -ms-flex-order: 3;
    order: 3;
    width: 100%;
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: 2.88px;
    margin-bottom: 20px;
    color: #131313;
    @extend %base-color;
}


/* PRODUCT DETAILS PAGE - DESCRIPTION */

.napoli_product_detail .product .summary .product_desc {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 4;
    -ms-flex-order: 4;
    order: 4;
    width: 100%;
    margin-bottom: 22px;
}

.napoli_product_detail .product .summary .product_desc p {
    width: 100%;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    margin-bottom: 0;
    color: #888888;
    @extend %desc-color;
}


/* PRODUCT DETAILS PAGE - FORM */

.napoli_product_detail .product .summary .cart {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 6;
    -ms-flex-order: 6;
    order: 6;
    width: 100%;
    margin-bottom: 33px;
}

.napoli_product_detail .product .summary .variations_form.cart {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.napoli_product_detail .product .summary .cart .variations {
    margin-bottom: 42px;
    table-layout: auto;
}

.napoli_product_detail .product .summary .cart .group_table {
    border: 0;
    margin-bottom: 42px;
    table-layout: auto;
}

.napoli_product_detail .product .summary .cart .group_table td.label {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1.4px;
    font-weight: 400;
    color: #fff;
    text-align: left;
}

.napoli_product_detail .product .summary .cart .variations .label {
    padding: 0;
}

.napoli_product_detail .product .summary .cart .variations .label label {
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.1px;
    font-weight: 400;
    text-transform: uppercase;
    color: #131313;
    margin-bottom: 14px;
    @extend %base-color;
}

.napoli_product_detail .product .summary .cart .variations .value {
    padding: 0;
}

.napoli_product_detail .product .summary .cart .variations .value fieldset {
    border-top: 4px solid #f2f2f2;
    border-bottom: 4px solid #f2f2f2;
    @extend %table-border-color;
}

.napoli_product_detail .product .summary .cart .variations .value ul {
    list-style-type: none;
    margin-bottom: 0;
    padding: 18px 0;
}

.napoli_product_detail .product .summary .cart .variations .value ul li:not(:last-child) {
    margin-bottom: 7px;
}

.napoli_product_detail .product .summary .cart .variations .value ul li input {
    display: none;
}

.napoli_product_detail .product .summary .cart .variations .value ul li label {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 29px;
    margin-right: 0;
    font-family: "Libre Baskerville";
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #131313;
    margin-bottom: 0;
    min-width: 121px;
    @extend %base-color;
}

.napoli_product_detail .product .summary .cart .variations .value ul li label:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #e6e6e6;
}

.napoli_product_detail .product .summary .cart .variations .value ul li input:checked + label:before {
    background-color: #c0af94;
    @extend %subtitle-bg-color;
}

.napoli_product_detail .product .summary .cart .variations .value ul li p {
    display: inline-block;
    margin-bottom: 0;
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.9px;
    font-weight: 400;
    color: #969696;
    position: relative;
    @extend %desc-color;
}

.napoli_product_detail .product .summary .cart .variations .value ul li .price {
    float: right;
    line-height: 28px;
}

.napoli_product_detail .single_variation_wrap {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.napoli_product_detail .single_variation {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    width: 50%;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.napoli_product_detail .variations_button {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    width: 50%;
}

.napoli_product_detail .product .summary .cart .qty {}

.napoli_product_detail .product .summary .cart .button {
    float: none;
}

body .napoli_product_detail .product .summary .cart .button:hover {
    color: #fff;
}


/* PRODUCT DETAILS PAGE - META */

.napoli_product_detail .product .summary .product_meta {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 7;
    -ms-flex-order: 7;
    order: 7;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    @extend %desc-color;
}

.napoli_product_detail .product .summary .product_meta a {
    text-decoration: none;
    color: #c0af94;
    @extend %subtitle-color;
}

.napoli_product_detail .product .summary .product_meta .sku_wrapper {
    width: 100%;
}

.napoli_product_detail .product .summary .product_meta .posted_in {
    width: 100%;
}

@media only screen and (max-width: 1199px) {
    .napoli_product_detail .product .napoli_images {
        max-width: 500px;
    }
    .napoli_product_detail .product .summary.entry-summary {
        max-width: 440px;
    }
    /* PRODUCT DETAILS PAGE - FORM */
    .napoli_product_detail .product .summary .cart .variations .value ul li {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: nowrap;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
    .napoli_product_detail .product .summary .cart .variations .value ul li label {
        min-width: 100px;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
    .napoli_product_detail .product .summary .cart .variations .value ul li p {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
    }
    .napoli_product_detail .product .summary .cart .variations .value ul li .price {
        float: none;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
    }
}

@media only screen and (max-width: 991px) {
    .napoli_product_detail .product .napoli_images {
        width: 50%;
        max-width: 360px;
    }
    .napoli_images .swiper-slide a {
        min-height: 360px;
    }
    .napoli_product_detail .product .summary.entry-summary {
        width: 50%;
        max-width: 360px;
    }
}

@media only screen and (max-width: 768px) {
    .napoli_product_detail .product .napoli_images {
        float: none;
        width: 100%;
        max-width: 570px;
        margin-left: auto;
        margin-right: auto;
    }
    .napoli_product_detail .product .napoli_images a {
        text-align: center;
    }
    .napoli_images .swiper-slide a {
        min-height: 570px;
    }
    .napoli_product_detail .product .summary.entry-summary {
        width: 100%;
        max-width: none;
        margin-top: 30px;
    }
    .napoli_product_detail .product .summary .variations_form.cart {
        display: inline-block;
        width: auto;
        min-width: 500px;
    }
}

@media only screen and (max-width: 767px) {
    .napoli_product_detail .product {
        padding: 0 15px;
    }
    .napoli_images .swiper-slide a {
        min-height: 360px;
    }
    .napoli_product_detail .product .summary .woocommerce-product-rating {
        width: 100%;
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
    }
    .napoli_product_detail .product_price {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        width: 100%;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 600px) {
    .napoli_product_detail .product .summary .variations_form.cart {
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        min-width: auto;
    }
    .napoli_product_detail .single_variation_wrap {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media only screen and (max-width: 400px) {
    .napoli_product_detail .single_variation {
        -webkit-order: 1;
        -ms-flex-order: 1;
        order: 1;
        width: 100%;
        margin-bottom: 30px;
    }
    .napoli_product_detail .variations_button {
        -webkit-order: 2;
        -ms-flex-order: 2;
        order: 2;
        width: 100%;
    }
}


/*================================================*/


/* PRODUCT DETAIL PAGE TABS */


/*================================================*/

.napoli_product_detail .product .woocommerce-tabs {
    padding-top: 60px;
}

.napoli_product_detail .product .woocommerce-tabs .tabs.wc-tabs {
    padding: 0;
    margin-bottom: 34px;
}

.napoli_product_detail .product .woocommerce-tabs .tabs.wc-tabs:before {
    border-bottom: 4px solid #f2f2f2;
    @extend %form-border-color;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li {
    background-color: transparent;
    border: none;
    border-radius: 0;
    margin: 0;
    padding: 0 15px;
    border-bottom: 4px solid transparent;
    transition: border-bottom 0.3s ease;
    position: relative;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li a {
    font-family: "Montserrat", sans-serif;
    font-size: 11px;
    line-height: 24px;
    letter-spacing: 2.20px;
    font-weight: 400;
    color: #888888;
    text-transform: uppercase;
    padding: 12px 0;
    @extend %desc-color;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li.active {
    border-bottom: 4px solid #c0af94;
    transition: border-bottom 0.3s ease;
    @extend %subtitle-border-color;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li:before,
.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li:after {
    display: none;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li.active a {
    color: #131313;
    @extend %base-color;
}


/* PRODUCT DETAILS PAGE - TABS PANEL*/

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel {
    border: none;
    box-shadow: none;
    background-color: transparent;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel h2 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 2.88px;
    font-weight: 400;
    margin-bottom: 26px;
    color: #131313;
    @extend %base-color;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel p {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    margin-bottom: 0;
    @extend %desc-color;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel table {
    border: none;
    border-collapse: collapse;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel table th,
.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel table td {
    border: none;
}


/* PRODUCT DETAILS PAGE - TABS PANEL REVIEWS*/

.napoli_product_detail .product .woocommerce-Reviews {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


/* PRODUCT DETAILS PAGE - TABS PANEL COMMENTS*/

.napoli_product_detail .product .woocommerce-Reviews #comments {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    width: 70%;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment {
    margin-bottom: 20px;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .avatar {
    width: 70px;
    padding: 0;
    background-color: transparent;
    border: none;
    margin-right: 20px;
    margin-top: 6px;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text {
    margin: 0 0 0 90px;
    border: none;
    border-radius: 0;
    padding: 0;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .star-rating {
    margin-top: 13px;
    margin-right: 20px;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .meta {
    font-family: "BebasNeueRegular";
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.92px;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 7px;
    text-transform: uppercase;
    color: #131313;
    @extend %base-color;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .meta strong {
    font-weight: inherit;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .date_publish {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.40px;
    font-weight: 400;
    color: #c0af94;
    margin-left: 10px;
    @extend %subtitle-color;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .description {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    @extend %desc-color;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .description p {
    margin: 0;
}


/* PRODUCT DETAILS PAGE - TABS PANEL FORM*/

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    width: 30%;
    padding-left: 50px;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-reply-title {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 1.92px;
    font-weight: 400;
    margin-top: 8px;
    color: #131313;
    @extend %base-color;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper #respond .comment-form-rating {
    margin: 10px 0 15px 0;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-rating label {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 1.50px;
    font-weight: 400;
    color: #c0af94;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-right: 15px;
    @extend %subtitle-color;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper #respond .comment-form-rating .stars {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 0;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-rating .stars a {
    height: 20px;
    vertical-align: middle;
}

body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-comment,
body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-author,
body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-email {
    margin: 0 0 12px;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper input,
.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper textarea {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    width: 100%;
    border: none;
    background-color: transparent;
    padding: 10px 0;
    border-bottom: 1px solid #f2f2f2;
    @extend %form-border-color;
    @extend %desc-color;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper textarea {
    resize: vertical;
}

body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .form-submit {
    margin-top: 46px;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit {
    display: inline-block;
    width: auto;
    min-width: 144px;
    border-radius: 0;
    box-sizing: border-box;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.1px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 8px 20px;
    background-color: transparent;
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    color: #131313;
    transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;
    @extend %base-light-bottom-color;
    @extend %base-color;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit:hover {
    border-top: 2px solid #131313;
    border-bottom: 2px solid #131313;
    background-color: #131313;
    color: #fff;
    opacity: 1;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}

@media only screen and (max-width: 1199px) {
    .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
        padding-left: 30px;
    }
}

@media only screen and (max-width: 991px) {
    .napoli_product_detail .product .woocommerce-Reviews #comments {
        width: 65%;
    }
    .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
        width: 35%;
    }
}

@media only screen and (max-width: 767px) {
    .napoli_product_detail .product .woocommerce-Reviews #comments {
        width: 100%;
    }
    .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
        width: 100%;
        padding-left: 0;
        max-width: 300px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        margin-top: 40px;
    }
    .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper #respond .comment-form-rating {
        text-align: left;
    }
}


/*================================================*/


/* PRODUCT DETAIL PAGE RELATED PRODUCTS */


/*================================================*/

.napoli_product_detail .product div.related.products {
    padding-top: 16px;
}

.napoli_product_detail div.product .up-sells .products,
.napoli_product_detail .product .related.products .products {
    margin-top: 31px;
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
}


/* PRODUCT DETAILS PAGE - UP SELLS*/

.napoli_product_detail div.product .up-sells h2,
.napoli_product_detail .product .related.products h2 {
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 2.88px;
    font-weight: 400;
    color: #131313;
    @extend %base-color;
}

.napoli_product_detail div.product .up-sells .products li.product,
.woocommerce .napoli_product_detail .product div.related.products li.product {
    width: 33.333333%;
}

.napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
.woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
    min-height: 370px;
}

@media only screen and (max-width: 1199px) {
    .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
    .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
        min-height: 300px;
    }
}

@media only screen and (max-width: 991px) {
    .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
    .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
        min-height: 230px;
    }
}

@media only screen and (max-width: 767px) {
    .napoli_product_detail div.product .up-sells .products,
    .woocommerce .napoli_product_detail .product div.related.products .products {
        padding-left: 0;
        padding-right: 0;
    }
    .napoli_product_detail div.product .up-sells .products li.product,
    .woocommerce .napoli_product_detail .product div.related.products li.product {
        width: 100%;
    }
    .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
    .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
        min-height: 370px;
    }
}

@media only screen and (max-width: 350px) {
    .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
    .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
        min-height: 290px;
    }
}


/*================================================*/


/* PRODUCT LIST PAGE */


/*================================================*/

.woocommerce.woocommerce-page ul.products {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-top: 0;
    margin-bottom: 20px;
}

.woocommerce.woocommerce-page ul.products::before {
    position: absolute;
}

.woocommerce.woocommerce-page ul.products::after {
    position: absolute;
}

.woocommerce ul.products li.product {
    float: none;
    margin: 0 0 35px 0;
    padding: 0 15px;
    color: #888888;
    text-align: center;
    list-style: none;
    @extend %desc-color;
}

.woocommerce ul.products li.product .napoli-prod-list-image {
    position: relative;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    margin-bottom: 27px;
}

.woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
    margin-bottom: 22px;
}

.woocommerce ul.products li.product .napoli-prod-list-image:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.woocommerce ul.products li.product .napoli-prod-list-image:hover:after {
    opacity: 0.7;
}

.woocommerce ul.products li.product .napoli-prod-list-image:hover .product-links-wrapp {
    opacity: 1;
}

.woocommerce ul.products li.product .napoli-prod-list-image .product-links-wrapp {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
    z-index: 10;
    opacity: 0;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-link {
    display: inline-block;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    font-weight: bold;
    text-transform: uppercase;
    color: #c0af94;
    margin-top: 15px;
    opacity: 1;
    transition: opacity 0.3s ease;
    @extend %subtitle-color;
}

.woocommerce ul.products li.product .category-product a {
    display: inline-block;
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    margin-bottom: 4px;
    @extend %desc-color;
}

.woocommerce ul.products.gutt-col-2 li.product h3 {
    font-size: 36px;
    line-height: 40px;
}

.woocommerce ul.products li.product h3 {
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 2.88px;
    font-weight: 400;
    padding: 0;
    color: #131313;
    @extend %base-color;
}

.woocommerce ul.products li.product .price {
    margin-top: 10px;
}

.woocommerce ul.products.gutt-col-2 li.product .price {
    margin-top: 8px;
}

.woocommerce ul.products.gutt-col-2 li.product {
    width: 50%;
}

.woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
    min-height: 570px;
}

.woocommerce ul.products.gutt-col-3 li.product {
    width: 33.333333%;
}

.woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
    min-height: 370px;
}

.woocommerce ul.products.gutt-col-4 li.product {
    width: 25%;
}

.woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
    min-height: 270px;
}


/* PRODUCT DETAILS PAGE - PAGINATION*/

.napoli-woocommerce-pagination .posts-navigation .screen-reader-text {
    display: none;
}

.napoli-woocommerce-pagination .nav-links {
    padding: 30px 15px 70px;
}

.napoli-woocommerce-pagination .nav-links:after,
.napoli-woocommerce-pagination .nav-links:before {
    content: '';
    display: table;
    clear: both;
}

.napoli-woocommerce-pagination .nav-links .nav-previous {
    width: 50%;
    float: left;
}

.napoli-woocommerce-pagination .nav-links .nav-next {
    width: 50%;
    float: right;
    text-align: right;
}

.napoli-woocommerce-pagination .nav-links .nav-previous a,
.napoli-woocommerce-pagination .nav-links .nav-next a {
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 2.40px;
    font-weight: 400;
    text-decoration: none;
    color: #585858;
    @extend %desc-color;
}

.napoli-woocommerce-pagination .nav-links .nav-previous a:hover,
.napoli-woocommerce-pagination .nav-links .nav-next a:hover {
    color: #c0af94;
    @extend %subtitle-color;
}

@media only screen and (max-width: 1199px) {
    .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
        min-height: 470px;
    }
    .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
        min-height: 300px;
    }
    .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
        min-height: 220px;
    }
}

@media only screen and (max-width: 991px) {
    .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
        min-height: 360px;
    }
    .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
        min-height: 230px;
    }
    .woocommerce ul.products.gutt-col-4 li.product {
        width: 33.333333%;
    }
    .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
        min-height: 230px;
    }
    .napoli-woocommerce-pagination .nav-links {
        padding: 10px 30px 30px;
    }
}

@media only screen and (max-width: 767px) {
    .woocommerce.woocommerce-page ul.products {
        padding-left: 15px;
        padding-right: 15px;
    }
    .woocommerce ul.products.gutt-col-3 li.product {
        width: 50%;
    }
    .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
        min-height: 360px;
    }
    .woocommerce ul.products.gutt-col-4 li.product {
        width: 50%;
    }
    .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
        min-height: 360px;
    }
    .napoli-woocommerce-pagination .nav-links .nav-previous a,
    .napoli-woocommerce-pagination .nav-links .nav-next a {
        letter-spacing: 1.8px;
    }
}

@media only screen and (max-width: 650px) {
    .woocommerce ul.products.gutt-col-2 li.product {
        width: 100%;
    }
    .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
        min-height: 370px;
    }
    .woocommerce ul.products.gutt-col-3 li.product {
        width: 100%;
    }
    .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
        min-height: 370px;
    }
    .woocommerce ul.products.gutt-col-4 li.product {
        width: 100%;
    }
    .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
        min-height: 370px;
    }
}

@media only screen and (max-width: 350px) {
    .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
        min-height: 290px;
    }
    .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
        min-height: 290px;
    }
    .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
        min-height: 290px;
    }
}


/*================================================*/


/* SHOP ICON */


/*================================================*/

.mini-cart-wrapper {
    display: inline-block;
    position: relative;
    margin-left: 45px;
    vertical-align: middle;
}

.mini-cart-wrapper .napoli-shop-icon {
    text-decoration: none;
    background-color: #f0f0f0;
    padding: 0 15px;
    font-size: 13px;
    line-height: 33px;
}

.header_trans-fixed .mini-cart-wrapper .napoli-shop-icon {
    background-color: #fff;
    opacity: 1;
}

.mini-cart-wrapper .napoli-shop-icon:before {
    display: inline-block;
    line-height: 33px;
    color: #131313;
    @extend %subtitle-color;
}

.mini-cart-wrapper .napoli-shop-icon .cart-contents {
    display: inline-block;
}

.mini-cart-wrapper .napoli-shop-icon .cart-contents-count {
    display: inline-block;
    line-height: 33px;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 1.12px;
    color: #131313;
    padding-left: 3px;
    @extend %base-color;
}

.napoli_mini_cart {
    position: absolute;
    right: 0;
    top: 60px;
    display: block;
    background-color: #131313;
    opacity: 0;
    visibility: hidden;
    min-width: 330px;
    padding: 23px 30px;
    text-align: center;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    @extend %dark-black-bg-color;
}

.mini-cart-wrapper:hover .napoli_mini_cart {
    opacity: 1;
    visibility: visible;
}

#topmenu .napoli_mini_cart .product_list_widget {
    display: block;
}

#topmenu .napoli_mini_cart .product_list_widget .empty {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1.4px;
    font-weight: 400;
    color: #fff;
    @extend %base-color;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    padding: 0;
    margin-bottom: 23px;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini_cart_item_thumbnail {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 40%;
    max-width: 70px;
    margin-top: 7px;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini_cart_item_thumbnail a {
    padding: 0;
    display: block;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini_cart_item_thumbnail img {
    float: none;
    max-width: 70px;
    width: 100%;
    margin-left: 0;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    width: 60%;
    padding-left: 20px;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_name {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1.4px;
    font-weight: 400;
    color: #fff;
    text-align: left;
    padding: 0;
    @extend %base-color;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_quantity {
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.88px;
    font-weight: 400;
    color: #888888;
    margin-bottom: 3px;
    @extend %desc-color;
}

#topmenu .napoli_mini_cart a.button {
    display: inline-block;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 1.1px;
    font-weight: bold;
    box-sizing: border-box;
    padding: 8px 20px;
    text-transform: uppercase;
    opacity: 1;
    margin-top: 7px;
    margin-bottom: 7px;
    text-decoration: none;
    border-radius: 0;
    text-align: center;
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    color: #fff;
    background-color: transparent;
}

#topmenu .napoli_mini_cart a.button:hover {
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
    color: #131313;
    background-color: #fff;
}

@media only screen and (max-width: 1199px) {
    .mini-cart-wrapper {
        margin-left: 30px;
    }
}

@media only screen and (max-width: 991px) {
    .mini-cart-wrapper {
        margin-left: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .mini-cart-wrapper {
        display: block;
        margin: 20px 10px 30px 10px;
    }
    .napoli_mini_cart {
        opacity: 1;
        visibility: visible;
        position: relative;
        right: auto;
        left: 0;
        top: 10px;
        width: 100%;
        min-width: auto;
    }
}


/*================================================*/


/* PRODUCT PAGE - CARD */


/*================================================*/

.napoli_cart.shop_table {
    margin-top: 26px;
}

.napoli_cart.shop_table ul {
    list-style-type: none;
    margin: 0;
}


/* PRODUCT PAGE - CARD HEADING */

.napoli_cart.shop_table .heading {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    border-bottom: 4px solid #f2f2f2;
    padding: 14px 0;
    margin-bottom: 40px;
    @extend %form-border-color;
}

.napoli_cart.shop_table .heading li {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20%;
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 2.40px;
    font-weight: 400;
    text-transform: uppercase;
    color: #131313;
    @extend %base-color;
}


/* PRODUCT PAGE - CARD ITEMS*/

.napoli_cart.shop_table ul .cart_item {
    margin-bottom: 40px;
}

.napoli_cart.shop_table ul .cart_item ul {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.napoli_cart.shop_table ul .cart_item ul li {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

.napoli_cart.shop_table .heading .heading-product {
    width: 51%;
    padding-left: 8.5%;
}

.napoli_cart.shop_table ul .cart_item ul .product-remove {
    width: 8.5%;
}

.napoli_cart.shop_table ul .cart_item ul .product-thumbnail {
    width: 17%;
}

.napoli_cart.shop_table ul .cart_item ul .product-name {
    width: 25.5%;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.napoli_cart.shop_table .heading .heading-price,
.napoli_cart.shop_table ul .cart_item ul .product-price {
    width: 17.5%;
}

.napoli_cart.shop_table .heading .heading-quantity,
.napoli_cart.shop_table ul .cart_item ul .product-quantity {
    width: 17%;
}

.napoli_cart.shop_table .heading .heading-total,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal {
    width: 14.5%;
}

body .napoli_cart.shop_table ul .cart_item ul .product-remove .remove {
    color: #d6d6d6;
    font-size: 25px;
    width: auto;
    height: auto;
    font-weight: 700;
}

.napoli_cart.shop_table ul .cart_item ul .product-remove .remove:hover {
    background-color: transparent;
}

.napoli_cart.shop_table ul .cart_item ul .product-thumbnail img {
    max-width: 170px;
    width: 100%;
}

.napoli_cart.shop_table ul .cart_item ul .product-name a {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 2px;
    font-weight: 400;
    text-decoration: none;
    color: #131313;
    @extend %base-color;
}

.napoli_cart.shop_table ul .cart_item ul .product-name .variation dt {
    display: inline-block;
    font-family: "Libre Baskerville";
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #131313;
    @extend %base-color;
}

.napoli_cart.shop_table ul .cart_item ul .product-name .variation dd {
    display: inline-block;
    margin-bottom: 0;
}

.napoli_cart.shop_table ul .cart_item ul .product-name .variation dd p {
    margin-bottom: 0;
    font-family: "Libre Baskerville";
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.9px;
    font-weight: 400;
    color: #969696;
    @extend %desc-color;
}

@media only screen and (max-width: 1199px) {
    .napoli_cart.shop_table ul .cart_item ul .product-name {
        padding-left: 15px;
    }
}

@media only screen and (max-width: 991px) {
    .napoli_cart.shop_table ul .cart_item ul .product-remove {
        width: 3.5%;
    }
    .napoli_cart.shop_table ul .cart_item ul .product-name {
        width: 30.5%;
    }
    .napoli_cart.shop_table ul .cart_item ul .product-name a {
        font-size: 18px;
        line-height: 20px;
    }
    .napoli_cart.shop_table .heading .heading-product {
        padding-left: 3.5%;
    }
}

@media only screen and (max-width: 767px) {
    .woocommerce-cart .woocommerce .napoli_cart.shop_table {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media only screen and (max-width: 650px) {
    .napoli_cart.shop_table .heading li {
        font-size: 9px;
    }
    .napoli_cart.shop_table .heading .heading-product {
        width: 31%;
        padding-left: 5%;
    }
    .napoli_cart.shop_table ul .cart_item ul .product-remove {
        width: 5%;
    }
    .napoli_cart.shop_table ul .cart_item ul .product-remove .remove {
        font-size: 18px;
    }
    .napoli_cart.shop_table ul .cart_item ul .product-thumbnail {
        width: 26%;
    }
    .napoli_cart.shop_table ul .cart_item ul .product-name {
        display: none;
    }
    .napoli_cart.shop_table .heading .heading-price,
    .napoli_cart.shop_table ul .cart_item ul .product-price {
        width: 23%;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .napoli_cart.shop_table .heading .heading-quantity,
    .napoli_cart.shop_table ul .cart_item ul .product-quantity {
        width: 23%;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
    .napoli_cart.shop_table ul .cart_item ul .product-quantity .qty {
        width: 100%;
        max-width: 80px;
    }
    .napoli_cart.shop_table .heading .heading-total,
    .napoli_cart.shop_table ul .cart_item ul .product-subtotal {
        width: 23%;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}


/* PRODUCT PAGE - CARD COUMPLEMENT*/

.napoli_cart.shop_table .complement-cart {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    border-bottom: 4px solid #f2f2f2;
    border-top: 4px solid #f2f2f2;
    padding: 40px 0;
    @extend %form-border-color;
}

.napoli_cart.shop_table .complement-cart .coupon {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
}

.napoli_cart.shop_table .complement-cart .coupon .input-text {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    border: none;
    padding: 0;
    background-color: transparent;
    border-bottom: 1px solid #f2f2f2;
    margin-right: 100px;
    transition: color 0.3s ease, border-color 0.3s ease;
    @extend %desc-color;
    @extend %form-border-color;
}

.napoli_cart.shop_table .complement-cart .coupon .input-text:focus {
    color: #131313;
    border-bottom: 1px solid #c0af94;
    @extend %base-color;
    @extend %subtitle-border-color;
}

.napoli_cart.shop_table .complement-cart .action {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50%;
    padding: 0;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: transparent;
}

@media only screen and (max-width: 991px) {
    .napoli_cart.shop_table .complement-cart .coupon .input-text {
        margin-right: 20px;
    }
}

@media only screen and (max-width: 767px) {
    .napoli_cart.shop_table .complement-cart .coupon {
        width: 70%;
    }
    .napoli_cart.shop_table .complement-cart .action {
        width: 30%;
    }
}

@media only screen and (max-width: 650px) {
    .napoli_cart.shop_table .complement-cart {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .napoli_cart.shop_table .complement-cart .coupon {
        width: 100%;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-bottom: 20px;
    }
    .napoli_cart.shop_table .complement-cart .action {
        width: 100%;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
    }
}

@media only screen and (max-width: 400px) {
    .napoli_cart.shop_table .complement-cart .coupon .input-text {
        margin-right: 0;
        margin-bottom: 20px;
    }
    .napoli_cart.shop_table .complement-cart .coupon {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }
    .napoli_cart.shop_table .complement-cart .action {
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
    }
}


/* PRODUCT PAGE - CARD TOTALS*/

.napoli-cart-collaterals .cart_totals {
    text-align: center;
    margin-top: 33px;
    margin-bottom: 65px;
}

.napoli-cart-collaterals .cart_totals h2 {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 36px;
    line-height: 40px;
    letter-spacing: 2.88px;
    font-weight: 400;
    margin-bottom: 26px;
    color: #131313;
    @extend %base-color;
}

.napoli-cart-collaterals .cart_totals .shop_table ul {
    list-style-type: none;
    margin: 0;
    margin-bottom: 25px;
}

.napoli-cart-collaterals .cart_totals .shop_table ul li {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.88px;
    font-weight: 400;
    color: #888888;
    margin-bottom: 0;
    text-transform: uppercase;
    @extend %desc-color;
}

.napoli-cart-collaterals .cart_totals .shop_table ul li span {
    color: #c0af94;
    @extend %subtitle-color;
}

.napoli-cart-collaterals .cart_totals a.button {
    margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
    .napoli-cart-collaterals .cart_totals {
        margin-bottom: 33px;
    }
}

@media only screen and (max-width: 767px) {
    .woocommerce-cart .woocommerce .napoli-cart-collaterals .cart_totals {
        padding-left: 15px;
        padding-right: 15px;
    }
}


/*================================================*/


/* PRODUCT PAGE - ICON ADD TO CART ON HOVER */


/*================================================*/

.woocommerce #respond input#submit.loading:after,
.woocommerce a.button.loading:after,
.woocommerce button.button.loading:after,
.woocommerce input.button.loading:after {
    top: auto;
    width: 20px;
    height: 20px;
    right: 2px;
}


/*================================================*/


/* PRODUCT PAGE - CHECKOUT */


/*================================================*/

.woocommerce form .form-row {
    margin: 0 0 40px 0;
}

.woocommerce form.checkout_coupon,
.woocommerce form.login {
    margin: 0 0 40px 0;
    padding: 20px 0 40px 0;
    border: none;
    border-bottom: 4px solid #f2f2f2;
    @extend %table-border-color;
}

.woocommerce form.checkout_coupon .form-row {
    padding: 0;
    margin: 0;
}

.woocommerce form.checkout_coupon .form-row input.input-text {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 40px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #131313;
    border: none;
    padding: 0;
    background-color: transparent;
    max-width: 290px;
    border-bottom: 1px solid #f2f2f2;
    transition: color 0.3s ease, border-color 0.3s ease;
    @extend %desc-color;
    @extend %table-border-color;
}

.woocommerce form.checkout h3 {
    font-size: 36px;
    line-height: 40px;
    font-weight: 400;
    letter-spacing: 2.88px;
    margin-bottom: 20px;
    color: #131313;
    @extend %base-color;
}

.woocommerce form.checkout #order_review_heading {
    margin-top: 40px;
}

.woocommerce form.checkout .form-row,
.woocommerce form.login .form-row {
    padding: 0;
    margin: 0 0 40px;
}

.woocommerce form.login .form-row label,
.woocommerce form.checkout .form-row label {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.88px;
    font-weight: 400;
    color: #131313;
    margin-bottom: 3px;
    text-transform: uppercase;
    @extend %base-color;
}

.woocommerce form.login .form-row .required,
.woocommerce form.checkout .form-row .required {
    color: inherit;
}

.woocommerce form.login .form-row input,
.woocommerce form.login .form-row textarea,
.woocommerce form.checkout .form-row input,
.woocommerce form.checkout .form-row textarea {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #131313;
    border: none;
    border-radius: 0;
    padding: 10px 0;
    background-color: transparent;
    border-bottom: 1px solid #f2f2f2;
    width: 100%;
    transition: color 0.3s ease, border-bottom 0.3s ease;
    @extend %desc-color;
    @extend %table-border-color;
}

.woocommerce form.login .form-row textarea,
.woocommerce form.checkout .form-row textarea {
    min-height: 100px;
}

.woocommerce form.login .form-row input:focus,
.woocommerce form.login .form-row textarea:focus,
.woocommerce form.checkout .form-row input:focus,
.woocommerce form.checkout .form-row textarea:focus,
.woocommerce form.checkout_coupon .form-row input.input-text:focus,
{
    color: #131313;
    border-bottom: 1px solid #c0af94;
    @extend %base-color;
    @extend %subtitle-border-color;
}

.woocommerce form.login .form-row input:-webkit-autofill,
.woocommerce form.checkout .form-row input:-webkit-autofill,
.woocommerce form.login .form-row input:-webkit-autofill:hover,
.woocommerce form.checkout .form-row input:-webkit-autofill:hover,
.woocommerce form.login .form-row input:-webkit-autofill:focus,
.woocommerce form.checkout .form-row input:-webkit-autofill:focus {
    background-color: #f9f5ee;
    transition: background-color 3000s ease 0s;
    box-shadow: 0 0 0px 1000px #f9f5ee inset;
}

.woocommerce form.login .form-row input[type="submit"] {
    margin-right: 40px;
}

.woocommerce form.login .form-row input[type="checkbox"],
.woocommerce form.checkout .form-row input[type="checkbox"] {
    display: none;
}

.woocommerce form.login .form-row label.checkbox,
.woocommerce form.checkout .form-row label.checkbox {
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 29px;
    margin-bottom: 0;
    margin-top: 0;
}

.woocommerce form.login .form-row label.checkbox:before,
.woocommerce form.checkout .form-row label.checkbox:before {
    content: "";
    display: inline-block;
    width: 14px;
    height: 14px;
    border-radius: 0;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #e6e6e6;
}

.woocommerce form.login .form-row input[type="checkbox"]:checked + label.checkbox:before,
.woocommerce form.checkout .form-row input[type="checkbox"]:checked + label.checkbox:before {
    background-color: #c0af94;
    @extend %subtitle-bg-color;
}

.woocommerce form.login .lost_password {
    margin-bottom: 0;
}

.woocommerce form.login .lost_password a {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.88px;
    font-weight: 400;
    color: #131313;
    text-transform: uppercase;
    text-decoration: none;
    @extend %base-color;
}


/* SELECT */

.select2-container .select2-choice {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    border-color: transparent;
    padding: 10px 5px;
    background-color: transparent;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 0;
    box-shadow: none;
    @extend %desc-color;
}

.select2-container.select2-dropdown-open.select2-drop-above .select2-choice {
    border: none;
    border-bottom: 1px solid #f2f2f2;
    border-radius: 0;
    @extend %form-border-color;
}

.select2-drop.select2-drop-above.select2-drop-active {
    border: none;
}

.select2-drop-active {
    border: none;
    border-radius: 0;
    color: #131313;
    border-bottom: 1px solid #c0af94;
    overflow-x: hidden;
    @extend %main-bg-color;
    @extend %base-color;
    @extend %subtitle-border-color;
}

.select2-search {
    padding: 0;
    position: relative;
}

.select2-search:after {
    content: "\f002";
    font-family: "FontAwesome";
    font-size: 12px;
    line-height: 28px;
    color: #888888;
    position: absolute;
    right: 5px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    @extend %desc-color;
}

body .select2-search input {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    border: none;
    padding: 10px 0;
    border: 1px solid #f2f2f2;
    border-bottom: 0;
    background-color: transparent;
    background-image: none;
    border-radius: 0;
    @extend %desc-color;
    @extend %table-border-color;
}

body .select2-results {
    border: none;
    padding: 0;
    margin-left: 0;
    margin-right: -20px;
    @extend %dark-black-bg-color;
}

body .select2-results li {
    font-family: "Libre Baskerville";
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    border: none;
    padding: 0;
    margin-left: 0;
    @extend %desc-color;
}

.select2-results li.select2-highlighted {
    background-color: transparent;
    opacity: 0.9;
    border-radius: 0;
    color: #131313;
    @extend %base-color;
}


/*SHOP TABLE*/

.woocommerce table.shop_table {
    border: none;
    border-collapse: collapse;
    margin: 0 0 33px 0;
}

.woocommerce table.shop_table .woocommerce-Price-amount {
    color: #c0af94;
    @extend %subtitle-color;
}

.woocommerce table.shop_table th,
.woocommerce table.shop_table td,
.woocommerce table.shop_table tr,
.woocommerce table.shop_table tfoot th,
.woocommerce table.shop_table tfoot td {
    border: none;
    padding: 0;
}

.woocommerce table.shop_table thead {
    border-bottom: 4px solid #f2f2f2;
    @extend %form-border-color;
}

body .woocommerce table.shop_table thead .product-name,
body .woocommerce table.shop_table thead .product-total {
    font-family: "Montserrat";
    font-size: 11px;
    line-height: 20px;
    letter-spacing: 2.40px;
    font-weight: 400;
    text-transform: uppercase;
    color: #131313;
    padding: 14px 0;
    @extend %base-color;
}

.woocommerce table.shop_table .cart_item .product-name {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 24px;
    line-height: 40px;
    letter-spacing: 1.4px;
    font-weight: 400;
    color: #131313;
    text-align: left;
    padding: 0;
    padding: 40px 10px 40px 0;
    @extend %base-color;
}

.woocommerce table.shop_table .cart_item .product-name strong {
    font-weight: inherit;
}

.woocommerce table.shop_table .cart_item .product-name .variation dt {
    display: inline-block;
    font-family: "Libre Baskerville";
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #131313;
    text-transform: none;
    @extend %base-color;
}

.woocommerce table.shop_table .cart_item .product-name .variation dd {
    margin-bottom: 0;
}

.woocommerce table.shop_table .cart_item .product-name .variation dd p {
    margin-bottom: 0;
    font-family: "Libre Baskerville";
    font-size: 12px;
    line-height: 28px;
    letter-spacing: 0.9px;
    font-weight: 400;
    color: #888888;
    text-transform: none;
    @extend %desc-color;
}

.woocommerce table.shop_table tfoot {
    border-top: 4px solid #f2f2f2;
    border-bottom: 4px solid #f2f2f2;
    @extend %form-border-color;
}

.woocommerce table.shop_table .cart-subtotal th,
.woocommerce table.shop_table .order-total th {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.88px;
    color: #888888;
    margin-bottom: 0;
    text-transform: uppercase;
    @extend %desc-color;
}

.woocommerce table.shop_table .cart-subtotal th {
    padding: 20px 0 10px 0;
}

.woocommerce table.shop_table .order-total th {
    padding: 10px 0 20px 0;
}

.woocommerce-checkout #payment {
    border-radius: 0;
    background-color: transparent;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods {
    padding: 20px 0;
    border-bottom: 0;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-weight: 400;
    color: #888888;
    margin-bottom: 0;
    @extend %desc-color;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li label {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 2.88px;
    color: #888888;
    margin-bottom: 0;
    display: inline-block;
    @extend %desc-color;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li img {
    margin-right: 20px;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li .about_paypal {
    font-family: "Montserrat";
    font-size: 12px;
    line-height: 30px;
    letter-spacing: 2.88px;
    color: #888888;
    margin-bottom: 0;
    display: inline-block;
    text-decoration: none;
    vertical-align: middle;
    @extend %desc-color;
}

.woocommerce-checkout-review-order #payment div.payment_box {
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    font-family: "Libre Baskerville";
    color: #131313;
    @extend %base-color;
    @extend %dark-black-bg-color;
}

.woocommerce-checkout-review-order #payment div.payment_box p {
    color: inherit;
}

.woocommerce-checkout-review-order .woocommerce-checkout-payment#payment .place-order {
    padding: 20px 0;
}

@media only screen and (max-width: 767px) {
    .woocommerce form.checkout_coupon,
    .woocommerce form.login,
    .woocommerce form.checkout {
        margin-left: 15px;
        margin-right: 15px;
    }
    .woocommerce #payment #place_order,
    .woocommerce-page #payment #place_order {
        width: auto;
    }
}

@media only screen and (max-width: 650px) {
    .woocommerce form.checkout_coupon .form-row {
        float: none;
        width: 100%;
        text-align: center
    }
    .woocommerce form.checkout_coupon .form-row input.input-text {
        margin-bottom: 20px;
    }
    .woocommerce form .form-row-last,
    .woocommerce-page form .form-row-first {
        width: 100%;
    }
    .woocommerce form .form-row-last,
    .woocommerce-page form .form-row-last {
        width: 100%;
    }
}


/*================================================*/


/* FULL SCREEN SLIDER */


/*================================================*/

.full_screen_slider {
    position: relative;
}

.full_screen_slider .swiper-wrapper {
    cursor: none;
}

.full_screen_slider.kenburn_slider {
    .swiper-slide {
        overflow: hidden;
        .full-screen-slider__img {
            transform: scale(1);
            transition: transform 10s ease-in;
        }
    }
    .swiper-slide-active {
        .full-screen-slider__img {
            transform: scale(1.5);
            transition: transform 10s ease-in;
        }
    }
}

// cursor width arrows and numbers
.slider-wrapperok-left,
.slider-wrapperok-right {
    position: absolute;
    top: 0;
    height: 100%;
    width: 50%;
    z-index: 15;
    overflow: hidden;
    cursor: none;
}

.slider-wrapperok-left {
    left: 0;
}

.slider-wrapperok-left:hover .slider-click.left {
    opacity: 1;
    visibility: visible;
}

.slider-wrapperok-right {
    right: 0;
}

.slider-wrapperok-right:hover .slider-click.right {
    opacity: 1;
    visibility: visible;
}

.full_screen_slider .slider-click {
    font-family: "Libre Baskerville";
    font-size: 14px;
    line-height: 40px;
    font-weight: 400;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 20;
    opacity: 0;
    visibility: hidden;
    color: #fff;
    width: 32px;
    height: 32px;
    transition: opacity 0.3s ease, visibility 0.3s ease;
}

.full_screen_slider .slider-click.left {
    left: 50px;
}

.full_screen_slider .slider-click.right {
    right: 50px;
}

.full_screen_slider .slider-click.right .arrow {
    left: auto;
    right: 60px;
    -webkit-transform: rotate(-135deg);
    -ms-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.full_screen_slider .slider-click.right .number {
    right: 0;
    left: auto;
}

.full_screen_slider .slider-click.right.disabled,
.full_screen_slider .slider-click.left.disabled {
    opacity: 0;
}

.full_screen_slider .slider-click .arrow {
    width: 22px;
    height: 22px;
    position: absolute;
    left: 60px;
    top: 50%;
    margin-top: -11px;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.full_screen_slider .slider-click .number {
    position: absolute;
    left: 0;
    width: 53px;
}

.full_screen_slider .slider-click .left {
    text-align: right;
    float: left;
    width: 23px;
    height: 20px;
    position: relative;
    top: -8px;
}

.full_screen_slider .slider-click .middle {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1px;
    height: 20px;
    margin-top: -10px;
    background: #fff;
    -webkit-transform: rotate(22deg);
    -ms-transform: rotate(22deg);
    transform: rotate(22deg);
}

.full_screen_slider .slider-click .right {
    float: right;
    width: 23px;
    height: 30px;
    position: relative;
    top: 8px;
}

.full-screen-slider__img {
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
}

.full-screen-slider__img:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
}

.full_screen_slider .pagination {
    display: none;
}

// copyright for slider
.napoli_copyright_overlay {
    position: absolute;
    z-index: 9999;
    top: 50%;
    left: 50%;
    display: none;
    border-radius: 5px;
    background: rgba(0, 0, 0, .95);
}

.napoli_copyright_overlay.active {
    display: block;
}

.napoli_copyright_overlay_text {
    font-size: 12px;
    padding: 12px;
    text-align: center;
    color: white;
}

@media only screen and (max-width: 768px) {
    .slider-wrapperok-left,
    .slider-wrapperok-right {
        display: none;
    }
    .slider-click {
        display: none;
    }
}

// sound-btn
.napoli-sound-btn {
    position: absolute;
    right: 30px;
    bottom: 120px;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 310;
    background-color: #cfac7f;
    transition: all 0.3s ease;
    padding: 0;
    border: none;
    @extend %subtitle-bg-color;
    &:before {
        content: '\f026';
        position: absolute;
        color: #ffffff;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-family: 'FontAwesome';
        font-size: 16px;
        line-height: 40px;
        display: block;
        transition: all 0.3s ease;
        @extend %base-color;
    }
    &.play {
        &:before {
            content: '\f028';
        }
    }
}

.napoli-audio-file {
    display: none;
}


/*================================================*/


/* FRELANCER PORTFOLIO */


/*================================================*/

@media only screen and (min-width: 768px) {
    body .port-det-slider .flexslider .slides {
        width: 100%;
    }
}

.port-det-slider {
    width: 100%;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    height: 735px;
    margin-bottom: 33px;
    overflow: hidden;
}

.port-det-slider .flexslider .flex-direction-nav {
    display: none;
}

.port-det-slider .flexslider .slides {
    height: 100%;
    margin-bottom: 0;
    display: block;
}

.port-det-slider .flexslider .slides li {
    width: 100%;
}

.port-det-slider .flexslider .slides li .port-slide-bg {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}

.port-det-slider #slider {
    width: 85%;
    margin-right: 30px;
    display: block;
}

.port-det-slider #slider .slides li {
    height: 100%;
}

.port-det-slider #carousel {
    width: 15%;
    display: block;
}

.port-det-slider #carousel .flex-viewport {
    height: 100%;
}

body .port-det-slider #carousel .slides li {
    height: 17%;
    width: 100%;
    margin: 0 0 30px 0;
    cursor: pointer;
}

@media only screen and (max-width: 1199px) {
    .port-det-slider #slider {
        width: 80%;
    }
    .port-det-slider #carousel {
        width: 20%;
    }
    .port-det-slider {
        height: 600px;
    }
    .port-det-slider #slider .slides li .port-slide-bg {
        height: 600px;
    }
    .port-det-slider #carousel .slides li .port-slide-bg {
        height: 102px;
    }
}

@media only screen and (max-width: 767px) {
    .port-det-slider {
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        height: 500px;
        margin-right: 30px;
        margin-left: 15px;
    }
    .port-det-slider #slider {
        width: 100%;
        margin-right: 0;
        height: 80%;
        margin-bottom: 20px;
    }
    .port-det-slider #slider .slides li .port-slide-bg {
        height: 400px;
    }
    .port-det-slider #carousel {
        width: 100%;
        height: 20%;
    }
    .port-det-slider #carousel .slides {}
    body .port-det-slider #carousel .slides li {
        width: 120px;
        height: 100%;
        margin: 0 30px 0 0;
    }
    .port-det-slider #carousel .slides li .port-slide-bg {
        height: 70px;
    }
}

.portfolio-single-content .portfolio-categories a {
    font-family: "Montserrat", sans-serif;
    color: #c0af94;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 0;
    letter-spacing: 2.4px;
    margin-bottom: 30px;
    text-decoration: none;
    @extend %subtitle-color;
}

.portfolio-single-content .portfolio-title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 400;
    color: #131313;
    margin-top: 22px;
    margin-bottom: 0;
    @extend %base-color;
}

.portfolio-single-content p {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.1px;
    font-weight: 400;
    font-family: "Libre Baskerville";
    color: #888888;
    margin-top: 17px;
    margin-bottom: 30px;
    @extend %desc-color;
}

.portfolio-single-content .napoli-portfolio-footer-line {
    border-top: 4px solid #f2f2f2;
    border-bottom: 4px solid #f2f2f2;
    padding: 33px 0;
    @extend %table-border-color;
}

.portfolio-single-content .napoli-portfolio-footer-line .portfolio-client .client-title,
.portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type .title-job-type,
.portfolio-single-content .napoli-portfolio-footer-line .title-date {
    font-family: "Montserrat", sans-serif;
    color: #131313;
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 0;
    letter-spacing: 0.5px;
    text-decoration: none;
    @extend %base-color;
}

.portfolio-single-content .napoli-portfolio-footer-line .portfolio-client .client-value,
.portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type .job-type-value,
.portfolio-single-content .napoli-portfolio-footer-line .date-value,
.portfolio-single-content .napoli-portfolio-footer-line .social-list a {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.1px;
    font-weight: 400;
    font-family: "Libre Baskerville";
    color: #888888;
    @extend %desc-color;
}

body .portfolio-single-content .napoli-portfolio-footer-line .single-share .ft-part {
    margin-bottom: 0;
}

.portfolio-single-content .napoli-portfolio-footer-line .social-list a {
    font-size: 12px;
}

.portfolio-single-content .napoli-portfolio-footer-line .social-list {
    margin: 0;
    padding: 0 0 0 5px;
    text-align: left;
}

.portfolio-single-content .single-pagination {
    padding: 50px 0;
    position: relative;
}

.portfolio-single-content .single-pagination:after,
.portfolio-single-content .single-pagination:before {
    content: '';
    display: table;
    clear: both;
}

@media only screen and (max-width: 991px) {
    .portfolio-single-content .napoli-portfolio-footer-line .portfolio-client,
    .portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type,
    .portfolio-single-content .napoli-portfolio-footer-line .date-value {
        margin-bottom: 20px;
    }
    .portfolio-single-content .napoli-portfolio-footer-line .single-share {
        margin-left: 0;
        margin-right: 0;
    }
    .portfolio-single-content .napoli-portfolio-footer-line .social-list li:first-child {
        margin-left: 0;
    }
    .portfolio-single-content .single-pagination {
        padding: 30px 0;
    }
}

@media only screen and (max-width: 767px) {
    .portfolio-single-content {
        margin-right: 15px;
        margin-left: 15px;
    }
    body .portfolio-single-content.no-marg {
        margin: 0px;
    }
}

#footer.static {
    position: static;
}


/*================================================*/


/* VIDEO GALLERY */


/*================================================*/

.iframe-video {
    width: 100%;
    height: 280px;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 24px;
    z-index: 200;
    &.play {
        .video-container {
            visibility: visible;
            opacity: 1;
        }
        .video-close-button {
            visibility: visible;
            opacity: 1;
        }
    }
    .video-container {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 100;
        opacity: 0;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        &:hover {
            opacity: 1;
        }
         .fluid-width-video-wrapper {
            padding-top: 280px;
            height: 100%;
        }
        iframe {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
    .video-content .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        width: 85px;
        height: 55px;
        text-align: center;
        background-color: #000000;
        opacity: 0.3;
        transition: background-color 0.3s ease, opacity 0.3s ease;
        &:before {
            content: '\f04b';
            display: inline-block;
            font-family: 'FontAwesome';
            font-size: 30px;
            line-height: 55px;
            color: #ffffff;
            opacity: 1;
            transition: none;
        }
        &:hover {
            background-color: #c0af94;
            opacity: 1;
            @extend %subtitle-bg-color;
        }
    }
    .video-close-button {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        z-index: 120;
        visibility: hidden;
        opacity: 0;
        transition: opacity 0.3s ease, visibility 0.3s ease;
        color: #fff;
    }
}

.iframe-video-title {
    font-family: "BebasNeueRegular", sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    color: #131313;
    margin-bottom: 33px;
    text-align: center;
    @extend %base-color;
}


/* PRICING
---------------------------*/

.pricing-item {
    padding: 60px 70px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background-color: #ffffff;
    @extend %main-bg-color;
}


/*Mask block*/

.pricing-item .mask-image {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 200px;
    height: 320px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: 30px;
}


/*SVG mask*/

.pricing-item .mask-image .pricing_mask {
    fill: #ffffff;
}

.pricing-item .mask-image .pricing-transparent-mask {
    fill: transparent;
}


/*Pricing info*/

.pricing-info {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.pricing-info .subtitle {
    display: block;
    margin: 0;
    margin-bottom: 24px;
    font-size: 12px;
    line-height: 25px;
    font-family: "Montserrat";
    letter-spacing: 2.4px;
    font-weight: 400;
    color: #c0af94;
    @extend %subtitle-color;
}

.pricing-info .title {
    display: block;
    margin: 0;
    margin-bottom: 22px;
    font-size: 36px;
    line-height: 40px;
    font-family: "BebasNeueRegular";
    letter-spacing: 2.88px;
    font-weight: 400;
    color: #131313;
    @extend %base-color;
}

.pricing-info .pricing-list p:empty {
    display: none;
}

.pricing-info .pricing-list ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 30px;
}

.pricing-info .pricing-list ul li {
    font-size: 14px;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-family: "Libre Baskerville";
    color: #888888;
    @extend %desc-color;
}

.pricing-info .a-btn-2 {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

@media (max-width: 1199px) {
    .pricing-item {
        padding: 60px 30px;
    }
}

@media (max-width: 991px) {
    .pricing-item {
        padding: 30px 15px;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        text-align: center;
    }
    .pricing-item .mask-image {
        margin: auto;
        margin-bottom: 40px;
        height: 220px;
    }
    .pricing-item .pricing-info {
        width: 100%;
    }
    .pricing-info .a-btn-2 {
        -webkit-align-self: center;
        -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    }
}

@media (max-width: 767px) {
    .pricing-item {
        max-width: 480px;
        margin-left: auto;
        margin-right: auto;
    }
}


/* COMMING SOON
---------------------------*/

.coming-page-wrapper {
    position: relative;
    text-align: center;
    padding-top: 31px;
    padding-bottom: 31px;
}

.coming-page-wrapper .title {
    font-size: 70px;
    line-height: 80px;
    font-family: "BebasNeueBook";
    color: #131313;
    margin: 0;
    margin-bottom: 7px;
    letter-spacing: 5.6px;
    font-weight: 400;
    @extend %base-color;
}

.coming-page-wrapper .subtitle {
    font-size: 14px;
    font-family: "Libre Baskerville";
    line-height: 28px;
    margin: 0;
    margin-bottom: 70px;
    color: #888888;
    letter-spacing: 1.12px;
    font-weight: 400;
    @extend %desc-color;
}


/*Coming soon counter*/

.coming-soon-wrap {
    position: relative;
    height: 310px;
    margin-bottom: 76px;
}

.coming-soon-bg {
    position: absolute;
    z-index: 0;
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}


/*Svg styles*/

.svg text {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.svg text::-moz-selection {
    background: none;
}

.svg text::selection {
    background: none;
}

.coming-soon {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.coming-soon .svg {
    width: 100%;
    height: 192px;
    left: 0;
    top: 0;
    position: absolute;
    text-anchor: middle;
}

.coming-soon .svg .count {
    font-weight: 900;
    font-family: "Montserrat";
    font-size: 150px;
}

.coming-soon .svg .svgRect {
    fill: #f2f2f2;
}

.coming-soon .svg .maskRect {
    fill: white;
}

.coming-soon-wrap .coming-soon-descr {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #f2f2f2;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    list-style: none;
    margin: 0;
    margin-left: 0;
    padding: 0;
    height: 120px;
    @extend %dark-black-bg-color;
}

.coming-soon-descr li {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 25%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 400;
    font-family: "Montserrat";
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    color: #c0af94;
    padding-top: 25px;
    letter-spacing: 2.4px;
    @extend %subtitle-color;
}

@media (max-width: 1199px) {
    .coming-page-wrapper .subtitle {
        margin-bottom: 50px;
    }
    .coming-soon-wrap {
        margin-bottom: 50px;
    }
}

@media (max-width: 991px) {
    .coming-page-wrapper .subtitle {
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
    }
    .coming-soon-wrap {
        height: 250px;
    }
    .coming-soon .svg {
        height: 152px;
    }
    .coming-soon .svg .count {
        font-size: 110px;
    }
    .coming-soon-wrap .coming-soon-descr {
        height: 100px;
    }
}

@media (max-width: 767px) {
    .coming-page-wrapper {
        top: 0;
        -webkit-transform: none;
        -ms-transform: none;
        transform: none;
        min-height: 100vh;
        padding-top: 100px;
        padding-bottom: 100px;
        box-sizing: border-box;
    }
    .coming-page-wrapper .title {
        font-size: 40px;
        line-height: 50px;
        padding: 0px 15px;
    }
    .coming-page-wrapper .subtitle {
        max-width: 400px;
        margin-bottom: 40px;
        padding: 0px 15px;
        margin-left: auto;
        margin-right: auto;
    }
    .coming-soon-wrap {
        height: 200px;
    }
    .coming-soon .svg {
        height: 100px;
    }
    .coming-soon .svg .count {
        font-size: 50px;
    }
    .coming-soon-wrap .coming-soon-descr {
        height: 100px;
    }
    .coming-soon-descr li {
        letter-spacing: 0.5px;
    }
}


/* FILMSTRIM GALLERY
---------------------------*/

.swipe-btn {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #ffffff;
    transition: all 0.3s ease;
    @extend %dark-black-bg-color;
    &:hover {
        &:before {
            color: #c0af94;
            @extend %subtitle-color;
        }
    }
    &.next {
        right: 10px;
        &:before {
            content: '\f105';
        }
        &:hover {
            right: 0;
        }
    }
    &.prev {
        left: 30px;
        @media (max-width: 991px) {
            left: 10px;
            &:hover {
                left: 0;
            }
        }
        &:before {
            content: '\f104';
        }
        &:hover {
            left: 20px;
        }
    }
    &:before {
        position: absolute;
        color: #131313;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-family: 'FontAwesome';
        font-size: 16px;
        line-height: 40px;
        display: block;
        transition: all 0.3s ease;
        @extend %base-color;
    }
}
/*need for corect view    Filmstrip Gallery slider js plagin*/
.caroufredsel_wrapper{
    width: 100%!important;
}
.filmstrim-gallery-outer {
    padding-left: 30px;
    position: relative;
    @media (max-width: 991px) {
        padding-left: 0;
        padding-right: 0;
    }
    .filmstrim-gallery {
        img {
            max-width: 100%;
            display: block;
            float: left;
        }
        .image-wrap {
            float: left;
            display: block;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
    body .caroufredsel_wrapper {
        width: 100%;
    }
}


/* SPLITTED SLIDER
---------------------------*/

.ms-section {
    position: relative;
    box-sizing: border-box;
    overflow: hidden;
    background-color: #fff;
    @extend %main-bg-color;
}

.ms-section.ms-table {
    display: table;
    width: 100%;
}

.ms-tableCell {
    display: table-cell;
    vertical-align: middle;
    width: 100%;
    height: 100%;
}

.ms-easing {
    transition: all .7s ease-out;
}

#multiscroll-nav {
    position: fixed;
    z-index: 100;
    margin-top: -32px;
    top: 50%;
    opacity: 1;
}

#multiscroll-nav.right {
    right: 17px;
}

#multiscroll-nav.left {
    left: 17px;
}

#multiscroll-nav ul {
    margin: 0;
    padding: 0;
}

#multiscroll-nav li {
    display: block;
    width: 14px;
    height: 13px;
    margin: 7px;
    position: relative;
}

#multiscroll-nav li a {
    display: block;
    position: relative;
    z-index: 1;
    width: 100%;
    height: 100%;
    cursor: pointer;
    text-decoration: none;
}

#multiscroll-nav li .active span {
    background: #333;
}

#multiscroll-nav span {
    top: 2px;
    left: 2px;
    width: 8px;
    height: 8px;
    border: 1px solid #000;
    background: rgba(0, 0, 0, 0);
    border-radius: 50%;
    position: absolute;
    z-index: 1;
}

.multiscroll-tooltip {
    position: absolute;
    color: #fff;
    font-size: 14px;
    font-family: arial, helvetica, sans-serif;
    top: -2px;
    white-space: nowrap;
    max-width: 220px;
}

.multiscroll-tooltip.right {
    right: 20px;
}

.multiscroll-tooltip.left {
    left: 20px;
}

.multiscroll-slider {
    width: 100%;
    position: relative;
    overflow: hidden;
    height: 100vh;
    &-left {
        position: relative;
        height: 100%;
        width: 50%;
        overflow: hidden;
        float: left;
        @media (min-width: 768px) and (max-width: 991px) {
            height: 100%;
            width: 100%;
        }
        @media (min-width: 480px) and (max-width: 767px) {
            height: auto;
            width: 50%;
        }
        @media (max-width: 479px) {
            height: auto;
            width: 100%;
        }
        .ms-section {
            padding: 14px 7px 14px 14px;
            @media (min-width: 768px) and (max-width: 991px) {
                padding: 14px 14px 7px 14px;
            }
            @media (max-width: 479px) {
                padding: 14px 14px 7px 14px;
            }
        }
    }
    &-right {
        position: relative;
        height: 100%;
        width: 50%;
        overflow: hidden;
        float: left;
        @media (min-width: 768px) and (max-width: 991px) {
            height: 100%;
            width: 100%;
        }
        @media (min-width: 480px) and (max-width: 767px) {
            height: auto;
            width: 50%;
        }
        @media (max-width: 479px) {
            height: auto;
            width: 100%;
        }
        .ms-section {
            padding: 14px 14px 14px 7px;
            @media (min-width: 768px) and (max-width: 991px) {
                padding: 7px 14px 14px 14px;
            }
            @media (max-width: 479px) {
                padding: 7px 14px 14px 14px;
            }
        }
    }
    body .ms-left,
    body .ms-right {
        width: 100%;
    }
}

.scroll-btn {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #ffffff;
    transition: all 0.3s ease;
    @extend %dark-black-bg-color;
    &:hover {
        &:before {
            color: #c0af94;
            @extend %subtitle-color;
        }
    }
    &.up {
        left: calc(50% + 6px);
        @media (max-width: 991px) and (min-width: 767px) {
            top: auto;
            bottom: calc(50% + 7px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        @media (min-width: 480px) and (max-width: 767px) {
            left: calc(50% + 6px);
        }
        @media (max-width: 480px) and (min-width: 320px) {
            top: auto;
            bottom: calc(50% + 7px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        &:before {
            content: '\f106';
        }
        &:hover {
            -webkit-transform: translateY(-70%);
            -ms-transform: translateY(-70%);
            transform: translateY(-70%);
            @media (max-width: 991px) {
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
            }
            @media (min-width: 480px) and (max-width: 767px) {
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
    &.down {
        right: calc(50% + 7px);
        @media (max-width: 991px) and (min-width: 767px) {
            top: calc(50% + 7px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        @media (min-width: 480px) and (max-width: 767px) {
            right: calc(50% + 7px);
        }
        @media (max-width: 480px) and (min-width: 320px) {
            top: calc(50% + 7px);
            left: 50%;
            -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
        &:before {
            content: '\f107';
        }
        &:hover {
            -webkit-transform: translateY(-20%);
            -ms-transform: translateY(-20%);
            transform: translateY(-20%);
            @media (max-width: 991px) {
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
            }
            @media (min-width: 480px) and (max-width: 767px) {
                -webkit-transform: translateY(-50%);
                -ms-transform: translateY(-50%);
                transform: translateY(-50%);
            }
        }
    }
    &:before {
        position: absolute;
        color: #131313;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-family: 'FontAwesome';
        font-size: 16px;
        line-height: 40px;
        display: block;
        transition: all 0.3s ease;
        @extend %base-color;
    }
}

.ms-inner {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: inherit;
}


/* SKILLS WRAPPER
---------------------------*/

.skill-wrapper {
    .main-title {
        font-weight: 400;
        letter-spacing: 2.88px;
        color: #131313;
        @extend %base-color;
    }
    .skills {
        margin-top: 29px;
        margin-bottom: 29px;
    }
    .skill {
        margin-bottom: 22px;
        .line {
            background-color: #f2f2f2;
            position: relative;
            width: 100%;
            height: 3px;
            margin-top: 10px;
            @extend %dark-black-bg-color;
            .active-line {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                width: 0;
                transition: width 1.5s ease;
                background-color: #000000;
                @extend %desc-bg-color;
            }
        }
        .label-skill {
            display: inline-block;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 0;
            letter-spacing: 2.4px;
            margin-bottom: 0;
            text-decoration: none;
            color: #c0af94;
            @extend %subtitle-color;
        }
        .value {
            display: inline-block;
            float: right;
            font-family: "Montserrat", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 20px;
            margin-top: 0;
            letter-spacing: 2.4px;
            margin-bottom: 0;
            text-decoration: none;
            color: #c0af94;
            @extend %subtitle-color;
        }
    }
}

@media (max-width: 767px) {
    .skill-wrapper {
        padding-left: 15px;
        padding-right: 15px;
    }
}


/* THUMBNAIL SLIDER
---------------------------*/

.thumb-slider-wrapp {
    width: 100%;
    position: relative;
    overflow: hidden;
    .flex-direction-nav {
        display: none;
    }
    .slides {
        height: 100%;
        display: block;
        margin-bottom: 0;
        li {
            width: 100%;
            height: 100%;
            .thumb-slider-bg {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
            }
        }
    }
    .main-thumb-slider {
        height: 100%;
    }
    .sub-thumb-slider {
        width: 100%;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 20;
        background-color: #131313;
        padding: 5px 0 5px 5px;
        transition: transform 0.3s ease;
        transform: translateY(100%);
        .flex-viewport {
            height: 100%;
            width: 100%;
        }
        .slides {
            li {
                .thumb-slider-bg {
                    cursor: pointer;
                }
            }
        }
        &.active {
            height: 130px;
            transform: translateY(0%);
        }
    }
    .thumb-slider-wrapp-arrow {
        position: absolute;
        width: 150px;
        height: 50px;
        bottom: 20px;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        transform: translateX(-50%);
        z-index: 20;
        cursor: pointer;
        transition: bottom 0.3s ease;
        .hide-images,
        .show-images {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 100%;
            font-size: 14px;
            line-height: 50px;
            font-family: "Libre Baskerville", sans-serif;
            color: #ffffff;
            font-weight: 400;
            letter-spacing: 1.12px;
            text-align: center;
            transition: opacity 0.3s ease, visibility 0.3s ease;
            &:after {
                font-family: 'FontAwesome';
                font-size: 20px;
                line-height: 20px;
                display: block;
                position: absolute;
                left: 50%;
                -webkit-transform: translateX(-50%);
                -ms-transform: translateX(-50%);
                transform: translateX(-50%);
                -webkit-animation-name: sliderArrowAnimation;
                animation-name: sliderArrowAnimation;
                -webkit-animation-duration: 2s;
                animation-duration: 2s;
                -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
                -webkit-animation-timing-function: linear;
                animation-timing-function: linear;
                opacity: 1;
                transition: all 0.3s ease;
            }
        }
        .show-images {
            &:after {
                content: '\f102';
                top: 0%;
            }
        }
        .hide-images {
            opacity: 0;
            visibility: hidden;
            &:after {
                content: '\f103';
                top: 100%;
            }
        }
        &.active {
            bottom: 150px;
            .show-images {
                opacity: 0;
                visibility: hidden;
            }
            .hide-images {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}


/* ERROR PAGE
---------------------------*/

.error404 {
    .hero-inner {
        .bigtext {
            font-family: "BebasNeueBook", sans-serif;
            font-size: 70px;
            font-weight: 300;
            letter-spacing: 5.6px;
            color: #ffffff;
        }
        .title {
            font-family: "Libre Baskerville", sans-serif;
            font-size: 14px;
            line-height: 28px;
            font-weight: 400;
            letter-spacing: 1.12px;
            color: #ffffff;
        }
    }
}


/* PHP
---------------------------*/

.fullview {
    height: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: background-color 0.3s ease-in-out;
    width: 100%;
    z-index: 10001;
}

.fullview--visible {
    opacity: 1;
    pointer-events: auto;
}

.rsArrow {
    color: white;
    display: block;
    position: absolute;
    top: 50%;
    z-index: 15;
    height: 66px;
    opacity: 0.5;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 36px;
    z-index: 500;
}

@media only screen and (min-width: 900px) {
    .rsArrow {
        height: 68px;
        opacity: 1;
        width: 85px;
    }
}

.fullview .rsArrow {
    opacity: 0.5;
    padding: 1.5em;
    transition: opacity 0.3s linear;
}

.fullview .rsArrowLeft {
    left: 3.7em;
    margin-left: -1.5em;
}

.rsArrowLeft .rsArrowIcn {
    border-left: 2px solid black;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}

.fullview .rsArrow {
    opacity: 0.5;
    padding: 1.5em;
    transition: opacity 0.3s linear;
}

.fullview .rsArrow .rsArrowIcn {
    border-color: white;
    height: 1.250em;
    left: 50%;
    margin: 0;
    position: absolute;
    top: 50%;
    width: 1.250em;
}

.fullview .rsArrowRight .rsArrowIcn {
    -webkit-transform: translate3d(-100%, -50%, 0) rotate(-45deg);
    transform: translate3d(-100%, -50%, 0) rotate(-45deg);
}

.fullview__close {
    background: url(../images/close-button.svg) no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 1.5em;
    position: absolute;
    right: 3.5em;
    top: 3.5em;
    width: 1.5em;
    z-index: 10;
    cursor: pointer;
}

.fullview__image {
    background: #000000;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.fullview__image img {
    width: 100%;
}


/* FLOW SLIDER
---------------------------*/

// change default style
/*need !important for corect view  Flow Gallery slider js plagin*/
body .flipster {
    width: 100%;
    height: 100%!important;
}
/*need !important for corect view  Flow Gallery slider js plagin*/
body .flipster-carousel {
     .flip-items {
        margin: 0;
        position: absolute;
        left: 50%!important;
        top: 50%!important;
        width: 66%!important;
        height: 72%!important;
        transform: translate(-50%, -50%);
    }
    .flip-item {
        width: 100%;
        height: 100%;
        .flip-content {
            width: 100%;
            height: 100%;
            .flow-item-slider {
                width: 100%;
                height: 100%;
            }
        }
    }
    .flip-prev,
    .flip-next,
    .flip-past,
    .flip-future {
        opacity: 1;
    }
    .flip-prev {
        transform: translateX(-43%) translateZ(0) rotateY(0) scale(0.7);
    }
    .flip-next {
        transform: translateX(43%) translateZ(0) rotateY(0) scale(0.7)
    }
    .flip-past {
        transform: translateX(-80%) translateZ(0) rotateY(0) scale(0.5);
    }
    .flip-future {
        transform: translateX(80%) translateZ(0) rotateY(0) scale(0.5);
    }
    .flip-current {
        box-shadow: 0 40px 50px rgba(0, 0, 0, 0.2);
    }
}

// custom style
.flipster-slider {
    .flipster-wrapp-outer {
        max-width: 1170px;
        height: 100%;
        display: block;
        margin: 0 auto;
        width: 100%;
        position: relative;
        overflow: hidden;
    }
    .flow-item-slider {
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    .flip-item {
        text-align: center;
        .flow-item-title {
            font-weight: 400;
            letter-spacing: 2.88px;
            margin-top: 35px;
            margin-bottom: 0;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
        }
        &.flip-current {
            .flow-item-title {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.flipto {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    cursor: pointer;
    background-color: #f0f0f0;
    transition: all 0.3s ease;
    @extend %dark-black-bg-color;
    &:hover {
        &:before {
            color: #c0af94;
            @extend %subtitle-color;
        }
    }
    &.flipto-next {
        right: 30px;
        &:before {
            content: '\f105';
        }
        &:hover {
            right: 20px;
        }
    }
    &.flipto-prev {
        left: 30px;
        &:before {
            content: '\f104';
        }
        &:hover {
            left: 20px;
        }
    }
    &:before {
        position: absolute;
        color: #131313;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        font-family: 'FontAwesome';
        font-size: 16px;
        line-height: 40px;
        display: block;
        transition: all 0.3s ease;
        @extend %base-color;
    }
}

@media only screen and (max-width: 1199px) {
    .flipster-slider .flipster-wrapp-outer {
        max-width: 970px;
    }
}

@media only screen and (max-width: 991px) {
    .flipster-slider .flipster-wrapp-outer {
        max-width: 750px;
    }
}

@media only screen and (max-width: 767px) {
    body .flipster-carousel {
        body .flip-items {
            margin: 0;
            position: absolute;
            left: 50%;
            top: 50%;
            width: 60%;
            height: 60%;
            transform: translate(-50%, -50%);
        }
    }
    .flipster-slider {
        .flipster-wrapp-outer {
            max-width: calc(100% - 30px);
        }
        .flip-item {
            .flow-item-title {
                display: none;
            }
        }
    }
    .flipto {
        display: none;
    }
}

.flipster-slider .flip-item {
    position: absolute;
    list-style: none;
    transition: all .3s ease;
    // top: 0;
}

.flipster-carousel .flip-future,
.flipster-carousel .flip-past {
    z-index: 1;
}

.flipster-carousel .flip-current {
    z-index: 999;
}

.flipster-carousel .flip-prev,
.flipster-carousel .flip-next {
    z-index: 998;
}



/*need for corect view multiscroll slider js plagin*/
.multiscroll-slider-right .ms-right,
.multiscroll-slider-left .ms-left{
width: 100% !important;
}