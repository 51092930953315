
.action-wrap {
  padding: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.action {
  background-color: #ffffff;
  padding: 27px 300px 28px 50px;
  position: relative; }

.action .title {
  font-size: 18px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  letter-spacing: 1.4px;
  margin-bottom: 6px;
  line-height: 24px; }

.action .subtitle {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  opacity: .9;
  padding-left: 2px;
  letter-spacing: .63px;
  line-height: 20px; }

.action .button {
  position: absolute;
  right: 49px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  padding: 1px 68px;
  font-weight: 500; }