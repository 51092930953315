/* 24. PAGINATION
------------------------*/

.pagination {
    width: 100%;
    text-align: center;
    margin: 37px 0 0;
}

.pagination.cs-pager .page-numbers.next:after {
    content: '\f105';
    font-family: FontAwesome;
    display: inline-block;
    color: #131313;
}

.pagination.cs-pager .page-numbers.prev:after {
    content: '\f104';
    font-family: FontAwesome;
    display: inline-block;
    color: #131313;
}


/* 25. FOOTER
------------------------*/

  #footer ,
body #footer {
    display: block;
    padding: 30px 0px;
    background-color: #131313;
    z-index: 99;
    width: 100%;
    margin: auto;
    min-height: 90px;
    bottom: 0;
    transition: none ;
    position: absolute;
    bottom: 0;
    left: 0;
    @extend %dark-black-bg-color;
    &.footer_style2 {
        background-color: transparent;
        bottom: 90px;
    }
    &.footer_style3 {
        position: static;
    }
}

#footer.fix-bottom {
    position: fixed;
    z-index: 300;
    bottom: 0;
    width: 100%;
    background-color: transparent;
}

@media (max-width: 767px) {
    #footer {
        padding: 20px 0px;
    }
}

#footer .copyright {
    letter-spacing: 0.96px;
    font-size: 12px;
    line-height: 30px;
    font-family: "Libre Baskerville", sans-serif;
    color: #8f8f8f;
    text-align: left;
    float: left;
    @extend %desc-color;
}

#footer .copyright a {
    color: #ffffff;
    transition: all 300ms ease;
    text-decoration: none;
    @extend %base-color;
}

#footer .copyright a:hover {
    opacity: 0.7;
    text-decoration: none;
}

#footer .social-links {
    float: right;
    line-height: 30px;
}

#footer .social-links .fa {
    font-size: 15px;
}

#footer .social-links a {
    font-size: 13px;
    text-decoration: none;
    color: #ffffff;
    display: inline-block;
    padding-left: 18px;
    transition: all .3s ease;
    @extend %desc-color;
}

#footer .social-links:hover a {
    opacity: .7;
}

#footer .social-links a:hover {
    opacity: 1;
}

body .social-list>li {
    position: relative;
    display: inline;
    margin: 0px 11px 0 1px;
    list-style: none  ;
}

body .social-list {
    margin-top: 15px;
    margin-left: 0 ;
    text-align: center;
    padding: 0 15px;
}

.social-list a {
    transition: all 300ms ease;
}

.social-list:hover a {
    opacity: .7;
}

.social-list a:hover {
    opacity: 1;
}