.hero {
  width: 100%;
  height: 40%;
  position: relative;
  background-size: cover;
  background-position: center; }

.hero-inner {
  position: relative;
  height: 100vh;
}

/*  HERO SLIDER
--------------------------*/
.hero-slider {
  width: 100%;
  height: 70%;
  position: relative;
  background-size: cover;
  background-position: center; }

body .hero-slider ul {
  list-style: none  ;
   }

.hero-slider,
.hero-slider .slides,
.hero-slider .slides .slide {
  margin: 0;
  padding: 0; }

.hero-slider .slides .slide {
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden; }

.slide-caption {
  position: absolute;
  left: 50px;
  bottom: 50px;
  right: 50px;
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
      transform: translateX(-100px);
  transition: all 1s ease 1s; }

.flex-active-slide .slide-caption {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
      transform: translateX(0px); }

.flex-control-paging {
  list-style: none;
  line-height: 0;
  margin: 10px 0 0;
  z-index: 99999;
  text-align: center; }

.flex-control-paging li {
  display: inline-block;
  margin: 5px;
  border: 1px solid #000; }

.flex-control-paging li a {
  display: block;
  text-decoration: none;
  text-indent: -9999px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer; }

.flex-control-paging li a.flex-active {
  background: #000; }
