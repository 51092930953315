/* 28. SLIDER
-----------------*/


@media (max-width: 767px) {
  .banner-slider-wrap {
    text-align: center;
  }
}

body .banner-slider-wrap .slider-banner {
  min-height: 500px ;
}

.banner-slider-wrap .title {
  font-size: 70px;
  font-family: "BebasNeueBook";
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 5.6px;
  margin-bottom: 40px;
  @extend %base-color;
}

@media (max-width: 767px) {
  .banner-slider-wrap .title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }
}

.banner-slider-wrap .subtitle {
  font-size: 12px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 2.4px;
  margin-bottom: 40px;
  -webkit-font-smoothing: antialiased;
  @extend %base-color;
}

@media (max-width: 480px) {
  .banner-slider-wrap .subtitle {
    margin-bottom: 20px;
  }
}

.banner-slider-wrap .descr {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.12px;
  max-width: 510px;
  @extend %base-color;
}

@media (max-width: 767px) {
  .banner-slider-wrap .descr {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

body .banner-slider-wrap .a-btn,
body .banner-slider-wrap .a-btn-2 {
  margin-top: 30px ;
}

.banner-slider-wrap .container {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

.banner-slider-wrap .img-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner-slider-wrap .swiper-arrow-right {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
      transform: translateX(-50%);
  content: '\f103';
  font-family: fontAwesome;
  color: #ffffff;
  width: 50px;
  height: 50px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.7s ease;
  @extend %base-color;
}

@media (max-width: 767px) {
  .banner-slider-wrap .swiper-arrow-right {
    bottom: 0px;
  }
}

.banner-slider-wrap .swiper-arrow-right .fa {
  font-size: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  -webkit-animation-name: sliderArrowAnimation;
  animation-name: sliderArrowAnimation;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  text-align: center;
  opacity: 1;
  transition: all 0.3s ease;
}

.banner-slider-wrap .swiper-arrow-right:hover .fa {
  -webkit-animation: none;
  animation: none;
  transition: all 0.3s ease;
  opacity: 0.6;
}

@-webkit-keyframes sliderArrowAnimation {
  0% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  25% {
    -webkit-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%); }
  50% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  75% {
    -webkit-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%); }
  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }

@keyframes sliderArrowAnimation {
  0% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  25% {
    -webkit-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%); }
  50% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  75% {
    -webkit-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%); }
  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }