@font-face {
    font-family: 'BebasNeueBook';
    src: url("../fonts/BebasNeueBook.eot");
    src: url("../fonts/BebasNeueBook.woff2") format("woff2"), url("../fonts/BebasNeueBook.woff") format("woff"), url("../fonts/BebasNeueBook.ttf") format("truetype"), url("../fonts/BebasNeueBook.svg#BebasNeueBook") format("svg"), url("../fonts/BebasNeueBook.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeueRegular';
    src: url("../fonts/BebasNeueRegular.eot");
    src: url("../fonts/BebasNeueRegular.woff2") format("woff2"), url("../fonts/BebasNeueRegular.woff") format("woff"), url("../fonts/BebasNeueRegular.ttf") format("truetype"), url("../fonts/BebasNeueRegular.svg#BebasNeueRegular") format("svg"), url("../fonts/BebasNeueRegular.eot?#iefix") format("embedded-opentype");
    font-weight: normal;
    font-style: normal;
}

body,
html {
    height: 100%;
}

.no-scroll {
    overflow-y: hidden;
}

.overflow-hidden {
    overflow: hidden;
}

.swiper-container,
.full_screen_slider {
    margin: 0 auto;
    position: relative;
    overflow: hidden;
    direction: ltr;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    height: inherit;
}

.swiper-wrapper {
    position: relative;
    width: 100%;
    -webkit-transition-property: -webkit-transform, left, top;
    -webkit-transition-duration: 0s;
    -webkit-transform: translate3d(0px, 0, 0);
    -webkit-transition-timing-function: ease;
    -moz-transition-property: -moz-transform, left, top;
    -moz-transition-duration: 0s;
    -moz-transform: translate3d(0px, 0, 0);
    -moz-transition-timing-function: ease;
    -o-transition-property: -o-transform, left, top;
    -o-transition-duration: 0s;
    -o-transform: translate3d(0px, 0, 0);
    -o-transition-timing-function: ease;
    -o-transform: translate(0px, 0px);
    -ms-transition-property: -ms-transform, left, top;
    -ms-transition-duration: 0s;
    -ms-transform: translate3d(0px, 0, 0);
    -ms-transition-timing-function: ease;
    transition-property: left, top, -webkit-transform;
    transition-property: transform, left, top;
    transition-property: transform, left, top, -webkit-transform;
    transition-duration: 0s;
    -webkit-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
    transition-timing-function: ease;
    box-sizing: content-box;
    cursor: s-resize;
    margin: 0 auto;
    height: inherit;
    float: left;
}

.swiper-slide {
    float: left;
    height: inherit;
    position: relative;
    width: 100%;
}

body {
    overflow-x: hidden;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    letter-spacing: .02em;
    font-size: 15px;
    line-height: 26px;
    color: #131313;
    position: relative;
    background: #ffffff;
    @extend %base-color;
    @extend %main-bg-color;
}

html {
    font-size: 15px;
}

html {
    font-size: 15px;
}

.main-wrapper {
    min-height: 100vh;
}

::-moz-selection {
    background: #000;
    color: #ffffff;
}

::selection {
    background: #000;
    color: #ffffff;
}

::-moz-selection {
    background: #000;
    color: #ffffff;
}

* {
    margin: 0;
    padding: 0;
    outline: 0;
    -webkit-font-smoothing: antialiased;
}

:focus {
    outline: 0;
}

a,
a:hover,
a:focus {
    color: #131313;
    outline: 0;
    transition: all 300ms ease;
    text-decoration: none;
    @extend %base-color;
}

a:hover,
a:focus {
    opacity: .7;
}

a {
    text-decoration: none;
}

.text-light a {
    color: #ffffff;
    @extend %base-color;
}

img {
    max-width: 100%;
    height: auto;
    display: inline-block;
    border: none;
}

.clip {
    clip: rect(auto, auto, auto, auto);
    height: 100%;
    position: absolute;
    width: 100%;
}


/* typography
---------------------------*/

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "BebasNeueRegular", sans-serif;
    font-weight: 600;
}

h1 {
    font-size: 44px;
    line-height: 54px;
}

h2 {
    font-size: 36px;
    line-height: 48px;
}

h3 {
    font-size: 30px;
    line-height: 36px;
}

h4 {
    font-size: 24px;
    line-height: 30px;
}

h5 {
    font-size: 18px;
    line-height: 24px;
}

h6 {
    font-size: 14px;
    line-height: 18px;
    font-style: italic;
}

p {
    font-family: "Libre Baskerville";
}


/* Responsive typography
---------------------------*/

@media (max-width: 768px) {
    body {
        font-size: 14px;
        line-height: 23px;
    }
    h1 {
        font-size: 34px;
        line-height: 40px;
    }
    h2 {
        font-size: 30px;
        line-height: 35px;
    }
    h3 {
        font-size: 26px;
        line-height: 29px;
    }
    h4 {
        font-size: 22px;
        line-height: 24px;
    }
    h5 {
        font-size: 17px;
        line-height: 19px;
    }
    h6 {
        font-size: 13px;
        line-height: 15px;
    }
}

.bottom-line {
    position: relative;
    margin-bottom: 30px;
}

.bottom-line:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    background: #111;
    bottom: -13px;
    @extend %base-bg-color;
}

.text-light .bottom-line:after {
    background: #ffffff;
    @extend %font-bg-color;
}

.text-light p {
    color: #ffffff;
    @extend %font-color;
}

.text-dark p {
    color: #000;
    @extend %base-color;
}

.monospace {
    font-family: 'Source Code Pro';
    color: #494949;
}

.text-light .monospace {
    color: #D0D0D0;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
    margin-top: 0;
}

.bigtext {
    font-size: 160px;
    line-height: 1;
    font-family: "Libre Baskerville", sans-serif;
    -webkit-font-smoothing: antialiased;
}

.notfound {
    text-decoration: none;
    transition: all 300ms ease;
}

.notfound:hover {
    opacity: .7;
}

.font-1 {
    font-family: "Libre Baskerville", sans-serif;
}

@media (max-width: 992px) {
    .bigtext {
        font-size: 130px;
    }
}

@media (max-width: 768px) {
    .bigtext {
        font-size: 70px;
    }
}

.text-light {
    color: #ffffff;
    @extend %base-color;
}

.text-dark {
    color: #131313;
    @extend %base-color;
}

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;
}

.text-right {
    text-align: right;
}


/*helper classes*/

.fullwidth {
    width: 100%;
}

.fullheight {
    height: 100%;
}

.vc_column_container {
    z-index: 30;
}

.grey {
    background-color: #F7F7F7;
}

.black {
    background-color: #000;
    @extend %base-bg-color;
}

.white {
    background-color: #ffffff;
    @extend %font-bg-color;
}

.gray {
    background-color: #f0f0f0;
}

.vCenter {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.highlight {
    background-color: #000;
    color: #ffffff;
    @extend %base-bg-color;
    @extend %font-color;
}

.overflow {
    overflow: hidden;
}

.video-container iframe {
    border: 0;
}

body .vimeo-video {
    width: 100%;
    height: 100%;
}


/*---------------------------*/


/* buttons
---------------------------*/

.a-btn,
.a-btn-2 {
    font-size: 11px;
    line-height: 20px;
    display: inline-block;
    letter-spacing: 1.1px;
    font-family: "Montserrat";
    font-weight: bold;
    box-sizing: border-box;
    padding: 8px 20px;
    text-transform: uppercase;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}

.a-btn {
    border-top: 2px solid rgba(255, 255, 255, 0.5);
    border-bottom: 2px solid rgba(255, 255, 255, 0.5);
    border-left: none;
    border-right: none;
    color: #ffffff;
    @extend %base-light-bottom-color;
    @extend %base-color;
}

.a-btn:hover {
    border-top: 2px solid #ffffff;
    border-bottom: 2px solid #ffffff;
    color: #000;
    background-color: #ffffff;
    opacity: 1;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}

.a-btn-2 {
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    color: #131313;
    @extend %base-color;
    @extend %base-light-bottom-color;
}

.a-btn-2:hover {
    border-top: 2px solid #131313;
    border-bottom: 2px solid #131313;
    border-left: none;
    border-right: none;
    color: #ffffff;
    background-color: #131313;
    opacity: 1;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}


/* overlays
---------------------------*/

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.overlay-dark {
    background-color: rgba(0, 0, 0, 0.25);
}

.overlay-dark-2x {
    background-color: rgba(0, 0, 0, 0.7);
}

[class*="overlay-dark"]~*,
[class*="overlay-light"]~* {
    position: relative;
}


/* parallax images
---------------------------*/

[data-stellar-background-ratio] {
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    position: relative;
}

.bg-cover {
    background-size: cover;
    background-position: center;
}

.bg-fixed {
    background-attachment: fixed;
}

@media (max-width: 1024px) {
    [data-stellar-background-ratio],
    body [data-stellar-background-ratio] {
        background-attachment: scroll;
        background-position-y: center;
    }
    .bg-fixed {
        background-attachment: scroll;
    }
}


/* bootstrap columns
---------------------------*/

.container,
section,
section>* {
    position: relative;
}

.container.no-padding [class*="col-"] {
    padding: 0;
}

.col-center {
    float: none;
    margin: 0 auto;
}

.col-right {
    float: right;
}

.equal-height [class*="col-"] {
    margin-bottom: 0;
}

.pad-0 {
    margin-bottom: 0;
}

// svg
.svgRect {
    -webkit-mask: url(#cfda4cf);
    mask: url(#cfda4cf);
}