.team-member .social {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
      transform: translateY(100%);
  transition: all 300ms ease;
  width: 100%;
  height: 100%;
}

.team-member .social .wrap:hover a {
  opacity: .5;
}

.team-member .social .wrap a {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  padding: 0 15px;
  transition: all 300ms ease;
  @extend %base-color;
}

.team-member .social .wrap a:hover {
  opacity: 1;
}

.team-member:hover .social {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
      transform: translateY(0);
}

.team-member .info {
  text-align: center;
  padding: 36px 15px;
}

@media (max-width: 991px) {
  .team-member .info {
    padding: 15px 15px 0px 15px;
  }
}

.team-member .info .title {
  font-size: 36px;
  line-height: 40px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  letter-spacing: 2.88px;
  @extend %base-color;
}

.team-member .info .position {
  letter-spacing: .8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  @extend %subtitle-color;
}

body  .team-member .avatar {
  position: relative;
  overflow: hidden;
  background-size: cover ;
  background: no-repeat;
  background-position: center;
  width: 100%;
  height: 370px;
  max-width: 370px;
  margin: auto;
}
// team member - style fullheight
.team-member.fullheight {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
  display: flex;
  align-items: flex-end;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.5);
  }
  .info {
    width: 100%;
    position: relative;
    z-index: 20;
    padding: 34px 15px;
    .title {
      margin-bottom: 20px;
      color: #ffffff;
      @extend %base-color;
    }
    .description {
      font-size: 14px;
      line-height: 28px;
      letter-spacing: 1.12px;
      font-family: "Libre Baskerville";
      font-weight: 400;
      font-style: normal;
      color: #ffffff;
      margin-bottom: 0;
      margin-top: 2px;
      @extend %base-color;
    }
  }
  .social {
    position: static;
    transform: none;
    background-color: transparent;
    margin-top: 25px;
    .vertical-align {
      position: static;
      transform: none;
    }
  }
}
@media only screen and (max-width: 767px) {
  body .team-member.fullheight {
    height: 370px;
  }
}