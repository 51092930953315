.monthly {
    border: 1px solid #EBEBEB;
}

.monthly-header {
    padding: 20px 15px;
    height: auto;
    background-color: #C4F2D4;
}

.monthly-header-title a:link,
.monthly-header-title a:visited,
.monthly-header-title a:last-of-type,
.monthly-header-title a:first-of-type {
    font-size: 20px;
    color: #fff;
    height: auto;
    background-color: transparent;
    border: none;
    line-height: 1;
}

.monthly-prev:after,
.monthly-next:after {
    border-color: #fff;
    opacity: 1;
    width: 15px;
    height: 15px;
}

.monthly-day,
.monthly-day-blank {
    position: relative;
    text-align: center;
}

.monthly-day-title-wrap {
    padding: 13px 0;
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
    color: #fff;
    border: none;
    outline: none;
    background-color: #039146;
}

.monthly-day-event>.monthly-day-number {
    position: absolute;
    widows: 20px;
    height: 70px;
    line-height: 70px;
    background-color: transparent;
    left: 0;
    right: 0;
    width: auto;
    margin: auto;
    transform: translateY(-50%);
    font-size: 20px;
    font-weight: 400;
    top: 50%;
    color: #333;
    &:after {
        transition: all .3s ease;
        z-index: -1;
        content: '';
        width: 50px;
        height: 50px;
        background-color: transparent;
        border-radius: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
    }
    &:hover {
        color: #fff;
        &:after {
            background: #31afea;
        }
    }
}

.monthly-today .monthly-day-number {
    &:after {
        content: '';
        width: 50px;
        height: 50px;
        display: inline-block;
        background-color: transparent;
        border-radius: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: auto;
        border: 2px solid #31afea;
    }
    &:hover {
        color: #fff;
        &:after {
            z-index: -1;
            content: '';
            width: 50px;
            height: 50px;
            background: #31afea;
        }
    }
}

@media only screen and (max-width: 500px) {
    .monthly-day-event>.monthly-day-number:after,
    .monthly-today .monthly-day-number:after {
        display: none;
    }
    .monthly-day-event>.monthly-day-number:hover {
        color: #333;
    }
}

@media only screen and (max-width: 991px) {
    .margin-sm-t-50 {
        margin-top: -50px;
    }
    .margin-sm-b-50 {
        margin-bottom: -50px;
    }
}