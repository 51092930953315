
// all post description - style default
.all-posts-descr {
  margin: 15px 0px;
}
@media (max-width: 767px) {
  .all-posts-descr {
    text-align: center;
    padding: 0px 15px;
    margin-bottom: 40px;
  }
}
.all-posts-descr h6 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #c0af94;
  font-style: normal;
  margin-top: -7px;
  margin-bottom: 27px;
  -webkit-font-smoothing: antialiased;
  @extend %subtitle-color;
}
@media (max-width: 991px) {
  .all-posts-descr h6 {
    margin-bottom: 10px;
  }
}
.all-posts-descr h5 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  color: #131313;
  margin-bottom: 20px;
  @extend %base-color;
}
.all-posts-descr p {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-family: "Libre Baskerville";
  font-weight: 400;
  color: #888888;
  margin-bottom: 30px;
  @extend %desc-color;
}

// all post description - style post-list
.post_list {
  .all-posts-descr {
    text-align: center;
    max-width: 770px;
    margin: 30px auto 58px auto;
    h5 {
      font-family: "BebasNeueBook";
      font-size: 70px;
      line-height: 77px;
      letter-spacing: 5.6px;
      font-weight: 400;
      margin-bottom: 10px;
    }
  }
}
@media (max-width: 768px) {
  .post_list {
    .all-posts-descr {
      margin: 30px auto;
      h5 {
        font-size: 34px;
        line-height: 40px;
      }
    }
  }
}
// all post description - style simple
.row.simple {
  .all-posts-descr {
    text-align: left;
    margin: 0 0 40px 0;
  }
}

// post - style default
.post-box {
  text-align: center;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 240px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  opacity: 1;
}
.post-box:hover {
  opacity: 1;
}
.post-box:before {
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}
.post-box .text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
  transition: all 0.5s ease;
}
@media (max-width: 767px) {
  .post-box {
    margin: 0px;
    margin-bottom: 5px;
  }
  .post-box .text {
    padding: 0px 15px;
  }
}
.post-box .text h6 {
  font-size: 24px;
  color: #ffffff;
  font-family: "BebasNeueRegular", sans-serif;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1.92px;
  margin-bottom: 10px;
  font-style: normal;
  transition: all 0.5s ease;
  @extend %base-color;
}
.post-box .text span {
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1.1px;
  color: #ffffff;
  font-weight: 400;
  transition: all 0.5s ease;
  -webkit-font-smoothing: antialiased;
  @extend %base-color;
}
.post-box:hover:before,
.post-box:hover .text h6,
.post-box:hover .text span {
  opacity: 0;
  transition: all 0.5s ease;
}

.post-box .post-descr {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 400ms ease;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
      transform: translateY(100%);
  background-color: #ffffff;
}
.post-box .post-descr .wrap {
  padding: 15px;
}
.post-box .post-descr h6 {
  font-size: 26px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.4;
  font-style: normal;
}
.post-box .post-descr p {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  opacity: .9;
  line-height: 24px;
}
.post-box .post-descr a {
  margin-top: 20px;
  font-weight: 300;
}
.post-box:hover .post-descr,
.post-box.active .post-descr {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
      transform: translateY(0);
}

// post - style post-list
.post_list {
  .post-box {
    height: auto;
    max-width: 770px;
    margin-left: auto;
    margin-right: auto;
    &:before {
      display: none;
    }
    &:hover {
      .text {
        .post-box-date {
          opacity: 1;
        }
      }
    }
    .post-box-img-wrapp {
      height: 470px;
      display: block;
      background-size: cover;
      background-position: top center;
      background-repeat: no-repeat;
    }
    .text {
      position: static;
      transform: none;
      margin: 30px 0 40px 0;
      .post-box-title {
        color: #131313;
        font-weight: 400;
        letter-spacing: 1.92px;
        margin-bottom: 0;
        @extend %base-color;
        a {
          text-decoration: none;
        }
      }
      .post-box-date {
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        letter-spacing: 1.1px;
        color: #c0af94;
        font-weight: 400;
        transition: all 0.5s ease;
        @extend %subtitle-color;
      }
    }
  }
}
@media only screen and (max-width: 991px) {
  .post_list {
    .post-box {
      .post-box-img-wrapp {
          height: 370px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .post_list {
    .post-box {
      .text {
        margin: 15px 0;
      }
    }
  }
}
// post - style simple
.row.simple {
  overflow-y: scroll;
  border-right: 2px solid #f0f0f0;
  @extend %form-border-color;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #c0af94;
    outline: none;
  }
}
.simple {
  margin-left: 0;
  margin-right: 0;
  .col-lg-12, .col-sm-12, .col-xs-12 {
    padding-right: 15px;
    padding-left: 0;
  }
  .post-box {
    height: auto;
    text-align: left;
    margin: 0 0 33px 0;
    &:before {
      display: none;
    }
    &:hover {
      .text {
        .post-box-date {
          opacity: 1;
        }
      }
    }
    .text {
      position: static;
      transform: none;
      .post-box-date {
        font-size: 12px;
        line-height: 20px;
        text-transform: uppercase;
        font-family: "Montserrat", sans-serif;
        letter-spacing: 1.1px;
        color: #c0af94;
        font-weight: 400;
        transition: all 0.5s ease;
        @extend %subtitle-color;
      }
      .post-box-title {
        color: #131313;
        font-weight: 400;
        letter-spacing: 1.92px;
        margin-bottom: 0;
        margin-top: 5px;
        @extend %base-color;
        a {
          text-decoration: none;
        }
      }
      .post-box-desc {
        font-size: 14px;
        line-height: 28px;
        letter-spacing: 1.12px;
        font-family: "Libre Baskerville";
        font-weight: 400;
        color: #888888;
        margin-bottom: 0;
        margin-top: 2px;
        @extend %desc-color;
        p {
          margin-bottom: 0;
          color: #888888;
          @extend %desc-color;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .simple {
    margin: 0 15px;
  }
  .simple {
    .post-box {
      .text {
        padding: 0;
      }
    }
  }
}


.scroll {
  background-color: rgb(192, 175, 148);
  position: absolute;
  left: 1379px;
  top: 191px;
  width: 4px;
  height: 30px;
  z-index: 10;
}