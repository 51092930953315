.price-1 {
    min-height: 100vh;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    > div{
        position: relative;
        z-index: 21;
    }
}