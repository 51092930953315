/*------------------------------------------------------------------

  Project:  Napoli
  Version:  1.6

  [Table of contents]

   Color SETTINGS
   DEFAULT STYLES
   STYLE GALLERY
   HEADER
   MENU
   ARROW LINKS
   HERO
   HERO SLIDER
   BANNER
   BANNER WITH GALLERY
   CALL TO ACTION
   ABOUT SECTION
   PORTFOLIO
   PORTFOLIO FILTER
   BLOCKQUOTE
   TEAM
   POSTS LIST
   TOGGLES
   SERVICES
   IMAGE SLIDER
   BLOG
   GOOGLE MAP
   CONTACT INFO
   CONTACT FORM
   PAGINATION
   FOOTER
   SIDEBAR
   PROTECTED PAGE
   SLIDER
   COMMENTS
   
  

/*-----------------------------------------------------------------*/
/* Color SETTINGS */
/*-----------------------------------------------------------------*/
/* WHITE COLOR */
body, a,
a:hover,
a:focus, .text-light a, .text-dark p, .text-light, .text-dark, .a-btn, .a-btn-2, .top-menu .logo span, .right-menu .logo span, .header_trans-fixed.header_top_bg .logo span, .header_trans-fixed.header_top_bg.open .logo span, #topmenu ul li a, .header_trans-fixed.header_top_bg #topmenu ul li a, .header_trans-fixed.header_top_bg.open #topmenu ul li a, .mob-nav, .header_trans-fixed .mob-nav i, .header_trans-fixed.open .mob-nav i, .napoli-top-social .social-icon, .header_trans-fixed .napoli-top-social .social-icon, .right-menu #topmenu .napoli-top-social .social li a, .header_trans-fixed .right-menu #topmenu .napoli-top-social .social li a, .top-banner .subtitle, .top-banner .title, .top-banner.center_content .title, .top-banner .descr, .banner-gallery .content-wrap .title, .about-section .title, .about-section .a-btn-2, .gallery-item .info-content h5, .modern .gallery-item .info-content h5, .fullwidth .gallery-item .info-content h5, .item-overlay > h5, .classic .item-overlay h5, .portfolio.classic.big .portfolio-title, .portfolio.big_gap .portfolio-title, .portfolio.masonry .item-portfolio-content .portfolio-title, .portfolio.grid .item-portfolio-content .portfolio-title, .single-proof_gallery .single-content > .title,
.single-proof_gallery .title, .single-proof_gallery .pixproof-data .grid__item .a-btn-2, .single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action:before, .single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__bg .proof-photo__id, body .dgwt-jg-gallery.justified-gallery .dgwt-jg-caption span, body .lg .lg-sub-html h4, .filter ul li.active, .team-member .social .wrap a, .team-member .info .title, .team-member.fullheight .info .title, .team-member.fullheight .info .description, .all-posts-descr h5, .post-box .text h6, .post-box .text span, .post_list .post-box .text .post-box-title, .simple .post-box .text .post-box-title, .services .content .title, .post-content h5, body .post-content .date, .single-post .title, .single-post .single-content blockquote p, .recent-post-single .recent-title, .sm-wrap-post .content .title, .sm-wrap-post .content .post-date .date, .contact-info .details h5, .contact-form .title, .wpcf7 form input:focus,
form.wpcf7-form input:focus,
form.wpcf7-form textarea:focus, .wpcf7 form input[type="submit"],
form.wpcf7-form #submit, #footer .copyright a, .widget_search input[type="text"], .sidebar-item ul li, .sidebar-item ul li a, .sidebar-item select, .protected-page .protected-title, .protected-page input[type="password"]:focus, .protected-page input[type="submit"], .banner-slider-wrap .title, .banner-slider-wrap .subtitle, .banner-slider-wrap .descr, .banner-slider-wrap .swiper-arrow-right, .comments .comment-reply-title, .comments .content .comment-reply-link:hover, .comments .person .author, .comments-title,
.comments-title span, #contactform h3,
.comments-form h3, #contactform textarea:focus,
#contactform input:focus,
.comments-form textarea:focus,
.comments-form input:focus, #contactform #submit,
.comments-form #submit, .about-details .content .title,
.simple-details .content .title, .about-details .content .text blockquote p, .titles .title, .insta-box .insta-box-follow, .insta-box .insta-box-follow a:hover, .post-nav a,
.pages,
.page-numbers:not('.next, .prev'), .post-nav a:hover,
.post-nav a:focus,
.page-numbers:hover,
.page-numbers:focus, body .next.page-numbers,
body .prev.page-numbers, .next.page-numbers:hover,
.prev.page-numbers:hover, .post-nav .pages,
.post-nav .current,
.pager-pagination .pages,
.pager-pagination .current, .woocommerce .napoli_product_detail div.product p.price ins,
.woocommerce .napoli_product_detail div.product span.price ins,
.woocommerce ul.products.default li.product .price ins,
.napoli_cart.shop_table ul .cart_item ul .product-price ins,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal ins,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price ins,
.woocommerce table.shop_table .cart_item .product-total ins, .woocommerce-page.woocommerce-cart .woocommerce input.button,
.woocommerce-page.woocommerce-checkout .woocommerce input.button, .woocommerce-page.woocommerce-cart a.button,
.woocommerce-page.woocommerce-checkout a.button,
.woocommerce-page.woocommerce a.button,
.woocommerce-page.woocommerce button.button.alt,
a.button.checkout, .woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a,
.woocommerce-page.woocommerce .woocommerce-message a.button, .woocommerce .napoli_product_detail .star-rating, .woocommerce .napoli_images span.onsale,
.woocommerce ul.products li.product .napoli-prod-list-image .onsale, .woocommerce .quantity .qty, .woocommerce .woocommerce-message .showcoupon,
.woocommerce .woocommerce-message a,
.woocommerce .woocommerce-info .showcoupon,
.woocommerce .woocommerce-info .showlogin, .napoli_product_detail .product .summary .product_title, .napoli_product_detail .product .summary .cart .variations .label label, .napoli_product_detail .product .summary .cart .variations .value ul li label, .napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li.active a, .napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel h2, .napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .meta, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-reply-title, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit, .napoli_product_detail div.product .up-sells h2,
.napoli_product_detail .product .related.products h2, .woocommerce ul.products li.product h3, .mini-cart-wrapper .napoli-shop-icon .cart-contents-count, #topmenu .napoli_mini_cart .product_list_widget .empty, #topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_name, .napoli_cart.shop_table .heading li, .napoli_cart.shop_table ul .cart_item ul .product-name a, .napoli_cart.shop_table ul .cart_item ul .product-name .variation dt, .napoli_cart.shop_table .complement-cart .coupon .input-text:focus, .napoli-cart-collaterals .cart_totals h2, .woocommerce form.checkout h3, .woocommerce form.login .form-row label,
.woocommerce form.checkout .form-row label, .woocommerce form.login .form-row input:focus,
.woocommerce form.login .form-row textarea:focus,
.woocommerce form.checkout .form-row input:focus,
.woocommerce form.checkout .form-row textarea:focus,
.woocommerce form.checkout_coupon .form-row input.input-text:focus, .woocommerce form.login .lost_password a, .select2-drop-active, .select2-results li.select2-highlighted, body .woocommerce table.shop_table thead .product-name,
body .woocommerce table.shop_table thead .product-total, .woocommerce table.shop_table .cart_item .product-name, .woocommerce table.shop_table .cart_item .product-name .variation dt, .woocommerce-checkout-review-order #payment div.payment_box, .napoli-sound-btn:before, .portfolio-single-content .portfolio-title, .portfolio-single-content .napoli-portfolio-footer-line .portfolio-client .client-title,
.portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type .title-job-type,
.portfolio-single-content .napoli-portfolio-footer-line .title-date, .iframe-video-title, .pricing-info .title, .coming-page-wrapper .title, .swipe-btn:before, .scroll-btn:before, .skill-wrapper .main-title, .flipto:before {
  color: #ffffff;
}

.bottom-line:after, .black, .highlight, .a-btn:hover, .a-btn-2:hover, .single-proof_gallery .pixproof-data .grid__item .a-btn-2:hover, .wpcf7 form input[type="submit"]:hover,
form.wpcf7-form #submit:hover, .widget_search input[type="submit"], .protected-page input[type="submit"]:hover, #contactform #submit:hover,
.comments-form #submit:hover, .woocommerce-page.woocommerce-cart .woocommerce input.button:hover,
.woocommerce-page.woocommerce-checkout .woocommerce input.button:hover, .woocommerce-page.woocommerce-cart a.button:hover,
.woocommerce-page.woocommerce-checkout a.button:hover,
.woocommerce-page.woocommerce a.button:hover,
.woocommerce-page.woocommerce button.button.alt:hover,
a.button.checkout:hover, .woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a:hover,
.woocommerce-page.woocommerce .woocommerce-message a.button:hover, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit:hover {
  background-color: #fff;
}

.a-btn:hover, .a-btn-2:hover, .single-proof_gallery .pixproof-data .grid__item .a-btn-2:hover, .wpcf7 form input[type="submit"]:hover,
form.wpcf7-form #submit:hover, .widget_search input[type="text"], .widget_search input[type="submit"], .widget_tag_cloud .tagcloud a, .sidebar-item h5, .sidebar-item input, .protected-page input[type="submit"]:hover, #contactform #submit:hover,
.comments-form #submit:hover, .woocommerce-page.woocommerce-cart .woocommerce input.button:hover,
.woocommerce-page.woocommerce-checkout .woocommerce input.button:hover, .woocommerce-page.woocommerce-cart a.button:hover,
.woocommerce-page.woocommerce-checkout a.button:hover,
.woocommerce-page.woocommerce a.button:hover,
.woocommerce-page.woocommerce button.button.alt:hover,
a.button.checkout:hover, .woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a:hover,
.woocommerce-page.woocommerce .woocommerce-message a.button:hover, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit:hover {
  border-color: #fff;
}

/* LIGHT WHITE COLOR */
.a-btn, .a-btn-2, .single-proof_gallery .pixproof-data .grid__item .a-btn-2, .wpcf7 form input[type="submit"],
form.wpcf7-form #submit, .protected-page input[type="submit"], #contactform #submit,
.comments-form #submit, .woocommerce-page.woocommerce-cart .woocommerce input.button,
.woocommerce-page.woocommerce-checkout .woocommerce input.button, .woocommerce-page.woocommerce-cart a.button,
.woocommerce-page.woocommerce-checkout a.button,
.woocommerce-page.woocommerce a.button,
.woocommerce-page.woocommerce button.button.alt,
a.button.checkout, .woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a,
.woocommerce-page.woocommerce .woocommerce-message a.button, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit {
  border-color: rgba(255, 255, 255, 0.5);
}

/* DARK COLOR */
.text-light p, .highlight, .a-btn:hover, .a-btn-2:hover, .about-section .a-btn-2:hover, .single-proof_gallery .pixproof-data .grid__item .a-btn-2:hover, .wpcf7 form input[type="submit"]:hover,
form.wpcf7-form #submit:hover, .widget_search input[type="submit"], .sidebar-item select option, .sidebar-item input, .protected-page input[type="submit"]:hover, #contactform #submit:hover,
.comments-form #submit:hover, .woocommerce-page.woocommerce-cart .woocommerce input.button:hover,
.woocommerce-page.woocommerce-checkout .woocommerce input.button:hover, .woocommerce-page.woocommerce-cart a.button:hover,
.woocommerce-page.woocommerce-checkout a.button:hover,
.woocommerce-page.woocommerce a.button:hover,
.woocommerce-page.woocommerce button.button.alt:hover,
a.button.checkout:hover, .woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a:hover,
.woocommerce-page.woocommerce .woocommerce-message a.button:hover, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit:hover {
  color: #131313;
}

.text-light .bottom-line:after, .white {
  background-color: #131313;
}

/* BLACK BACKGROUND-COLOR */
body, .header_top_bg, .header_trans-fixed.header_top_bg.open, #topmenu, .banner-gallery .content-wrap, .banner-list li, .about-details .content .text blockquote, .select2-drop-active, .pricing-item, .ms-section {
  background-color: #1b1b1b;
}

/* GOLD COLOR */
.top-banner.left_content .subtitle, .top-banner.center_content .subtitle, .about-section .subtitle, .fullwidth .gallery-item .info-content .subtitle, .portfolio.classic.big .categories, .portfolio.grid .item-portfolio-content .category, .portfolio.grid .item-portfolio-content .category a, body .single-proof_gallery .pixproof-data .grid__item .entry__meta-box .meta-box__title, .team-member .info .position, .all-posts-descr h6, .post_list .post-box .text .post-box-date, .simple .post-box .text .post-box-date, .single-post .date-post, .protected-page form, .comments .person .comment-date, .about-details .content .subtitle,
.simple-details .content .subtitle, .single-pagination > div a.content:hover, .single-pagination > div a.content i, .woocommerce .napoli_product_detail div.product p.price,
.woocommerce .napoli_product_detail div.product span.price,
.woocommerce ul.products.default li.product .price,
.napoli_cart.shop_table ul .cart_item ul .product-price,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price,
.woocommerce table.shop_table .cart_item .product-total, .napoli_product_detail .product .summary .product_meta a, .napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .date_publish, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-rating label, .woocommerce ul.products li.product .napoli-prod-list-image .napoli-link, .napoli-woocommerce-pagination .nav-links .nav-previous a:hover,
.napoli-woocommerce-pagination .nav-links .nav-next a:hover, .mini-cart-wrapper .napoli-shop-icon:before, .napoli-cart-collaterals .cart_totals .shop_table ul li span, .woocommerce table.shop_table .woocommerce-Price-amount, .portfolio-single-content .portfolio-categories a, .pricing-info .subtitle, .coming-soon-descr li, .swipe-btn:hover:before, .scroll-btn:hover:before, .skill-wrapper .skill .label-skill, .skill-wrapper .skill .value, .flipto:hover:before {
  color: #cfac7f;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected .meta__action.select-action, .woocommerce .napoli_images span.onsale,
.woocommerce ul.products li.product .napoli-prod-list-image .onsale, .woocommerce .woocommerce-message,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-error, .napoli_product_detail .product .summary .cart .variations .value ul li input:checked + label:before, .woocommerce form.login .form-row input[type="checkbox"]:checked + label.checkbox:before,
.woocommerce form.checkout .form-row input[type="checkbox"]:checked + label.checkbox:before, .napoli-sound-btn, .iframe-video .video-content .play-button:hover {
  background-color: #cfac7f;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected .meta__action.select-action, .single-post .single-content blockquote, .wpcf7 form input:focus,
form.wpcf7-form input:focus,
form.wpcf7-form textarea:focus, .protected-page input[type="password"]:focus, #contactform textarea:focus,
#contactform input:focus,
.comments-form textarea:focus,
.comments-form input:focus, .single blockquote,
.about-details .content blockquote,
.about-section .content blockquote,
.portfolio-single-content blockquote, .about-details .content .text blockquote, .woocommerce .woocommerce-message,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-error, .napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li.active, .napoli_cart.shop_table .complement-cart .coupon .input-text:focus, .woocommerce form.login .form-row input:focus,
.woocommerce form.login .form-row textarea:focus,
.woocommerce form.checkout .form-row input:focus,
.woocommerce form.checkout .form-row textarea:focus,
.woocommerce form.checkout_coupon .form-row input.input-text:focus, .select2-drop-active {
  border-color: #cfac7f;
}

/* GREY COLOR */
.top-banner.center_content .descr, .banner-gallery .content-wrap .description, .about-section .descr, .single-proof_gallery .pixproof-data .grid__item .entry__meta-box span, body .lg .lg-sub-html .dgwt-jg-item-desc, .filter ul li, .all-posts-descr p, .simple .post-box .text .post-box-desc, .simple .post-box .text .post-box-desc p, .services .content .text, .single-post p, body .contact-info, .contact-info .details a,
.contact-info .details h6, form.wpcf7-form input,
form.wpcf7-form textarea, #footer .copyright, #footer .social-links a, .protected-page input[type="password"], .comments .content .comment-reply-link, .comments .content .text, #contactform textarea,
#contactform input,
.comments-form textarea,
.comments-form input, blockquote cite, .about-details .content .text,
.simple-details .content .text, .titles .subtitle, .insta-box .insta-box-follow a, .single-pagination > div a.content, .woocommerce .napoli_product_detail div.product p.price del,
.woocommerce .napoli_product_detail div.product span.price del,
.woocommerce ul.products.default li.product .price del,
.napoli_cart.shop_table ul .cart_item ul .product-price del,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal del,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price del,
.woocommerce table.shop_table .cart_item .product-total del, .napoli_product_detail .product .summary .woocommerce-product-rating .woocommerce-review-link, .napoli_product_detail .product .summary .product_desc p, .napoli_product_detail .product .summary .cart .variations .value ul li p, .napoli_product_detail .product .summary .product_meta, .napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li a, .napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel p, .napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .description, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper input,
.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper textarea, .woocommerce ul.products li.product, .woocommerce ul.products li.product .category-product a, .napoli-woocommerce-pagination .nav-links .nav-previous a,
.napoli-woocommerce-pagination .nav-links .nav-next a, #topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_quantity, .napoli_cart.shop_table ul .cart_item ul .product-name .variation dd p, .napoli_cart.shop_table .complement-cart .coupon .input-text, .napoli-cart-collaterals .cart_totals .shop_table ul li, .woocommerce form.checkout_coupon .form-row input.input-text, .woocommerce form.login .form-row input,
.woocommerce form.login .form-row textarea,
.woocommerce form.checkout .form-row input,
.woocommerce form.checkout .form-row textarea, .select2-container .select2-choice, .select2-search:after, body .select2-search input, body .select2-results li, .woocommerce table.shop_table .cart_item .product-name .variation dd p, .woocommerce table.shop_table .cart-subtotal th,
.woocommerce table.shop_table .order-total th, .woocommerce-checkout-review-order #payment .payment_methods.methods li, .woocommerce-checkout-review-order #payment .payment_methods.methods li label, .woocommerce-checkout-review-order #payment .payment_methods.methods li .about_paypal, .portfolio-single-content p, .portfolio-single-content .napoli-portfolio-footer-line .portfolio-client .client-value,
.portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type .job-type-value,
.portfolio-single-content .napoli-portfolio-footer-line .date-value,
.portfolio-single-content .napoli-portfolio-footer-line .social-list a, .pricing-info .pricing-list ul li, .coming-page-wrapper .subtitle {
  color: #707070;
}

.skill-wrapper .skill .line .active-line {
  background-color: #707070;
}

.top-banner.center_content, .woocommerce .quantity .qty, .napoli_product_detail .product .summary .cart .variations .value fieldset, .woocommerce form.checkout_coupon,
.woocommerce form.login, .woocommerce form.checkout_coupon .form-row input.input-text, .woocommerce form.login .form-row input,
.woocommerce form.login .form-row textarea,
.woocommerce form.checkout .form-row input,
.woocommerce form.checkout .form-row textarea, body .select2-search input, .portfolio-single-content .napoli-portfolio-footer-line {
  border-color: #252525;
}

/* DARK DARK BLCK */
.about-section, .services, .single-post .single-content blockquote, .sm-wrap-post a, body .contact-info, #footer,
body #footer, .single blockquote,
.about-details .content blockquote,
.about-section .content blockquote,
.portfolio-single-content blockquote, .about-details-section, .post-nav a,
.pages,
.page-numbers:not('.next, .prev'), .napoli_mini_cart, body .select2-results, .woocommerce-checkout-review-order #payment div.payment_box, .coming-soon-wrap .coming-soon-descr, .swipe-btn, .scroll-btn, .skill-wrapper .skill .line, .flipto {
  background-color: #151515;
}

.row.simple, form.wpcf7-form input,
form.wpcf7-form textarea, .protected-page input[type="password"], #contactform textarea,
#contactform input,
.comments-form textarea,
.comments-form input, .napoli_product_detail .product .woocommerce-tabs .tabs.wc-tabs:before, .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper input,
.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper textarea, .napoli_cart.shop_table .heading, .napoli_cart.shop_table .complement-cart, .napoli_cart.shop_table .complement-cart .coupon .input-text, .select2-container.select2-dropdown-open.select2-drop-above .select2-choice, .woocommerce table.shop_table thead, .woocommerce table.shop_table tfoot {
  border-color: #3a3a3a;
}

.mini-cart-wrapper .napoli-shop-icon,
.header_trans-fixed .mini-cart-wrapper .napoli-shop-icon {
  background-color: #202020;
}

.napoli_product_detail .product .summary .cart .variations .value ul li label:before {
  background-color: #505050;
}

body .napoli_product_detail .product .summary .cart .button:hover {
  color: #131313;
}

.woocommerce form.login .form-row label.checkbox:before,
.woocommerce form.checkout .form-row label.checkbox:before {
  background-color: #505050;
}

.woocommerce-checkout-review-order #payment div.payment_box:before {
  border-bottom-color: #151515;
}

.coming-soon #coming-base {
  fill: #151515;
}

.widget_search input[type="text"],
.sidebar-item input {
  background-color: transparent;
}

.sidebar-item select {
  background-color: transparent;
}

.select2-container .select2-choice {
  border-color: transparent;
  border-bottom-color: #3a3a3a;
}

.fullwidth .gallery-item .info-content {
  background-color: rgba(0, 0, 0, 0.9);
}

.portfolio.classic.big .item-overlay {
  background-color: #000000;
}

.pricing-item .mask-image .pricing_mask {
  fill: #1b1b1b;
}

.simple::-webkit-scrollbar-thumb {
  background-color: #cfac7f;
}

@media only screen and (max-width: 1199px) {
  .right-menu #topmenu .napoli-top-social .social {
    background-color: #1b1b1b;
  }
  .header_trans-fixed .right-menu #topmenu .napoli-top-social .social {
    background-color: transparent;
  }
}

@media (min-width: 768px) {
  #topmenu ul ul {
    background-color: #151515;
  }
  #topmenu > ul > li > ul:before {
    border-bottom: 5px solid #151515;
  }
  #topmenu ul ul li a {
    color: #ffffff;
  }
  #topmenu ul ul li.current-menu-item > a {
    color: #cfac7f;
  }
  .header_trans-fixed.header_top_bg #topmenu ul ul li.current-menu-item > a {
    color: #cfac7f;
  }
}

@media (max-width: 767px) {
  .header_trans-fixed.open .right-menu #topmenu .napoli-top-social .social li a {
    color: #ffffff;
  }
}

/*---------------------------------------------------------------*/
/*  DEFAULT STYLES
/*---------------------------------------------------------------*/
@font-face {
  font-family: 'BebasNeueBook';
  src: url("../fonts/BebasNeueBook.eot");
  src: url("../fonts/BebasNeueBook.woff2") format("woff2"), url("../fonts/BebasNeueBook.woff") format("woff"), url("../fonts/BebasNeueBook.ttf") format("truetype"), url("../fonts/BebasNeueBook.svg#BebasNeueBook") format("svg"), url("../fonts/BebasNeueBook.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'BebasNeueRegular';
  src: url("../fonts/BebasNeueRegular.eot");
  src: url("../fonts/BebasNeueRegular.woff2") format("woff2"), url("../fonts/BebasNeueRegular.woff") format("woff"), url("../fonts/BebasNeueRegular.ttf") format("truetype"), url("../fonts/BebasNeueRegular.svg#BebasNeueRegular") format("svg"), url("../fonts/BebasNeueRegular.eot?#iefix") format("embedded-opentype");
  font-weight: normal;
  font-style: normal;
}

body,
html {
  height: 100%;
}

.no-scroll {
  overflow-y: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.swiper-container,
.full_screen_slider {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  direction: ltr;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: inherit;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  -webkit-transition-property: -webkit-transform, left, top;
  -webkit-transition-duration: 0s;
  -webkit-transform: translate3d(0px, 0, 0);
  -webkit-transition-timing-function: ease;
  -moz-transition-property: -moz-transform, left, top;
  -moz-transition-duration: 0s;
  -moz-transform: translate3d(0px, 0, 0);
  -moz-transition-timing-function: ease;
  -o-transition-property: -o-transform, left, top;
  -o-transition-duration: 0s;
  -o-transform: translate3d(0px, 0, 0);
  -o-transition-timing-function: ease;
  -o-transform: translate(0px, 0px);
  -ms-transition-property: -ms-transform, left, top;
  -ms-transition-duration: 0s;
  -ms-transform: translate3d(0px, 0, 0);
  -ms-transition-timing-function: ease;
  transition-property: left, top, -webkit-transform;
  transition-property: transform, left, top;
  transition-property: transform, left, top, -webkit-transform;
  transition-duration: 0s;
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
  transition-timing-function: ease;
  box-sizing: content-box;
  cursor: s-resize;
  margin: 0 auto;
  height: inherit;
  float: left;
}

.swiper-slide {
  float: left;
  height: inherit;
  position: relative;
  width: 100%;
}

body {
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  letter-spacing: .02em;
  font-size: 15px;
  line-height: 26px;
  color: #131313;
  position: relative;
  background: #ffffff;
}

html {
  font-size: 15px;
}

html {
  font-size: 15px;
}

.main-wrapper {
  min-height: 100vh;
}

::-moz-selection {
  background: #000;
  color: #ffffff;
}

::selection {
  background: #000;
  color: #ffffff;
}

::-moz-selection {
  background: #000;
  color: #ffffff;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  -webkit-font-smoothing: antialiased;
}

:focus {
  outline: 0;
}

a,
a:hover,
a:focus {
  color: #131313;
  outline: 0;
  transition: all 300ms ease;
  text-decoration: none;
}

a:hover,
a:focus {
  opacity: .7;
}

a {
  text-decoration: none;
}

.text-light a {
  color: #ffffff;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
  border: none;
}

.clip {
  clip: rect(auto, auto, auto, auto);
  height: 100%;
  position: absolute;
  width: 100%;
}

/* typography
---------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "BebasNeueRegular", sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 44px;
  line-height: 54px;
}

h2 {
  font-size: 36px;
  line-height: 48px;
}

h3 {
  font-size: 30px;
  line-height: 36px;
}

h4 {
  font-size: 24px;
  line-height: 30px;
}

h5 {
  font-size: 18px;
  line-height: 24px;
}

h6 {
  font-size: 14px;
  line-height: 18px;
  font-style: italic;
}

p {
  font-family: "Libre Baskerville";
}

/* Responsive typography
---------------------------*/
@media (max-width: 768px) {
  body {
    font-size: 14px;
    line-height: 23px;
  }
  h1 {
    font-size: 34px;
    line-height: 40px;
  }
  h2 {
    font-size: 30px;
    line-height: 35px;
  }
  h3 {
    font-size: 26px;
    line-height: 29px;
  }
  h4 {
    font-size: 22px;
    line-height: 24px;
  }
  h5 {
    font-size: 17px;
    line-height: 19px;
  }
  h6 {
    font-size: 13px;
    line-height: 15px;
  }
}

.bottom-line {
  position: relative;
  margin-bottom: 30px;
}

.bottom-line:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 1px;
  background: #111;
  bottom: -13px;
}

.text-light .bottom-line:after {
  background: #ffffff;
}

.text-light p {
  color: #ffffff;
}

.text-dark p {
  color: #000;
}

.monospace {
  font-family: 'Source Code Pro';
  color: #494949;
}

.text-light .monospace {
  color: #D0D0D0;
}

h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child {
  margin-top: 0;
}

.bigtext {
  font-size: 160px;
  line-height: 1;
  font-family: "Libre Baskerville", sans-serif;
  -webkit-font-smoothing: antialiased;
}

.notfound {
  text-decoration: none;
  transition: all 300ms ease;
}

.notfound:hover {
  opacity: .7;
}

.font-1 {
  font-family: "Libre Baskerville", sans-serif;
}

@media (max-width: 992px) {
  .bigtext {
    font-size: 130px;
  }
}

@media (max-width: 768px) {
  .bigtext {
    font-size: 70px;
  }
}

.text-light {
  color: #ffffff;
}

.text-dark {
  color: #131313;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

/*helper classes*/
.fullwidth {
  width: 100%;
}

.fullheight {
  height: 100%;
}

.vc_column_container {
  z-index: 30;
}

.grey {
  background-color: #F7F7F7;
}

.black {
  background-color: #000;
}

.white {
  background-color: #ffffff;
}

.gray {
  background-color: #f0f0f0;
}

.vCenter {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.highlight {
  background-color: #000;
  color: #ffffff;
}

.overflow {
  overflow: hidden;
}

.video-container iframe {
  border: 0;
}

body .vimeo-video {
  width: 100%;
  height: 100%;
}

/*---------------------------*/
/* buttons
---------------------------*/
.a-btn,
.a-btn-2 {
  font-size: 11px;
  line-height: 20px;
  display: inline-block;
  letter-spacing: 1.1px;
  font-family: "Montserrat";
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
}

.a-btn {
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  border-left: none;
  border-right: none;
  color: #ffffff;
}

.a-btn:hover {
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  color: #000;
  background-color: #ffffff;
  opacity: 1;
}

.a-btn-2 {
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  color: #131313;
}

.a-btn-2:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  border-left: none;
  border-right: none;
  color: #ffffff;
  background-color: #131313;
  opacity: 1;
}

/* overlays
---------------------------*/
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.overlay-dark {
  background-color: rgba(0, 0, 0, 0.25);
}

.overlay-dark-2x {
  background-color: rgba(0, 0, 0, 0.7);
}

[class*="overlay-dark"] ~ *,
[class*="overlay-light"] ~ * {
  position: relative;
}

/* parallax images
---------------------------*/
[data-stellar-background-ratio] {
  background-attachment: fixed;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.bg-cover {
  background-size: cover;
  background-position: center;
}

.bg-fixed {
  background-attachment: fixed;
}

@media (max-width: 1024px) {
  [data-stellar-background-ratio],
  body [data-stellar-background-ratio] {
    background-attachment: scroll;
    background-position-y: center;
  }
  .bg-fixed {
    background-attachment: scroll;
  }
}

/* bootstrap columns
---------------------------*/
.container,
section,
section > * {
  position: relative;
}

.container.no-padding [class*="col-"] {
  padding: 0;
}

.col-center {
  float: none;
  margin: 0 auto;
}

.col-right {
  float: right;
}

.equal-height [class*="col-"] {
  margin-bottom: 0;
}

.pad-0 {
  margin-bottom: 0;
}

.svgRect {
  -webkit-mask: url(#cfda4cf);
  mask: url(#cfda4cf);
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/*  STYLE GALLERY
/*---------------------------------------------------------------*/
.select-for-hovers h5 {
  display: inline-block;
  padding: 2px 8px;
  font-weight: 400;
  text-decoration: none;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville",sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span {
  position: relative;
  padding: 1px 20px 1px 10px;
  display: inline-block;
  font-weight: 400;
  border: 1px solid #888888;
  text-decoration: none;
  min-width: 200px;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville",sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span::after {
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: 5px;
}

.select-for-hovers {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
  margin-top: 20px;
}

.select-for-hovers .content {
  position: relative;
  text-align: right;
  width: 100%;
}

.select-for-hovers .hover {
  display: inline-block;
  position: relative;
}

.select-for-hovers .hover .list {
  text-align: left;
  position: absolute;
  background-color: #fff;
  margin-left: 0;
  z-index: 10;
  width: 100%;
  border: 1px solid #131313;
  display: none;
}

.select-for-hovers .hover .list li {
  list-style: none;
  font-weight: 400;
  padding: 1px 8px;
  cursor: pointer;
  background-color: transparent;
  transition: all 350ms ease;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville",sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover .list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/*  PRELOADER
/*---------------------------------------------------------------*/
.select-for-hovers h5 {
  display: inline-block;
  padding: 2px 8px;
  font-weight: 400;
  text-decoration: none;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville",sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span {
  position: relative;
  padding: 1px 20px 1px 10px;
  display: inline-block;
  font-weight: 400;
  border: 1px solid #888888;
  text-decoration: none;
  min-width: 200px;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville",sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span::after {
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: 5px;
}

.select-for-hovers {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
  margin-top: 20px;
}

.select-for-hovers .content {
  position: relative;
  text-align: right;
  width: 100%;
}

.select-for-hovers .hover {
  display: inline-block;
  position: relative;
}

.select-for-hovers .hover .list {
  text-align: left;
  position: absolute;
  background-color: #fff;
  margin-left: 0;
  z-index: 10;
  width: 100%;
  border: 1px solid #131313;
  display: none;
}

.select-for-hovers .hover .list li {
  list-style: none;
  font-weight: 400;
  padding: 1px 8px;
  cursor: pointer;
  background-color: transparent;
  transition: all 350ms ease;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville",sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover .list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/*---------------------------------------------------------------*/
/*  HEADER
/*---------------------------------------------------------------*/
header {
  position: relative;
  width: 100%;
  z-index: 999;
  text-align: center;
}

@media (max-width: 767px) {
  header {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

header.absolute {
  position: absolute;
  margin-bottom: 0;
}

header .logo {
  margin-top: 20px;
  margin-bottom: 15px;
}

header a.logo {
  text-decoration: none;
}

iframe[name="advpp_manager"] {
  position: absolute;
  left: -9999px;
}

.header_top_bg {
  background-color: #ffffff;
}

.header_top_bg.enable_fixed.fixed {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
}

.header_trans-fixed.header_top_bg {
  background-color: transparent;
  position: fixed;
  z-index: 1000;
  padding-left: 15px;
  padding-right: 15px;
  top: 0;
  width: 100%;
}

.header_trans-fixed.header_top_bg.open {
  background-color: #fff;
}

@media (min-width: 768px) {
  .header_trans-fixed #topmenu {
    background-color: transparent;
  }
}

/*Text logo*/
.top-menu {
  padding-bottom: 10px;
}

.top-menu .logo {
  display: inline-block;
}

.top-menu .logo span {
  font-family: "BebasNeueRegular";
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 1.76px;
  min-width: 170px;
  text-align: center;
  background-color: transparent;
  color: #131313;
  display: inline-block;
}

@media (min-width: 992px) {
  .top-menu .logo span {
    padding: 24px 10px;
  }
}

@media (max-width: 991px) {
  .top-menu .logo span {
    padding: 15px 10px;
  }
}

@media (min-width: 767px) {
  .top-menu {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .right-menu .logo {
    float: left;
    margin: 0;
  }
  .right-menu .logo img {
    margin-top: 13px;
  }
}

.right-menu .logo span {
  vertical-align: middle;
  text-align: left;
  margin-bottom: 13px;
  font-family: "BebasNeueRegular";
  font-size: 22px;
  line-height: 24px;
  font-weight: 400;
  letter-spacing: 1.76px;
  padding-top: 12px;
  padding-bottom: 0;
  margin: 0;
  color: #131313;
}

.header_trans-fixed.header_top_bg .logo span {
  color: #fff;
}

.header_trans-fixed.header_top_bg.open .logo span {
  color: #131313;
}

@media (min-width: 992px) {
  .right-menu .logo span {
    float: left;
  }
}

/*---------------------------------------------------------------*/
/* MENU
/*---------------------------------------------------------------*/
#topmenu {
  width: 100%;
  text-align: center;
  background: #ffffff;
}

#topmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

#topmenu ul li {
  display: inline-block;
  position: relative;
}

#topmenu ul li a {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  font-weight: 400;
  display: block;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 20px 5px;
  line-height: 45px;
  letter-spacing: 0.96px;
  transition: all .3s ease;
  -webkit-font-smoothing: antialiased;
}

.header_trans-fixed.header_top_bg #topmenu ul li a {
  color: #fff;
}

.header_trans-fixed.header_top_bg.open #topmenu ul li a {
  color: #131313;
}

.top-menu #topmenu ul li a {
  padding: 0;
  margin-bottom: 5px;
}

#topmenu .social .fa {
  font-size: 15px;
}

/*Sub menu*/
#topmenu ul ul {
  position: absolute;
  z-index: 999;
  left: 0px;
  top: 50px;
  min-width: 215px;
  display: none;
  box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

@media (min-width: 768px) {
  #topmenu ul ul {
    background-color: rgba(19, 19, 19, 0.8);
    padding: 10px 0px;
  }
}

@media (min-width: 768px) {
  .top-menu #topmenu ul ul {
    left: -20px;
  }
}

@media (max-width: 767px) {
  #topmenu ul li ul {
    box-shadow: none;
    font-style: normal;
  }
  #topmenu ul {
    box-shadow: none;
    font-style: normal;
  }
}

@media (min-width: 768px) {
  #topmenu > ul > li > ul:before {
    position: absolute;
    content: '';
    top: -5px;
    left: 20px;
    width: 0px;
    height: 0px;
    border-top: none;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid rgba(19, 19, 19, 0.8);
  }
}

@media (min-width: 768px) {
  #topmenu ul ul li {
    display: block;
    margin-bottom: 5px;
  }
  #topmenu ul ul li:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .top-menu #topmenu ul li {
    margin: 0px 10px 5px 10px;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .top-menu #topmenu ul li {
    margin: 0px 20px 5px 20px;
    padding: 0;
  }
}

#topmenu ul ul li::before {
  content: '';
  display: table;
  clear: both;
}

#topmenu ul ul li a {
  font-size: 12px;
  line-height: 26px;
  padding: 0px 20px;
  font-family: "Montserrat";
  letter-spacing: 0.72px;
  display: block;
  width: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 768px) {
  body #topmenu ul ul li a {
    color: #ffffff;
    text-transform: none;
  }
}

@media (min-width: 768px) {
  #topmenu ul ul li.current-menu-item > a {
    color: #c0af94;
  }
  .header_trans-fixed.header_top_bg #topmenu ul ul li.current-menu-item > a {
    color: #c0af94;
  }
}

#topmenu ul ul li a::before {
  content: none;
}

body #topmenu > ul > li > ul > li:hover ul {
  display: block;
}

#topmenu > ul > li > ul > li > ul {
  left: 101%;
  top: -15px;
}

.mob-nav {
  display: none;
  width: 20px;
  height: 20px;
  margin: 0 auto 12px;
  font-size: 14px;
  color: #131313;
  opacity: 1;
}

.mob-nav:hover {
  opacity: 0.7;
}

.header_trans-fixed .mob-nav i {
  color: #fff;
}

.header_trans-fixed.open .mob-nav i {
  color: #131313;
}

@media (max-width: 767px) {
  .top-menu .logo {
    margin-bottom: 0;
    margin-top: 0;
  }
  body .no-padd-mob {
    padding: 0;
  }
  .right-menu #topmenu .menu li.menu-item-has-children > a,
  #topmenu .menu li.menu-item-has-children > a {
    position: relative;
  }
  #topmenu ul li a:hover {
    opacity: 1;
  }
  .mob-nav {
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  #topmenu {
    display: none;
    overflow-y: auto;
    height: 100vh;
    position: absolute;
    padding-bottom: 55px;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 50px;
    text-align: left;
    padding-top: 20px;
  }
  #topmenu ul ul {
    display: block;
    position: static;
  }
  body #topmenu ul.menu > li > ul > li > ul {
    display: block;
  }
  #topmenu ul.menu {
    width: 100%;
  }
  body #topmenu ul.menu li {
    display: block;
    float: none;
    margin-bottom: 0px;
  }
  body #topmenu ul.menu li a {
    padding: 5px 10px;
    line-height: 25px;
    display: block;
    width: 100%;
    float: none;
    transition: all 0.5s ease;
  }
  #topmenu ul.menu li a:hover {
    opacity: 0.7;
    transition: all 0.5s ease;
  }
  /*1 level menu*/
  #topmenu > ul.menu > li {
    margin-bottom: 15px;
  }
  #topmenu > ul.menu > li > a {
    font-size: 14px;
  }
  /*2 level menu*/
  #topmenu > ul.menu > li > ul > li {
    margin: 0px 15px;
    border: 1px solid #f1f2f3;
    margin-top: -1px;
  }
  /*3 level menu*/
  #topmenu > ul.menu > li > ul > li > ul > li {
    margin: 0px 15px;
    border: 1px solid #f1f2f3;
    margin-top: -1px;
  }
  #topmenu > ul.menu > li > ul > li > ul > li:last-child {
    margin-bottom: 20px;
  }
  body #topmenu .social li a {
    padding: 0px 10px;
    line-height: 25px;
  }
}

@media (min-width: 768px) {
  #topmenu > ul > li:hover ul {
    display: block;
  }
  body #topmenu {
    display: block;
  }
  #topmenu > ul > li > ul > li > ul {
    left: -100%;
    top: -15px;
  }
  .sub-menu li a {
    z-index: 1999;
  }
  body .pl30md {
    padding-left: 30px;
    padding-right: 0;
  }
}

@media (min-width: 768px) {
  /*Top menu*/
  .right-menu {
    max-width: 1170px;
    width: 100%;
    margin: auto;
    display: table;
    padding: 22px 0 13px;
  }
  .right-menu #topmenu {
    text-align: right;
  }
  .right-menu #topmenu ul ul {
    left: 10px;
    top: 50px;
  }
  .top-menu #topmenu ul ul {
    left: -20px;
    top: 50px;
  }
  .right-menu #topmenu > ul > li > ul > li > ul {
    left: 100%;
    top: -10px;
  }
  .top-menu #topmenu > ul > li > ul > li > ul {
    left: 100%;
    top: -10px;
  }
  .right-menu #topmenu .social {
    text-align: right;
    margin-left: 40px;
    vertical-align: top;
  }
  .right-menu #topmenu .social li a {
    padding: 0;
    margin-left: 18px;
  }
  .right-menu #topmenu .social li a:hover {
    opacity: 0.5;
    transition: all 0.5s ease;
  }
  .right-menu #topmenu .social li a::after,
  .right-menu #topmenu .social li a::before {
    content: none;
  }
  .right-menu #topmenu > ul > li > a {
    position: relative;
    padding: 0 0 5px 0px;
    margin-left: 30px;
  }
  .right-menu #topmenu > ul > li > a:hover,
  .top-menu #topmenu > ul > li > a:hover {
    opacity: 1;
  }
  .right-menu #topmenu > ul > li > a::before,
  .top-menu #topmenu > ul > li > a::before {
    position: absolute;
    content: '';
    width: 0%;
    height: 4px;
    bottom: 10px;
    left: 0;
    background-image: url("../images/menu-border.svg");
    background-repeat-x: repeat;
    z-index: 10;
    transition: all 0.5s ease;
  }
  .right-menu #topmenu > ul > li > a:hover::before,
  .top-menu #topmenu > ul > li > a:hover::before {
    width: 100%;
    transition: all 0.5s ease;
  }
  .right-menu #topmenu > ul > li.current-menu-item > a::before,
  .top-menu #topmenu > ul > li.current-menu-item > a::before,
  .right-menu #topmenu > ul > li.current-menu-parent > a::before,
  .top-menu #topmenu > ul > li.current-menu-parent > a::before {
    width: 0%;
  }
  .right-menu #topmenu > ul > li.current-menu-item > a,
  .top-menu #topmenu > ul > li.current-menu-item > a,
  .right-menu #topmenu > ul > li.current-menu-parent > a,
  .top-menu #topmenu > ul > li.current-menu-parent > a {
    opacity: 0.7;
    transition: all 0.5s ease;
  }
  .right-menu .logo img {
    margin-bottom: 12px;
    max-height: 75px;
  }
}

@media (max-width: 767px) {
  .right-menu #topmenu .social {
    display: block;
  }
  .right-menu #topmenu .social li {
    display: inline-block;
  }
  .right-menu #topmenu .social li a {
    padding: 0 10px 5px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .right-menu {
    text-align: left;
  }
  .right-menu .logo {
    display: inline-block;
    margin-top: 9px;
    margin-bottom: 0;
  }
  body .right-menu #topmenu {
    display: inline-block;
    width: auto;
    float: right;
  }
  .right-menu #topmenu ul ul {
    left: -5px;
  }
  .right-menu #topmenu .menu > li > a {
    margin-left: 15px;
  }
  .right-menu #topmenu .social {
    margin-left: 0px;
    float: right;
  }
}

/*================================================*/
/* TOP SOCIALS */
/*================================================*/
.napoli-top-social {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  vertical-align: top;
}

.napoli-top-social .social-icon {
  display: none;
  font-size: 12px;
  line-height: 45px;
  color: #131313;
  opacity: 1;
  padding: 0 10px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 30;
}

.header_trans-fixed .napoli-top-social .social-icon {
  color: #fff;
}

.napoli-top-social .social-icon:hover {
  opacity: 0.7;
}

.right-menu #topmenu .napoli-top-social .social {
  margin-left: 0;
}

#topmenu .social li {
  display: inline-block;
  margin-left: 12px;
}

.right-menu #topmenu .napoli-top-social .social li a {
  margin-left: 0;
  color: #131313;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.header_trans-fixed .right-menu #topmenu .napoli-top-social .social li a {
  color: #fff;
}

.right-menu #topmenu .napoli-top-social .social:hover a {
  opacity: 0.7;
}

.right-menu #topmenu .napoli-top-social .social li a:hover {
  opacity: 1;
}

@media only screen and (max-width: 1199px) {
  .napoli-top-social .social-icon {
    display: inline-block;
  }
  .right-menu #topmenu .napoli-top-social .social {
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 120px;
    z-index: 25;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    transition: visibility 0.3s ease, opacity 0.3s ease;
  }
  .header_trans-fixed .right-menu #topmenu .napoli-top-social .social {
    background-color: transparent;
  }
  .right-menu #topmenu .napoli-top-social .social li {
    margin-left: 10px;
  }
  .right-menu #topmenu .napoli-top-social .social.active {
    visibility: visible;
    opacity: 1;
  }
  .right-menu #topmenu .napoli-top-social .social li a {
    line-height: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .napoli-top-social {
    margin-left: 10px;
  }
  .right-menu #topmenu .napoli-top-social .social {
    right: -10px;
    left: auto;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
  body .right-menu #topmenu .menu > li > a {
    margin-left: 7px;
  }
}

@media only screen and (max-width: 767px) {
  .napoli-top-social .social-icon {
    display: none;
  }
  .right-menu #topmenu .napoli-top-social .social {
    position: static;
    visibility: visible;
    opacity: 1;
  }
  .right-menu #topmenu .napoli-top-social .social li {
    margin-left: 7px;
  }
  .header_trans-fixed.open .right-menu #topmenu .napoli-top-social .social li a {
    color: #131313;
  }
}

/*---------------------------------------------------------------*/
/*  ARROW LINKS
/*---------------------------------------------------------------*/
.side-link {
  display: inline-block;
  position: fixed;
  text-decoration: none;
  top: 50%;
  z-index: 9999;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.left.side-link {
  left: 10px;
}

.right.side-link {
  right: 10px;
}

.side-link .side-title {
  font-family: 'Montserrat';
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  width: 1em;
  font-size: 18px;
  line-height: 1.2;
  transition: all .5s ease;
  -webkit-font-smoothing: antialiased;
}

.left.side-link .side-title {
  margin-left: 10px;
}

.right.side-link .side-title {
  margin-right: 10px;
}

.side-arrow:before,
.side-arrow:after {
  content: "";
  position: absolute;
  width: 3px;
  height: 50%;
  background-color: #000;
  transition: all .5s ease;
}

.left .side-arrow:before,
.left .side-arrow:after {
  left: 0px;
}

.right .side-arrow:before,
.right .side-arrow:after {
  right: 0px;
}

.left .side-arrow:before {
  bottom: 50%;
  -webkit-transform-origin: bottom left;
  -ms-transform-origin: bottom left;
  transform-origin: bottom left;
}

.left .side-arrow:after {
  top: 50%;
  -webkit-transform-origin: top left;
  -ms-transform-origin: top left;
  transform-origin: top left;
}

.right .side-arrow:before {
  bottom: 50%;
  -webkit-transform-origin: bottom right;
  -ms-transform-origin: bottom right;
  transform-origin: bottom right;
}

.right .side-arrow:after {
  top: 50%;
  -webkit-transform-origin: top right;
  -ms-transform-origin: top right;
  transform-origin: top right;
}

.left.side-link:hover .side-arrow:before {
  height: 30px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.left.side-link:hover .side-arrow:after {
  height: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.left.side-link:hover .side-title {
  margin-left: 40px;
  opacity: 0;
}

.right.side-link:hover .side-arrow:before {
  height: 30px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.right.side-link:hover .side-arrow:after {
  height: 30px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.right.side-link:hover .side-title {
  margin-right: 40px;
  opacity: 0;
}

/*---------------------------------------------------------------*/
/*  HERO &&  HERO SLIDER
------------------------*/
/*---------------------------------------------------------------*/
.hero {
  width: 100%;
  height: 40%;
  position: relative;
  background-size: cover;
  background-position: center;
}

.hero-inner {
  position: relative;
  height: 100vh;
}

/*  HERO SLIDER
--------------------------*/
.hero-slider {
  width: 100%;
  height: 70%;
  position: relative;
  background-size: cover;
  background-position: center;
}

body .hero-slider ul {
  list-style: none;
}

.hero-slider,
.hero-slider .slides,
.hero-slider .slides .slide {
  margin: 0;
  padding: 0;
}

.hero-slider .slides .slide {
  position: relative;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.slide-caption {
  position: absolute;
  left: 50px;
  bottom: 50px;
  right: 50px;
  opacity: 0;
  -webkit-transform: translateX(-100px);
  -ms-transform: translateX(-100px);
  transform: translateX(-100px);
  transition: all 1s ease 1s;
}

.flex-active-slide .slide-caption {
  opacity: 1;
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
}

.flex-control-paging {
  list-style: none;
  line-height: 0;
  margin: 10px 0 0;
  z-index: 99999;
  text-align: center;
}

.flex-control-paging li {
  display: inline-block;
  margin: 5px;
  border: 1px solid #000;
}

.flex-control-paging li a {
  display: block;
  text-decoration: none;
  text-indent: -9999px;
  width: 8px;
  height: 8px;
  background: #ffffff;
  border: 1px solid #ffffff;
  cursor: pointer;
}

.flex-control-paging li a.flex-active {
  background: #000;
}

/*---------------------------------------------------------------*/
/*  BANNER &&  BANNER WITH GALLERY
--------------------------*/
/*---------------------------------------------------------------*/
.enable_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20;
}

.top-banner {
  position: relative;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  transition: none;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  background-attachment: scroll;
  background-position: center top;
  padding-left: 0;
  padding-right: 0;
}

.top-banner .fix-inner {
  height: 690px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -2;
}

.top-banner .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20;
}

@media (max-width: 992px) {
  .top-banner .fix-inner {
    height: 100vh;
  }
}

@media (max-width: 480px) {
  .top-banner {
    text-align: center;
  }
}

.top-banner .subtitle {
  font-size: 12px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 2.4px;
  margin-bottom: 40px;
  padding-top: 150px;
  -webkit-font-smoothing: antialiased;
}

.top-banner.left_content .subtitle {
  color: #c0af94;
}

.top-banner.center_content .subtitle {
  margin-bottom: 31px;
  color: #c0af94;
}

@media (max-width: 480px) {
  .top-banner .subtitle {
    padding-top: 30px;
  }
}

.top-banner .title {
  font-size: 70px;
  font-family: "BebasNeueBook";
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 5.6px;
  margin-bottom: 40px;
}

.top-banner.center_content .title {
  margin-bottom: 25px;
  color: #131313;
}

@media (max-width: 767px) {
  .top-banner .title {
    font-size: 40px;
    line-height: 50px;
  }
}

.top-banner .descr {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.12px;
  max-width: 510px;
}

.top-banner.center_content .descr {
  margin-left: auto;
  margin-right: auto;
  max-width: 970px;
  color: #888888;
}

.vertical-align {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

body .top-banner .a-btn {
  margin-top: 30px;
}

/*Top banner full height*/
.top-banner.full-height .container .subtitle {
  padding-top: 0;
}

/* 09. BANNER WITH GALLERY
------------------------*/
.banner-gallery {
  position: relative;
}

.banner-gallery .content-wrap {
  position: absolute;
  background-color: #ffffff;
}

.banner-gallery .content-wrap .content {
  left: 0;
  padding: 15px 70px;
}

.banner-gallery .content-wrap .title {
  font-size: 60px;
  font-family: "BebasNeueBook", sans-serif;
  color: #131313;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 5.6px;
  margin-bottom: 20px;
}

.banner-gallery .content-wrap .description {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  max-width: 430px;
  margin: auto;
  letter-spacing: 1.12px;
}

.banner-gallery .content-wrap .button {
  margin-left: auto;
  margin-top: 31px;
}

body .banner-list {
  display: block;
  width: 100%;
  margin: 0;
}

.banner-list:before,
.banner-list:after {
  content: '';
  display: table;
}

.banner-list li {
  -webkit-perspective: 400px;
  -o-perspective: 400px;
  -ms-perspective: 400px;
  perspective: 400px;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
}

.banner-list li span {
  display: block;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: content-box;
}

/*---------------------------------------------------------------*/
/*  CALL TO ACTION
/*---------------------------------------------------------------*/
.action-wrap {
  padding: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.action {
  background-color: #ffffff;
  padding: 27px 300px 28px 50px;
  position: relative;
}

.action .title {
  font-size: 18px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  letter-spacing: 1.4px;
  margin-bottom: 6px;
  line-height: 24px;
}

.action .subtitle {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  opacity: .9;
  padding-left: 2px;
  letter-spacing: .63px;
  line-height: 20px;
}

.action .button {
  position: absolute;
  right: 49px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  padding: 1px 68px;
  font-weight: 500;
}

/*---------------------------------------------------------------*/
/*  ABOUT SECTION
/*---------------------------------------------------------------*/
.about-section {
  position: relative;
  min-height: 420px;
  background-color: #f2f2f2;
}

@media (max-width: 767px) {
  .about-section {
    padding: 15px;
    padding-bottom: 30px;
    text-align: center;
  }
}

.about-section .person-wrap {
  float: left;
  height: 420px;
  width: 500px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-right: 70px;
}

@media (max-width: 1199px) {
  .about-section .person-wrap {
    width: 400px;
    margin-right: 60px;
  }
}

@media (max-width: 991px) {
  .about-section .person-wrap {
    width: 345px;
    margin-right: 30px;
  }
}

@media (max-width: 767px) {
  .about-section .person-wrap {
    float: none;
    width: 100%;
    max-width: 480px;
    height: 300px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}

.about-section .content {
  padding-top: 60px;
  padding-right: 70px;
}

@media (max-width: 991px) {
  .about-section .content {
    padding-top: 30px;
    padding-right: 30px;
  }
}

@media (max-width: 767px) {
  .about-section .content {
    padding: 0px;
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

.about-section .subtitle {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  color: #c0af94;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 30px;
  -webkit-font-smoothing: antialiased;
}

.about-section .title {
  font-size: 36px;
  font-family: "BebasNeueRegular", sans-serif;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 2.88px;
  margin-bottom: 20px;
  color: #131313;
}

.about-section .descr {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1.12px;
  color: #888888;
  margin-bottom: 30px;
}

.about-section .a-btn-2 {
  color: #131313;
}

.about-section .a-btn-2:hover {
  color: #ffffff;
}

/*---------------------------------------------------------------*/
/*  PORTFOLIO &&  PORTFOLIO FILTER &&  BLOCKQUOTE
/*---------------------------------------------------------------*/
/*Gridrotator - Fallback css*/
.ri-grid {
  width: 600px;
}

.ri-grid ul li,
.ri-grid ul li a {
  width: 100px;
  height: 100px;
}

.ri-grid ul li a img {
  width: 100%;
}

.ri-grid {
  margin: 30px auto 30px;
  position: relative;
  height: auto;
}

.ri-grid ul {
  list-style: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */
.ri-grid ul:before,
.ri-grid ul:after {
  content: '';
  display: table;
}

.ri-grid ul:after {
  clear: both;
}

.ri-grid ul {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}

.ri-grid ul li {
  -webkit-perspective: 400px;
  -o-perspective: 400px;
  -ms-perspective: 400px;
  perspective: 400px;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
}

.ri-grid ul li a {
  display: block;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #333;
  box-sizing: content-box;
}

/* Grid wrapper sizes */
.ri-grid-size-1 {
  width: 55%;
}

.ri-grid-size-2 {
  width: 100%;
}

.ri-grid-size-3 {
  width: 100%;
  margin-top: 0px;
}

/* Shadow style */
.ri-shadow:after,
.ri-shadow:before {
  content: "";
  position: absolute;
  z-index: -2;
  bottom: 15px;
  left: 10px;
  width: 50%;
  height: 20%;
  max-width: 300px;
  max-height: 100px;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.ri-shadow:after {
  right: 10px;
  left: auto;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

.ri-grid-loading:after,
.ri-grid-loading:before {
  display: none;
}

.ri-loading-image {
  display: none;
}

.ri-grid-loading .ri-loading-image {
  position: relative;
  width: 30px;
  height: 30px;
  left: 50%;
  margin: 100px 0 0 -15px;
  display: block;
}

.gallery-item {
  display: block;
  height: 180px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.gallery9 .gallery-item {
  width: 100px;
  height: 100px;
  display: inline-block;
}

.gallery-item:hover {
  opacity: 1;
}

.gallery-item .info-content {
  background-color: rgba(19, 19, 19, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all 500ms ease;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.gallery-item .info-content h5 {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.92px;
}

.gallery-item:hover .info-content {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.modern .gallery-item {
  height: auto;
  text-align: center;
}

.modern .gallery-item img {
  width: 100%;
}

.modern .gallery-item .info-content {
  background-color: rgba(19, 19, 19, 0.3);
}

.modern .gallery-item .info-content h5 {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.92px;
}

/* Full with gallery */
.fullwidth.full-single {
  width: 20%;
  display: block;
  float: left;
}

.fullwidth .gallery-item {
  margin: 0 5px 10px 5px;
  height: auto;
  width: calc(100% - 10px);
  box-sizing: border-box;
}

.fullwidth .gallery-item img {
  width: auto;
}

.fullwidth .gallery-item .info-content {
  height: 100%;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  background-color: rgba(255, 255, 255, 0.9);
}

.fullwidth .gallery-item .info-content h5 {
  letter-spacing: 1.1px;
  margin-bottom: 6px;
  color: #131313;
}

.fullwidth .gallery-item .info-content .subtitle {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: 2.6px;
}

.fullwidth .gallery-item:hover .info-content {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.full-single:nth-child(14n+1) {
  width: 20%;
}

.full-single:nth-child(14n+1) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+2) {
  width: 40%;
}

.full-single:nth-child(14n+2) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+3) {
  width: 40%;
}

.full-single:nth-child(14n+3) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+4) {
  width: 20%;
}

.full-single:nth-child(14n+4) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+5) {
  width: 40%;
}

.full-single:nth-child(14n+5) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+6) {
  width: 20%;
}

.full-single:nth-child(14n+6) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+7) {
  width: 20%;
}

.full-single:nth-child(14n+7) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+8) {
  width: 20%;
}

.full-single:nth-child(14n+8) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+9) {
  width: 20%;
}

.full-single:nth-child(14n+9) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+10) {
  width: 40%;
}

.full-single:nth-child(14n+10) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+11) {
  width: 40%;
}

.full-single:nth-child(14n+11) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+12) {
  width: 20%;
}

.full-single:nth-child(14n+12) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+13) {
  width: 20%;
}

.full-single:nth-child(14n+13) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+14) {
  width: 40%;
}

.full-single:nth-child(14n+14) .gallery-item {
  height: 227px;
}

.portfolio-wrapper {
  margin-bottom: 0px;
  padding: 0;
}

@media (max-width: 991px) {
  .full-single:nth-child(14n+1) {
    width: 20%;
  }
  .full-single:nth-child(14n+1) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+2) {
    width: 40%;
  }
  .full-single:nth-child(14n+2) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+3) {
    width: 40%;
  }
  .full-single:nth-child(14n+3) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+4) {
    width: 20%;
  }
  .full-single:nth-child(14n+4) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+5) {
    width: 40%;
  }
  .full-single:nth-child(14n+5) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+6) {
    width: 20%;
  }
  .full-single:nth-child(14n+6) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+7) {
    width: 20%;
  }
  .full-single:nth-child(14n+7) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+8) {
    width: 20%;
  }
  .full-single:nth-child(14n+8) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+9) {
    width: 20%;
  }
  .full-single:nth-child(14n+9) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+10) {
    width: 40%;
  }
  .full-single:nth-child(14n+10) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+11) {
    width: 40%;
  }
  .full-single:nth-child(14n+11) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+12) {
    width: 20%;
  }
  .full-single:nth-child(14n+12) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+13) {
    width: 20%;
  }
  .full-single:nth-child(14n+13) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+14) {
    width: 40%;
  }
  .full-single:nth-child(14n+14) .gallery-item {
    height: 142px;
  }
}

@media (max-width: 767px) {
  .full-single:nth-child(14n+1),
  .full-single:nth-child(14n+2),
  .full-single:nth-child(14n+3),
  .full-single:nth-child(14n+4),
  .full-single:nth-child(14n+5),
  .full-single:nth-child(14n+6),
  .full-single:nth-child(14n+7),
  .full-single:nth-child(14n+8),
  .full-single:nth-child(14n+9),
  .full-single:nth-child(14n+10),
  .full-single:nth-child(14n+11),
  .full-single:nth-child(14n+12),
  .full-single:nth-child(14n+13),
  .full-single:nth-child(14n+14) {
    width: 50%;
  }
  .full-single:nth-child(14n+1) .gallery-item,
  .full-single:nth-child(14n+2) .gallery-item,
  .full-single:nth-child(14n+3) .gallery-item,
  .full-single:nth-child(14n+4) .gallery-item,
  .full-single:nth-child(14n+5) .gallery-item,
  .full-single:nth-child(14n+6) .gallery-item,
  .full-single:nth-child(14n+7) .gallery-item,
  .full-single:nth-child(14n+8) .gallery-item,
  .full-single:nth-child(14n+9) .gallery-item,
  .full-single:nth-child(14n+10) .gallery-item,
  .full-single:nth-child(14n+11) .gallery-item,
  .full-single:nth-child(14n+12) .gallery-item,
  .full-single:nth-child(14n+13) .gallery-item,
  .full-single:nth-child(14n+14) .gallery-item {
    height: 370px;
  }
}

@media (max-width: 480px) {
  .full-single:nth-child(14n+1),
  .full-single:nth-child(14n+2),
  .full-single:nth-child(14n+3),
  .full-single:nth-child(14n+4),
  .full-single:nth-child(14n+5),
  .full-single:nth-child(14n+6),
  .full-single:nth-child(14n+7),
  .full-single:nth-child(14n+8),
  .full-single:nth-child(14n+9),
  .full-single:nth-child(14n+10),
  .full-single:nth-child(14n+11),
  .full-single:nth-child(14n+12),
  .full-single:nth-child(14n+13),
  .full-single:nth-child(14n+14) {
    width: 100%;
  }
}

/*Portfolio simple*/
body .vc_row-fluid[data-vc-full-width=true] .portfolio {
  margin-left: 0px;
  margin-right: 0px;
  padding: 0px 20px;
}

@media (max-width: 991px) {
  .portfolio {
    padding: 0px 10px;
  }
}

@media (max-width: 767px) {
  .portfolio {
    padding: 0px 15px;
  }
}

.item {
  float: left;
  display: block;
  position: relative;
}

.portfolio .item {
  height: 250px;
}

.item .item-img {
  margin-left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item:after {
  padding-top: 0%;
  display: block;
  content: "";
}

/* 2;4 column items */
.col-2 .item {
  width: 50%;
}

.col-4 .item {
  width: 25%;
}

.portfolio-gallery.gallery-single {
  margin: 0 -15px;
}

.item.item-singe {
  min-height: 270px;
}

body .portfolio .item {
  max-height: 270px;
}

.portfolio.col-3 .item {
  width: 33%;
}

.portfolio.col-4 .item {
  width: 25%;
}

.portfolio.col-6 .item {
  width: 50%;
}

@media (max-width: 991px) {
  .portfolio.col-6 .item,
  .portfolio.col-4 .item,
  .portfolio.col-3 .item {
    width: 50%;
  }
  body [data-space] .item-img {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .portfolio.col-6 .item,
  .portfolio.col-4 .item,
  .portfolio.col-3 .item {
    width: 100%;
  }
}

.item-link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}

body .portfolio .item-link {
  margin: 0px 10px 20px 10px;
}

@media (max-width: 767px) {
  body .portfolio .item-link {
    margin: 0px;
    margin-bottom: 30px;
  }
}

body .portfolio.classic .item-link {
  margin: 0px 15px 30px 15px;
}

@media (max-width: 767px) {
  body .portfolio.classic .item-link {
    margin: 0px;
    margin-bottom: 30px;
  }
}

.item-link:hover {
  opacity: 1;
}

.item-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0;
}

.item-img .images {
  width: 33.33%;
  height: 33.33%;
  float: left;
  position: relative;
  display: block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.item-img .images-one {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.item-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all .5s ease;
}

.item-overlay > h5 {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  font-family: "BebasNeueRegular";
  color: #ffffff;
  text-align: center;
}

/*Potfolio classic */
.classic .item-overlay {
  transition: all 0.5s ease;
}

.classic .item-overlay h5 {
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1.6px;
}

body .portfolio.classic .item {
  height: 400px;
  max-height: 400px;
}

.portfolio.classic .item-link {
  min-height: 370px;
  height: 370px;
}

/*Potfolio classic BIG*/
body .portfolio.classic.big .item {
  height: 523px;
  max-height: 523px;
}

.portfolio.classic.big .item-link {
  min-height: 493px;
  height: 493px;
}

.portfolio.classic.big .item-overlay {
  background-color: #f2f2f2;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.classic.big .item-link:hover .item-overlay {
  opacity: 0.9;
}

.portfolio.classic.big .portfolio-title {
  position: absolute;
  top: auto;
  left: 45px;
  bottom: 50px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 1.6px;
  opacity: 0;
  transition: opacity 0.5s ease;
  color: #131313;
}

.portfolio.classic.big .item-link:hover .portfolio-title {
  opacity: 1;
}

.portfolio.classic.big .categories {
  position: absolute;
  top: auto;
  left: 45px;
  bottom: 30px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 0;
  transition: opacity 0.5s ease;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 0;
  text-decoration: none;
  color: #c0af94;
}

.portfolio.classic.big .item-link:hover .categories {
  opacity: 1;
}

@media (max-width: 1199px) {
  body .portfolio.classic.big .item {
    height: 400px;
    max-height: 400px;
  }
  .portfolio.classic.big .item-link {
    min-height: 370px;
    height: 370px;
  }
}

/*Potfolio Big Gap */
body .portfolio.big_gap .item {
  height: 575px;
  max-height: 575px;
}

body .portfolio.big_gap .item-link {
  min-height: 495px;
  height: 495px;
  margin: 0px 40px 80px 40px;
}

body .portfolio.big_gap .item.block_item_0 {
  height: 905px;
  max-height: 905px;
}

.portfolio.big_gap .item.block_item_0 .item-link {
  height: 825px;
  min-height: 825px;
}

body .portfolio.big_gap .item.block_item_1 {
  height: 445px;
  max-height: 445px;
}

.portfolio.big_gap .item.block_item_1 .item-link {
  height: 365px;
  min-height: 365px;
}

body .portfolio.big_gap .item.block_item_2 {
  height: 850px;
  max-height: 850px;
}

.portfolio.big_gap .item.block_item_2 .item-link {
  height: 770px;
  min-height: 770px;
}

body .portfolio.big_gap .item.block_item_4 {
  height: 625px;
  max-height: 625px;
}

.portfolio.big_gap .item.block_item_4 .item-link {
  height: 545px;
  min-height: 545px;
}

body .portfolio.big_gap .item.block_item_5 {
  height: 900px;
  max-height: 900px;
}

.portfolio.big_gap .item.block_item_5 .item-link {
  height: 820px;
  min-height: 820px;
}

body .portfolio.big_gap .item.block_item_6 {
  height: 460px;
  max-height: 460px;
}

.portfolio.big_gap .item.block_item_6 .item-link {
  height: 380px;
  min-height: 380px;
}

.portfolio.big_gap .portfolio-title {
  position: absolute;
  top: auto;
  left: 70px;
  bottom: 108px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 1.6px;
  opacity: 0;
  transition: opacity 0.5s ease;
  color: #ffffff;
}

.portfolio.big_gap .item-link:hover .portfolio-title {
  opacity: 1;
}

.portfolio.big_gap .big_gap_share {
  position: absolute;
  bottom: 52px;
  left: 60px;
  margin: 0;
  list-style-type: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.big_gap .big_gap_share li {
  display: inline-block;
  margin: 10px 3px;
}

.portfolio.big_gap .big_gap_share li:first-child {
  margin-left: 0;
}

.portfolio.big_gap .big_gap_share li i,
.portfolio.big_gap .big_gap_share li button {
  font-family: 'FontAwesome';
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  padding: 0 10px;
  background-color: transparent;
  border: none;
  border-color: transparent;
  transition: opacity 0.3 s ease;
}

.portfolio.big_gap .big_gap_share:hover li button {
  opacity: 0.7;
}

.portfolio.big_gap .big_gap_share:hover li button:hover {
  opacity: 1;
}

.portfolio.big_gap .item-link:hover .big_gap_share {
  opacity: 1;
}

@media (max-width: 991px) {
  .portfolio.big_gap .portfolio-title {
    left: 30px;
    bottom: 100px;
  }
  .portfolio.big_gap .big_gap_share {
    left: 20px;
    bottom: 30px;
  }
}

@media (max-width: 767px) {
  body .portfolio.big_gap .item-link {
    margin: 0px 40px 30px 40px;
    height: 370px;
    min-height: 370px;
  }
  body .portfolio.big_gap .item {
    height: 400px;
    max-height: 400px;
  }
  body .portfolio.big_gap .item.block_item_0,
  body .portfolio.big_gap .item.block_item_1,
  body .portfolio.big_gap .item.block_item_2,
  body .portfolio.big_gap .item.block_item_3,
  body .portfolio.big_gap .item.block_item_4,
  body .portfolio.big_gap .item.block_item_5,
  body .portfolio.big_gap .item.block_item_6 {
    height: 400px;
    max-height: 400px;
  }
  .portfolio.big_gap .item.block_item_0 .item-link,
  .portfolio.big_gap .item.block_item_1 .item-link,
  .portfolio.big_gap .item.block_item_2 .item-link,
  .portfolio.big_gap .item.block_item_3 .item-link,
  .portfolio.big_gap .item.block_item_4 .item-link,
  .portfolio.big_gap .item.block_item_5 .item-link,
  .portfolio.big_gap .item.block_item_6 .item-link {
    height: 370px;
    min-height: 370px;
  }
}

/*Potfolio Masonry */
body .portfolio.masonry .item {
  max-height: none;
  height: auto;
}

body .portfolio.masonry .item-link {
  position: relative;
  display: block;
  margin: 0 15px 7px 15px;
}

.portfolio.masonry .item-link img {
  width: 100%;
}

.portfolio.masonry .item-overlay {
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.masonry .item-link:hover .item-overlay {
  background-color: #000000;
  opacity: 0.3;
}

.portfolio.masonry .item-portfolio-content {
  margin: 0 15px 24px 15px;
  text-align: center;
  max-height: 56px;
  overflow: hidden;
}

.portfolio.masonry .item-portfolio-content .portfolio-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #131313;
  margin-bottom: 0;
}

.portfolio.masonry .item-portfolio-content .portfolio-title a {
  text-decoration: none;
  color: inherit;
}

/*Portfolio Grid */
body .portfolio.grid .item {
  padding-top: 305px;
  max-height: none;
  height: auto;
}

.portfolio.grid .item-link {
  height: 280px;
}

.portfolio.grid .item-overlay {
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.grid .item-link:hover .item-overlay {
  background-color: #000000;
  opacity: 0.3;
}

.portfolio.grid .item-portfolio-content {
  display: block;
  margin: 25px 10px 15px;
  text-align: center;
  overflow: hidden;
  max-height: 56px;
}

.portfolio.grid .item-portfolio-content .portfolio-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #131313;
  margin-bottom: 0;
}

.portfolio.grid .item-portfolio-content .portfolio-title a {
  text-decoration: none;
}

.portfolio.grid .item-portfolio-content .category {
  color: #c0af94;
}

.portfolio.grid .item-portfolio-content .category a {
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  text-decoration: none;
}

/*Hover styles*/
/*Default hover*/
.portfolio.simple .item-link .item-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease;
}

.item-link:hover .item-overlay {
  opacity: 0;
  transition: all 0.5s ease;
}

/* .gallery-single .gallery-item:hover .item-img, */
.portfolio.simple .item-link:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.5s ease;
}

/*Hover 1 - Zoom out*/
.gallery-single .gallery-item.hover1 .item-img,
.portfolio .item-link.hover1 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  width: 120%;
  height: 100%;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  left: -10%;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover1:hover .item-img,
.portfolio .item-link.hover1:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

/*Hover 2 - Slide*/
body .gallery-single .gallery-item.hover2 .item-img,
body .portfolio .item-link.hover2 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  width: 120%;
  left: -20%;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover2:hover .item-img,
.portfolio .item-link.hover2:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  left: 0%;
  width: 120%;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover2 .info-content {
  left: -7%;
}

/*Hover 3 - Rotate (+ zoom out)*/
.gallery-single .gallery-item.hover3 .item-img,
.portfolio .item-link.hover3 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: rotate(15deg) scale(1.4);
  -ms-transform: rotate(15deg) scale(1.4);
  transform: rotate(15deg) scale(1.4);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover3:hover .item-img,
.portfolio .item-link.hover3:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  transition: all 0.5s ease-in-out;
}

/*Hover 4 - Blur*/
.gallery-single .gallery-item.hover4 .item-img,
.portfolio .item-link.hover4 .item-img {
  -webkit-filter: grayscale(0%) blur(3px);
  filter: grayscale(0%) blur(3px);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover4:hover .item-img,
.portfolio .item-link.hover4:hover .item-img {
  -webkit-filter: grayscale(0%) blur(0px);
  filter: grayscale(0%) blur(0px);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

/*Hover 5 - Greyscale*/
.gallery-single .gallery-item.hover5 .item-img,
.portfolio .item-link.hover5 .item-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover5:hover .item-img,
.portfolio .item-link.hover5:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

/*Hover 6 - Sepia*/
.gallery-single .gallery-item.hover6 .item-img,
.portfolio .item-link.hover6 .item-img {
  -webkit-filter: sepia(100%) grayscale(0%);
  filter: sepia(100%) grayscale(0%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover6:hover .item-img,
.portfolio .item-link.hover6:hover .item-img {
  -webkit-filter: sepia(0%) grayscale(0%);
  filter: sepia(0%) grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

/*Hover 7 - Blur + Greyscale*/
.gallery-single .gallery-item.hover7 .item-img,
.portfolio .item-link.hover7 .item-img {
  -webkit-filter: blur(0px) grayscale(0%);
  filter: blur(0px) grayscale(0%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover7:hover .item-img,
.portfolio .item-link.hover7:hover .item-img {
  -webkit-filter: blur(3px) grayscale(100%);
  filter: blur(3px) grayscale(100%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

/*Hover 8 - Opacity*/
.gallery-single .gallery-item.hover8 .item-img,
.portfolio .item-link.hover8 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover8:hover .item-img,
.portfolio .item-link.hover8:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
}

/*Hover 9 - Shine*/
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

.gallery-single .gallery-item.hover9 .item-img,
.portfolio .item-link.hover9 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover9 .item-img::before,
.portfolio .item-link.hover9 .item-img::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.gallery-single .gallery-item.hover9:hover .item-img,
.portfolio .item-link.hover9:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover9:hover .item-img::before,
.portfolio .item-link.hover9:hover .item-img::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}

/*Gallery protected page*/
.single-proof_gallery .single-content > .title,
.single-proof_gallery .title {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-weight: 400;
  font-family: "BebasNeueRegular";
  color: #131313;
  margin: 0;
  padding-top: 35px;
}

@media (max-width: 767px) {
  .single-proof_gallery .single-content > .title,
  .single-proof_gallery .title {
    padding-top: 20px;
    text-align: center;
  }
}

.single-proof_gallery .date-post {
  display: none;
}

.single-proof_gallery .single-content .post-info {
  display: none;
}

.single-proof_gallery .pixproof-data {
  margin-bottom: 75px;
}

@media (max-width: 1199px) {
  .single-proof_gallery .pixproof-data {
    margin-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .single-proof_gallery .pixproof-data {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data {
    text-align: center;
  }
}

.single-proof_gallery .pixproof-data .grid {
  font-size: 0px;
}

.single-proof_gallery .pixproof-data .grid__item {
  display: inline-block;
  vertical-align: top;
  width: auto;
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data .grid__item {
    display: block;
  }
}

.single-proof_gallery .pixproof-data .grid__item .entry__meta-box {
  display: inline-block;
  vertical-align: bottom;
  line-height: 20px;
}

.single-proof_gallery .pixproof-data .grid__item .entry__meta-box span {
  font-size: 14px;
  line-height: 20px;
  font-family: "Libre Baskerville";
  letter-spacing: 1.12px;
  color: #888888;
  display: inline-block;
  vertical-align: top;
  padding-top: 25px;
  padding-right: 40px;
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data .grid__item .entry__meta-box span {
    padding-right: 0px;
  }
}

.single-proof_gallery .pixproof-data .grid__item .entry__meta-box button:not(:last-child) {
  margin-right: 15px;
}

body .single-proof_gallery .pixproof-data .grid__item .entry__meta-box .meta-box__title {
  font-size: 12px;
  line-height: 20px;
  vertical-align: top;
  font-family: "Montserrat";
  color: #c0af94;
  text-transform: uppercase;
  letter-spacing: 2.4px;
  display: inline-block;
  padding-top: 26px;
  padding-right: 10px;
}

.single-proof_gallery .pixproof-data .grid__item .a-btn-2 {
  background: transparent;
  border-left: none;
  border-right: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  color: #131313;
}

.single-proof_gallery .pixproof-data .grid__item .a-btn-2:hover {
  background: #131313;
  color: #ffffff;
}

.single-proof_gallery .pixproof-data .grid__item:last-child {
  float: right;
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data .grid__item:last-child {
    padding-top: 20px;
    float: none;
  }
}

.single-proof_gallery #pixproof_gallery {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .single-proof_gallery #pixproof_gallery {
    margin-bottom: 40px;
  }
}

.single-proof_gallery #pixproof_gallery .proof-photo {
  cursor: pointer;
  margin-bottom: 30px;
  /*Image*/
  /*Hover effects on non-selected photo*/
  /*Hover effects on selected photo*/
}

.single-proof_gallery #pixproof_gallery .proof-photo .img-wrap {
  height: 180px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-proof_gallery #pixproof_gallery .proof-photo .img-wrap:before {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__container {
  position: relative;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__meta {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__status {
  display: none;
}

body .single-proof_gallery #pixproof_gallery .proof-photo .meta__action.zoom-action {
  font-size: 0px;
  padding: 0;
  position: absolute;
  background-color: transparent;
  width: 100%;
  height: 180px;
  top: 0;
  z-index: 49;
}

.single-proof_gallery #pixproof_gallery .proof-photo .actions-nav {
  margin: 0;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action {
  font-size: 0px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  padding: 0;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  position: relative;
  text-decoration: none;
  opacity: 0;
  z-index: 50;
  margin-left: 15px;
  margin-top: 15px;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action:hover {
  opacity: 1;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action .button-text {
  font-size: 0px;
  white-space: nowrap;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action:before {
  content: '\f067';
  font-family: "FontAwesome";
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__bg {
  position: relative;
  /*Title*/
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__bg .proof-photo__id {
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  font-family: "BebasNeueRegular";
  color: #ffffff;
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .proof-photo__id {
  opacity: 1;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .img-wrap:before {
  opacity: 1;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .meta__action.select-action {
  opacity: 1;
  background-color: transparent;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .meta__action.select-action:hover:before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected .meta__action.select-action {
  opacity: 1;
  border: 1px solid #c0af94;
  background-color: #c0af94;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected .meta__action.select-action:before {
  content: '\f00c';
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected:hover .meta__action.select-action:hover:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '\f068';
  transition: all 0.5s ease;
}

/*Justified gallery*/
.justified-gallery .dgwt-jg-caption {
  background: rgba(0, 0, 0, 0.5);
}

body .dgwt-jg-gallery.justified-gallery .dgwt-jg-caption span {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  letter-spacing: 1.92px;
  font-weight: 400;
  opacity: 1;
}

body .lg .lg-sub-html h4 {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.92px;
}

body .lg .lg-sub-html .dgwt-jg-item-desc {
  letter-spacing: .8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #888888;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

body .single-proof_gallery .mfp-arrow-left {
  left: 5px;
}

body .single-proof_gallery .mfp-arrow-right {
  right: 100px;
}

/*Izotop container portfolio*/
@media (max-width: 767px) {
  body .izotope-container .item-single {
    margin-bottom: 5px;
  }
  body .izotope-container .item-single:last-child {
    margin-bottom: 30px;
  }
}

/* 13. PORTFOLIO FILTER
-------------------------*/
.filter {
  text-align: center;
}

.filter.grid {
  text-align: left;
}

.filter.grid ul,
.filter.sim ul,
.filter.cla ul {
  margin: 30px 0;
}

.filter.masonry ul {
  margin: 63px 0;
}

.filter ul li {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: lowercase;
  display: inline-block;
  margin: 8px;
  cursor: pointer;
  opacity: 1;
  color: #b2b2b2;
  transition: all 300ms ease;
}

.filter ul li:hover {
  opacity: 0.7;
}

.filter ul li.active {
  color: #131313;
  opacity: 1;
}

.filter.grid ul li:first-child {
  margin-left: 0;
}

@media (max-width: 991px) {
  .filter.masonry ul {
    margin: 30px 0;
  }
}

@media (max-width: 767px) {
  .filter,
  .filter.grid {
    text-align: center;
    padding: 0 15px;
  }
  .filter.grid ul li:first-child {
    margin-left: 8px;
  }
}

/* 14. BLOCKQUOTE
------------------*/
blockquote {
  border: none;
  font-size: 15px;
  padding: 10px 0;
}

blockquote footer {
  font-size: 85%;
}

.single-proof_gallery .proof_gallery .pixproof-data {
  position: relative;
  margin-bottom: 24px;
}

.single-proof_gallery .proof_gallery .meta-box__title {
  display: block;
  font-weight: bold;
}

@media screen and (min-width: 899px) {
  .single-proof_gallery .proof_gallery .button-download {
    position: absolute;
    right: 0;
  }
}

.single-proof_gallery .proof_gallery .proof-photo {
  padding-bottom: 0;
  -moz-transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  -o-transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  -webkit-transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
  text-align: center;
}

.single-proof_gallery .proof_gallery .proof-photo:hover .proof-photo__bg {
  background: #EEE9E9;
}

.single-proof_gallery .proof_gallery .proof-photo:hover .proof-photo__meta {
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

.single-proof_gallery .proof_gallery .proof-photo:hover .separator {
  max-width: 80%;
  margin-top: 12px;
  margin-bottom: 12px;
}

.single-proof_gallery .proof_gallery .proof-photo.selected .proof-photo__status {
  opacity: 1;
}

.single-proof_gallery .proof_gallery .proof-photo.selected .proof-photo__status span.ticker {
  opacity: 1;
}

.single-proof_gallery .proof_gallery .proof-photo.selecting .proof-photo__status span.ticker {
  opacity: 0;
}

.single-proof_gallery .proof_gallery .proof-photo.selecting .proof-photo__status span.spinner {
  opacity: 1;
}

.single-proof_gallery .proof_gallery .proof-photo.scrooled_from_comments {
  -moz-transform: scale(1.2, 1.2);
  -ms-transform: scale(1.2, 1.2);
  -o-transform: scale(1.2, 1.2);
  -webkit-transform: scale(1.2, 1.2);
  transform: scale(1.2, 1.2);
  z-index: 50;
}

.single-proof_gallery .proof_gallery .proof-photo a {
  color: white;
}

.single-proof_gallery .proof_gallery .proof-photo .actions-nav {
  text-align: center;
  margin: 12px 0;
  padding: 0;
}

.single-proof_gallery body .proof_gallery .proof-photo .nav {
  list-style: none;
  margin-left: 0;
}

.single-proof_gallery .proof_gallery .proof-photo .nav:after {
  content: "";
  display: table;
  clear: both;
}

.single-proof_gallery .proof_gallery .proof-photo .nav > li {
  margin: 0;
}

.single-proof_gallery .proof_gallery .proof-photo .nav > li,
.single-proof_gallery .proof_gallery .proof-photo .nav > li > a {
  display: inline-block;
  *display: inline;
  zoom: 1;
}

.single-proof_gallery .proof_gallery .proof-photo .nav--stacked > li {
  display: list-item;
}

.single-proof_gallery .proof_gallery .proof-photo .nav--stacked > li > a {
  display: block;
  width: 100%;
}

.single-proof_gallery .proof_gallery .proof-photo__bg {
  display: inline-block;
  margin: 0 auto;
}

.single-proof_gallery .proof_gallery .proof-photo__container {
  position: relative;
}

.single-proof_gallery .proof_gallery .proof-photo__meta {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.single-proof_gallery .proof_gallery .proof-photo__meta .flexbox {
  height: 100%;
}

.single-proof_gallery .proof_gallery .proof-photo__meta .separator {
  max-width: 0;
  margin: 0 auto;
  height: 1px;
  background: white;
  -moz-transition: all 0.1s linear;
  -o-transition: all 0.1s linear;
  -webkit-transition: all 0.1s linear;
  transition: all 0.1s linear;
}

.single-proof_gallery .proof_gallery .proof-photo__status {
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
}

.single-proof_gallery .proof_gallery .proof-photo__status span.ticker {
  display: block;
  font-size: 18px;
  color: white;
  line-height: 32px;
  width: 32px;
  text-align: center;
  opacity: 0;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.single-proof_gallery .proof_gallery .proof-photo__status span.spinner {
  position: absolute;
  right: 0;
  bottom: 0;
  border: 3px solid transparent;
  border-radius: 100%;
  opacity: 0;
  -moz-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.single-proof_gallery .proof_gallery .proof-photo__id {
  display: block;
  padding: 6px 0;
  text-align: center;
}

.single-proof_gallery body .proof_gallery .meta__action {
  margin: 0;
  color: black;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
}

.single-proof_gallery .proof_gallery .meta__action--popup {
  color: white;
}

.single-proof_gallery .proof_gallery .pixproof_photo_ref {
  padding: 3px 6px;
  margin: 0 3px;
  color: white;
  background: black;
  cursor: pointer;
}

.single-proof_gallery .proof_gallery .gallery {
  margin-bottom: 20px;
}

.single-proof_gallery .proof_gallery .gallery-item {
  float: left;
  margin: 0 4px 4px 0;
  overflow: hidden;
  position: relative;
}

.single-proof_gallery .proof_gallery .gallery-columns-1 .gallery-item {
  max-width: 100%;
  width: 100%;
}

.single-proof_gallery .proof_gallery .gallery-columns-2 .gallery-item {
  max-width: 48%;
  width: 48%;
  max-width: -webkit-calc(50% - 4px);
  max-width: calc(50% - 4px);
  width: calc(50% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-3 .gallery-item {
  max-width: 32%;
  width: 32%;
  max-width: -webkit-calc(33.3% - 4px);
  max-width: calc(33.3% - 4px);
  width: calc(33.3% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-4 .gallery-item {
  max-width: 23%;
  width: 23%;
  max-width: -webkit-calc(25% - 4px);
  max-width: calc(25% - 4px);
  width: calc(25% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-5 .gallery-item {
  max-width: 19%;
  width: 19%;
  max-width: -webkit-calc(20% - 4px);
  max-width: calc(20% - 4px);
  width: calc(20% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-6 .gallery-item {
  max-width: 15%;
  width: 15%;
  max-width: -webkit-calc(16.7% - 4px);
  max-width: calc(16.7% - 4px);
  width: calc(16.7% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-7 .gallery-item {
  max-width: 13%;
  width: 13%;
  max-width: -webkit-calc(14.28% - 4px);
  max-width: calc(14.28% - 4px);
  width: calc(14.28% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-8 .gallery-item {
  max-width: 11%;
  width: 11%;
  max-width: -webkit-calc(12.5% - 4px);
  max-width: calc(12.5% - 4px);
  width: calc(12.5% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-9 .gallery-item {
  max-width: 9%;
  width: 9%;
  max-width: -webkit-calc(11.1% - 4px);
  max-width: calc(11.1% - 4px);
  width: calc(11.1% - 4px);
}

.single-proof_gallery .proof_gallery .gallery-columns-1 .gallery-item:nth-of-type(1n),
.single-proof_gallery .proof_gallery .gallery-columns-2 .gallery-item:nth-of-type(2n),
.single-proof_gallery .proof_gallery .gallery-columns-3 .gallery-item:nth-of-type(3n),
.single-proof_gallery .proof_gallery .gallery-columns-4 .gallery-item:nth-of-type(4n),
.single-proof_gallery .proof_gallery .gallery-columns-5 .gallery-item:nth-of-type(5n),
.single-proof_gallery .proof_gallery .gallery-columns-6 .gallery-item:nth-of-type(6n),
.single-proof_gallery .proof_gallery .gallery-columns-7 .gallery-item:nth-of-type(7n),
.single-proof_gallery .proof_gallery .gallery-columns-8 .gallery-item:nth-of-type(8n),
.single-proof_gallery .proof_gallery .gallery-columns-9 .gallery-item:nth-of-type(9n) {
  margin-right: 0;
}

.single-proof_gallery .proof_gallery .gallery-columns-1.gallery-size-medium figure.gallery-item:nth-of-type(1n+1),
.single-proof_gallery .proof_gallery .gallery-columns-1.gallery-size-thumbnail figure.gallery-item:nth-of-type(1n+1),
.single-proof_gallery .proof_gallery .gallery-columns-2.gallery-size-thumbnail figure.gallery-item:nth-of-type(2n+1),
.single-proof_gallery .proof_gallery .gallery-columns-3.gallery-size-thumbnail figure.gallery-item:nth-of-type(3n+1) {
  clear: left;
}

.single-proof_gallery .proof_gallery .gallery-caption {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fff;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
  max-height: 50%;
  opacity: 0;
  padding: 6px 8px;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  width: 100%;
}

.single-proof_gallery .proof_gallery .gallery-caption:before {
  content: "";
  height: 100%;
  min-height: 49px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.single-proof_gallery .proof_gallery .gallery-item:hover .gallery-caption {
  opacity: 1;
}

.single-proof_gallery .proof_gallery .gallery-columns-7 .gallery-caption,
.single-proof_gallery .proof_gallery .gallery-columns-8 .gallery-caption,
.single-proof_gallery .proof_gallery .gallery-columns-9 .gallery-caption {
  display: none;
}

.single-proof_gallery .proof_gallery button[disabled],
.single-proof_gallery .proof_gallery btn.disabled {
  pointer-events: none;
  cursor: not-allowed;
  filter: alpha(opacity=30);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: .30;
}

.single-proof_gallery .mfp-arrow {
  position: absolute;
  top: 50%;
  display: block;
  outline: none;
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.single-proof_gallery .mfp-arrow:hover,
.single-proof_gallery .mfp-arrow:active,
.single-proof_gallery .mfp-arrow:focus {
  background-color: transparent;
}

.single-proof_gallery .mfp-arrow:before,
.single-proof_gallery .mfp-arrow:after {
  font-size: 7em;
}

.single-proof_gallery .mfp-close {
  outline: none;
  padding: 0 24px;
}

.single-proof_gallery .mfp-close:hover,
.single-proof_gallery .mfp-close:active,
.single-proof_gallery .mfp-close:focus {
  background-color: transparent;
}

.single-proof_gallery .mfp-arrow-left {
  left: 2%;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 30px solid transparent;
  border-right: 30px solid white;
}

.single-proof_gallery .mfp-arrow-right {
  right: 2%;
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-right: 30px solid transparent;
  border-left: 30px solid white;
}

.single-proof_gallery .mfp-counter {
  font-size: 1em;
  color: white;
}

.single-proof_gallery div.mfp-bottom-bar {
  margin-top: -50px;
}

.single-proof_gallery div.mfp-bottom-bar .mfp-title {
  margin-top: 10px;
}

.single-proof_gallery img.mfp-img {
  padding: 80px 0;
}

.single-proof_gallery div.mfp-figure:after {
  content: none;
}

.single-proof_gallery .proof_gallery .proof-photo__status span.spinner {
  height: 20px;
  width: 20px;
  margin: 0 6px 6px 0;
  border-left: 3px solid rgba(255, 255, 255, 0.15);
  border-right: 3px solid rgba(255, 255, 255, 0.15);
  border-bottom: 3px solid rgba(255, 255, 255, 0.15);
  border-top: 3px solid rgba(255, 255, 255, 0.8);
  border-radius: 100%;
  -webkit-animation: rotation .6s infinite linear;
  -moz-animation: rotation .6s infinite linear;
  -o-animation: rotation .6s infinite linear;
  animation: rotation .6s infinite linear;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}

@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}

@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

/*---------------------------------------------------------------*/
/*  TEAM
/*---------------------------------------------------------------*/
.team-member .social {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  transition: all 300ms ease;
  width: 100%;
  height: 100%;
}

.team-member .social .wrap:hover a {
  opacity: .5;
}

.team-member .social .wrap a {
  display: inline-block;
  color: #ffffff;
  font-size: 16px;
  padding: 0 15px;
  transition: all 300ms ease;
}

.team-member .social .wrap a:hover {
  opacity: 1;
}

.team-member:hover .social {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.team-member .info {
  text-align: center;
  padding: 36px 15px;
}

@media (max-width: 991px) {
  .team-member .info {
    padding: 15px 15px 0px 15px;
  }
}

.team-member .info .title {
  font-size: 36px;
  line-height: 40px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  letter-spacing: 2.88px;
}

.team-member .info .position {
  letter-spacing: .8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

body .team-member .avatar {
  position: relative;
  overflow: hidden;
  background-size: cover;
  background: no-repeat;
  background-position: center;
  width: 100%;
  height: 370px;
  max-width: 370px;
  margin: auto;
}

.team-member.fullheight {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 10;
  display: flex;
  align-items: flex-end;
}

.team-member.fullheight:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: inline-block;
  background-color: rgba(0, 0, 0, 0.5);
}

.team-member.fullheight .info {
  width: 100%;
  position: relative;
  z-index: 20;
  padding: 34px 15px;
}

.team-member.fullheight .info .title {
  margin-bottom: 20px;
  color: #ffffff;
}

.team-member.fullheight .info .description {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-family: "Libre Baskerville";
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  margin-bottom: 0;
  margin-top: 2px;
}

.team-member.fullheight .social {
  position: static;
  transform: none;
  background-color: transparent;
  margin-top: 25px;
}

.team-member.fullheight .social .vertical-align {
  position: static;
  transform: none;
}

@media only screen and (max-width: 767px) {
  body .team-member.fullheight {
    height: 370px;
  }
}

/*---------------------------------------------------------------*/
/*  POSTS LIST
/*---------------------------------------------------------------*/
.all-posts-descr {
  margin: 15px 0px;
}

@media (max-width: 767px) {
  .all-posts-descr {
    text-align: center;
    padding: 0px 15px;
    margin-bottom: 40px;
  }
}

.all-posts-descr h6 {
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-family: "Montserrat";
  font-weight: 400;
  color: #c0af94;
  font-style: normal;
  margin-top: -7px;
  margin-bottom: 27px;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 991px) {
  .all-posts-descr h6 {
    margin-bottom: 10px;
  }
}

.all-posts-descr h5 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  color: #131313;
  margin-bottom: 20px;
}

.all-posts-descr p {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-family: "Libre Baskerville";
  font-weight: 400;
  color: #888888;
  margin-bottom: 30px;
}

.post_list .all-posts-descr {
  text-align: center;
  max-width: 770px;
  margin: 30px auto 58px auto;
}

.post_list .all-posts-descr h5 {
  font-family: "BebasNeueBook";
  font-size: 70px;
  line-height: 77px;
  letter-spacing: 5.6px;
  font-weight: 400;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .post_list .all-posts-descr {
    margin: 30px auto;
  }
  .post_list .all-posts-descr h5 {
    font-size: 34px;
    line-height: 40px;
  }
}

.row.simple .all-posts-descr {
  text-align: left;
  margin: 0 0 40px 0;
}

.post-box {
  text-align: center;
  display: block;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 240px;
  margin-top: 15px;
  margin-bottom: 15px;
  position: relative;
  overflow: hidden;
  opacity: 1;
}

.post-box:hover {
  opacity: 1;
}

.post-box:before {
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  content: '';
  background-color: rgba(0, 0, 0, 0.3);
  transition: all 0.5s ease;
}

.post-box .text {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.5s ease;
}

@media (max-width: 767px) {
  .post-box {
    margin: 0px;
    margin-bottom: 5px;
  }
  .post-box .text {
    padding: 0px 15px;
  }
}

.post-box .text h6 {
  font-size: 24px;
  color: #ffffff;
  font-family: "BebasNeueRegular", sans-serif;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 1.92px;
  margin-bottom: 10px;
  font-style: normal;
  transition: all 0.5s ease;
}

.post-box .text span {
  font-size: 11px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1.1px;
  color: #ffffff;
  font-weight: 400;
  transition: all 0.5s ease;
  -webkit-font-smoothing: antialiased;
}

.post-box:hover:before,
.post-box:hover .text h6,
.post-box:hover .text span {
  opacity: 0;
  transition: all 0.5s ease;
}

.post-box .post-descr {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all 400ms ease;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  background-color: #ffffff;
}

.post-box .post-descr .wrap {
  padding: 15px;
}

.post-box .post-descr h6 {
  font-size: 26px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  margin-bottom: 30px;
  line-height: 1.4;
  font-style: normal;
}

.post-box .post-descr p {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  opacity: .9;
  line-height: 24px;
}

.post-box .post-descr a {
  margin-top: 20px;
  font-weight: 300;
}

.post-box:hover .post-descr,
.post-box.active .post-descr {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.post_list .post-box {
  height: auto;
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
}

.post_list .post-box:before {
  display: none;
}

.post_list .post-box:hover .text .post-box-date {
  opacity: 1;
}

.post_list .post-box .post-box-img-wrapp {
  height: 470px;
  display: block;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.post_list .post-box .text {
  position: static;
  transform: none;
  margin: 30px 0 40px 0;
}

.post_list .post-box .text .post-box-title {
  color: #131313;
  font-weight: 400;
  letter-spacing: 1.92px;
  margin-bottom: 0;
}

.post_list .post-box .text .post-box-title a {
  text-decoration: none;
}

.post_list .post-box .text .post-box-date {
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1.1px;
  color: #c0af94;
  font-weight: 400;
  transition: all 0.5s ease;
}

@media only screen and (max-width: 991px) {
  .post_list .post-box .post-box-img-wrapp {
    height: 370px;
  }
}

@media only screen and (max-width: 767px) {
  .post_list .post-box .text {
    margin: 15px 0;
  }
}

.row.simple {
  overflow-y: scroll;
  border-right: 2px solid #f0f0f0;
}

.row.simple::-webkit-scrollbar {
  width: 4px;
}

.row.simple::-webkit-scrollbar-thumb {
  background-color: #c0af94;
  outline: none;
}

.simple {
  margin-left: 0;
  margin-right: 0;
}

.simple .col-lg-12, .simple .col-sm-12, .simple .col-xs-12 {
  padding-right: 15px;
  padding-left: 0;
}

.simple .post-box {
  height: auto;
  text-align: left;
  margin: 0 0 33px 0;
}

.simple .post-box:before {
  display: none;
}

.simple .post-box:hover .text .post-box-date {
  opacity: 1;
}

.simple .post-box .text {
  position: static;
  transform: none;
}

.simple .post-box .text .post-box-date {
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  letter-spacing: 1.1px;
  color: #c0af94;
  font-weight: 400;
  transition: all 0.5s ease;
}

.simple .post-box .text .post-box-title {
  color: #131313;
  font-weight: 400;
  letter-spacing: 1.92px;
  margin-bottom: 0;
  margin-top: 5px;
}

.simple .post-box .text .post-box-title a {
  text-decoration: none;
}

.simple .post-box .text .post-box-desc {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-family: "Libre Baskerville";
  font-weight: 400;
  color: #888888;
  margin-bottom: 0;
  margin-top: 2px;
}

.simple .post-box .text .post-box-desc p {
  margin-bottom: 0;
  color: #888888;
}

@media only screen and (max-width: 767px) {
  .simple {
    margin: 0 15px;
  }
  .simple .post-box .text {
    padding: 0;
  }
}

.scroll {
  background-color: #c0af94;
  position: absolute;
  left: 1379px;
  top: 191px;
  width: 4px;
  height: 30px;
  z-index: 10;
}

/*---------------------------------------------------------------*/
/*  TOGGLES &&  IMAGE SLIDER &&   SERVICES
------------------------*/
/*---------------------------------------------------------------*/
.toggle {
  margin-bottom: 10px;
}

.toggle-title {
  position: relative;
  display: inline-block;
  font-family: 'Montserrat';
  letter-spacing: .1em;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
}

.toggle-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #000;
  bottom: -3px;
  transition: all .5s ease;
}

.toggle.active .toggle-title:after {
  width: 100%;
}

.toggle-content {
  padding-top: 10px;
  font-size: 14px;
  display: none;
}

.toggle.active .toggle-content {
  display: block;
}

/* 18. SERVICES
------------------------*/
.services {
  background-color: #F4F4F4;
  padding: 30px 15px;
  min-height: 295px;
  margin-bottom: 30px;
}

.services .img-wrap {
  width: 100%;
  height: 110px;
  margin: auto;
  position: relative;
}

.services .img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 110px;
}

.services .content {
  padding-top: 3px;
}

.services .content .title {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 2.08px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
}

.services .content .text {
  font-size: 12px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.96px;
  font-weight: 400;
}

/* 19. IMAGE SLIDER
--------------------*/
.img-slider {
  position: relative;
}

.img-slider img {
  width: 100%;
}

.img-slider ul {
  list-style: none;
}

.post-slider-img {
  width: 100%;
  height: 247px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.img-slider .flex-direction-nav {
  margin-left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  opacity: .5;
  transition: all .3s ease;
}

.img-slider:hover .flex-direction-nav {
  opacity: 1;
}

.img-slider .flex-direction-nav li {
  display: inline-block;
}

.img-slider .flex-next,
.img-slider .flex-prev {
  background: #ffffff;
  display: block;
  padding: 5px;
  font-size: 40px;
  color: #555;
}

.post .img-slider .flex-next,
.post .img-slider .flex-prev {
  padding: 0;
}

/*---------------------------------------------------------------*/
/*  BLOG
/*---------------------------------------------------------------*/
.post {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  body .banner-gallery .content-wrap {
    width: 100%;
    margin-left: 0;
  }
  .post {
    width: 100%;
  }
}

body .post a {
  text-decoration: none;
  display: block;
  position: relative;
  opacity: 1;
  transition: all 0.5s ease;
  width: 100%;
  background-color: #343434;
}

.post a .post-content .post-media {
  display: none;
}

.post a .post-media {
  display: block;
  width: 100%;
  height: 240px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.post a:before {
  display: block;
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 0.5s ease;
}

.post-content {
  margin: 0;
  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  transition: all 0.5s ease;
}

.post-content.sticky .title {
  position: relative;
}

.post-content.sticky .title::before {
  content: "\f249";
  font-family: "FontAwesome";
  margin-right: 8px;
}

.post-content h5 {
  font-size: 24px;
  line-height: 30px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #ffffff;
  display: inline-block;
  font-weight: 400;
  text-transform: none;
  text-align: center;
  letter-spacing: 1.92px;
  margin: 0;
  margin-bottom: 13px;
}

body .post-content .date {
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.1px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  text-align: center;
  line-height: normal;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

.post iframe {
  border: none;
  display: block;
}

.post a:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

.post a:hover:before {
  opacity: 0;
  transition: all 0.5s ease;
}

.post a:hover .date {
  opacity: 0;
  transition: all 0.5s ease;
}

.post a:hover h5 {
  opacity: 0;
  transition: all 0.5s ease;
}

/*Detail post*/
.single-post .date-post {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  color: #c0af94;
  text-transform: uppercase;
  padding-top: 35px;
  padding-bottom: 17px;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 767px) {
  .single-post .date-post {
    padding-top: 30px;
    text-align: center;
  }
}

.single-post .title {
  font-family: "BebasNeueBook";
  font-size: 70px;
  line-height: 80px;
  letter-spacing: 5.6px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 15px;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  color: #131313;
}

@media (max-width: 767px) {
  .single-post .title {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
  }
}

.single-post p {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Libre Baskerville";
  color: #888888;
  margin-bottom: 30px;
}

.single-post .single-content blockquote {
  margin: 30px 0px;
  overflow: hidden;
  text-align: center;
  padding: 20px 30px 20px 25px;
  border-left: 4px solid #c0af94;
  background-color: #ebebeb;
}

@media (max-width: 767px) {
  .single-post .single-content blockquote {
    padding: 15px;
  }
}

.single-post .single-content blockquote p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Libre Baskerville";
  color: #131313;
  text-align: left;
  font-style: normal;
  margin: 0;
  letter-spacing: 1.28px;
}

/*Recent posts*/
.recent-post-single .recent-title {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  margin: 0;
  margin-top: 34px;
  margin-bottom: 34px;
  color: #131313;
}

.sm-wrap-post a {
  display: block;
  background-color: #343434;
  position: relative;
  min-height: 240px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition: all 0.5s ease;
}

.sm-wrap-post a:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

.sm-wrap-post .content {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  opacity: 1;
  transition: all 0.5s ease;
}

.sm-wrap-post a:hover .content {
  opacity: 0;
  transition: all 0.5s ease;
}

.sm-wrap-post .content .title {
  font-size: 24px;
  line-height: 30px;
  font-family: "BebasNeueRegular";
  letter-spacing: 1.92px;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
}

.sm-wrap-post .content .post-date {
  display: block;
  height: auto;
  line-height: 20px;
}

.sm-wrap-post .content .post-date .date {
  font-size: 11px;
  line-height: 20px;
  font-family: "Montserrat";
  letter-spacing: 1.1px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
  display: inline-block;
  vertical-align: top;
  -webkit-font-smoothing: antialiased;
}

.sm-wrap-post a:before {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 1;
  transition: all 0.5s ease;
}

.sm-wrap-post a:hover:before {
  opacity: 0;
  transition: all 0.5s ease;
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/*  GOOGLE MAP && CONTACT INFO  && CONTACT FORM
/*---------------------------------------------------------------*/
body #google-map {
  width: 68%;
  float: left;
  height: 480px;
}

#google-map img {
  max-width: none;
}

/* 22. CONTACT INFO
---------------------*/
body .contact-info {
  float: left;
  width: 32%;
  color: #000;
  z-index: 888;
  display: block;
  padding: 60px 70px 20px 70px;
  background-color: #f2f2f2;
  height: 480px;
}

@media (max-width: 991px) {
  .contact-info {
    padding: 40px 20px 20px 20px;
  }
}

.contact-info .info-box {
  margin-bottom: 24px;
}

.contact-info .info-box:last-child {
  margin-bottom: 0;
}

.contact-info i {
  float: left;
  width: 50px;
}

.contact-info .details {
  display: inline-block;
}

.contact-info .details h5 {
  font-size: 36px;
  line-height: 40px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  letter-spacing: 2.88px;
  margin-bottom: 8px;
}

.contact-info h5 {
  margin-bottom: 10px;
}

.contact-info .details a,
.contact-info .details h6 {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  text-decoration: none;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-style: normal;
  display: block;
  margin: 0px;
  font-weight: 400;
  color: #888888;
}

.contact-info .details a:hover {
  opacity: 1;
}

/* 23. CONTACT FORM
----------------------*/
.contact-form {
  margin-top: 30px;
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .contact-form {
    text-align: center;
    margin-top: 0px;
    margin-bottom: 30px;
  }
}

.contact-form .title {
  font-size: 36px;
  line-height: 40px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  letter-spacing: 2.88px;
  margin-bottom: 16px;
}

.wpcf7-form,
.wpcf7 {
  font-family: "Libre Baskerville", sans-serif;
}

.wpcf7 form label {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  width: 100%;
}

form.wpcf7-form input,
form.wpcf7-form textarea {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  background: none;
  width: 100%;
  border: 0;
  border-radius: 0;
  color: #131313;
  border-bottom: 2px solid #f2f2f2;
  padding: 10px 0px;
  letter-spacing: 1.04px;
  margin-bottom: 22px;
  transition: all .3s ease;
}

.wpcf7 form textarea {
  min-height: 50px;
  max-height: 50px;
  resize: none;
  margin-bottom: 20px;
}

form.wpcf7-form input::-moz-placeholder {
  color: #888888;
}

.wpcf7 form input::-moz-placeholder {
  color: #888888;
}

form.wpcf7-form input::-webkit-input-placeholder {
  color: #888888;
}

.wpcf7 form input::-webkit-input-placeholder {
  color: #888888;
}

form.wpcf7-form input:-moz-placeholder {
  color: #888888;
}

.wpcf7 form input:-moz-placeholder {
  color: #888888;
}

form.wpcf7-form input:-ms-input-placeholder {
  color: #888888;
}

.wpcf7 form input:-ms-input-placeholder {
  color: #888888;
}

form.wpcf7-form textarea::-moz-placeholder {
  color: #888888;
}

.wpcf7 form textarea::-moz-placeholder {
  color: #888888;
}

form.wpcf7-form textarea::-webkit-input-placeholder {
  color: #888888;
}

.wpcf7 form textarea::-webkit-input-placeholder {
  color: #888888;
}

form.wpcf7-form textarea:-moz-placeholder {
  color: #888888;
}

.wpcf7 form textarea:-moz-placeholder {
  color: #888888;
}

form.wpcf7-form textarea:-ms-input-placeholder {
  color: #888888;
}

.wpcf7 form textarea:-ms-input-placeholder {
  color: #888888;
}

form.wpcf7-form textarea {
  min-height: 50px;
  max-height: 50px;
  resize: none;
  margin-bottom: 24px;
}

.wpcf7 form input:focus,
form.wpcf7-form input:focus,
form.wpcf7-form textarea:focus {
  color: #131313;
  border-bottom: 2px solid #c0af94;
}

.wpcf7 form input[type="submit"],
form.wpcf7-form #submit {
  font-size: 11px;
  line-height: 20px;
  display: inline-block;
  letter-spacing: 1.1px;
  font-family: "Montserrat";
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  text-decoration: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  border-left: none;
  border-right: none;
  color: #131313;
  width: auto;
  margin-top: 7px;
  margin-bottom: 0px;
  -webkit-font-smoothing: antialiased;
  border-radius: 0px;
}

@media (max-width: 767px) {
  .wpcf7 form input[type="submit"],
  form.wpcf7-form #submit {
    margin-bottom: 30px;
  }
}

.wpcf7 form input[type="submit"]:hover,
form.wpcf7-form #submit:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  border-left: none;
  border-right: none;
  color: #ffffff;
  background-color: #131313;
  opacity: 1;
}

.contact-message {
  display: none;
  text-transform: capitalize;
  text-align: center;
  margin: 50px 0;
}

/*---------------------------------------------------------------*/
/*  FOOTER &&  PAGINATION
/*---------------------------------------------------------------*/
/* 24. PAGINATION
------------------------*/
.pagination {
  width: 100%;
  text-align: center;
  margin: 37px 0 0;
}

.pagination.cs-pager .page-numbers.next:after {
  content: '\f105';
  font-family: FontAwesome;
  display: inline-block;
  color: #131313;
}

.pagination.cs-pager .page-numbers.prev:after {
  content: '\f104';
  font-family: FontAwesome;
  display: inline-block;
  color: #131313;
}

/* 25. FOOTER
------------------------*/
#footer,
body #footer {
  display: block;
  padding: 30px 0px;
  background-color: #131313;
  z-index: 99;
  width: 100%;
  margin: auto;
  min-height: 90px;
  bottom: 0;
  transition: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

#footer.footer_style2,
body #footer.footer_style2 {
  background-color: transparent;
  bottom: 90px;
}

#footer.footer_style3,
body #footer.footer_style3 {
  position: static;
}

#footer.fix-bottom {
  position: fixed;
  z-index: 300;
  bottom: 0;
  width: 100%;
  background-color: transparent;
}

@media (max-width: 767px) {
  #footer {
    padding: 20px 0px;
  }
}

#footer .copyright {
  letter-spacing: 0.96px;
  font-size: 12px;
  line-height: 30px;
  font-family: "Libre Baskerville", sans-serif;
  color: #8f8f8f;
  text-align: left;
  float: left;
}

#footer .copyright a {
  color: #ffffff;
  transition: all 300ms ease;
  text-decoration: none;
}

#footer .copyright a:hover {
  opacity: 0.7;
  text-decoration: none;
}

#footer .social-links {
  float: right;
  line-height: 30px;
}

#footer .social-links .fa {
  font-size: 15px;
}

#footer .social-links a {
  font-size: 13px;
  text-decoration: none;
  color: #ffffff;
  display: inline-block;
  padding-left: 18px;
  transition: all .3s ease;
}

#footer .social-links:hover a {
  opacity: .7;
}

#footer .social-links a:hover {
  opacity: 1;
}

body .social-list > li {
  position: relative;
  display: inline;
  margin: 0px 11px 0 1px;
  list-style: none;
}

body .social-list {
  margin-top: 15px;
  margin-left: 0;
  text-align: center;
  padding: 0 15px;
}

.social-list a {
  transition: all 300ms ease;
}

.social-list:hover a {
  opacity: .7;
}

.social-list a:hover {
  opacity: 1;
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/*  SIDEBAR
/*---------------------------------------------------------------*/
/* 26. SIDEBAR
-----------------*/
.sidebar-item {
  margin-bottom: 50px;
}

.sidebar-item > h5,
.sidebar-item .screen-reader-text {
  letter-spacing: .75px;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 21px;
  line-height: 21px;
  display: block;
}

.sidebar-item .screen-reader-text,
.screen-reader-text {
  display: none;
}

.widget_search label {
  display: block;
  width: 100%;
}

.widget_search .searchform {
  position: relative;
}

.widget_search input[type="text"] {
  background-color: #ffffff;
  border: 1px solid #131313;
  border-radius: 2px;
  color: #131313;
  padding: 5px 70px 5px 10px;
  font-weight: 400;
  width: 100%;
}

.widget_search input[type="submit"] {
  background: #131313;
  border: 1px solid #131313;
  border-radius: 2px;
  color: #ffffff;
  padding: 5px 10px;
  font-weight: 400;
  transition: all 300ms ease;
  position: absolute;
  right: 0;
  bottom: 0;
}

.widget_search input[type="submit"]:hover {
  color: #131313;
  background: #ffffff;
}

.widget_rss .rss-date {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  opacity: .7;
}

.widget_rss ul li {
  margin-bottom: 15px;
}

.widget_tag_cloud .tagcloud a {
  border: 1px solid #131313;
  padding: 2px 5px;
  text-decoration: none;
  margin: 3px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  vertical-align: top;
  font-weight: 600;
}

.widget_calendar table td,
.widget_calendar table th {
  text-align: center;
}

.sidebar-item:first-child {
  margin-top: 0;
}

.sidebar-item h5 {
  padding: 0px 7px 10px 3px;
  width: 100%;
  border-bottom: 1px solid #131313;
  font-family: "Montserrat", sans-serif;
}

.page ol,
.page ul,
.page ul,
.single-content ol,
.single-content ul,
.sidebar-item ul {
  margin-left: 20px;
  font-family: "Montserrat", sans-serif;
}

.sidebar-item ul li {
  color: rgba(19, 19, 19, 0.7);
}

.sidebar-item ul li a {
  text-decoration: none;
  color: #131313;
}

.sidebar-item select {
  max-width: 100%;
  font-family: "Montserrat", sans-serif;
  padding: 5px 10px;
}

.sidebar-item select option {
  max-width: 255px;
  width: 100%;
}

.sidebar-item input {
  padding: 4px 5px 6px 9px;
  border: 1px solid #a6a6a6;
  font-family: "Montserrat", sans-serif;
}

.page ul.slides {
  margin-left: 0;
}

.post-banner {
  height: 400px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.post-details .date-post {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  margin-top: 20px;
  letter-spacing: .6px;
}

.post-details .title {
  font-size: 44px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 12px;
  letter-spacing: 3px;
}

.pagination a.img {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 150px;
  width: 100%;
  display: block;
  opacity: 1;
  margin: 0;
  background-color: #000;
  text-decoration: none;
  padding-top: 87px;
  margin-bottom: 5px;
}

/*---------------------------------------------------------------*/
/*   PROTECTED PAGE
/*---------------------------------------------------------------*/
/* 27. PROTECTED PAGE
-----------------*/
.protected-page .protected-title {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  color: #131313;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  text-align: center;
  padding: 280px 15px 16px 15px;
}

@media (max-width: 767px) {
  .protected-page .protected-title {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.protected-page form {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-weight: 400;
  color: #c0af94;
  text-align: center;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 767px) {
  .protected-page form {
    padding: 0px 15px;
  }
}

.protected-page input[type="password"] {
  margin-top: 35px;
  display: block;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  padding: 10px 0px;
  text-align: center;
  font-family: "Libre Baskerville";
  letter-spacing: 0.39px;
  font-weight: 400;
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 2px solid #f2f2f2;
  transition: all 0.5s ease;
  color: #888888;
}

@media (max-width: 767px) {
  .protected-page input[type="password"] {
    margin-top: 10px;
  }
}

.protected-page input[type="password"]:focus {
  color: #131313;
  border-bottom: 2px solid #c0af94;
  transition: all 0.5s ease;
}

.protected-page input[type="password"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #888888;
}

.protected-page input[type="password"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #888888;
}

.protected-page input[type="password"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #888888;
}

.protected-page input[type="password"]:-moz-placeholder {
  /* Firefox 18- */
  color: #888888;
}

@media (max-width: 767px) {
  .protected-page input[type="password"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.protected-page input[type="submit"] {
  font-size: 11px;
  line-height: 20px;
  display: inline-block;
  letter-spacing: 1.1px;
  font-family: "Montserrat";
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  border-left: none;
  border-right: none;
  color: #131313;
  background-color: transparent;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-font-smoothing: antialiased;
  border-radius: 0px;
}

@media (max-width: 767px) {
  .protected-page input[type="submit"] {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.protected-page input[type="submit"]:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  border-left: none;
  border-right: none;
  color: #ffffff;
  background-color: #131313;
  opacity: 1;
}

.single-content.protected-page .post-info {
  display: none;
}

.single-proof_gallery .recent-post-single {
  display: none;
}

.single-proof_gallery .comments {
  display: none;
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/*  SLIDER
/*---------------------------------------------------------------*/
/* 28. SLIDER
-----------------*/
@media (max-width: 767px) {
  .banner-slider-wrap {
    text-align: center;
  }
}

body .banner-slider-wrap .slider-banner {
  min-height: 500px;
}

.banner-slider-wrap .title {
  font-size: 70px;
  font-family: "BebasNeueBook";
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 5.6px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  .banner-slider-wrap .title {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
  }
}

.banner-slider-wrap .subtitle {
  font-size: 12px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 2.4px;
  margin-bottom: 40px;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 480px) {
  .banner-slider-wrap .subtitle {
    margin-bottom: 20px;
  }
}

.banner-slider-wrap .descr {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.12px;
  max-width: 510px;
}

@media (max-width: 767px) {
  .banner-slider-wrap .descr {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

body .banner-slider-wrap .a-btn,
body .banner-slider-wrap .a-btn-2 {
  margin-top: 30px;
}

.banner-slider-wrap .container {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.banner-slider-wrap .img-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
}

.banner-slider-wrap .swiper-arrow-right {
  position: absolute;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  content: '\f103';
  font-family: fontAwesome;
  color: #ffffff;
  width: 50px;
  height: 50px;
  z-index: 10;
  cursor: pointer;
  transition: all 0.7s ease;
}

@media (max-width: 767px) {
  .banner-slider-wrap .swiper-arrow-right {
    bottom: 0px;
  }
}

.banner-slider-wrap .swiper-arrow-right .fa {
  font-size: 20px;
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-animation-name: sliderArrowAnimation;
  animation-name: sliderArrowAnimation;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  text-align: center;
  opacity: 1;
  transition: all 0.3s ease;
}

.banner-slider-wrap .swiper-arrow-right:hover .fa {
  -webkit-animation: none;
  animation: none;
  transition: all 0.3s ease;
  opacity: 0.6;
}

@-webkit-keyframes sliderArrowAnimation {
  0% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  25% {
    -webkit-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%);
  }
  50% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  75% {
    -webkit-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%);
  }
  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@keyframes sliderArrowAnimation {
  0% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  25% {
    -webkit-transform: translate(-50%, -70%);
    transform: translate(-50%, -70%);
  }
  50% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  75% {
    -webkit-transform: translate(-50%, -30%);
    transform: translate(-50%, -30%);
  }
  100% {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/* /*Else 1 versions css && COMMENTS
/*---------------------------------------------------------------*/
.single-content ul.comments {
  margin-left: 0;
}

.comments {
  margin-top: 73px;
  margin-bottom: 20px;
}

.comments .comment {
  margin-bottom: 3px;
}

.comments .content {
  position: relative;
  padding-right: 40px;
}

.comments .content .comment-reply-link {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #bababa;
  text-decoration: none;
  font-weight: 400;
  transition: all 300ms ease;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.comments .comment-reply-title {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  padding-top: 44px;
  margin-bottom: 13px;
  color: #131313;
}

.comments .comment-reply-title strong {
  font-weight: 400;
}

.comments .comment-reply-title a {
  text-decoration: none;
}

@media (max-width: 767px) {
  .comments .comment-reply-title {
    padding-top: 20px;
  }
}

.comments .content .comment-reply-link:hover {
  color: #131313;
}

.comments .content .text {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #585858;
  overflow: hidden;
  line-height: 22px;
  margin-top: 6px;
  letter-spacing: .6px;
}

.comments .content .text p {
  margin-bottom: 16px;
}

.comments .person img {
  vertical-align: top;
  float: left;
  margin-right: 20px;
  margin-top: 6px;
}

.comments .person .author {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1.92px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  margin: 0;
  text-decoration: none;
  color: #131313;
}

.comments .person .author:hover {
  opacity: .7;
}

.comments .person .comment-date {
  font-size: 12px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  margin-left: 5px;
  font-weight: 400;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.comments-title,
.comments-title span {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  margin-bottom: 26px;
  color: #131313;
}

.comment-title {
  border-bottom: 1px dashed #a6a6a6;
  padding-bottom: 6px;
}

.comments li.comment {
  list-style: none;
}

.comments ul.children {
  margin-top: 30px;
}

.comments-form {
  padding-top: 2px;
  margin-bottom: 80px;
  clear: both;
}

body .comments-form input[type=submit]:hover {
  opacity: 1;
}

#contactform h3,
.comments-form h3 {
  display: block;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  letter-spacing: 1.25px;
  margin-bottom: 20px;
}

#contactform textarea,
#contactform input,
.comments-form textarea,
.comments-form input {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  background: none;
  width: 100%;
  color: #888888;
  border: 0;
  border-bottom: 2px solid #f2f2f2;
  padding: 10px 0px;
  letter-spacing: 1.12px;
  margin-bottom: 13px;
  transition: all .5s ease;
}

#contactform textarea:focus,
#contactform input:focus,
.comments-form textarea:focus,
.comments-form input:focus {
  border-bottom: 2px solid #c0af94;
  transition: all .5s ease;
}

#contactform textarea::-moz-placeholder,
#contactform input::-moz-placeholder,
.comments-form textarea::-moz-placeholder,
.comments-form input::-moz-placeholder {
  color: #888888;
}

#contactform textarea::-webkit-input-placeholder,
#contactform input::-webkit-input-placeholder,
.comments-form textarea::-webkit-input-placeholder,
.comments-form input::-webkit-input-placeholder {
  color: #888888;
}

#contactform textarea:-moz-placeholder,
#contactform input:-moz-placeholder,
.comments-form textarea:-moz-placeholder,
.comments-form input:-moz-placeholder {
  color: #888888;
}

#contactform textarea:-ms-input-placeholder,
#contactform input:-ms-input-placeholder,
.comments-form textarea:-ms-input-placeholder,
.comments-form input:-ms-input-placeholder {
  color: #888888;
}

#contactform textarea,
.comments-form textarea {
  min-height: 50px;
  max-height: 50px;
  resize: none;
}

#contactform #submit,
.comments-form #submit {
  font-size: 11px;
  line-height: 20px;
  display: inline-block;
  letter-spacing: 1.1px;
  font-family: "Montserrat";
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  border-left: none;
  border-right: none;
  color: #131313;
  background-color: transparent;
  margin-top: 25px;
  margin-bottom: 0;
  width: auto;
  -webkit-font-smoothing: antialiased;
  border-radius: 0px;
}

#contactform #submit:hover,
.comments-form #submit:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  border-left: none;
  border-right: none;
  color: #ffffff;
  background-color: #131313;
  opacity: 1;
}

#contactform {
  display: block;
}

.empty-post-list {
  padding-top: 130px;
  text-align: center;
  display: block;
  width: 100%;
}

.wpb_column > .wpb_wrapper > p:last-child {
  margin-bottom: 10px;
}

.vc_column_container .wpb_wrapper .vc_btn3.vc_btn3-style-outline {
  border-width: 1px;
  padding: 3px 22px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 26px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.black p.separator {
  background-color: #ffffff;
}

.portfolio-gallery a.gallery-item {
  float: none;
  padding: 0;
}

.blog .sidebar-item {
  margin-left: 15px;
}

.error404 .main-wrapper {
  min-height: 100vh;
  overflow: hidden;
}

body .container .ytbg iframe,
body .hero .vc_row-fluid .vc_column_container .ytbg iframe {
  margin-left: 0;
}

body .mb_OnlyYT,
body .mb_YTPTime,
body span.mb_YTPMuteUnmute {
  font-size: 0;
  display: none;
}

.mb_YTPPlaypause {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
}

.mb_YTPPlaypause:before {
  font-family: 'FontAwesome';
  content: "\f04c";
  font-size: 25px;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
  text-shadow: 1px 1px 2px black;
}

.mb_YTPPlaypause.active:before {
  content: "\f04b";
}

.mb_YTPBar {
  bottom: 0;
  left: 25px;
}

.mb_YTPBar .buttonBar {
  height: 60px;
}

.single blockquote,
.about-details .content blockquote,
.about-section .content blockquote,
.portfolio-single-content blockquote {
  padding: 26px 26px 26px 64px;
  border: none;
  border-left: 4px solid #131313;
  font-style: italic;
  font-family: "Libre Baskerville", sans-serif;
  background-color: #F4F4F4;
  font-size: 18px;
  letter-spacing: 1.38px;
  color: #131313;
  margin-top: -4px;
  margin-bottom: 25px;
}

blockquote cite {
  line-height: 1.42857;
  color: #777;
  font-style: normal;
  display: block;
  font-size: 14px;
  margin-top: 15px;
}

blockquote cite:before {
  content: '- ';
  margin-right: 7px;
}

body #topmenu ul > li > ul > li > ul {
  display: none;
}

.single table td,
.single table th,
table td,
table th {
  padding: 7px;
}

.post-info {
  margin: 10px 0 15px;
}

.post-info span a,
.post-info span {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  text-decoration: none;
  color: #c0af94;
  text-transform: uppercase;
}

.post-info span a,
.post-info span {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  text-decoration: none;
  color: #c0af94;
  text-transform: uppercase;
}

.post-info span {
  padding-right: 16px;
  border-right: 1px solid #E8E8E8;
}

.post-info span:last-child {
  border: 0 none;
}

div.wpcf7-mail-sent-ok {
  left: 0;
  right: 0;
}

body .wpcf7-not-valid {
  border-bottom: 2px solid red;
}

.wpcf7-form-control-wrap {
  position: relative;
  display: block;
}

.wpcf7-form-control-wrap .wpcf7-not-valid-tip {
  position: absolute;
  top: 50px;
}

div.wpcf7-mail-sent-ok,
div.wpcf7-mail-sent-ng,
div.wpcf7-validation-errors {
  border: none;
}

.ajax-loader {
  display: none;
}

div.wpcf7 {
  position: relative;
}

body div.wpcf7-response-output {
  position: absolute;
  bottom: 20px;
  margin: 0;
}

@media (min-width: 1200px) {
  div.wpcf7-response-output {
    left: -15px;
  }
}

@media (max-width: 767px) {
  div.wpcf7-response-output {
    position: relative;
    text-align: center;
    bottom: 20px;
  }
}

.empty-post-list form {
  padding-top: 20px;
  width: 270px;
  margin: auto;
  display: block;
}

.empty-post-list input {
  display: inline-block;
  margin: 0 auto;
  height: 36px;
}

.empty-post-list input[type=search] {
  border: 1px solid #ccc;
  padding-left: 7px;
}

.empty-post-list label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  float: left;
  width: 182px;
}

.abs * {
  color: inherit;
}

.tabs ul,
.hero-slider ol,
.tabs ol {
  margin-left: 0;
}

/*.container .wpb_row {
  margin-bottom: 20px;
}*/
@media (max-width: 767px) {
  .slide-caption {
    left: 20px;
    bottom: 20px;
    right: 20px;
  }
  .vc_column_container.vc_col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .page .wpb_row,
  .page .wpb_content_element,
  .page ul.wpb_thumbnails-fluid > li,
  .page .last_toggle_el_margin,
  .page .wpb_button {
    margin-bottom: 0;
  }
  body .pad-top-120 {
    padding-top: 110px;
  }
  [class*="col-sm"] {
    margin-bottom: 0;
  }
  #footer .copyright,
  #footer .social-links {
    float: none;
    text-align: center;
  }
  #footer .copyright a,
  #footer .social-links a {
    padding: 0 4px;
  }
}

@media screen and (max-width: 600px) {
  html {
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  body .hero-slider .slides .slide {
    height: 400px;
  }
  .side-link .side-title {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  body .hero-slider .slides .slide {
    height: 300px;
  }
}

.social-links.single-post {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.social-links.single-post a {
  padding-left: 20px;
}

.click-on-this {
  text-decoration: none;
}

.social-list-portfolio:hover a {
  opacity: .6;
}

.social-list-portfolio a:hover {
  opacity: 1;
}

/* nn. ABOUT DETAILS
-----------------*/
.about-details-section {
  background-color: #f2f2f2;
}

.about-details {
  padding: 60px 70px 20px;
}

.about-details.style2 {
  padding: 77px 0 71px 0;
}

@media (max-width: 1199px) {
  .about-details {
    padding: 30px 40px 10px;
  }
}

@media (max-width: 991px) {
  .about-details {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .about-details {
    padding: 30px 15px;
  }
  .about-details.style2 {
    padding: 60px 0;
  }
}

.simple-details {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .simple-details {
    text-align: center;
    padding: 0px 15px;
  }
}

.about-details .content .subtitle,
.simple-details .content .subtitle {
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 1199px) {
  .about-details .content .subtitle,
  .simple-details .content .subtitle {
    margin-bottom: 20px;
  }
}

.simple-details .content .subtitle {
  margin-top: -3px;
  margin-bottom: 28px;
}

@media (max-width: 1199px) {
  .simple-details .content .subtitle {
    margin-bottom: 15px;
  }
}

.about-details .content .title,
.simple-details .content .title {
  font-family: "BebasNeueRegular";
  font-size: 36px;
  line-height: 40px;
  color: #131313;
  letter-spacing: 2.88px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .about-details .content .title,
  .simple-details .content .title {
    font-size: 30px;
    line-height: 30px;
  }
}

.simple-details .content .title {
  line-height: 36px;
  margin-bottom: 25px;
}

@media (max-width: 1199px) {
  .simple-details .content .title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.about-details .content .text,
.simple-details .content .text {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  letter-spacing: 1.12px;
}

.about-details .content .text p,
.simple-details .content .text p {
  margin-top: 28px;
}

.about-details.style2 .content .text p {
  margin-top: 0;
  margin-bottom: 5px;
}

.simple-details .content .text {
  margin-bottom: 30px;
}

.about-details .content .text blockquote {
  margin-top: 25px;
  overflow: hidden;
  text-align: center;
  padding: 20px 30px 20px 25px;
  border-left: 4px solid #c0af94;
  background-color: #ebebeb;
}

@media (max-width: 1199px) {
  .about-details .content .text blockquote {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .about-details .content .text blockquote {
    padding: 15px;
  }
}

.about-details .content .text blockquote p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Libre Baskerville";
  color: #131313;
  text-align: left;
  font-style: normal;
  margin: 0;
  letter-spacing: 1.28px;
}

.about-details .content .button {
  margin-top: 14px;
  margin-left: 0;
}

/* nn. TITLES
-----------------*/
.titles {
  max-width: 770px;
  margin: auto;
}

.titles.left {
  max-width: none;
}

.titles.left .title,
.titles.left .subtitle {
  text-align: left;
}

.titles .title {
  font-size: 36px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 2px;
}

.titles .subtitle {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  letter-spacing: .73px;
}

/* nn. INSTAGRAM
-----------------*/
.insta-box .insta-box-follow {
  font-size: 36px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  float: left;
  width: 20.5%;
  letter-spacing: 2.88px;
  padding-top: 35px;
  word-break: break-all;
  line-height: 1.2;
}

@media (max-width: 1199px) {
  .insta-box .insta-box-follow {
    font-size: 30px;
  }
}

.insta-box .insta-box-follow a {
  font-size: 14px;
  display: inline-block;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  display: block;
  font-weight: 400;
  letter-spacing: 1.12px;
  margin-top: 5px;
  text-decoration: none;
  transition: all 300ms ease;
}

.insta-box .insta-box-follow a:hover {
  color: #131313;
}

.insta-box .insta-img-wrap {
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.insta-box .insta-img-wrap a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.insta-box .insta-img-wrap .img {
  display: block;
  width: 125px;
  height: 125px;
  margin: 0;
  margin-bottom: 40px;
}

@media (max-width: 1199px) {
  .insta-box .insta-img-wrap .img {
    width: 115px;
    height: 115px;
  }
}

@media (max-width: 991px) {
  .insta-box .insta-box-follow {
    font-size: 24px;
    float: none;
    width: 100%;
    text-align: center;
    padding: 10px 0px 40px;
  }
  .insta-box .insta-img-wrap .img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 767px) {
  .insta-box {
    padding: 0px 15px;
  }
  .insta-box.col-xs-12 {
    padding: 0px 30px;
  }
  .insta-box .insta-img-wrap {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .insta-box .insta-img-wrap a {
    -ms-flex-preferred-size: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
  .insta-box .insta-img-wrap a .img {
    margin-left: auto;
    margin-right: auto;
  }
}

.portfolio-single-content {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #585858;
  line-height: 1.7;
  letter-spacing: .7px;
}

body .no-padd {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1200px) {
  body .hero > .container {
    padding: 0;
  }
  body .no-padd-md {
    padding: 0;
  }
}

@media (max-width: 992px) {
  body #google-map {
    min-height: 310px;
  }
  .top-banner .banner-text {
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 991px) {
  .banner-gallery .content-wrap .content {
    padding: 15px 30px;
  }
  .banner-gallery .content-wrap .title {
    font-size: 35px;
    letter-spacing: 3px;
    line-height: 50px;
  }
  .about-details {
    text-align: center;
  }
}

@media (min-width: 768px) {
  body .pad-sm-l-0 {
    padding-left: 0;
  }
  body .pad-sm-r-0 {
    padding-right: 0;
  }
  #contactform input,
  .comments-form input {
    width: 48.5%;
    float: left;
  }
  #contactform input[name="author"],
  .comments-form input[name="author"] {
    margin-right: 3%;
  }
}

@media (max-width: 768px) {
  .post-details .title {
    line-height: 1.2;
  }
  .top-banner {
    height: 100vmin;
    min-height: 500px;
  }
  .top-banner.center_content {
    min-height: 300px;
  }
  .top-banner .banner-text {
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .wpcf7 form input,
  form.wpcf7-form input {
    margin-bottom: 10px;
  }
  body .no-pad-xs {
    padding: 0;
  }
  .action {
    padding: 27px 30px;
    text-align: center;
  }
  .action .button {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin-top: 20px;
    padding: 1px 39px;
  }
  .banner-gallery::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }
  body .banner-gallery .content-wrap {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px 15px;
    margin-left: 0;
  }
  .banner-gallery .content-wrap .content {
    padding: 0;
  }
}

@media (max-width: 767px) {
  body #google-map,
  body .contact-info {
    float: none;
    width: 100%;
    height: auto;
  }
  body #google-map {
    min-height: 300px;
    height: 300px;
  }
  .contact-info .details {
    margin-left: 0;
  }
  .contact-info {
    margin-top: 30px;
    text-align: center;
    padding: 30px 15px;
  }
  .top-banner .banner-text {
    max-width: 90%;
    padding: 0 15px;
  }
  .top-banner .banner-text .title {
    font-size: 28px;
  }
  .hero {
    padding: 0;
  }
  .top-banner .subtitle {
    letter-spacing: 5px;
  }
  .about-details .content .text blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    padding: 23px 25px 14px;
  }
  .about-details .content .text p {
    margin-bottom: 15px;
  }
  .about-details .content .text blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    margin-top: 13px;
  }
  .about-details .content .button {
    margin-top: 5px;
  }
  .about-details .content .subtitle {
    letter-spacing: 5px;
  }
  .team-member .avatar {
    height: 300px;
  }
  .team-member .info .title {
    font-size: 22px;
  }
  .banner-gallery .content-wrap .title {
    font-size: 30px;
    margin-bottom: 30px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .single blockquote,
  .about-details .content blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    padding: 26px 26px 26px 36px;
  }
  .pagination {
    margin: 15px 0 0;
  }
  .comments {
    margin-top: 28px;
    text-align: center;
  }
  .comments .person .comment-date {
    display: block;
  }
  .comments .content {
    padding-right: 0;
  }
  .comments .person img {
    float: none;
    display: block;
    margin: auto auto 15px;
  }
  .comments .content .comment-reply-link {
    top: auto;
    bottom: -15px;
  }
  .comments .comment {
    margin-bottom: 30px;
  }
  .comments-title {
    margin-bottom: 20px;
  }
  .comments-form {
    margin-bottom: 60px;
  }
  .post-details,
  .single-content {
    text-align: center;
  }
  .post-details .title {
    font-size: 30px;
  }
  .single blockquote,
  .about-details .content blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    font-size: 16px;
    padding: 26px;
  }
  .single-content p {
    margin-bottom: 15px;
  }
  .about-section {
    padding: 30px 15px;
  }
  .about-section .title {
    font-size: 26px;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 1.75em;
  table-layout: fixed;
  width: 100%;
}

table,
th,
td {
  border: 1px solid #d1d1d1;
}

dd {
  margin: 0 0 1.75em;
}

address {
  font-style: italic;
}

abbr,
acronym {
  border-bottom: 1px dotted #d1d1d1;
  cursor: help;
}

code {
  background-color: #d1d1d1;
  color: #131313;
}

mark,
ins {
  background: #007acc;
  color: #ffffff;
  padding: 0.125em 0.25em;
  text-decoration: none;
}

.price ins {
  background-color: transparent;
}

kbd {
  background-color: transparent;
  color: #131313;
  font-weight: 100;
  box-shadow: none;
  opacity: .9;
}

.alignleft {
  float: left;
  margin: 0.375em 1.75em 1.75em 0;
}

.alignright {
  float: right;
  margin: 0.375em 0 1.75em 1.75em;
}

.wp-caption {
  margin-bottom: 1.75em;
  max-width: 100%;
}

.wp-caption .wp-caption-text {
  font-style: italic;
  opacity: .9;
  font-size: 13px;
}

/*PAGE NAVIGATION */
.post-nav,
.pager-pagination {
  text-align: center;
  clear: both;
  font-family: "Montserrat", sans-serif;
  padding: 50px 0;
}

.post-nav a,
.pages,
.page-numbers:not('.next, .prev') {
  border: 1px solid #131313;
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  height: 24px;
  line-height: 22px;
  margin-right: 4px;
  text-align: center;
  width: 24px;
  color: #ffffff;
  text-decoration: none;
  background-color: #131313;
}

.post-nav a:hover,
.post-nav a:focus,
.page-numbers:hover,
.page-numbers:focus {
  opacity: .7;
  border-color: transparent;
  color: #ffffff;
  text-decoration: none;
}

body .next.page-numbers,
body .prev.page-numbers {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  background-color: transparent;
  color: #131313;
}

.prev.page-numbers {
  padding-right: 10px;
}

.next.page-numbers {
  padding-left: 10px;
}

.next.page-numbers:hover,
.prev.page-numbers:hover {
  color: #131313;
}

.post-nav .pages,
.post-nav .current,
.pager-pagination .pages,
.pager-pagination .current {
  opacity: .7;
  border-color: transparent;
  color: #ffffff;
}

.post-nav a .pages {
  opacity: 1;
}

.post-nav a .pages {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .post-nav,
  .pager-pagination {
    padding: 30px 0;
  }
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  padding: 0 10px;
  background: #131313;
  border-color: #131313;
  color: #ffffff;
  transition: all 300ms ease;
  border-radius: 0px;
}

button:hover,
html input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover {
  opacity: .8;
}

figure.gallery-item {
  display: inline-block;
  max-width: 33.33%;
  text-align: center;
  overflow: visible;
  vertical-align: top;
  width: 100%;
  height: auto;
  padding: 0 1.1400652% 2.2801304%;
}

.gallery-caption {
  padding-top: 7px;
}

.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

caption {
  color: #131313;
  opacity: .7;
}

div.wpcf7-response-output {
  margin: auto auto 20px;
  padding: 0;
}

.main-wrapper {
  overflow-x: hidden;
  position: relative;
}

body .display {
  display: block;
}

.portfolio-single-content .social-list {
  text-align: right;
}

.portfolio-single-content .social-list li {
  margin: 0px 1px 0 11px;
}

.single-pagination {
  padding: 50px 0;
}

.single-pagination > div {
  display: inline-block;
}

.single-pagination > div.pag-prev {
  float: left;
}

.single-pagination > div.pag-prev a {
  padding-left: 20px;
}

.single-pagination > div.pag-prev a:hover i {
  left: 5px;
}

.single-pagination > div.pag-prev i {
  left: 0;
  transition: left 300ms ease;
}

.single-pagination > div.pag-next {
  float: right;
}

.single-pagination > div.pag-next a {
  padding-right: 20px;
}

.single-pagination > div.pag-next a:hover i {
  right: 5px;
}

.single-pagination > div.pag-next i {
  right: 0;
  transition: right 300ms ease;
}

.single-pagination > div a.content {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #585858;
  line-height: 1.7;
  letter-spacing: .7px;
  text-decoration: none;
  position: relative;
  display: block;
}

.single-pagination > div a.content:hover {
  color: #c0af94;
}

.single-pagination > div a.content i {
  font-size: 18px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #c0af94;
}

@media screen and (max-width: 991px) {
  .single-pagination {
    padding: 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .single-pagination > div.pag-prev {
    float: left;
    margin-left: 15px;
  }
  .single-pagination > div.pag-next {
    float: right;
    margin-right: 15px;
  }
}

/*Another styles*/
.bypostauthor {
  font-size: inherit;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.top-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.top-banner .subtitle {
  padding-top: 0;
}

.top-banner .content {
  margin-top: 150px;
  margin-bottom: 150px;
  z-index: 30;
}

.top-banner.left_content .content {
  margin-top: 213px;
  margin-bottom: 213px;
}

.top-banner.center_content .content {
  margin-top: 75px;
  margin-bottom: 60px;
}

.top-banner.full-height .content {
  margin-top: auto;
  margin-bottom: auto;
}

.protected-page form {
  padding-bottom: 280px;
}

.sm-wrap-post .content .title {
  padding-right: 15px;
  padding-left: 15px;
}

.post-content h5 {
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 1200px) {
  .protected-page .protected-title {
    padding-top: 150px;
  }
  .protected-page form {
    padding-bottom: 150px;
  }
  .post-banner {
    height: 300px;
  }
}

@media (min-height: 600px) and (min-width: 1280px) {
  .protected-page .protected-title {
    padding-top: 100px;
  }
  .protected-page form {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .top-banner .content {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .top-banner.left_content .content {
    margin-top: 150px;
    margin-bottom: 150px;
  }
  .protected-page .protected-title {
    padding-top: 100px;
  }
  .protected-page form {
    padding-bottom: 100px;
  }
  .select-for-hovers .content {
    text-align: center;
  }
  .services {
    min-height: 280px;
  }
  .post-banner {
    height: 250px;
  }
  .single-post .title {
    font-size: 48px;
    line-height: 1.2;
  }
  .comments .comment-reply-title {
    padding-top: 0;
    font-size: 25px;
    line-height: 1.2;
  }
  .recent-post-single .recent-title {
    font-size: 25px;
    line-height: 1.2;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.top-banner .content {
  max-width: 1170px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.top-banner.center_content {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.top-banner.center_content .content {
  text-align: center;
}

@media (max-width: 1199px) {
  .top-banner .content {
    max-width: 970px;
  }
}

@media (max-width: 991px) {
  .top-banner .content {
    max-width: 750px;
  }
}

@media (max-width: 767px) {
  .top-banner .content {
    padding: 0px 15px;
  }
}

.top-banner .title {
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .single-post .title {
    font-size: 40px;
  }
  .single-post p {
    margin-bottom: 15px;
  }
  .contact-info {
    margin-top: 0px;
  }
  .contact-info .details h5,
  .contact-form h2 {
    font-size: 28px;
  }
  .wpcf7 form input[type="submit"],
  form.wpcf7-form #submit {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .sm-wrap-post a {
    min-height: 150px;
  }
  .blog .sidebar-item {
    margin-right: 15px;
  }
}

@media screen and (max-width: 600px) {
  .top-banner .content,
  .top-banner.center_content .content {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .top-banner.left_content .content {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .protected-page .protected-title {
    padding-top: 60px;
    font-size: 26px;
    padding-bottom: 0;
  }
  .protected-page form {
    padding-bottom: 60px;
  }
  .protected-page input[type="submit"] {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .single-post .title {
    font-size: 30px;
  }
}

.error-height {
  height: 100vh;
}

body .top-banner.top_align .content {
  top: 0;
  margin-top: 0;
}

/* change */
body .post.no-image a:hover,
body .no-image.recent-simple-post a:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

.post.no-image a:hover:before {
  opacity: 1;
  transition: all 0.5s ease;
}

.post.no-image a:hover .date {
  opacity: 1;
  transition: all 0.5s ease;
}

.post.no-image a:hover h5,
.no-image.recent-simple-post .sm-wrap-post a:hover .content {
  opacity: 1;
  transition: all 0.5s ease;
}

.empty-post-list {
  padding-bottom: 130px;
}

.search-no-results .main-wrapper {
  height: auto;
}

body .blog {
  height: auto;
}

.banner-list li span {
  background-size: cover;
}

.container.about,
.about-us .hero > .row.about-us,
.about-section {
  background-color: #f2f2f2;
}

.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.wpb_single_image {
  text-align: center;
}

.custom-row {
  margin-left: -30px;
  margin-right: -30px;
}

.just-gallery img {
  display: initial;
  max-width: none;
}

.pswp__caption__center {
  max-width: none;
  text-align: center;
  padding: 10px 40px;
}

.item-desc {
  letter-spacing: .8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #888;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.pswp__caption h4 {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1.92px;
  margin-bottom: 3px;
}

.pswp__img {
  top: 23px;
}

.pswp__img:hover {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

/* 10 hovers animation */
.select-for-hovers h5 {
  display: inline-block;
  padding: 2px 8px;
  font-weight: 400;
  text-decoration: none;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span {
  position: relative;
  padding: 1px 20px 1px 10px;
  display: inline-block;
  font-weight: 400;
  border: 1px solid #888888;
  text-decoration: none;
  min-width: 200px;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span::after {
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: 5px;
}

.select-for-hovers {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
  margin-top: 20px;
}

.select-for-hovers .content {
  position: relative;
  text-align: right;
  width: 100%;
}

.select-for-hovers .hover {
  display: inline-block;
  position: relative;
}

.select-for-hovers .hover .list {
  text-align: left;
  position: absolute;
  background-color: #fff;
  margin-left: 0;
  z-index: 10;
  width: 100%;
  border: 1px solid #131313;
  display: none;
}

.select-for-hovers .hover .list li {
  list-style: none;
  font-weight: 400;
  padding: 1px 8px;
  cursor: pointer;
  background-color: transparent;
  transition: all 350ms ease;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover .list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* end 10 hovers animation */
.sliphover-container {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1.92px;
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
.woocommerce form .form-row.woocommerce-invalid .select2-container,
.woocommerce form .form-row.woocommerce-invalid input.input-text,
.woocommerce form .form-row.woocommerce-invalid select {
  border-color: #984f4f;
}

.woocommerce form .form-row.woocommerce-validated .select2-container,
.woocommerce form .form-row.woocommerce-validated input.input-text,
.woocommerce form .form-row.woocommerce-validated select {
  border-color: #364e24;
}

/*================================================*/
/* PRODUCT PRICE */
/*================================================*/
.woocommerce .napoli_product_detail div.product p.price,
.woocommerce .napoli_product_detail div.product span.price,
.woocommerce ul.products.default li.product .price,
.napoli_cart.shop_table ul .cart_item ul .product-price,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price,
.woocommerce table.shop_table .cart_item .product-total {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-weight: 600;
  color: #c0af94;
  margin-bottom: 0;
}

.woocommerce .napoli_product_detail div.product p.price del,
.woocommerce .napoli_product_detail div.product span.price del,
.woocommerce ul.products.default li.product .price del,
.napoli_cart.shop_table ul .cart_item ul .product-price del,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal del,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price del,
.woocommerce table.shop_table .cart_item .product-total del {
  color: #d4d4d4;
  opacity: 1;
  display: inline-block;
  padding-right: 10px;
}

.woocommerce .napoli_product_detail div.product p.price ins,
.woocommerce .napoli_product_detail div.product span.price ins,
.woocommerce ul.products.default li.product .price ins,
.napoli_cart.shop_table ul .cart_item ul .product-price ins,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal ins,
#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_price ins,
.woocommerce table.shop_table .cart_item .product-total ins {
  padding: 0;
  color: #131313;
  font-weight: 600;
}

/*================================================*/
/* BUTTONS */
/*================================================*/
.woocommerce-page.woocommerce-cart .woocommerce input.button,
.woocommerce-page.woocommerce-checkout .woocommerce input.button {
  display: inline-block;
  width: auto;
  min-width: 144px;
  border-radius: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.1px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 20px;
  background-color: transparent;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  color: #131313;
  transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;
  right: auto;
  top: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.woocommerce-page.woocommerce-cart .woocommerce input.button:hover,
.woocommerce-page.woocommerce-checkout .woocommerce input.button:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  background-color: #131313;
  color: #fff;
  opacity: 1;
}

.woocommerce-page.woocommerce-cart a.button,
.woocommerce-page.woocommerce-checkout a.button,
.woocommerce-page.woocommerce a.button,
.woocommerce-page.woocommerce button.button.alt,
a.button.checkout {
  display: inline-block;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.1px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  color: #131313;
  border-radius: 0;
  background-color: transparent;
  margin-top: 0;
  opacity: 1;
}

.woocommerce-page.woocommerce-cart a.button:hover,
.woocommerce-page.woocommerce-checkout a.button:hover,
.woocommerce-page.woocommerce a.button:hover,
.woocommerce-page.woocommerce button.button.alt:hover,
a.button.checkout:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  background-color: #131313;
  color: #fff;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a,
.woocommerce-page.woocommerce .woocommerce-message a.button {
  display: inline-block;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.1px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  color: #ffffff;
  border-radius: 0;
  background-color: transparent;
  margin-top: 0;
  opacity: 1;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart a:hover,
.woocommerce-page.woocommerce .woocommerce-message a.button:hover {
  opacity: 1;
  border-top: 2px solid #ffffff;
  border-bottom: 2px solid #ffffff;
  color: #000;
  background-color: #ffffff;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-add-to-cart .added_to_cart {
  display: none;
}

/*================================================*/
/* PRODUCT RATING */
/*================================================*/
.woocommerce .napoli_product_detail .star-rating {
  font-size: 12px;
  height: 20px;
  color: #131313;
}

.woocommerce .napoli_product_detail .star-rating:before {
  color: #e6e6e6;
}

/*================================================*/
/* PRODUCT ONSALE */
/*================================================*/
.woocommerce .napoli_images span.onsale,
.woocommerce ul.products li.product .napoli-prod-list-image .onsale {
  position: absolute;
  top: 30px;
  left: 30px;
  bottom: auto;
  right: auto;
  border-radius: 0;
  display: inline-block;
  margin: 0;
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-weight: 400;
  background-color: #c0af94;
  color: #fff;
  text-transform: uppercase;
  padding: 8px 20px;
  min-height: auto;
  min-width: auto;
}

/*================================================*/
/* PRODUCT QUANTITY */
/*================================================*/
.woocommerce .quantity .qty {
  width: 80px;
  height: 40px;
  margin-right: 22px;
  border: 2px solid #f2f2f2;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  font-weight: 700;
  color: #131313;
  padding-right: 7px;
  background-color: transparent;
}

/*================================================*/
/* PRODUCT MESSAGE */
/*================================================*/
.woocommerce .woocommerce-message,
.woocommerce .woocommerce-info,
.woocommerce .woocommerce-error {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 40px;
  border-top: 4px solid #c0af94;
  background-color: #c0af94;
  color: #f9f5ee;
}

.woocommerce .woocommerce-error {
  background-color: #984f4f;
  border-top: 4px solid #984f4f;
}

.woocommerce .woocommerce-message:before,
.woocommerce .woocommerce-info:before,
.woocommerce .woocommerce-error:before {
  color: #f9f5ee;
}

.woocommerce .woocommerce-message .showcoupon,
.woocommerce .woocommerce-message a,
.woocommerce .woocommerce-info .showcoupon,
.woocommerce .woocommerce-info .showlogin {
  color: #fff;
}

@media only screen and (max-width: 767px) {
  body .woocommerce .woocommerce-message,
  body .woocommerce .woocommerce-info,
  body .woocommerce .woocommerce-error {
    margin-left: 15px;
    margin-right: 15px;
  }
  .woocommerce .woocommerce-message,
  .woocommerce .woocommerce-info,
  .woocommerce .woocommerce-error {
    line-height: 20px;
  }
}

/*================================================*/
/* PRODUCT DETAIL PAGE */
/*================================================*/
.napoli_product_detail {
  margin-top: 0;
  margin-bottom: 20px;
}

/* PRODUCT DETAILS PAGE - IMAGES */
.napoli_product_detail .product .napoli_images {
  width: 52%;
  float: left;
  max-width: 600px;
  margin-top: 5px;
  position: relative;
}

.napoli_product_detail .product .napoli_images a {
  display: block;
}

/* PRODUCT DETAILS PAGE - SLIDER */
.napoli_images .swiper-slide a {
  display: block;
  min-height: 600px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}

.napoli_images .pagination {
  position: absolute;
  bottom: 22px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  margin: 0;
  width: auto;
}

.napoli_images .pagination .swiper-pagination-switch {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0.4;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.napoli_images .pagination .swiper-pagination-switch:hover {
  opacity: 0.7;
}

.napoli_images .pagination .swiper-active-switch {
  opacity: 1;
}

/* PRODUCT DETAILS PAGE - INFO */
.napoli_product_detail .product .summary.entry-summary {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  max-width: 500px;
  margin-bottom: 0;
}

/* PRODUCT DETAILS PAGE - PRICE */
.napoli_product_detail .product_price {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  width: 40%;
  margin-bottom: 27px;
}

/* PRODUCT DETAILS PAGE - RATING */
.napoli_product_detail .product .summary .woocommerce-product-rating {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  width: 60%;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin-bottom: 0;
}

.napoli_product_detail .product .summary .woocommerce-product-rating .star-rating {
  margin-top: 3px;
  margin-right: 5px;
}

.napoli_product_detail .product .summary .woocommerce-product-rating .woocommerce-review-link {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #888888;
}

/* PRODUCT DETAILS PAGE - TITLE */
.napoli_product_detail .product .summary .product_title {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 3;
  -ms-flex-order: 3;
  order: 3;
  width: 100%;
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 2.88px;
  margin-bottom: 20px;
  color: #131313;
}

/* PRODUCT DETAILS PAGE - DESCRIPTION */
.napoli_product_detail .product .summary .product_desc {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 4;
  -ms-flex-order: 4;
  order: 4;
  width: 100%;
  margin-bottom: 22px;
}

.napoli_product_detail .product .summary .product_desc p {
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  margin-bottom: 0;
  color: #888888;
}

/* PRODUCT DETAILS PAGE - FORM */
.napoli_product_detail .product .summary .cart {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 6;
  -ms-flex-order: 6;
  order: 6;
  width: 100%;
  margin-bottom: 33px;
}

.napoli_product_detail .product .summary .variations_form.cart {
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.napoli_product_detail .product .summary .cart .variations {
  margin-bottom: 42px;
  table-layout: auto;
}

.napoli_product_detail .product .summary .cart .group_table {
  border: 0;
  margin-bottom: 42px;
  table-layout: auto;
}

.napoli_product_detail .product .summary .cart .group_table td.label {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1.4px;
  font-weight: 400;
  color: #fff;
  text-align: left;
}

.napoli_product_detail .product .summary .cart .variations .label {
  padding: 0;
}

.napoli_product_detail .product .summary .cart .variations .label label {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.1px;
  font-weight: 400;
  text-transform: uppercase;
  color: #131313;
  margin-bottom: 14px;
}

.napoli_product_detail .product .summary .cart .variations .value {
  padding: 0;
}

.napoli_product_detail .product .summary .cart .variations .value fieldset {
  border-top: 4px solid #f2f2f2;
  border-bottom: 4px solid #f2f2f2;
}

.napoli_product_detail .product .summary .cart .variations .value ul {
  list-style-type: none;
  margin-bottom: 0;
  padding: 18px 0;
}

.napoli_product_detail .product .summary .cart .variations .value ul li:not(:last-child) {
  margin-bottom: 7px;
}

.napoli_product_detail .product .summary .cart .variations .value ul li input {
  display: none;
}

.napoli_product_detail .product .summary .cart .variations .value ul li label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 29px;
  margin-right: 0;
  font-family: "Libre Baskerville";
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #131313;
  margin-bottom: 0;
  min-width: 121px;
}

.napoli_product_detail .product .summary .cart .variations .value ul li label:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #e6e6e6;
}

.napoli_product_detail .product .summary .cart .variations .value ul li input:checked + label:before {
  background-color: #c0af94;
}

.napoli_product_detail .product .summary .cart .variations .value ul li p {
  display: inline-block;
  margin-bottom: 0;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.9px;
  font-weight: 400;
  color: #969696;
  position: relative;
}

.napoli_product_detail .product .summary .cart .variations .value ul li .price {
  float: right;
  line-height: 28px;
}

.napoli_product_detail .single_variation_wrap {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.napoli_product_detail .single_variation {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  width: 50%;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.napoli_product_detail .variations_button {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  width: 50%;
}

.napoli_product_detail .product .summary .cart .button {
  float: none;
}

body .napoli_product_detail .product .summary .cart .button:hover {
  color: #fff;
}

/* PRODUCT DETAILS PAGE - META */
.napoli_product_detail .product .summary .product_meta {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 7;
  -ms-flex-order: 7;
  order: 7;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
}

.napoli_product_detail .product .summary .product_meta a {
  text-decoration: none;
  color: #c0af94;
}

.napoli_product_detail .product .summary .product_meta .sku_wrapper {
  width: 100%;
}

.napoli_product_detail .product .summary .product_meta .posted_in {
  width: 100%;
}

@media only screen and (max-width: 1199px) {
  .napoli_product_detail .product .napoli_images {
    max-width: 500px;
  }
  .napoli_product_detail .product .summary.entry-summary {
    max-width: 440px;
  }
  /* PRODUCT DETAILS PAGE - FORM */
  .napoli_product_detail .product .summary .cart .variations .value ul li {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .napoli_product_detail .product .summary .cart .variations .value ul li label {
    min-width: 100px;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .napoli_product_detail .product .summary .cart .variations .value ul li p {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .napoli_product_detail .product .summary .cart .variations .value ul li .price {
    float: none;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {
  .napoli_product_detail .product .napoli_images {
    width: 50%;
    max-width: 360px;
  }
  .napoli_images .swiper-slide a {
    min-height: 360px;
  }
  .napoli_product_detail .product .summary.entry-summary {
    width: 50%;
    max-width: 360px;
  }
}

@media only screen and (max-width: 768px) {
  .napoli_product_detail .product .napoli_images {
    float: none;
    width: 100%;
    max-width: 570px;
    margin-left: auto;
    margin-right: auto;
  }
  .napoli_product_detail .product .napoli_images a {
    text-align: center;
  }
  .napoli_images .swiper-slide a {
    min-height: 570px;
  }
  .napoli_product_detail .product .summary.entry-summary {
    width: 100%;
    max-width: none;
    margin-top: 30px;
  }
  .napoli_product_detail .product .summary .variations_form.cart {
    display: inline-block;
    width: auto;
    min-width: 500px;
  }
}

@media only screen and (max-width: 767px) {
  .napoli_product_detail .product {
    padding: 0 15px;
  }
  .napoli_images .swiper-slide a {
    min-height: 360px;
  }
  .napoli_product_detail .product .summary .woocommerce-product-rating {
    width: 100%;
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .napoli_product_detail .product_price {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    width: 100%;
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 600px) {
  .napoli_product_detail .product .summary .variations_form.cart {
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-width: auto;
  }
  .napoli_product_detail .single_variation_wrap {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 400px) {
  .napoli_product_detail .single_variation {
    -webkit-order: 1;
    -ms-flex-order: 1;
    order: 1;
    width: 100%;
    margin-bottom: 30px;
  }
  .napoli_product_detail .variations_button {
    -webkit-order: 2;
    -ms-flex-order: 2;
    order: 2;
    width: 100%;
  }
}

/*================================================*/
/* PRODUCT DETAIL PAGE TABS */
/*================================================*/
.napoli_product_detail .product .woocommerce-tabs {
  padding-top: 60px;
}

.napoli_product_detail .product .woocommerce-tabs .tabs.wc-tabs {
  padding: 0;
  margin-bottom: 34px;
}

.napoli_product_detail .product .woocommerce-tabs .tabs.wc-tabs:before {
  border-bottom: 4px solid #f2f2f2;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li {
  background-color: transparent;
  border: none;
  border-radius: 0;
  margin: 0;
  padding: 0 15px;
  border-bottom: 4px solid transparent;
  transition: border-bottom 0.3s ease;
  position: relative;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li a {
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  line-height: 24px;
  letter-spacing: 2.20px;
  font-weight: 400;
  color: #888888;
  text-transform: uppercase;
  padding: 12px 0;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li.active {
  border-bottom: 4px solid #c0af94;
  transition: border-bottom 0.3s ease;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li:before,
.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li:after {
  display: none;
}

.napoli_product_detail div.product .woocommerce-tabs ul.tabs.wc-tabs li.active a {
  color: #131313;
}

/* PRODUCT DETAILS PAGE - TABS PANEL*/
.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel {
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel h2 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-weight: 400;
  margin-bottom: 26px;
  color: #131313;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel p {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 0;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel table {
  border: none;
  border-collapse: collapse;
}

.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel table th,
.napoli_product_detail div.product .woocommerce-tabs .woocommerce-Tabs-panel table td {
  border: none;
}

/* PRODUCT DETAILS PAGE - TABS PANEL REVIEWS*/
.napoli_product_detail .product .woocommerce-Reviews {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

/* PRODUCT DETAILS PAGE - TABS PANEL COMMENTS*/
.napoli_product_detail .product .woocommerce-Reviews #comments {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 1;
  -ms-flex-order: 1;
  order: 1;
  width: 70%;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment {
  margin-bottom: 20px;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .avatar {
  width: 70px;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-right: 20px;
  margin-top: 6px;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text {
  margin: 0 0 0 90px;
  border: none;
  border-radius: 0;
  padding: 0;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .star-rating {
  margin-top: 13px;
  margin-right: 20px;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .meta {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1.92px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 7px;
  text-transform: uppercase;
  color: #131313;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .meta strong {
  font-weight: inherit;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .date_publish {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-weight: 400;
  color: #c0af94;
  margin-left: 10px;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .description {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
}

.napoli_product_detail .product #reviews #comments .commentlist .comment .comment-text .description p {
  margin: 0;
}

/* PRODUCT DETAILS PAGE - TABS PANEL FORM*/
.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-order: 2;
  -ms-flex-order: 2;
  order: 2;
  width: 30%;
  padding-left: 50px;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-reply-title {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1.92px;
  font-weight: 400;
  margin-top: 8px;
  color: #131313;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper #respond .comment-form-rating {
  margin: 10px 0 15px 0;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-rating label {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 1.50px;
  font-weight: 400;
  color: #c0af94;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-right: 15px;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper #respond .comment-form-rating .stars {
  display: inline-block;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 0;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-rating .stars a {
  height: 20px;
  vertical-align: middle;
}

body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-comment,
body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-author,
body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form-email {
  margin: 0 0 12px;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper input,
.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper textarea {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  width: 100%;
  border: none;
  background-color: transparent;
  padding: 10px 0;
  border-bottom: 1px solid #f2f2f2;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper textarea {
  resize: vertical;
}

body .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .form-submit {
  margin-top: 46px;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit {
  display: inline-block;
  width: auto;
  min-width: 144px;
  border-radius: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.1px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 8px 20px;
  background-color: transparent;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  color: #131313;
  transition: color 0.3s ease, background-color 0.3s ease, border 0.3s ease;
}

.napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper .comment-form .form-submit input#submit:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  background-color: #131313;
  color: #fff;
  opacity: 1;
}

@media only screen and (max-width: 1199px) {
  .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .napoli_product_detail .product .woocommerce-Reviews #comments {
    width: 65%;
  }
  .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
    width: 35%;
  }
}

@media only screen and (max-width: 767px) {
  .napoli_product_detail .product .woocommerce-Reviews #comments {
    width: 100%;
  }
  .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper {
    width: 100%;
    padding-left: 0;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    margin-top: 40px;
  }
  .napoli_product_detail .product .woocommerce-Reviews #review_form_wrapper #respond .comment-form-rating {
    text-align: left;
  }
}

/*================================================*/
/* PRODUCT DETAIL PAGE RELATED PRODUCTS */
/*================================================*/
.napoli_product_detail .product div.related.products {
  padding-top: 16px;
}

.napoli_product_detail div.product .up-sells .products,
.napoli_product_detail .product .related.products .products {
  margin-top: 31px;
  margin-bottom: 0;
  margin-left: -15px;
  margin-right: -15px;
}

/* PRODUCT DETAILS PAGE - UP SELLS*/
.napoli_product_detail div.product .up-sells h2,
.napoli_product_detail .product .related.products h2 {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-weight: 400;
  color: #131313;
}

.napoli_product_detail div.product .up-sells .products li.product,
.woocommerce .napoli_product_detail .product div.related.products li.product {
  width: 33.333333%;
}

.napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
.woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
  min-height: 370px;
}

@media only screen and (max-width: 1199px) {
  .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
  .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
    min-height: 300px;
  }
}

@media only screen and (max-width: 991px) {
  .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
  .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
    min-height: 230px;
  }
}

@media only screen and (max-width: 767px) {
  .napoli_product_detail div.product .up-sells .products,
  .woocommerce .napoli_product_detail .product div.related.products .products {
    padding-left: 0;
    padding-right: 0;
  }
  .napoli_product_detail div.product .up-sells .products li.product,
  .woocommerce .napoli_product_detail .product div.related.products li.product {
    width: 100%;
  }
  .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
  .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
    min-height: 370px;
  }
}

@media only screen and (max-width: 350px) {
  .napoli_product_detail div.product .up-sells .products li.product .napoli-prod-list-image,
  .woocommerce .napoli_product_detail .product div.related.products li.product .napoli-prod-list-image {
    min-height: 290px;
  }
}

/*================================================*/
/* PRODUCT LIST PAGE */
/*================================================*/
.woocommerce.woocommerce-page ul.products {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 0;
  margin-bottom: 20px;
}

.woocommerce.woocommerce-page ul.products::before {
  position: absolute;
}

.woocommerce.woocommerce-page ul.products::after {
  position: absolute;
}

.woocommerce ul.products li.product {
  float: none;
  margin: 0 0 35px 0;
  padding: 0 15px;
  color: #888888;
  text-align: center;
  list-style: none;
}

.woocommerce ul.products li.product .napoli-prod-list-image {
  position: relative;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  margin-bottom: 27px;
}

.woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
  margin-bottom: 22px;
}

.woocommerce ul.products li.product .napoli-prod-list-image:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.woocommerce ul.products li.product .napoli-prod-list-image:hover:after {
  opacity: 0.7;
}

.woocommerce ul.products li.product .napoli-prod-list-image:hover .product-links-wrapp {
  opacity: 1;
}

.woocommerce ul.products li.product .napoli-prod-list-image .product-links-wrapp {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: opacity 0.3s ease;
  z-index: 10;
  opacity: 0;
}

.woocommerce ul.products li.product .napoli-prod-list-image .napoli-link {
  display: inline-block;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  font-weight: bold;
  text-transform: uppercase;
  color: #c0af94;
  margin-top: 15px;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.woocommerce ul.products li.product .category-product a {
  display: inline-block;
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 4px;
}

.woocommerce ul.products.gutt-col-2 li.product h3 {
  font-size: 36px;
  line-height: 40px;
}

.woocommerce ul.products li.product h3 {
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 2.88px;
  font-weight: 400;
  padding: 0;
  color: #131313;
}

.woocommerce ul.products li.product .price {
  margin-top: 10px;
}

.woocommerce ul.products.gutt-col-2 li.product .price {
  margin-top: 8px;
}

.woocommerce ul.products.gutt-col-2 li.product {
  width: 50%;
}

.woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
  min-height: 570px;
}

.woocommerce ul.products.gutt-col-3 li.product {
  width: 33.333333%;
}

.woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
  min-height: 370px;
}

.woocommerce ul.products.gutt-col-4 li.product {
  width: 25%;
}

.woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
  min-height: 270px;
}

/* PRODUCT DETAILS PAGE - PAGINATION*/
.napoli-woocommerce-pagination .posts-navigation .screen-reader-text {
  display: none;
}

.napoli-woocommerce-pagination .nav-links {
  padding: 30px 15px 70px;
}

.napoli-woocommerce-pagination .nav-links:after,
.napoli-woocommerce-pagination .nav-links:before {
  content: '';
  display: table;
  clear: both;
}

.napoli-woocommerce-pagination .nav-links .nav-previous {
  width: 50%;
  float: left;
}

.napoli-woocommerce-pagination .nav-links .nav-next {
  width: 50%;
  float: right;
  text-align: right;
}

.napoli-woocommerce-pagination .nav-links .nav-previous a,
.napoli-woocommerce-pagination .nav-links .nav-next a {
  font-family: "Montserrat";
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-weight: 400;
  text-decoration: none;
  color: #585858;
}

.napoli-woocommerce-pagination .nav-links .nav-previous a:hover,
.napoli-woocommerce-pagination .nav-links .nav-next a:hover {
  color: #c0af94;
}

@media only screen and (max-width: 1199px) {
  .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
    min-height: 470px;
  }
  .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
    min-height: 300px;
  }
  .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
    min-height: 220px;
  }
}

@media only screen and (max-width: 991px) {
  .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
    min-height: 360px;
  }
  .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
    min-height: 230px;
  }
  .woocommerce ul.products.gutt-col-4 li.product {
    width: 33.333333%;
  }
  .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
    min-height: 230px;
  }
  .napoli-woocommerce-pagination .nav-links {
    padding: 10px 30px 30px;
  }
}

@media only screen and (max-width: 767px) {
  .woocommerce.woocommerce-page ul.products {
    padding-left: 15px;
    padding-right: 15px;
  }
  .woocommerce ul.products.gutt-col-3 li.product {
    width: 50%;
  }
  .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
    min-height: 360px;
  }
  .woocommerce ul.products.gutt-col-4 li.product {
    width: 50%;
  }
  .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
    min-height: 360px;
  }
  .napoli-woocommerce-pagination .nav-links .nav-previous a,
  .napoli-woocommerce-pagination .nav-links .nav-next a {
    letter-spacing: 1.8px;
  }
}

@media only screen and (max-width: 650px) {
  .woocommerce ul.products.gutt-col-2 li.product {
    width: 100%;
  }
  .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
    min-height: 370px;
  }
  .woocommerce ul.products.gutt-col-3 li.product {
    width: 100%;
  }
  .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
    min-height: 370px;
  }
  .woocommerce ul.products.gutt-col-4 li.product {
    width: 100%;
  }
  .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
    min-height: 370px;
  }
}

@media only screen and (max-width: 350px) {
  .woocommerce ul.products.gutt-col-2 li.product .napoli-prod-list-image {
    min-height: 290px;
  }
  .woocommerce ul.products.gutt-col-3 li.product .napoli-prod-list-image {
    min-height: 290px;
  }
  .woocommerce ul.products.gutt-col-4 li.product .napoli-prod-list-image {
    min-height: 290px;
  }
}

/*================================================*/
/* SHOP ICON */
/*================================================*/
.mini-cart-wrapper {
  display: inline-block;
  position: relative;
  margin-left: 45px;
  vertical-align: middle;
}

.mini-cart-wrapper .napoli-shop-icon {
  text-decoration: none;
  background-color: #f0f0f0;
  padding: 0 15px;
  font-size: 13px;
  line-height: 33px;
}

.header_trans-fixed .mini-cart-wrapper .napoli-shop-icon {
  background-color: #fff;
  opacity: 1;
}

.mini-cart-wrapper .napoli-shop-icon:before {
  display: inline-block;
  line-height: 33px;
  color: #131313;
}

.mini-cart-wrapper .napoli-shop-icon .cart-contents {
  display: inline-block;
}

.mini-cart-wrapper .napoli-shop-icon .cart-contents-count {
  display: inline-block;
  line-height: 33px;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1.12px;
  color: #131313;
  padding-left: 3px;
}

.napoli_mini_cart {
  position: absolute;
  right: 0;
  top: 60px;
  display: block;
  background-color: #131313;
  opacity: 0;
  visibility: hidden;
  min-width: 330px;
  padding: 23px 30px;
  text-align: center;
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

.mini-cart-wrapper:hover .napoli_mini_cart {
  opacity: 1;
  visibility: visible;
}

#topmenu .napoli_mini_cart .product_list_widget {
  display: block;
}

#topmenu .napoli_mini_cart .product_list_widget .empty {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1.4px;
  font-weight: 400;
  color: #fff;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 23px;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini_cart_item_thumbnail {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 40%;
  max-width: 70px;
  margin-top: 7px;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini_cart_item_thumbnail a {
  padding: 0;
  display: block;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini_cart_item_thumbnail img {
  float: none;
  max-width: 70px;
  width: 100%;
  margin-left: 0;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 60%;
  padding-left: 20px;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_name {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 1.4px;
  font-weight: 400;
  color: #fff;
  text-align: left;
  padding: 0;
}

#topmenu .napoli_mini_cart .product_list_widget .mini_cart_item .mini-cart-data .mini_cart_item_quantity {
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.88px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 3px;
}

#topmenu .napoli_mini_cart a.button {
  display: inline-block;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 1.1px;
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  opacity: 1;
  margin-top: 7px;
  margin-bottom: 7px;
  text-decoration: none;
  border-radius: 0;
  text-align: center;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  border-bottom: 2px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  background-color: transparent;
}

#topmenu .napoli_mini_cart a.button:hover {
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  color: #131313;
  background-color: #fff;
}

@media only screen and (max-width: 1199px) {
  .mini-cart-wrapper {
    margin-left: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .mini-cart-wrapper {
    margin-left: 10px;
  }
}

@media only screen and (max-width: 767px) {
  .mini-cart-wrapper {
    display: block;
    margin: 20px 10px 30px 10px;
  }
  .napoli_mini_cart {
    opacity: 1;
    visibility: visible;
    position: relative;
    right: auto;
    left: 0;
    top: 10px;
    width: 100%;
    min-width: auto;
  }
}

/*================================================*/
/* PRODUCT PAGE - CARD */
/*================================================*/
.napoli_cart.shop_table {
  margin-top: 26px;
}

.napoli_cart.shop_table ul {
  list-style-type: none;
  margin: 0;
}

/* PRODUCT PAGE - CARD HEADING */
.napoli_cart.shop_table .heading {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-bottom: 4px solid #f2f2f2;
  padding: 14px 0;
  margin-bottom: 40px;
}

.napoli_cart.shop_table .heading li {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20%;
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-weight: 400;
  text-transform: uppercase;
  color: #131313;
}

/* PRODUCT PAGE - CARD ITEMS*/
.napoli_cart.shop_table ul .cart_item {
  margin-bottom: 40px;
}

.napoli_cart.shop_table ul .cart_item ul {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.napoli_cart.shop_table ul .cart_item ul li {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.napoli_cart.shop_table .heading .heading-product {
  width: 51%;
  padding-left: 8.5%;
}

.napoli_cart.shop_table ul .cart_item ul .product-remove {
  width: 8.5%;
}

.napoli_cart.shop_table ul .cart_item ul .product-thumbnail {
  width: 17%;
}

.napoli_cart.shop_table ul .cart_item ul .product-name {
  width: 25.5%;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.napoli_cart.shop_table .heading .heading-price,
.napoli_cart.shop_table ul .cart_item ul .product-price {
  width: 17.5%;
}

.napoli_cart.shop_table .heading .heading-quantity,
.napoli_cart.shop_table ul .cart_item ul .product-quantity {
  width: 17%;
}

.napoli_cart.shop_table .heading .heading-total,
.napoli_cart.shop_table ul .cart_item ul .product-subtotal {
  width: 14.5%;
}

body .napoli_cart.shop_table ul .cart_item ul .product-remove .remove {
  color: #d6d6d6;
  font-size: 25px;
  width: auto;
  height: auto;
  font-weight: 700;
}

.napoli_cart.shop_table ul .cart_item ul .product-remove .remove:hover {
  background-color: transparent;
}

.napoli_cart.shop_table ul .cart_item ul .product-thumbnail img {
  max-width: 170px;
  width: 100%;
}

.napoli_cart.shop_table ul .cart_item ul .product-name a {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 2px;
  font-weight: 400;
  text-decoration: none;
  color: #131313;
}

.napoli_cart.shop_table ul .cart_item ul .product-name .variation dt {
  display: inline-block;
  font-family: "Libre Baskerville";
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #131313;
}

.napoli_cart.shop_table ul .cart_item ul .product-name .variation dd {
  display: inline-block;
  margin-bottom: 0;
}

.napoli_cart.shop_table ul .cart_item ul .product-name .variation dd p {
  margin-bottom: 0;
  font-family: "Libre Baskerville";
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.9px;
  font-weight: 400;
  color: #969696;
}

@media only screen and (max-width: 1199px) {
  .napoli_cart.shop_table ul .cart_item ul .product-name {
    padding-left: 15px;
  }
}

@media only screen and (max-width: 991px) {
  .napoli_cart.shop_table ul .cart_item ul .product-remove {
    width: 3.5%;
  }
  .napoli_cart.shop_table ul .cart_item ul .product-name {
    width: 30.5%;
  }
  .napoli_cart.shop_table ul .cart_item ul .product-name a {
    font-size: 18px;
    line-height: 20px;
  }
  .napoli_cart.shop_table .heading .heading-product {
    padding-left: 3.5%;
  }
}

@media only screen and (max-width: 767px) {
  .woocommerce-cart .woocommerce .napoli_cart.shop_table {
    padding-left: 15px;
    padding-right: 15px;
  }
}

@media only screen and (max-width: 650px) {
  .napoli_cart.shop_table .heading li {
    font-size: 9px;
  }
  .napoli_cart.shop_table .heading .heading-product {
    width: 31%;
    padding-left: 5%;
  }
  .napoli_cart.shop_table ul .cart_item ul .product-remove {
    width: 5%;
  }
  .napoli_cart.shop_table ul .cart_item ul .product-remove .remove {
    font-size: 18px;
  }
  .napoli_cart.shop_table ul .cart_item ul .product-thumbnail {
    width: 26%;
  }
  .napoli_cart.shop_table ul .cart_item ul .product-name {
    display: none;
  }
  .napoli_cart.shop_table .heading .heading-price,
  .napoli_cart.shop_table ul .cart_item ul .product-price {
    width: 23%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .napoli_cart.shop_table .heading .heading-quantity,
  .napoli_cart.shop_table ul .cart_item ul .product-quantity {
    width: 23%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .napoli_cart.shop_table ul .cart_item ul .product-quantity .qty {
    width: 100%;
    max-width: 80px;
  }
  .napoli_cart.shop_table .heading .heading-total,
  .napoli_cart.shop_table ul .cart_item ul .product-subtotal {
    width: 23%;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

/* PRODUCT PAGE - CARD COUMPLEMENT*/
.napoli_cart.shop_table .complement-cart {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  border-bottom: 4px solid #f2f2f2;
  border-top: 4px solid #f2f2f2;
  padding: 40px 0;
}

.napoli_cart.shop_table .complement-cart .coupon {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
}

.napoli_cart.shop_table .complement-cart .coupon .input-text {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  border: none;
  padding: 0;
  background-color: transparent;
  border-bottom: 1px solid #f2f2f2;
  margin-right: 100px;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.napoli_cart.shop_table .complement-cart .coupon .input-text:focus {
  color: #131313;
  border-bottom: 1px solid #c0af94;
}

.napoli_cart.shop_table .complement-cart .action {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50%;
  padding: 0;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  background-color: transparent;
}

@media only screen and (max-width: 991px) {
  .napoli_cart.shop_table .complement-cart .coupon .input-text {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 767px) {
  .napoli_cart.shop_table .complement-cart .coupon {
    width: 70%;
  }
  .napoli_cart.shop_table .complement-cart .action {
    width: 30%;
  }
}

@media only screen and (max-width: 650px) {
  .napoli_cart.shop_table .complement-cart {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .napoli_cart.shop_table .complement-cart .coupon {
    width: 100%;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .napoli_cart.shop_table .complement-cart .action {
    width: 100%;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

@media only screen and (max-width: 400px) {
  .napoli_cart.shop_table .complement-cart .coupon .input-text {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .napoli_cart.shop_table .complement-cart .coupon {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .napoli_cart.shop_table .complement-cart .action {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

/* PRODUCT PAGE - CARD TOTALS*/
.napoli-cart-collaterals .cart_totals {
  text-align: center;
  margin-top: 33px;
  margin-bottom: 65px;
}

.napoli-cart-collaterals .cart_totals h2 {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-weight: 400;
  margin-bottom: 26px;
  color: #131313;
}

.napoli-cart-collaterals .cart_totals .shop_table ul {
  list-style-type: none;
  margin: 0;
  margin-bottom: 25px;
}

.napoli-cart-collaterals .cart_totals .shop_table ul li {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.88px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 0;
  text-transform: uppercase;
}

.napoli-cart-collaterals .cart_totals .shop_table ul li span {
  color: #c0af94;
}

.napoli-cart-collaterals .cart_totals a.button {
  margin-bottom: 0;
}

@media only screen and (max-width: 991px) {
  .napoli-cart-collaterals .cart_totals {
    margin-bottom: 33px;
  }
}

@media only screen and (max-width: 767px) {
  .woocommerce-cart .woocommerce .napoli-cart-collaterals .cart_totals {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*================================================*/
/* PRODUCT PAGE - ICON ADD TO CART ON HOVER */
/*================================================*/
.woocommerce #respond input#submit.loading:after,
.woocommerce a.button.loading:after,
.woocommerce button.button.loading:after,
.woocommerce input.button.loading:after {
  top: auto;
  width: 20px;
  height: 20px;
  right: 2px;
}

/*================================================*/
/* PRODUCT PAGE - CHECKOUT */
/*================================================*/
.woocommerce form .form-row {
  margin: 0 0 40px 0;
}

.woocommerce form.checkout_coupon,
.woocommerce form.login {
  margin: 0 0 40px 0;
  padding: 20px 0 40px 0;
  border: none;
  border-bottom: 4px solid #f2f2f2;
}

.woocommerce form.checkout_coupon .form-row {
  padding: 0;
  margin: 0;
}

.woocommerce form.checkout_coupon .form-row input.input-text {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 40px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #131313;
  border: none;
  padding: 0;
  background-color: transparent;
  max-width: 290px;
  border-bottom: 1px solid #f2f2f2;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.woocommerce form.checkout h3 {
  font-size: 36px;
  line-height: 40px;
  font-weight: 400;
  letter-spacing: 2.88px;
  margin-bottom: 20px;
  color: #131313;
}

.woocommerce form.checkout #order_review_heading {
  margin-top: 40px;
}

.woocommerce form.checkout .form-row,
.woocommerce form.login .form-row {
  padding: 0;
  margin: 0 0 40px;
}

.woocommerce form.login .form-row label,
.woocommerce form.checkout .form-row label {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.88px;
  font-weight: 400;
  color: #131313;
  margin-bottom: 3px;
  text-transform: uppercase;
}

.woocommerce form.login .form-row .required,
.woocommerce form.checkout .form-row .required {
  color: inherit;
}

.woocommerce form.login .form-row input,
.woocommerce form.login .form-row textarea,
.woocommerce form.checkout .form-row input,
.woocommerce form.checkout .form-row textarea {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #131313;
  border: none;
  border-radius: 0;
  padding: 10px 0;
  background-color: transparent;
  border-bottom: 1px solid #f2f2f2;
  width: 100%;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.woocommerce form.login .form-row textarea,
.woocommerce form.checkout .form-row textarea {
  min-height: 100px;
}

.woocommerce form.login .form-row input:focus,
.woocommerce form.login .form-row textarea:focus,
.woocommerce form.checkout .form-row input:focus,
.woocommerce form.checkout .form-row textarea:focus,
.woocommerce form.checkout_coupon .form-row input.input-text:focus {
  color: #131313;
  border-bottom: 1px solid #c0af94;
}

.woocommerce form.login .form-row input:-webkit-autofill,
.woocommerce form.checkout .form-row input:-webkit-autofill,
.woocommerce form.login .form-row input:-webkit-autofill:hover,
.woocommerce form.checkout .form-row input:-webkit-autofill:hover,
.woocommerce form.login .form-row input:-webkit-autofill:focus,
.woocommerce form.checkout .form-row input:-webkit-autofill:focus {
  background-color: #f9f5ee;
  transition: background-color 3000s ease 0s;
  box-shadow: 0 0 0px 1000px #f9f5ee inset;
}

.woocommerce form.login .form-row input[type="submit"] {
  margin-right: 40px;
}

.woocommerce form.login .form-row input[type="checkbox"],
.woocommerce form.checkout .form-row input[type="checkbox"] {
  display: none;
}

.woocommerce form.login .form-row label.checkbox,
.woocommerce form.checkout .form-row label.checkbox {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 29px;
  margin-bottom: 0;
  margin-top: 0;
}

.woocommerce form.login .form-row label.checkbox:before,
.woocommerce form.checkout .form-row label.checkbox:before {
  content: "";
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 0;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background-color: #e6e6e6;
}

.woocommerce form.login .form-row input[type="checkbox"]:checked + label.checkbox:before,
.woocommerce form.checkout .form-row input[type="checkbox"]:checked + label.checkbox:before {
  background-color: #c0af94;
}

.woocommerce form.login .lost_password {
  margin-bottom: 0;
}

.woocommerce form.login .lost_password a {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.88px;
  font-weight: 400;
  color: #131313;
  text-transform: uppercase;
  text-decoration: none;
}

/* SELECT */
.select2-container .select2-choice {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  border-color: transparent;
  padding: 10px 5px;
  background-color: transparent;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
  box-shadow: none;
}

.select2-container.select2-dropdown-open.select2-drop-above .select2-choice {
  border: none;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 0;
}

.select2-drop.select2-drop-above.select2-drop-active {
  border: none;
}

.select2-drop-active {
  border: none;
  border-radius: 0;
  color: #131313;
  border-bottom: 1px solid #c0af94;
  overflow-x: hidden;
}

.select2-search {
  padding: 0;
  position: relative;
}

.select2-search:after {
  content: "\f002";
  font-family: "FontAwesome";
  font-size: 12px;
  line-height: 28px;
  color: #888888;
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

body .select2-search input {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  border: none;
  padding: 10px 0;
  border: 1px solid #f2f2f2;
  border-bottom: 0;
  background-color: transparent;
  background-image: none;
  border-radius: 0;
}

body .select2-results {
  border: none;
  padding: 0;
  margin-left: 0;
  margin-right: -20px;
}

body .select2-results li {
  font-family: "Libre Baskerville";
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  border: none;
  padding: 0;
  margin-left: 0;
}

.select2-results li.select2-highlighted {
  background-color: transparent;
  opacity: 0.9;
  border-radius: 0;
  color: #131313;
}

/*SHOP TABLE*/
.woocommerce table.shop_table {
  border: none;
  border-collapse: collapse;
  margin: 0 0 33px 0;
}

.woocommerce table.shop_table .woocommerce-Price-amount {
  color: #c0af94;
}

.woocommerce table.shop_table th,
.woocommerce table.shop_table td,
.woocommerce table.shop_table tr,
.woocommerce table.shop_table tfoot th,
.woocommerce table.shop_table tfoot td {
  border: none;
  padding: 0;
}

.woocommerce table.shop_table thead {
  border-bottom: 4px solid #f2f2f2;
}

body .woocommerce table.shop_table thead .product-name,
body .woocommerce table.shop_table thead .product-total {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2.40px;
  font-weight: 400;
  text-transform: uppercase;
  color: #131313;
  padding: 14px 0;
}

.woocommerce table.shop_table .cart_item .product-name {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 1.4px;
  font-weight: 400;
  color: #131313;
  text-align: left;
  padding: 0;
  padding: 40px 10px 40px 0;
}

.woocommerce table.shop_table .cart_item .product-name strong {
  font-weight: inherit;
}

.woocommerce table.shop_table .cart_item .product-name .variation dt {
  display: inline-block;
  font-family: "Libre Baskerville";
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #131313;
  text-transform: none;
}

.woocommerce table.shop_table .cart_item .product-name .variation dd {
  margin-bottom: 0;
}

.woocommerce table.shop_table .cart_item .product-name .variation dd p {
  margin-bottom: 0;
  font-family: "Libre Baskerville";
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.9px;
  font-weight: 400;
  color: #888888;
  text-transform: none;
}

.woocommerce table.shop_table tfoot {
  border-top: 4px solid #f2f2f2;
  border-bottom: 4px solid #f2f2f2;
}

.woocommerce table.shop_table .cart-subtotal th,
.woocommerce table.shop_table .order-total th {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.88px;
  color: #888888;
  margin-bottom: 0;
  text-transform: uppercase;
}

.woocommerce table.shop_table .cart-subtotal th {
  padding: 20px 0 10px 0;
}

.woocommerce table.shop_table .order-total th {
  padding: 10px 0 20px 0;
}

.woocommerce-checkout #payment {
  border-radius: 0;
  background-color: transparent;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods {
  padding: 20px 0;
  border-bottom: 0;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-weight: 400;
  color: #888888;
  margin-bottom: 0;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li label {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.88px;
  color: #888888;
  margin-bottom: 0;
  display: inline-block;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li img {
  margin-right: 20px;
}

.woocommerce-checkout-review-order #payment .payment_methods.methods li .about_paypal {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 30px;
  letter-spacing: 2.88px;
  color: #888888;
  margin-bottom: 0;
  display: inline-block;
  text-decoration: none;
  vertical-align: middle;
}

.woocommerce-checkout-review-order #payment div.payment_box {
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  font-family: "Libre Baskerville";
  color: #131313;
}

.woocommerce-checkout-review-order #payment div.payment_box p {
  color: inherit;
}

.woocommerce-checkout-review-order .woocommerce-checkout-payment#payment .place-order {
  padding: 20px 0;
}

@media only screen and (max-width: 767px) {
  .woocommerce form.checkout_coupon,
  .woocommerce form.login,
  .woocommerce form.checkout {
    margin-left: 15px;
    margin-right: 15px;
  }
  .woocommerce #payment #place_order,
  .woocommerce-page #payment #place_order {
    width: auto;
  }
}

@media only screen and (max-width: 650px) {
  .woocommerce form.checkout_coupon .form-row {
    float: none;
    width: 100%;
    text-align: center;
  }
  .woocommerce form.checkout_coupon .form-row input.input-text {
    margin-bottom: 20px;
  }
  .woocommerce form .form-row-last,
  .woocommerce-page form .form-row-first {
    width: 100%;
  }
  .woocommerce form .form-row-last,
  .woocommerce-page form .form-row-last {
    width: 100%;
  }
}

/*================================================*/
/* FULL SCREEN SLIDER */
/*================================================*/
.full_screen_slider {
  position: relative;
}

.full_screen_slider .swiper-wrapper {
  cursor: none;
}

.full_screen_slider.kenburn_slider .swiper-slide {
  overflow: hidden;
}

.full_screen_slider.kenburn_slider .swiper-slide .full-screen-slider__img {
  transform: scale(1);
  transition: transform 10s ease-in;
}

.full_screen_slider.kenburn_slider .swiper-slide-active .full-screen-slider__img {
  transform: scale(1.5);
  transition: transform 10s ease-in;
}

.slider-wrapperok-left,
.slider-wrapperok-right {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  z-index: 15;
  overflow: hidden;
  cursor: none;
}

.slider-wrapperok-left {
  left: 0;
}

.slider-wrapperok-left:hover .slider-click.left {
  opacity: 1;
  visibility: visible;
}

.slider-wrapperok-right {
  right: 0;
}

.slider-wrapperok-right:hover .slider-click.right {
  opacity: 1;
  visibility: visible;
}

.full_screen_slider .slider-click {
  font-family: "Libre Baskerville";
  font-size: 14px;
  line-height: 40px;
  font-weight: 400;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  color: #fff;
  width: 32px;
  height: 32px;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.full_screen_slider .slider-click.left {
  left: 50px;
}

.full_screen_slider .slider-click.right {
  right: 50px;
}

.full_screen_slider .slider-click.right .arrow {
  left: auto;
  right: 60px;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.full_screen_slider .slider-click.right .number {
  right: 0;
  left: auto;
}

.full_screen_slider .slider-click.right.disabled,
.full_screen_slider .slider-click.left.disabled {
  opacity: 0;
}

.full_screen_slider .slider-click .arrow {
  width: 22px;
  height: 22px;
  position: absolute;
  left: 60px;
  top: 50%;
  margin-top: -11px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.full_screen_slider .slider-click .number {
  position: absolute;
  left: 0;
  width: 53px;
}

.full_screen_slider .slider-click .left {
  text-align: right;
  float: left;
  width: 23px;
  height: 20px;
  position: relative;
  top: -8px;
}

.full_screen_slider .slider-click .middle {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 1px;
  height: 20px;
  margin-top: -10px;
  background: #fff;
  -webkit-transform: rotate(22deg);
  -ms-transform: rotate(22deg);
  transform: rotate(22deg);
}

.full_screen_slider .slider-click .right {
  float: right;
  width: 23px;
  height: 30px;
  position: relative;
  top: 8px;
}

.full-screen-slider__img {
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  position: relative;
}

.full-screen-slider__img:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.25);
}

.full_screen_slider .pagination {
  display: none;
}

.napoli_copyright_overlay {
  position: absolute;
  z-index: 9999;
  top: 50%;
  left: 50%;
  display: none;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.95);
}

.napoli_copyright_overlay.active {
  display: block;
}

.napoli_copyright_overlay_text {
  font-size: 12px;
  padding: 12px;
  text-align: center;
  color: white;
}

@media only screen and (max-width: 768px) {
  .slider-wrapperok-left,
  .slider-wrapperok-right {
    display: none;
  }
  .slider-click {
    display: none;
  }
}

.napoli-sound-btn {
  position: absolute;
  right: 30px;
  bottom: 120px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  z-index: 310;
  background-color: #cfac7f;
  transition: all 0.3s ease;
  padding: 0;
  border: none;
}

.napoli-sound-btn:before {
  content: '\f026';
  position: absolute;
  color: #ffffff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: 'FontAwesome';
  font-size: 16px;
  line-height: 40px;
  display: block;
  transition: all 0.3s ease;
}

.napoli-sound-btn.play:before {
  content: '\f028';
}

.napoli-audio-file {
  display: none;
}

/*================================================*/
/* FRELANCER PORTFOLIO */
/*================================================*/
@media only screen and (min-width: 768px) {
  body .port-det-slider .flexslider .slides {
    width: 100%;
  }
}

.port-det-slider {
  width: 100%;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 735px;
  margin-bottom: 33px;
  overflow: hidden;
}

.port-det-slider .flexslider .flex-direction-nav {
  display: none;
}

.port-det-slider .flexslider .slides {
  height: 100%;
  margin-bottom: 0;
  display: block;
}

.port-det-slider .flexslider .slides li {
  width: 100%;
}

.port-det-slider .flexslider .slides li .port-slide-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 100%;
}

.port-det-slider #slider {
  width: 85%;
  margin-right: 30px;
  display: block;
}

.port-det-slider #slider .slides li {
  height: 100%;
}

.port-det-slider #carousel {
  width: 15%;
  display: block;
}

.port-det-slider #carousel .flex-viewport {
  height: 100%;
}

body .port-det-slider #carousel .slides li {
  height: 17%;
  width: 100%;
  margin: 0 0 30px 0;
  cursor: pointer;
}

@media only screen and (max-width: 1199px) {
  .port-det-slider #slider {
    width: 80%;
  }
  .port-det-slider #carousel {
    width: 20%;
  }
  .port-det-slider {
    height: 600px;
  }
  .port-det-slider #slider .slides li .port-slide-bg {
    height: 600px;
  }
  .port-det-slider #carousel .slides li .port-slide-bg {
    height: 102px;
  }
}

@media only screen and (max-width: 767px) {
  .port-det-slider {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 500px;
    margin-right: 30px;
    margin-left: 15px;
  }
  .port-det-slider #slider {
    width: 100%;
    margin-right: 0;
    height: 80%;
    margin-bottom: 20px;
  }
  .port-det-slider #slider .slides li .port-slide-bg {
    height: 400px;
  }
  .port-det-slider #carousel {
    width: 100%;
    height: 20%;
  }
  body .port-det-slider #carousel .slides li {
    width: 120px;
    height: 100%;
    margin: 0 30px 0 0;
  }
  .port-det-slider #carousel .slides li .port-slide-bg {
    height: 70px;
  }
}

.portfolio-single-content .portfolio-categories a {
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  text-decoration: none;
}

.portfolio-single-content .portfolio-title {
  font-size: 36px;
  line-height: 48px;
  font-weight: 400;
  color: #131313;
  margin-top: 22px;
  margin-bottom: 0;
}

.portfolio-single-content p {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.1px;
  font-weight: 400;
  font-family: "Libre Baskerville";
  color: #888888;
  margin-top: 17px;
  margin-bottom: 30px;
}

.portfolio-single-content .napoli-portfolio-footer-line {
  border-top: 4px solid #f2f2f2;
  border-bottom: 4px solid #f2f2f2;
  padding: 33px 0;
}

.portfolio-single-content .napoli-portfolio-footer-line .portfolio-client .client-title,
.portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type .title-job-type,
.portfolio-single-content .napoli-portfolio-footer-line .title-date {
  font-family: "Montserrat", sans-serif;
  color: #131313;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 0.5px;
  text-decoration: none;
}

.portfolio-single-content .napoli-portfolio-footer-line .portfolio-client .client-value,
.portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type .job-type-value,
.portfolio-single-content .napoli-portfolio-footer-line .date-value,
.portfolio-single-content .napoli-portfolio-footer-line .social-list a {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.1px;
  font-weight: 400;
  font-family: "Libre Baskerville";
  color: #888888;
}

body .portfolio-single-content .napoli-portfolio-footer-line .single-share .ft-part {
  margin-bottom: 0;
}

.portfolio-single-content .napoli-portfolio-footer-line .social-list a {
  font-size: 12px;
}

.portfolio-single-content .napoli-portfolio-footer-line .social-list {
  margin: 0;
  padding: 0 0 0 5px;
  text-align: left;
}

.portfolio-single-content .single-pagination {
  padding: 50px 0;
  position: relative;
}

.portfolio-single-content .single-pagination:after,
.portfolio-single-content .single-pagination:before {
  content: '';
  display: table;
  clear: both;
}

@media only screen and (max-width: 991px) {
  .portfolio-single-content .napoli-portfolio-footer-line .portfolio-client,
  .portfolio-single-content .napoli-portfolio-footer-line .portfolio-jop-type,
  .portfolio-single-content .napoli-portfolio-footer-line .date-value {
    margin-bottom: 20px;
  }
  .portfolio-single-content .napoli-portfolio-footer-line .single-share {
    margin-left: 0;
    margin-right: 0;
  }
  .portfolio-single-content .napoli-portfolio-footer-line .social-list li:first-child {
    margin-left: 0;
  }
  .portfolio-single-content .single-pagination {
    padding: 30px 0;
  }
}

@media only screen and (max-width: 767px) {
  .portfolio-single-content {
    margin-right: 15px;
    margin-left: 15px;
  }
  body .portfolio-single-content.no-marg {
    margin: 0px;
  }
}

#footer.static {
  position: static;
}

/*================================================*/
/* VIDEO GALLERY */
/*================================================*/
.iframe-video {
  width: 100%;
  height: 280px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 24px;
  z-index: 200;
}

.iframe-video.play .video-container {
  visibility: visible;
  opacity: 1;
}

.iframe-video.play .video-close-button {
  visibility: visible;
  opacity: 1;
}

.iframe-video .video-container {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.iframe-video .video-container:hover {
  opacity: 1;
}

.iframe-video .video-container .fluid-width-video-wrapper {
  padding-top: 280px;
  height: 100%;
}

.iframe-video .video-container iframe {
  display: block;
  width: 100%;
  height: 100%;
}

.iframe-video .video-content .play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 85px;
  height: 55px;
  text-align: center;
  background-color: #000000;
  opacity: 0.3;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.iframe-video .video-content .play-button:before {
  content: '\f04b';
  display: inline-block;
  font-family: 'FontAwesome';
  font-size: 30px;
  line-height: 55px;
  color: #ffffff;
  opacity: 1;
  transition: none;
}

.iframe-video .video-content .play-button:hover {
  background-color: #c0af94;
  opacity: 1;
}

.iframe-video .video-close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  z-index: 120;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  color: #fff;
}

.iframe-video-title {
  font-family: "BebasNeueRegular", sans-serif;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #131313;
  margin-bottom: 33px;
  text-align: center;
}

/* PRICING
---------------------------*/
.pricing-item {
  padding: 60px 70px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: #ffffff;
}

/*Mask block*/
.pricing-item .mask-image {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 200px;
  height: 320px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 30px;
}

/*SVG mask*/
.pricing-item .mask-image .pricing_mask {
  fill: #ffffff;
}

.pricing-item .mask-image .pricing-transparent-mask {
  fill: transparent;
}

/*Pricing info*/
.pricing-info {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.pricing-info .subtitle {
  display: block;
  margin: 0;
  margin-bottom: 24px;
  font-size: 12px;
  line-height: 25px;
  font-family: "Montserrat";
  letter-spacing: 2.4px;
  font-weight: 400;
  color: #c0af94;
}

.pricing-info .title {
  display: block;
  margin: 0;
  margin-bottom: 22px;
  font-size: 36px;
  line-height: 40px;
  font-family: "BebasNeueRegular";
  letter-spacing: 2.88px;
  font-weight: 400;
  color: #131313;
}

.pricing-info .pricing-list p:empty {
  display: none;
}

.pricing-info .pricing-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-bottom: 30px;
}

.pricing-info .pricing-list ul li {
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 1.12px;
  font-family: "Libre Baskerville";
  color: #888888;
}

.pricing-info .a-btn-2 {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

@media (max-width: 1199px) {
  .pricing-item {
    padding: 60px 30px;
  }
}

@media (max-width: 991px) {
  .pricing-item {
    padding: 30px 15px;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    text-align: center;
  }
  .pricing-item .mask-image {
    margin: auto;
    margin-bottom: 40px;
    height: 220px;
  }
  .pricing-item .pricing-info {
    width: 100%;
  }
  .pricing-info .a-btn-2 {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }
}

@media (max-width: 767px) {
  .pricing-item {
    max-width: 480px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* COMMING SOON
---------------------------*/
.coming-page-wrapper {
  position: relative;
  text-align: center;
  padding-top: 31px;
  padding-bottom: 31px;
}

.coming-page-wrapper .title {
  font-size: 70px;
  line-height: 80px;
  font-family: "BebasNeueBook";
  color: #131313;
  margin: 0;
  margin-bottom: 7px;
  letter-spacing: 5.6px;
  font-weight: 400;
}

.coming-page-wrapper .subtitle {
  font-size: 14px;
  font-family: "Libre Baskerville";
  line-height: 28px;
  margin: 0;
  margin-bottom: 70px;
  color: #888888;
  letter-spacing: 1.12px;
  font-weight: 400;
}

/*Coming soon counter*/
.coming-soon-wrap {
  position: relative;
  height: 310px;
  margin-bottom: 76px;
}

.coming-soon-bg {
  position: absolute;
  z-index: 0;
  left: 1px;
  top: 1px;
  right: 1px;
  bottom: 1px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/*Svg styles*/
.svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.svg text::-moz-selection {
  background: none;
}

.svg text::selection {
  background: none;
}

.coming-soon {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.coming-soon .svg {
  width: 100%;
  height: 192px;
  left: 0;
  top: 0;
  position: absolute;
  text-anchor: middle;
}

.coming-soon .svg .count {
  font-weight: 900;
  font-family: "Montserrat";
  font-size: 150px;
}

.coming-soon .svg .svgRect {
  fill: #f2f2f2;
}

.coming-soon .svg .maskRect {
  fill: white;
}

.coming-soon-wrap .coming-soon-descr {
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  background: #f2f2f2;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  margin: 0;
  margin-left: 0;
  padding: 0;
  height: 120px;
}

.coming-soon-descr li {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 25%;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-weight: 400;
  font-family: "Montserrat";
  text-transform: uppercase;
  font-size: 12px;
  line-height: 20px;
  color: #c0af94;
  padding-top: 25px;
  letter-spacing: 2.4px;
}

@media (max-width: 1199px) {
  .coming-page-wrapper .subtitle {
    margin-bottom: 50px;
  }
  .coming-soon-wrap {
    margin-bottom: 50px;
  }
}

@media (max-width: 991px) {
  .coming-page-wrapper .subtitle {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  .coming-soon-wrap {
    height: 250px;
  }
  .coming-soon .svg {
    height: 152px;
  }
  .coming-soon .svg .count {
    font-size: 110px;
  }
  .coming-soon-wrap .coming-soon-descr {
    height: 100px;
  }
}

@media (max-width: 767px) {
  .coming-page-wrapper {
    top: 0;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    min-height: 100vh;
    padding-top: 100px;
    padding-bottom: 100px;
    box-sizing: border-box;
  }
  .coming-page-wrapper .title {
    font-size: 40px;
    line-height: 50px;
    padding: 0px 15px;
  }
  .coming-page-wrapper .subtitle {
    max-width: 400px;
    margin-bottom: 40px;
    padding: 0px 15px;
    margin-left: auto;
    margin-right: auto;
  }
  .coming-soon-wrap {
    height: 200px;
  }
  .coming-soon .svg {
    height: 100px;
  }
  .coming-soon .svg .count {
    font-size: 50px;
  }
  .coming-soon-wrap .coming-soon-descr {
    height: 100px;
  }
  .coming-soon-descr li {
    letter-spacing: 0.5px;
  }
}

/* FILMSTRIM GALLERY
---------------------------*/
.swipe-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.swipe-btn:hover:before {
  color: #c0af94;
}

.swipe-btn.next {
  right: 10px;
}

.swipe-btn.next:before {
  content: '\f105';
}

.swipe-btn.next:hover {
  right: 0;
}

.swipe-btn.prev {
  left: 30px;
}

@media (max-width: 991px) {
  .swipe-btn.prev {
    left: 10px;
  }
  .swipe-btn.prev:hover {
    left: 0;
  }
}

.swipe-btn.prev:before {
  content: '\f104';
}

.swipe-btn.prev:hover {
  left: 20px;
}

.swipe-btn:before {
  position: absolute;
  color: #131313;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: 'FontAwesome';
  font-size: 16px;
  line-height: 40px;
  display: block;
  transition: all 0.3s ease;
}

/*need for corect view    Filmstrip Gallery slider js plagin*/
.caroufredsel_wrapper {
  width: 100% !important;
}

.filmstrim-gallery-outer {
  padding-left: 30px;
  position: relative;
}

@media (max-width: 991px) {
  .filmstrim-gallery-outer {
    padding-left: 0;
    padding-right: 0;
  }
}

.filmstrim-gallery-outer .filmstrim-gallery img {
  max-width: 100%;
  display: block;
  float: left;
}

.filmstrim-gallery-outer .filmstrim-gallery .image-wrap {
  float: left;
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.filmstrim-gallery-outer body .caroufredsel_wrapper {
  width: 100%;
}

/* SPLITTED SLIDER
---------------------------*/
.ms-section {
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fff;
}

.ms-section.ms-table {
  display: table;
  width: 100%;
}

.ms-tableCell {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
  height: 100%;
}

.ms-easing {
  transition: all .7s ease-out;
}

#multiscroll-nav {
  position: fixed;
  z-index: 100;
  margin-top: -32px;
  top: 50%;
  opacity: 1;
}

#multiscroll-nav.right {
  right: 17px;
}

#multiscroll-nav.left {
  left: 17px;
}

#multiscroll-nav ul {
  margin: 0;
  padding: 0;
}

#multiscroll-nav li {
  display: block;
  width: 14px;
  height: 13px;
  margin: 7px;
  position: relative;
}

#multiscroll-nav li a {
  display: block;
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
}

#multiscroll-nav li .active span {
  background: #333;
}

#multiscroll-nav span {
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border: 1px solid #000;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
}

.multiscroll-tooltip {
  position: absolute;
  color: #fff;
  font-size: 14px;
  font-family: arial, helvetica, sans-serif;
  top: -2px;
  white-space: nowrap;
  max-width: 220px;
}

.multiscroll-tooltip.right {
  right: 20px;
}

.multiscroll-tooltip.left {
  left: 20px;
}

.multiscroll-slider {
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100vh;
}

.multiscroll-slider-left {
  position: relative;
  height: 100%;
  width: 50%;
  overflow: hidden;
  float: left;
}

@media (min-width: 768px) and (max-width: 991px) {
  .multiscroll-slider-left {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .multiscroll-slider-left {
    height: auto;
    width: 50%;
  }
}

@media (max-width: 479px) {
  .multiscroll-slider-left {
    height: auto;
    width: 100%;
  }
}

.multiscroll-slider-left .ms-section {
  padding: 14px 7px 14px 14px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .multiscroll-slider-left .ms-section {
    padding: 14px 14px 7px 14px;
  }
}

@media (max-width: 479px) {
  .multiscroll-slider-left .ms-section {
    padding: 14px 14px 7px 14px;
  }
}

.multiscroll-slider-right {
  position: relative;
  height: 100%;
  width: 50%;
  overflow: hidden;
  float: left;
}

@media (min-width: 768px) and (max-width: 991px) {
  .multiscroll-slider-right {
    height: 100%;
    width: 100%;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .multiscroll-slider-right {
    height: auto;
    width: 50%;
  }
}

@media (max-width: 479px) {
  .multiscroll-slider-right {
    height: auto;
    width: 100%;
  }
}

.multiscroll-slider-right .ms-section {
  padding: 14px 14px 14px 7px;
}

@media (min-width: 768px) and (max-width: 991px) {
  .multiscroll-slider-right .ms-section {
    padding: 7px 14px 14px 14px;
  }
}

@media (max-width: 479px) {
  .multiscroll-slider-right .ms-section {
    padding: 7px 14px 14px 14px;
  }
}

.multiscroll-slider body .ms-left,
.multiscroll-slider body .ms-right {
  width: 100%;
}

.scroll-btn {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #ffffff;
  transition: all 0.3s ease;
}

.scroll-btn:hover:before {
  color: #c0af94;
}

.scroll-btn.up {
  left: calc(50% + 6px);
}

@media (max-width: 991px) and (min-width: 767px) {
  .scroll-btn.up {
    top: auto;
    bottom: calc(50% + 7px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .scroll-btn.up {
    left: calc(50% + 6px);
  }
}

@media (max-width: 480px) and (min-width: 320px) {
  .scroll-btn.up {
    top: auto;
    bottom: calc(50% + 7px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.scroll-btn.up:before {
  content: '\f106';
}

.scroll-btn.up:hover {
  -webkit-transform: translateY(-70%);
  -ms-transform: translateY(-70%);
  transform: translateY(-70%);
}

@media (max-width: 991px) {
  .scroll-btn.up:hover {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .scroll-btn.up:hover {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.scroll-btn.down {
  right: calc(50% + 7px);
}

@media (max-width: 991px) and (min-width: 767px) {
  .scroll-btn.down {
    top: calc(50% + 7px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .scroll-btn.down {
    right: calc(50% + 7px);
  }
}

@media (max-width: 480px) and (min-width: 320px) {
  .scroll-btn.down {
    top: calc(50% + 7px);
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.scroll-btn.down:before {
  content: '\f107';
}

.scroll-btn.down:hover {
  -webkit-transform: translateY(-20%);
  -ms-transform: translateY(-20%);
  transform: translateY(-20%);
}

@media (max-width: 991px) {
  .scroll-btn.down:hover {
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .scroll-btn.down:hover {
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.scroll-btn:before {
  position: absolute;
  color: #131313;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: 'FontAwesome';
  font-size: 16px;
  line-height: 40px;
  display: block;
  transition: all 0.3s ease;
}

.ms-inner {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: inherit;
}

/* SKILLS WRAPPER
---------------------------*/
.skill-wrapper .main-title {
  font-weight: 400;
  letter-spacing: 2.88px;
  color: #131313;
}

.skill-wrapper .skills {
  margin-top: 29px;
  margin-bottom: 29px;
}

.skill-wrapper .skill {
  margin-bottom: 22px;
}

.skill-wrapper .skill .line {
  background-color: #f2f2f2;
  position: relative;
  width: 100%;
  height: 3px;
  margin-top: 10px;
}

.skill-wrapper .skill .line .active-line {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  transition: width 1.5s ease;
  background-color: #000000;
}

.skill-wrapper .skill .label-skill {
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 0;
  text-decoration: none;
  color: #c0af94;
}

.skill-wrapper .skill .value {
  display: inline-block;
  float: right;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 0;
  text-decoration: none;
  color: #c0af94;
}

@media (max-width: 767px) {
  .skill-wrapper {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* THUMBNAIL SLIDER
---------------------------*/
.thumb-slider-wrapp {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.thumb-slider-wrapp .flex-direction-nav {
  display: none;
}

.thumb-slider-wrapp .slides {
  height: 100%;
  display: block;
  margin-bottom: 0;
}

.thumb-slider-wrapp .slides li {
  width: 100%;
  height: 100%;
}

.thumb-slider-wrapp .slides li .thumb-slider-bg {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.thumb-slider-wrapp .main-thumb-slider {
  height: 100%;
}

.thumb-slider-wrapp .sub-thumb-slider {
  width: 100%;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 20;
  background-color: #131313;
  padding: 5px 0 5px 5px;
  transition: transform 0.3s ease;
  transform: translateY(100%);
}

.thumb-slider-wrapp .sub-thumb-slider .flex-viewport {
  height: 100%;
  width: 100%;
}

.thumb-slider-wrapp .sub-thumb-slider .slides li .thumb-slider-bg {
  cursor: pointer;
}

.thumb-slider-wrapp .sub-thumb-slider.active {
  height: 130px;
  transform: translateY(0%);
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow {
  position: absolute;
  width: 150px;
  height: 50px;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  z-index: 20;
  cursor: pointer;
  transition: bottom 0.3s ease;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow .hide-images,
.thumb-slider-wrapp .thumb-slider-wrapp-arrow .show-images {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  font-size: 14px;
  line-height: 50px;
  font-family: "Libre Baskerville", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.12px;
  text-align: center;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow .hide-images:after,
.thumb-slider-wrapp .thumb-slider-wrapp-arrow .show-images:after {
  font-family: 'FontAwesome';
  font-size: 20px;
  line-height: 20px;
  display: block;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-animation-name: sliderArrowAnimation;
  animation-name: sliderArrowAnimation;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  opacity: 1;
  transition: all 0.3s ease;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow .show-images:after {
  content: '\f102';
  top: 0%;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow .hide-images {
  opacity: 0;
  visibility: hidden;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow .hide-images:after {
  content: '\f103';
  top: 100%;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow.active {
  bottom: 150px;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow.active .show-images {
  opacity: 0;
  visibility: hidden;
}

.thumb-slider-wrapp .thumb-slider-wrapp-arrow.active .hide-images {
  opacity: 1;
  visibility: visible;
}

/* ERROR PAGE
---------------------------*/
.error404 .hero-inner .bigtext {
  font-family: "BebasNeueBook", sans-serif;
  font-size: 70px;
  font-weight: 300;
  letter-spacing: 5.6px;
  color: #ffffff;
}

.error404 .hero-inner .title {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1.12px;
  color: #ffffff;
}

/* PHP
---------------------------*/
.fullview {
  height: 100%;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  top: 0;
  transition: background-color 0.3s ease-in-out;
  width: 100%;
  z-index: 10001;
}

.fullview--visible {
  opacity: 1;
  pointer-events: auto;
}

.rsArrow {
  color: white;
  display: block;
  position: absolute;
  top: 50%;
  z-index: 15;
  height: 66px;
  opacity: 0.5;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 36px;
  z-index: 500;
}

@media only screen and (min-width: 900px) {
  .rsArrow {
    height: 68px;
    opacity: 1;
    width: 85px;
  }
}

.fullview .rsArrow {
  opacity: 0.5;
  padding: 1.5em;
  transition: opacity 0.3s linear;
}

.fullview .rsArrowLeft {
  left: 3.7em;
  margin-left: -1.5em;
}

.rsArrowLeft .rsArrowIcn {
  border-left: 2px solid black;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.fullview .rsArrow {
  opacity: 0.5;
  padding: 1.5em;
  transition: opacity 0.3s linear;
}

.fullview .rsArrow .rsArrowIcn {
  border-color: white;
  height: 1.250em;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  width: 1.250em;
}

.fullview .rsArrowRight .rsArrowIcn {
  -webkit-transform: translate3d(-100%, -50%, 0) rotate(-45deg);
  transform: translate3d(-100%, -50%, 0) rotate(-45deg);
}

.fullview__close {
  background: url(../images/close-button.svg) no-repeat;
  background-size: cover;
  cursor: pointer;
  height: 1.5em;
  position: absolute;
  right: 3.5em;
  top: 3.5em;
  width: 1.5em;
  z-index: 10;
  cursor: pointer;
}

.fullview__image {
  background: #000000;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.fullview__image img {
  width: 100%;
}

/* FLOW SLIDER
---------------------------*/
/*need !important for corect view  Flow Gallery slider js plagin*/
body .flipster {
  width: 100%;
  height: 100% !important;
}

/*need !important for corect view  Flow Gallery slider js plagin*/
body .flipster-carousel .flip-items {
  margin: 0;
  position: absolute;
  left: 50% !important;
  top: 50% !important;
  width: 66% !important;
  height: 72% !important;
  transform: translate(-50%, -50%);
}

body .flipster-carousel .flip-item {
  width: 100%;
  height: 100%;
}

body .flipster-carousel .flip-item .flip-content {
  width: 100%;
  height: 100%;
}

body .flipster-carousel .flip-item .flip-content .flow-item-slider {
  width: 100%;
  height: 100%;
}

body .flipster-carousel .flip-prev,
body .flipster-carousel .flip-next,
body .flipster-carousel .flip-past,
body .flipster-carousel .flip-future {
  opacity: 1;
}

body .flipster-carousel .flip-prev {
  transform: translateX(-43%) translateZ(0) rotateY(0) scale(0.7);
}

body .flipster-carousel .flip-next {
  transform: translateX(43%) translateZ(0) rotateY(0) scale(0.7);
}

body .flipster-carousel .flip-past {
  transform: translateX(-80%) translateZ(0) rotateY(0) scale(0.5);
}

body .flipster-carousel .flip-future {
  transform: translateX(80%) translateZ(0) rotateY(0) scale(0.5);
}

body .flipster-carousel .flip-current {
  box-shadow: 0 40px 50px rgba(0, 0, 0, 0.2);
}

.flipster-slider .flipster-wrapp-outer {
  max-width: 1170px;
  height: 100%;
  display: block;
  margin: 0 auto;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.flipster-slider .flow-item-slider {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.flipster-slider .flip-item {
  text-align: center;
}

.flipster-slider .flip-item .flow-item-title {
  font-weight: 400;
  letter-spacing: 2.88px;
  margin-top: 35px;
  margin-bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in, visibility 0.3s ease-in;
}

.flipster-slider .flip-item.flip-current .flow-item-title {
  opacity: 1;
  visibility: visible;
}

.flipto {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: #f0f0f0;
  transition: all 0.3s ease;
}

.flipto:hover:before {
  color: #c0af94;
}

.flipto.flipto-next {
  right: 30px;
}

.flipto.flipto-next:before {
  content: '\f105';
}

.flipto.flipto-next:hover {
  right: 20px;
}

.flipto.flipto-prev {
  left: 30px;
}

.flipto.flipto-prev:before {
  content: '\f104';
}

.flipto.flipto-prev:hover {
  left: 20px;
}

.flipto:before {
  position: absolute;
  color: #131313;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  font-family: 'FontAwesome';
  font-size: 16px;
  line-height: 40px;
  display: block;
  transition: all 0.3s ease;
}

@media only screen and (max-width: 1199px) {
  .flipster-slider .flipster-wrapp-outer {
    max-width: 970px;
  }
}

@media only screen and (max-width: 991px) {
  .flipster-slider .flipster-wrapp-outer {
    max-width: 750px;
  }
}

@media only screen and (max-width: 767px) {
  body .flipster-carousel body .flip-items {
    margin: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60%;
    height: 60%;
    transform: translate(-50%, -50%);
  }
  .flipster-slider .flipster-wrapp-outer {
    max-width: calc(100% - 30px);
  }
  .flipster-slider .flip-item .flow-item-title {
    display: none;
  }
  .flipto {
    display: none;
  }
}

.flipster-slider .flip-item {
  position: absolute;
  list-style: none;
  transition: all .3s ease;
}

.flipster-carousel .flip-future,
.flipster-carousel .flip-past {
  z-index: 1;
}

.flipster-carousel .flip-current {
  z-index: 999;
}

.flipster-carousel .flip-prev,
.flipster-carousel .flip-next {
  z-index: 998;
}

/*need for corect view multiscroll slider js plagin*/
.multiscroll-slider-right .ms-right,
.multiscroll-slider-left .ms-left {
  width: 100% !important;
}

/*---------------------------------------------------------------*/
.single-content ul.comments {
  margin-left: 0;
}

.comments {
  margin-top: 73px;
  margin-bottom: 20px;
}

.comments .comment {
  margin-bottom: 3px;
}

.comments .content {
  position: relative;
  padding-right: 40px;
}

.comments .content .comment-reply-link {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #bababa;
  text-decoration: none;
  font-weight: 400;
  transition: all 300ms ease;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.comments .comment-reply-title {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  padding-top: 44px;
  margin-bottom: 13px;
  color: #131313;
}

.comments .comment-reply-title strong {
  font-weight: 400;
}

.comments .comment-reply-title a {
  text-decoration: none;
}

@media (max-width: 767px) {
  .comments .comment-reply-title {
    padding-top: 20px;
  }
}

.comments .content .comment-reply-link:hover {
  color: #131313;
}

.comments .content .text {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #585858;
  overflow: hidden;
  line-height: 22px;
  margin-top: 6px;
  letter-spacing: .6px;
}

.comments .content .text p {
  margin-bottom: 16px;
}

.comments .person img {
  vertical-align: top;
  float: left;
  margin-right: 20px;
  margin-top: 6px;
}

.comments .person .author {
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 1.92px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  margin: 0;
  text-decoration: none;
  color: #131313;
}

.comments .person .author:hover {
  opacity: .7;
}

.comments .person .comment-date {
  font-size: 12px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  margin-left: 5px;
  font-weight: 400;
  letter-spacing: 2.4px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.comments-title,
.comments-title span {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  margin-bottom: 26px;
  color: #131313;
}

.comment-title {
  border-bottom: 1px dashed #a6a6a6;
  padding-bottom: 6px;
}

.comments li.comment {
  list-style: none;
}

.comments ul.children {
  margin-top: 30px;
}

.comments-form {
  padding-top: 2px;
  margin-bottom: 80px;
  clear: both;
}

body .comments-form input[type=submit]:hover {
  opacity: 1;
}

#contactform h3,
.comments-form h3 {
  display: block;
  font-size: 16px;
  font-family: "Libre Baskerville", sans-serif;
  color: #131313;
  font-weight: bold;
  letter-spacing: 1.25px;
  margin-bottom: 20px;
}

#contactform textarea,
#contactform input,
.comments-form textarea,
.comments-form input {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  background: none;
  width: 100%;
  color: #888888;
  border: 0;
  border-bottom: 2px solid #f2f2f2;
  padding: 10px 0px;
  letter-spacing: 1.12px;
  margin-bottom: 13px;
  transition: all .5s ease;
}

#contactform textarea:focus,
#contactform input:focus,
.comments-form textarea:focus,
.comments-form input:focus {
  border-bottom: 2px solid #c0af94;
  transition: all .5s ease;
}

#contactform textarea::-moz-placeholder,
#contactform input::-moz-placeholder,
.comments-form textarea::-moz-placeholder,
.comments-form input::-moz-placeholder {
  color: #888888;
}

#contactform textarea::-webkit-input-placeholder,
#contactform input::-webkit-input-placeholder,
.comments-form textarea::-webkit-input-placeholder,
.comments-form input::-webkit-input-placeholder {
  color: #888888;
}

#contactform textarea:-moz-placeholder,
#contactform input:-moz-placeholder,
.comments-form textarea:-moz-placeholder,
.comments-form input:-moz-placeholder {
  color: #888888;
}

#contactform textarea:-ms-input-placeholder,
#contactform input:-ms-input-placeholder,
.comments-form textarea:-ms-input-placeholder,
.comments-form input:-ms-input-placeholder {
  color: #888888;
}

#contactform textarea,
.comments-form textarea {
  min-height: 50px;
  max-height: 50px;
  resize: none;
}

#contactform #submit,
.comments-form #submit {
  font-size: 11px;
  line-height: 20px;
  display: inline-block;
  letter-spacing: 1.1px;
  font-family: "Montserrat";
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  border-left: none;
  border-right: none;
  color: #131313;
  background-color: transparent;
  margin-top: 25px;
  margin-bottom: 0;
  width: auto;
  -webkit-font-smoothing: antialiased;
  border-radius: 0px;
}

#contactform #submit:hover,
.comments-form #submit:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  border-left: none;
  border-right: none;
  color: #ffffff;
  background-color: #131313;
  opacity: 1;
}

#contactform {
  display: block;
}

.empty-post-list {
  padding-top: 130px;
  text-align: center;
  display: block;
  width: 100%;
}

.wpb_column > .wpb_wrapper > p:last-child {
  margin-bottom: 10px;
}

.vc_column_container .wpb_wrapper .vc_btn3.vc_btn3-style-outline {
  border-width: 1px;
  padding: 3px 22px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  line-height: 26px;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
}

.black p.separator {
  background-color: #ffffff;
}

.portfolio-gallery a.gallery-item {
  float: none;
  padding: 0;
}

.blog .sidebar-item {
  margin-left: 15px;
}

.error404 .main-wrapper {
  min-height: 100vh;
  overflow: hidden;
}

body .container .ytbg iframe,
body .hero .vc_row-fluid .vc_column_container .ytbg iframe {
  margin-left: 0;
}

body .mb_OnlyYT,
body .mb_YTPTime,
body span.mb_YTPMuteUnmute {
  font-size: 0;
  display: none;
}

.mb_YTPPlaypause {
  display: block;
  width: 50px;
  height: 50px;
  font-size: 0;
}

.mb_YTPPlaypause:before {
  font-family: 'FontAwesome';
  content: "\f04c";
  font-size: 25px;
  color: #ffffff;
  height: 50px;
  line-height: 50px;
  text-shadow: 1px 1px 2px black;
}

.mb_YTPPlaypause.active:before {
  content: "\f04b";
}

.mb_YTPBar {
  bottom: 0;
  left: 25px;
}

.mb_YTPBar .buttonBar {
  height: 60px;
}

.single blockquote,
.about-details .content blockquote,
.about-section .content blockquote,
.portfolio-single-content blockquote {
  padding: 26px 26px 26px 64px;
  border: none;
  border-left: 4px solid #131313;
  font-style: italic;
  font-family: "Libre Baskerville", sans-serif;
  background-color: #F4F4F4;
  font-size: 18px;
  letter-spacing: 1.38px;
  color: #131313;
  margin-top: -4px;
  margin-bottom: 25px;
}

blockquote cite {
  line-height: 1.42857;
  color: #777;
  font-style: normal;
  display: block;
  font-size: 14px;
  margin-top: 15px;
}

blockquote cite:before {
  content: '- ';
  margin-right: 7px;
}

body #topmenu ul > li > ul > li > ul {
  display: none;
}

.single table td,
.single table th,
table td,
table th {
  padding: 7px;
}

.post-info {
  margin: 10px 0 15px;
}

.post-info span a,
.post-info span {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  text-decoration: none;
  color: #c0af94;
  text-transform: uppercase;
}

.post-info span a,
.post-info span {
  font-family: "Montserrat";
  font-size: 11px;
  line-height: 20px;
  font-weight: 400;
  letter-spacing: 1.1px;
  text-decoration: none;
  color: #c0af94;
  text-transform: uppercase;
}

.post-info span {
  padding-right: 16px;
  border-right: 1px solid #E8E8E8;
}

.post-info span:last-child {
  border: 0 none;
}

div.wpcf7-mail-sent-ok {
  left: 0;
  right: 0;
}

body .wpcf7-not-valid {
  border-bottom: 2px solid red;
}

.wpcf7-form-control-wrap {
  position: relative;
  display: block;
}

.wpcf7-form-control-wrap .wpcf7-not-valid-tip {
  position: absolute;
  top: 50px;
}

div.wpcf7-mail-sent-ok,
div.wpcf7-mail-sent-ng,
div.wpcf7-validation-errors {
  border: none;
}

.ajax-loader {
  display: none;
}

div.wpcf7 {
  position: relative;
}

body div.wpcf7-response-output {
  position: absolute;
  bottom: 20px;
  margin: 0;
}

@media (min-width: 1200px) {
  div.wpcf7-response-output {
    left: -15px;
  }
}

@media (max-width: 767px) {
  div.wpcf7-response-output {
    position: relative;
    text-align: center;
    bottom: 20px;
  }
}

.empty-post-list form {
  padding-top: 20px;
  width: 270px;
  margin: auto;
  display: block;
}

.empty-post-list input {
  display: inline-block;
  margin: 0 auto;
  height: 36px;
}

.empty-post-list input[type=search] {
  border: 1px solid #ccc;
  padding-left: 7px;
}

.empty-post-list label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
  float: left;
  width: 182px;
}

.abs * {
  color: inherit;
}

.tabs ul,
.hero-slider ol,
.tabs ol {
  margin-left: 0;
}

/*.container .wpb_row {
  margin-bottom: 20px;
}*/
@media (max-width: 767px) {
  .slide-caption {
    left: 20px;
    bottom: 20px;
    right: 20px;
  }
  .vc_column_container.vc_col-sm-12 {
    padding-left: 0;
    padding-right: 0;
  }
  .page .wpb_row,
  .page .wpb_content_element,
  .page ul.wpb_thumbnails-fluid > li,
  .page .last_toggle_el_margin,
  .page .wpb_button {
    margin-bottom: 0;
  }
  body .pad-top-120 {
    padding-top: 110px;
  }
  [class*="col-sm"] {
    margin-bottom: 0;
  }
  #footer .copyright,
  #footer .social-links {
    float: none;
    text-align: center;
  }
  #footer .copyright a,
  #footer .social-links a {
    padding: 0 4px;
  }
}

@media screen and (max-width: 600px) {
  html {
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  body .hero-slider .slides .slide {
    height: 400px;
  }
  .side-link .side-title {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  body .hero-slider .slides .slide {
    height: 300px;
  }
}

.social-links.single-post {
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.social-links.single-post a {
  padding-left: 20px;
}

.click-on-this {
  text-decoration: none;
}

.social-list-portfolio:hover a {
  opacity: .6;
}

.social-list-portfolio a:hover {
  opacity: 1;
}

/* nn. ABOUT DETAILS
-----------------*/
.about-details-section {
  background-color: #f2f2f2;
}

.about-details {
  padding: 60px 70px 20px;
}

.about-details.style2 {
  padding: 77px 0 71px 0;
}

@media (max-width: 1199px) {
  .about-details {
    padding: 30px 40px 10px;
  }
}

@media (max-width: 991px) {
  .about-details {
    padding: 30px;
  }
}

@media (max-width: 767px) {
  .about-details {
    padding: 30px 15px;
  }
  .about-details.style2 {
    padding: 60px 0;
  }
}

.simple-details {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .simple-details {
    text-align: center;
    padding: 0px 15px;
  }
}

.about-details .content .subtitle,
.simple-details .content .subtitle {
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  -webkit-font-smoothing: antialiased;
}

@media (max-width: 1199px) {
  .about-details .content .subtitle,
  .simple-details .content .subtitle {
    margin-bottom: 20px;
  }
}

.simple-details .content .subtitle {
  margin-top: -3px;
  margin-bottom: 28px;
}

@media (max-width: 1199px) {
  .simple-details .content .subtitle {
    margin-bottom: 15px;
  }
}

.about-details .content .title,
.simple-details .content .title {
  font-family: "BebasNeueRegular";
  font-size: 36px;
  line-height: 40px;
  color: #131313;
  letter-spacing: 2.88px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .about-details .content .title,
  .simple-details .content .title {
    font-size: 30px;
    line-height: 30px;
  }
}

.simple-details .content .title {
  line-height: 36px;
  margin-bottom: 25px;
}

@media (max-width: 1199px) {
  .simple-details .content .title {
    font-size: 30px;
    line-height: 30px;
    margin-bottom: 15px;
  }
}

.about-details .content .text,
.simple-details .content .text {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  letter-spacing: 1.12px;
}

.about-details .content .text p,
.simple-details .content .text p {
  margin-top: 28px;
}

.about-details.style2 .content .text p {
  margin-top: 0;
  margin-bottom: 5px;
}

.simple-details .content .text {
  margin-bottom: 30px;
}

.about-details .content .text blockquote {
  margin-top: 25px;
  overflow: hidden;
  text-align: center;
  padding: 20px 30px 20px 25px;
  border-left: 4px solid #c0af94;
  background-color: #ebebeb;
}

@media (max-width: 1199px) {
  .about-details .content .text blockquote {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .about-details .content .text blockquote {
    padding: 15px;
  }
}

.about-details .content .text blockquote p {
  font-size: 16px;
  line-height: 30px;
  font-family: "Libre Baskerville";
  color: #131313;
  text-align: left;
  font-style: normal;
  margin: 0;
  letter-spacing: 1.28px;
}

.about-details .content .button {
  margin-top: 14px;
  margin-left: 0;
}

/* nn. TITLES
-----------------*/
.titles {
  max-width: 770px;
  margin: auto;
}

.titles.left {
  max-width: none;
}

.titles.left .title,
.titles.left .subtitle {
  text-align: left;
}

.titles .title {
  font-size: 36px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
  letter-spacing: 2px;
}

.titles .subtitle {
  font-size: 14px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  font-weight: 400;
  line-height: 28px;
  text-align: center;
  letter-spacing: .73px;
}

/* nn. INSTAGRAM
-----------------*/
.insta-box .insta-box-follow {
  font-size: 36px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  float: left;
  width: 20.5%;
  letter-spacing: 2.88px;
  padding-top: 35px;
  word-break: break-all;
  line-height: 1.2;
}

@media (max-width: 1199px) {
  .insta-box .insta-box-follow {
    font-size: 30px;
  }
}

.insta-box .insta-box-follow a {
  font-size: 14px;
  display: inline-block;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  display: block;
  font-weight: 400;
  letter-spacing: 1.12px;
  margin-top: 5px;
  text-decoration: none;
  transition: all 300ms ease;
}

.insta-box .insta-box-follow a:hover {
  color: #131313;
}

.insta-box .insta-img-wrap {
  overflow: hidden;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.insta-box .insta-img-wrap a {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.insta-box .insta-img-wrap .img {
  display: block;
  width: 125px;
  height: 125px;
  margin: 0;
  margin-bottom: 40px;
}

@media (max-width: 1199px) {
  .insta-box .insta-img-wrap .img {
    width: 115px;
    height: 115px;
  }
}

@media (max-width: 991px) {
  .insta-box .insta-box-follow {
    font-size: 24px;
    float: none;
    width: 100%;
    text-align: center;
    padding: 10px 0px 40px;
  }
  .insta-box .insta-img-wrap .img {
    width: 100px;
    height: 100px;
  }
}

@media (max-width: 767px) {
  .insta-box {
    padding: 0px 15px;
  }
  .insta-box.col-xs-12 {
    padding: 0px 30px;
  }
  .insta-box .insta-img-wrap {
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: distribute;
    -webkit-justify-content: space-around;
    justify-content: space-around;
  }
  .insta-box .insta-img-wrap a {
    -ms-flex-preferred-size: 50%;
    -webkit-flex-basis: 50%;
    flex-basis: 50%;
  }
  .insta-box .insta-img-wrap a .img {
    margin-left: auto;
    margin-right: auto;
  }
}

.portfolio-single-content {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #585858;
  line-height: 1.7;
  letter-spacing: .7px;
}

body .no-padd {
  padding-left: 0;
  padding-right: 0;
}

@media (min-width: 1200px) {
  body .hero > .container {
    padding: 0;
  }
  body .no-padd-md {
    padding: 0;
  }
}

@media (max-width: 992px) {
  body #google-map {
    min-height: 310px;
  }
  .top-banner .banner-text {
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 991px) {
  .banner-gallery .content-wrap .content {
    padding: 15px 30px;
  }
  .banner-gallery .content-wrap .title {
    font-size: 35px;
    letter-spacing: 3px;
    line-height: 50px;
  }
  .about-details {
    text-align: center;
  }
}

@media (min-width: 768px) {
  body .pad-sm-l-0 {
    padding-left: 0;
  }
  body .pad-sm-r-0 {
    padding-right: 0;
  }
  #contactform input,
  .comments-form input {
    width: 48.5%;
    float: left;
  }
  #contactform input[name="author"],
  .comments-form input[name="author"] {
    margin-right: 3%;
  }
}

@media (max-width: 768px) {
  .post-details .title {
    line-height: 1.2;
  }
  .top-banner {
    height: 100vmin;
    min-height: 500px;
  }
  .top-banner.center_content {
    min-height: 300px;
  }
  .top-banner .banner-text {
    position: relative;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .wpcf7 form input,
  form.wpcf7-form input {
    margin-bottom: 10px;
  }
  body .no-pad-xs {
    padding: 0;
  }
  .action {
    padding: 27px 30px;
    text-align: center;
  }
  .action .button {
    position: static;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    margin-top: 20px;
    padding: 1px 39px;
  }
  .banner-gallery::before {
    content: '';
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
  }
  body .banner-gallery .content-wrap {
    -webkit-transform: none;
    -ms-transform: none;
    transform: none;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 30px 15px;
    margin-left: 0;
  }
  .banner-gallery .content-wrap .content {
    padding: 0;
  }
}

@media (max-width: 767px) {
  body #google-map,
  body .contact-info {
    float: none;
    width: 100%;
    height: auto;
  }
  body #google-map {
    min-height: 300px;
    height: 300px;
  }
  .contact-info .details {
    margin-left: 0;
  }
  .contact-info {
    margin-top: 30px;
    text-align: center;
    padding: 30px 15px;
  }
  .top-banner .banner-text {
    max-width: 90%;
    padding: 0 15px;
  }
  .top-banner .banner-text .title {
    font-size: 28px;
  }
  .hero {
    padding: 0;
  }
  .top-banner .subtitle {
    letter-spacing: 5px;
  }
  .about-details .content .text blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    padding: 23px 25px 14px;
  }
  .about-details .content .text p {
    margin-bottom: 15px;
  }
  .about-details .content .text blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    margin-top: 13px;
  }
  .about-details .content .button {
    margin-top: 5px;
  }
  .about-details .content .subtitle {
    letter-spacing: 5px;
  }
  .team-member .avatar {
    height: 300px;
  }
  .team-member .info .title {
    font-size: 22px;
  }
  .banner-gallery .content-wrap .title {
    font-size: 30px;
    margin-bottom: 30px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  .single blockquote,
  .about-details .content blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    padding: 26px 26px 26px 36px;
  }
  .pagination {
    margin: 15px 0 0;
  }
  .comments {
    margin-top: 28px;
    text-align: center;
  }
  .comments .person .comment-date {
    display: block;
  }
  .comments .content {
    padding-right: 0;
  }
  .comments .person img {
    float: none;
    display: block;
    margin: auto auto 15px;
  }
  .comments .content .comment-reply-link {
    top: auto;
    bottom: -15px;
  }
  .comments .comment {
    margin-bottom: 30px;
  }
  .comments-title {
    margin-bottom: 20px;
  }
  .comments-form {
    margin-bottom: 60px;
  }
  .post-details,
  .single-content {
    text-align: center;
  }
  .post-details .title {
    font-size: 30px;
  }
  .single blockquote,
  .about-details .content blockquote,
  .about-section .content blockquote,
  .portfolio-single-content blockquote {
    font-size: 16px;
    padding: 26px;
  }
  .single-content p {
    margin-bottom: 15px;
  }
  .about-section {
    padding: 30px 15px;
  }
  .about-section .title {
    font-size: 26px;
  }
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border-width: 1px 0 0 1px;
  margin: 0 0 1.75em;
  table-layout: fixed;
  width: 100%;
}

table,
th,
td {
  border: 1px solid #d1d1d1;
}

dd {
  margin: 0 0 1.75em;
}

address {
  font-style: italic;
}

abbr,
acronym {
  border-bottom: 1px dotted #d1d1d1;
  cursor: help;
}

code {
  background-color: #d1d1d1;
  color: #131313;
}

mark,
ins {
  background: #007acc;
  color: #ffffff;
  padding: 0.125em 0.25em;
  text-decoration: none;
}

.price ins {
  background-color: transparent;
}

kbd {
  background-color: transparent;
  color: #131313;
  font-weight: 100;
  box-shadow: none;
  opacity: .9;
}

.alignleft {
  float: left;
  margin: 0.375em 1.75em 1.75em 0;
}

.alignright {
  float: right;
  margin: 0.375em 0 1.75em 1.75em;
}

.wp-caption {
  margin-bottom: 1.75em;
  max-width: 100%;
}

.wp-caption .wp-caption-text {
  font-style: italic;
  opacity: .9;
  font-size: 13px;
}

/*PAGE NAVIGATION */
.post-nav,
.pager-pagination {
  text-align: center;
  clear: both;
  font-family: "Montserrat", sans-serif;
  padding: 50px 0;
}

.post-nav a,
.pages,
.page-numbers:not('.next, .prev') {
  border: 1px solid #131313;
  border-radius: 2px;
  display: inline-block;
  font-size: 13px;
  height: 24px;
  line-height: 22px;
  margin-right: 4px;
  text-align: center;
  width: 24px;
  color: #ffffff;
  text-decoration: none;
  background-color: #131313;
}

.post-nav a:hover,
.post-nav a:focus,
.page-numbers:hover,
.page-numbers:focus {
  opacity: .7;
  border-color: transparent;
  color: #ffffff;
  text-decoration: none;
}

body .next.page-numbers,
body .prev.page-numbers {
  font-family: "Montserrat", sans-serif;
  text-decoration: none;
  background-color: transparent;
  color: #131313;
}

.prev.page-numbers {
  padding-right: 10px;
}

.next.page-numbers {
  padding-left: 10px;
}

.next.page-numbers:hover,
.prev.page-numbers:hover {
  color: #131313;
}

.post-nav .pages,
.post-nav .current,
.pager-pagination .pages,
.pager-pagination .current {
  opacity: .7;
  border-color: transparent;
  color: #ffffff;
}

.post-nav a .pages {
  opacity: 1;
}

.post-nav a .pages {
  opacity: 1;
}

@media screen and (max-width: 991px) {
  .post-nav,
  .pager-pagination {
    padding: 30px 0;
  }
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  padding: 0 10px;
  background: #131313;
  border-color: #131313;
  color: #ffffff;
  transition: all 300ms ease;
  border-radius: 0px;
}

button:hover,
html input[type=button]:hover,
input[type=reset]:hover,
input[type=submit]:hover {
  opacity: .8;
}

figure.gallery-item {
  display: inline-block;
  max-width: 33.33%;
  text-align: center;
  overflow: visible;
  vertical-align: top;
  width: 100%;
  height: auto;
  padding: 0 1.1400652% 2.2801304%;
}

.gallery-caption {
  padding-top: 7px;
}

.gallery-columns-1 .gallery-item {
  max-width: 100%;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

caption {
  color: #131313;
  opacity: .7;
}

div.wpcf7-response-output {
  margin: auto auto 20px;
  padding: 0;
}

.main-wrapper {
  overflow-x: hidden;
  position: relative;
}

body .display {
  display: block;
}

.portfolio-single-content .social-list {
  text-align: right;
}

.portfolio-single-content .social-list li {
  margin: 0px 1px 0 11px;
}

.single-pagination {
  padding: 50px 0;
}

.single-pagination > div {
  display: inline-block;
}

.single-pagination > div.pag-prev {
  float: left;
}

.single-pagination > div.pag-prev a {
  padding-left: 20px;
}

.single-pagination > div.pag-prev a:hover i {
  left: 5px;
}

.single-pagination > div.pag-prev i {
  left: 0;
  transition: left 300ms ease;
}

.single-pagination > div.pag-next {
  float: right;
}

.single-pagination > div.pag-next a {
  padding-right: 20px;
}

.single-pagination > div.pag-next a:hover i {
  right: 5px;
}

.single-pagination > div.pag-next i {
  right: 0;
  transition: right 300ms ease;
}

.single-pagination > div a.content {
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  color: #585858;
  line-height: 1.7;
  letter-spacing: .7px;
  text-decoration: none;
  position: relative;
  display: block;
}

.single-pagination > div a.content:hover {
  color: #c0af94;
}

.single-pagination > div a.content i {
  font-size: 18px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #c0af94;
}

@media screen and (max-width: 991px) {
  .single-pagination {
    padding: 30px 0;
  }
}

@media screen and (max-width: 767px) {
  .single-pagination > div.pag-prev {
    float: left;
    margin-left: 15px;
  }
  .single-pagination > div.pag-next {
    float: right;
    margin-right: 15px;
  }
}

/*Another styles*/
.bypostauthor {
  font-size: inherit;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.top-banner {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: auto;
}

.top-banner .subtitle {
  padding-top: 0;
}

.top-banner .content {
  margin-top: 150px;
  margin-bottom: 150px;
  z-index: 30;
}

.top-banner.left_content .content {
  margin-top: 213px;
  margin-bottom: 213px;
}

.top-banner.center_content .content {
  margin-top: 75px;
  margin-bottom: 60px;
}

.top-banner.full-height .content {
  margin-top: auto;
  margin-bottom: auto;
}

.protected-page form {
  padding-bottom: 280px;
}

.sm-wrap-post .content .title {
  padding-right: 15px;
  padding-left: 15px;
}

.post-content h5 {
  padding-right: 15px;
  padding-left: 15px;
}

@media screen and (max-width: 1200px) {
  .protected-page .protected-title {
    padding-top: 150px;
  }
  .protected-page form {
    padding-bottom: 150px;
  }
  .post-banner {
    height: 300px;
  }
}

@media (min-height: 600px) and (min-width: 1280px) {
  .protected-page .protected-title {
    padding-top: 100px;
  }
  .protected-page form {
    padding-bottom: 100px;
  }
}

@media screen and (max-width: 992px) {
  .top-banner .content {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .top-banner.left_content .content {
    margin-top: 150px;
    margin-bottom: 150px;
  }
  .protected-page .protected-title {
    padding-top: 100px;
  }
  .protected-page form {
    padding-bottom: 100px;
  }
  .select-for-hovers .content {
    text-align: center;
  }
  .services {
    min-height: 280px;
  }
  .post-banner {
    height: 250px;
  }
  .single-post .title {
    font-size: 48px;
    line-height: 1.2;
  }
  .comments .comment-reply-title {
    padding-top: 0;
    font-size: 25px;
    line-height: 1.2;
  }
  .recent-post-single .recent-title {
    font-size: 25px;
    line-height: 1.2;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.top-banner .content {
  max-width: 1170px;
  position: relative;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.top-banner.center_content {
  border-top: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}

.top-banner.center_content .content {
  text-align: center;
}

@media (max-width: 1199px) {
  .top-banner .content {
    max-width: 970px;
  }
}

@media (max-width: 991px) {
  .top-banner .content {
    max-width: 750px;
  }
}

@media (max-width: 767px) {
  .top-banner .content {
    padding: 0px 15px;
  }
}

.top-banner .title {
  line-height: 1.1;
}

@media screen and (max-width: 768px) {
  .single-post .title {
    font-size: 40px;
  }
  .single-post p {
    margin-bottom: 15px;
  }
  .contact-info {
    margin-top: 0px;
  }
  .contact-info .details h5,
  .contact-form h2 {
    font-size: 28px;
  }
  .wpcf7 form input[type="submit"],
  form.wpcf7-form #submit {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 767px) {
  .sm-wrap-post a {
    min-height: 150px;
  }
  .blog .sidebar-item {
    margin-right: 15px;
  }
}

@media screen and (max-width: 600px) {
  .top-banner .content,
  .top-banner.center_content .content {
    margin-top: 70px;
    margin-bottom: 70px;
  }
  .top-banner.left_content .content {
    margin-top: 100px;
    margin-bottom: 100px;
  }
  .protected-page .protected-title {
    padding-top: 60px;
    font-size: 26px;
    padding-bottom: 0;
  }
  .protected-page form {
    padding-bottom: 60px;
  }
  .protected-page input[type="submit"] {
    margin-top: 30px;
    margin-bottom: 10px;
  }
  .single-post .title {
    font-size: 30px;
  }
}

.error-height {
  height: 100vh;
}

body .top-banner.top_align .content {
  top: 0;
  margin-top: 0;
}

/* change */
body .post.no-image a:hover,
body .no-image.recent-simple-post a:hover {
  opacity: 1;
  transition: all 0.5s ease;
}

.post.no-image a:hover:before {
  opacity: 1;
  transition: all 0.5s ease;
}

.post.no-image a:hover .date {
  opacity: 1;
  transition: all 0.5s ease;
}

.post.no-image a:hover h5,
.no-image.recent-simple-post .sm-wrap-post a:hover .content {
  opacity: 1;
  transition: all 0.5s ease;
}

.empty-post-list {
  padding-bottom: 130px;
}

.search-no-results .main-wrapper {
  height: auto;
}

body .blog {
  height: auto;
}

.banner-list li span {
  background-size: cover;
}

.container.about,
.about-us .hero > .row.about-us,
.about-section {
  background-color: #f2f2f2;
}

.wpb_single_image img {
  height: auto;
  max-width: 100%;
  vertical-align: top;
}

.wpb_single_image {
  text-align: center;
}

.custom-row {
  margin-left: -30px;
  margin-right: -30px;
}

.just-gallery img {
  display: initial;
  max-width: none;
}

.pswp__caption__center {
  max-width: none;
  text-align: center;
  padding: 10px 40px;
}

.item-desc {
  letter-spacing: .8px;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #888;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}

.pswp__caption h4 {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1.92px;
  margin-bottom: 3px;
}

.pswp__img {
  top: 23px;
}

.pswp__img:hover {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

/* 10 hovers animation */
.select-for-hovers h5 {
  display: inline-block;
  padding: 2px 8px;
  font-weight: 400;
  text-decoration: none;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span {
  position: relative;
  padding: 1px 20px 1px 10px;
  display: inline-block;
  font-weight: 400;
  border: 1px solid #888888;
  text-decoration: none;
  min-width: 200px;
  text-align: left;
  cursor: pointer;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover > span::after {
  content: "\f107";
  font-family: 'FontAwesome';
  position: absolute;
  right: 5px;
}

.select-for-hovers {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  text-align: right;
  margin-top: 20px;
}

.select-for-hovers .content {
  position: relative;
  text-align: right;
  width: 100%;
}

.select-for-hovers .hover {
  display: inline-block;
  position: relative;
}

.select-for-hovers .hover .list {
  text-align: left;
  position: absolute;
  background-color: #fff;
  margin-left: 0;
  z-index: 10;
  width: 100%;
  border: 1px solid #131313;
  display: none;
}

.select-for-hovers .hover .list li {
  list-style: none;
  font-weight: 400;
  padding: 1px 8px;
  cursor: pointer;
  background-color: transparent;
  transition: all 350ms ease;
  font-size: 12px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  margin: auto;
  letter-spacing: 1.12px;
}

.select-for-hovers .hover .list li:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

/* end 10 hovers animation */
.sliphover-container {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-weight: 400;
  letter-spacing: 1.92px;
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
.monthly {
  border: 1px solid #EBEBEB;
}

.monthly-header {
  padding: 20px 15px;
  height: auto;
  background-color: #C4F2D4;
}

.monthly-header-title a:link,
.monthly-header-title a:visited,
.monthly-header-title a:last-of-type,
.monthly-header-title a:first-of-type {
  font-size: 20px;
  color: #fff;
  height: auto;
  background-color: transparent;
  border: none;
  line-height: 1;
}

.monthly-prev:after,
.monthly-next:after {
  border-color: #fff;
  opacity: 1;
  width: 15px;
  height: 15px;
}

.monthly-day,
.monthly-day-blank {
  position: relative;
  text-align: center;
}

.monthly-day-title-wrap {
  padding: 13px 0;
  border-width: 1px;
  border-style: solid;
  font-size: 14px;
  color: #fff;
  border: none;
  outline: none;
  background-color: #039146;
}

.monthly-day-event > .monthly-day-number {
  position: absolute;
  widows: 20px;
  height: 70px;
  line-height: 70px;
  background-color: transparent;
  left: 0;
  right: 0;
  width: auto;
  margin: auto;
  transform: translateY(-50%);
  font-size: 20px;
  font-weight: 400;
  top: 50%;
  color: #333;
}

.monthly-day-event > .monthly-day-number:after {
  transition: all .3s ease;
  z-index: -1;
  content: '';
  width: 50px;
  height: 50px;
  background-color: transparent;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}

.monthly-day-event > .monthly-day-number:hover {
  color: #fff;
}

.monthly-day-event > .monthly-day-number:hover:after {
  background: #31afea;
}

.monthly-today .monthly-day-number:after {
  content: '';
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: transparent;
  border-radius: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  border: 2px solid #31afea;
}

.monthly-today .monthly-day-number:hover {
  color: #fff;
}

.monthly-today .monthly-day-number:hover:after {
  z-index: -1;
  content: '';
  width: 50px;
  height: 50px;
  background: #31afea;
}

@media only screen and (max-width: 500px) {
  .monthly-day-event > .monthly-day-number:after,
  .monthly-today .monthly-day-number:after {
    display: none;
  }
  .monthly-day-event > .monthly-day-number:hover {
    color: #333;
  }
}

@media only screen and (max-width: 991px) {
  .margin-sm-t-50 {
    margin-top: -50px;
  }
  .margin-sm-b-50 {
    margin-bottom: -50px;
  }
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
.price-1 {
  min-height: 100vh;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
}

.price-1 > div {
  position: relative;
  z-index: 21;
}

/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
/*---------------------------------------------------------------*/
.margin-lg-0t {
  margin-top: 0px;
}

.margin-lg-5t {
  margin-top: 5px;
}

.margin-lg-10t {
  margin-top: 10px;
}

.margin-lg-15t {
  margin-top: 15px;
}

.margin-lg-20t {
  margin-top: 20px;
}

.margin-lg-25t {
  margin-top: 25px;
}

.margin-lg-30t {
  margin-top: 30px;
}

.margin-lg-35t {
  margin-top: 35px;
}

.margin-lg-40t {
  margin-top: 40px;
}

.margin-lg-45t {
  margin-top: 45px;
}

.margin-lg-50t {
  margin-top: 50px;
}

.margin-lg-55t {
  margin-top: 55px;
}

.margin-lg-60t {
  margin-top: 60px;
}

.margin-lg-65t {
  margin-top: 65px;
}

.margin-lg-70t {
  margin-top: 70px;
}

.margin-lg-75t {
  margin-top: 75px;
}

.margin-lg-80t {
  margin-top: 80px;
}

.margin-lg-85t {
  margin-top: 85px;
}

.margin-lg-90t {
  margin-top: 90px;
}

.margin-lg-95t {
  margin-top: 95px;
}

.margin-lg-100t {
  margin-top: 100px;
}

.margin-lg-105t {
  margin-top: 105px;
}

.margin-lg-110t {
  margin-top: 110px;
}

.margin-lg-115t {
  margin-top: 115px;
}

.margin-lg-120t {
  margin-top: 120px;
}

.margin-lg-125t {
  margin-top: 125px;
}

.margin-lg-130t {
  margin-top: 130px;
}

.margin-lg-135t {
  margin-top: 135px;
}

.margin-lg-140t {
  margin-top: 140px;
}

.margin-lg-145t {
  margin-top: 145px;
}

.margin-lg-150t {
  margin-top: 150px;
}

.margin-lg-155t {
  margin-top: 155px;
}

.margin-lg-160t {
  margin-top: 160px;
}

.margin-lg-165t {
  margin-top: 165px;
}

.margin-lg-170t {
  margin-top: 170px;
}

.margin-lg-175t {
  margin-top: 175px;
}

.margin-lg-180t {
  margin-top: 180px;
}

.margin-lg-185t {
  margin-top: 185px;
}

.margin-lg-190t {
  margin-top: 190px;
}

.margin-lg-195t {
  margin-top: 195px;
}

.margin-lg-200t {
  margin-top: 200px;
}

.margin-lg-0b {
  margin-bottom: 0px;
}

.margin-lg-5b {
  margin-bottom: 5px;
}

.margin-lg-10b {
  margin-bottom: 10px;
}

.margin-lg-15b {
  margin-bottom: 15px;
}

.margin-lg-20b {
  margin-bottom: 20px;
}

.margin-lg-25b {
  margin-bottom: 25px;
}

.margin-lg-30b {
  margin-bottom: 30px;
}

.margin-lg-35b {
  margin-bottom: 35px;
}

.margin-lg-40b {
  margin-bottom: 40px;
}

.margin-lg-45b {
  margin-bottom: 45px;
}

.margin-lg-50b {
  margin-bottom: 50px;
}

.margin-lg-55b {
  margin-bottom: 55px;
}

.margin-lg-60b {
  margin-bottom: 60px;
}

.margin-lg-65b {
  margin-bottom: 65px;
}

.margin-lg-70b {
  margin-bottom: 70px;
}

.margin-lg-75b {
  margin-bottom: 75px;
}

.margin-lg-80b {
  margin-bottom: 80px;
}

.margin-lg-85b {
  margin-bottom: 85px;
}

.margin-lg-90b {
  margin-bottom: 90px;
}

.margin-lg-95b {
  margin-bottom: 95px;
}

.margin-lg-100b {
  margin-bottom: 100px;
}

.margin-lg-105b {
  margin-bottom: 105px;
}

.margin-lg-110b {
  margin-bottom: 110px;
}

.margin-lg-115b {
  margin-bottom: 115px;
}

.margin-lg-120b {
  margin-bottom: 120px;
}

.margin-lg-125b {
  margin-bottom: 125px;
}

.margin-lg-130b {
  margin-bottom: 130px;
}

.margin-lg-135b {
  margin-bottom: 135px;
}

.margin-lg-140b {
  margin-bottom: 140px;
}

.margin-lg-145b {
  margin-bottom: 145px;
}

.margin-lg-150b {
  margin-bottom: 150px;
}

.margin-lg-155b {
  margin-bottom: 155px;
}

.margin-lg-160b {
  margin-bottom: 160px;
}

.margin-lg-165b {
  margin-bottom: 165px;
}

.margin-lg-170b {
  margin-bottom: 170px;
}

.margin-lg-175b {
  margin-bottom: 175px;
}

.margin-lg-180b {
  margin-bottom: 180px;
}

.margin-lg-185b {
  margin-bottom: 185px;
}

.margin-lg-190b {
  margin-bottom: 190px;
}

.margin-lg-195b {
  margin-bottom: 195px;
}

.margin-lg-200b {
  margin-bottom: 200px;
}

.padding-lg-0t {
  padding-top: 0px;
}

.padding-lg-5t {
  padding-top: 5px;
}

.padding-lg-10t {
  padding-top: 10px;
}

.padding-lg-15t {
  padding-top: 15px;
}

.padding-lg-20t {
  padding-top: 20px;
}

.padding-lg-25t {
  padding-top: 25px;
}

.padding-lg-30t {
  padding-top: 30px;
}

.padding-lg-35t {
  padding-top: 35px;
}

.padding-lg-40t {
  padding-top: 40px;
}

.padding-lg-45t {
  padding-top: 45px;
}

.padding-lg-50t {
  padding-top: 50px;
}

.padding-lg-55t {
  padding-top: 55px;
}

.padding-lg-60t {
  padding-top: 60px;
}

.padding-lg-65t {
  padding-top: 65px;
}

.padding-lg-70t {
  padding-top: 70px;
}

.padding-lg-75t {
  padding-top: 75px;
}

.padding-lg-80t {
  padding-top: 80px;
}

.padding-lg-85t {
  padding-top: 85px;
}

.padding-lg-90t {
  padding-top: 90px;
}

.padding-lg-95t {
  padding-top: 95px;
}

.padding-lg-100t {
  padding-top: 100px;
}

.padding-lg-105t {
  padding-top: 105px;
}

.padding-lg-110t {
  padding-top: 110px;
}

.padding-lg-115t {
  padding-top: 115px;
}

.padding-lg-120t {
  padding-top: 120px;
}

.padding-lg-125t {
  padding-top: 125px;
}

.padding-lg-130t {
  padding-top: 130px;
}

.padding-lg-135t {
  padding-top: 135px;
}

.padding-lg-140t {
  padding-top: 140px;
}

.padding-lg-145t {
  padding-top: 145px;
}

.padding-lg-150t {
  padding-top: 150px;
}

.padding-lg-155t {
  padding-top: 155px;
}

.padding-lg-160t {
  padding-top: 160px;
}

.padding-lg-165t {
  padding-top: 165px;
}

.padding-lg-170t {
  padding-top: 170px;
}

.padding-lg-175t {
  padding-top: 175px;
}

.padding-lg-180t {
  padding-top: 180px;
}

.padding-lg-185t {
  padding-top: 185px;
}

.padding-lg-190t {
  padding-top: 190px;
}

.padding-lg-195t {
  padding-top: 195px;
}

.padding-lg-200t {
  padding-top: 200px;
}

.padding-lg-0b {
  padding-bottom: 0px;
}

.padding-lg-5b {
  padding-bottom: 5px;
}

.padding-lg-10b {
  padding-bottom: 10px;
}

.padding-lg-10b {
  padding-bottom: 10px;
}

.padding-lg-15b {
  padding-bottom: 15px;
}

.padding-lg-20b {
  padding-bottom: 20px;
}

.padding-lg-25b {
  padding-bottom: 25px;
}

.padding-lg-30b {
  padding-bottom: 30px;
}

.padding-lg-35b {
  padding-bottom: 35px;
}

.padding-lg-40b {
  padding-bottom: 40px;
}

.padding-lg-45b {
  padding-bottom: 45px;
}

.padding-lg-50b {
  padding-bottom: 50px;
}

.padding-lg-55b {
  padding-bottom: 55px;
}

.padding-lg-60b {
  padding-bottom: 60px;
}

.padding-lg-65b {
  padding-bottom: 65px;
}

.padding-lg-70b {
  padding-bottom: 70px;
}

.padding-lg-75b {
  padding-bottom: 75px;
}

.padding-lg-80b {
  padding-bottom: 80px;
}

.padding-lg-85b {
  padding-bottom: 85px;
}

.padding-lg-90b {
  padding-bottom: 90px;
}

.padding-lg-95b {
  padding-bottom: 95px;
}

.padding-lg-100b {
  padding-bottom: 100px;
}

.padding-lg-105b {
  padding-bottom: 105px;
}

.padding-lg-110b {
  padding-bottom: 110px;
}

.padding-lg-115b {
  padding-bottom: 115px;
}

.padding-lg-120b {
  padding-bottom: 120px;
}

.padding-lg-125b {
  padding-bottom: 125px;
}

.padding-lg-130b {
  padding-bottom: 130px;
}

.padding-lg-135b {
  padding-bottom: 135px;
}

.padding-lg-140b {
  padding-bottom: 140px;
}

.padding-lg-145b {
  padding-bottom: 145px;
}

.padding-lg-150b {
  padding-bottom: 150px;
}

.padding-lg-155b {
  padding-bottom: 155px;
}

.padding-lg-160b {
  padding-bottom: 160px;
}

.padding-lg-165b {
  padding-bottom: 165px;
}

.padding-lg-170b {
  padding-bottom: 170px;
}

.padding-lg-175b {
  padding-bottom: 175px;
}

.padding-lg-180b {
  padding-bottom: 180px;
}

.padding-lg-185b {
  padding-bottom: 185px;
}

.padding-lg-190b {
  padding-bottom: 190px;
}

.padding-lg-195b {
  padding-bottom: 195px;
}

.padding-lg-200b {
  padding-bottom: 200px;
}

@media screen and (max-width: 1199px) {
  .margin-md-0t {
    margin-top: 0px;
  }
  .margin-md-5t {
    margin-top: 5px;
  }
  .margin-md-10t {
    margin-top: 10px;
  }
  .margin-md-15t {
    margin-top: 15px;
  }
  .margin-md-20t {
    margin-top: 20px;
  }
  .margin-md-25t {
    margin-top: 25px;
  }
  .margin-md-30t {
    margin-top: 30px;
  }
  .margin-md-35t {
    margin-top: 35px;
  }
  .margin-md-40t {
    margin-top: 40px;
  }
  .margin-md-45t {
    margin-top: 45px;
  }
  .margin-md-50t {
    margin-top: 50px;
  }
  .margin-md-55t {
    margin-top: 55px;
  }
  .margin-md-60t {
    margin-top: 60px;
  }
  .margin-md-65t {
    margin-top: 65px;
  }
  .margin-md-70t {
    margin-top: 70px;
  }
  .margin-md-75t {
    margin-top: 75px;
  }
  .margin-md-80t {
    margin-top: 80px;
  }
  .margin-md-85t {
    margin-top: 85px;
  }
  .margin-md-90t {
    margin-top: 90px;
  }
  .margin-md-95t {
    margin-top: 95px;
  }
  .margin-md-100t {
    margin-top: 100px;
  }
  .margin-md-105t {
    margin-top: 105px;
  }
  .margin-md-110t {
    margin-top: 110px;
  }
  .margin-md-115t {
    margin-top: 115px;
  }
  .margin-md-120t {
    margin-top: 120px;
  }
  .margin-md-125t {
    margin-top: 125px;
  }
  .margin-md-130t {
    margin-top: 130px;
  }
  .margin-md-135t {
    margin-top: 135px;
  }
  .margin-md-140t {
    margin-top: 140px;
  }
  .margin-md-145t {
    margin-top: 145px;
  }
  .margin-md-150t {
    margin-top: 150px;
  }
  .margin-md-155t {
    margin-top: 155px;
  }
  .margin-md-160t {
    margin-top: 160px;
  }
  .margin-md-165t {
    margin-top: 165px;
  }
  .margin-md-170t {
    margin-top: 170px;
  }
  .margin-md-175t {
    margin-top: 175px;
  }
  .margin-md-180t {
    margin-top: 180px;
  }
  .margin-md-185t {
    margin-top: 185px;
  }
  .margin-md-190t {
    margin-top: 190px;
  }
  .margin-md-195t {
    margin-top: 195px;
  }
  .margin-md-200t {
    margin-top: 200px;
  }
  .margin-md-0b {
    margin-bottom: 0px;
  }
  .margin-md-5b {
    margin-bottom: 5px;
  }
  .margin-md-10b {
    margin-bottom: 10px;
  }
  .margin-md-15b {
    margin-bottom: 15px;
  }
  .margin-md-20b {
    margin-bottom: 20px;
  }
  .margin-md-25b {
    margin-bottom: 25px;
  }
  .margin-md-30b {
    margin-bottom: 30px;
  }
  .margin-md-35b {
    margin-bottom: 35px;
  }
  .margin-md-40b {
    margin-bottom: 40px;
  }
  .margin-md-45b {
    margin-bottom: 45px;
  }
  .margin-md-50b {
    margin-bottom: 50px;
  }
  .margin-md-55b {
    margin-bottom: 55px;
  }
  .margin-md-60b {
    margin-bottom: 60px;
  }
  .margin-md-65b {
    margin-bottom: 65px;
  }
  .margin-md-70b {
    margin-bottom: 70px;
  }
  .margin-md-75b {
    margin-bottom: 75px;
  }
  .margin-md-80b {
    margin-bottom: 80px;
  }
  .margin-md-85b {
    margin-bottom: 85px;
  }
  .margin-md-90b {
    margin-bottom: 90px;
  }
  .margin-md-95b {
    margin-bottom: 95px;
  }
  .margin-md-100b {
    margin-bottom: 100px;
  }
  .margin-md-105b {
    margin-bottom: 105px;
  }
  .margin-md-110b {
    margin-bottom: 110px;
  }
  .margin-md-115b {
    margin-bottom: 115px;
  }
  .margin-md-120b {
    margin-bottom: 120px;
  }
  .margin-md-125b {
    margin-bottom: 125px;
  }
  .margin-md-130b {
    margin-bottom: 130px;
  }
  .margin-md-135b {
    margin-bottom: 135px;
  }
  .margin-md-140b {
    margin-bottom: 140px;
  }
  .margin-md-145b {
    margin-bottom: 145px;
  }
  .margin-md-150b {
    margin-bottom: 150px;
  }
  .margin-md-155b {
    margin-bottom: 155px;
  }
  .margin-md-160b {
    margin-bottom: 160px;
  }
  .margin-md-165b {
    margin-bottom: 165px;
  }
  .margin-md-170b {
    margin-bottom: 170px;
  }
  .margin-md-175b {
    margin-bottom: 175px;
  }
  .margin-md-180b {
    margin-bottom: 180px;
  }
  .margin-md-185b {
    margin-bottom: 185px;
  }
  .margin-md-190b {
    margin-bottom: 190px;
  }
  .margin-md-195b {
    margin-bottom: 195px;
  }
  .margin-md-200b {
    margin-bottom: 200px;
  }
  .padding-md-0t {
    padding-top: 0px;
  }
  .padding-md-5t {
    padding-top: 5px;
  }
  .padding-md-10t {
    padding-top: 10px;
  }
  .padding-md-15t {
    padding-top: 15px;
  }
  .padding-md-20t {
    padding-top: 20px;
  }
  .padding-md-25t {
    padding-top: 25px;
  }
  .padding-md-30t {
    padding-top: 30px;
  }
  .padding-md-35t {
    padding-top: 35px;
  }
  .padding-md-40t {
    padding-top: 40px;
  }
  .padding-md-45t {
    padding-top: 45px;
  }
  .padding-md-50t {
    padding-top: 50px;
  }
  .padding-md-55t {
    padding-top: 55px;
  }
  .padding-md-60t {
    padding-top: 60px;
  }
  .padding-md-65t {
    padding-top: 65px;
  }
  .padding-md-70t {
    padding-top: 70px;
  }
  .padding-md-75t {
    padding-top: 75px;
  }
  .padding-md-80t {
    padding-top: 80px;
  }
  .padding-md-85t {
    padding-top: 85px;
  }
  .padding-md-90t {
    padding-top: 90px;
  }
  .padding-md-95t {
    padding-top: 95px;
  }
  .padding-md-100t {
    padding-top: 100px;
  }
  .padding-md-105t {
    padding-top: 105px;
  }
  .padding-md-110t {
    padding-top: 110px;
  }
  .padding-md-115t {
    padding-top: 115px;
  }
  .padding-md-120t {
    padding-top: 120px;
  }
  .padding-md-125t {
    padding-top: 125px;
  }
  .padding-md-130t {
    padding-top: 130px;
  }
  .padding-md-135t {
    padding-top: 135px;
  }
  .padding-md-140t {
    padding-top: 140px;
  }
  .padding-md-145t {
    padding-top: 145px;
  }
  .padding-md-150t {
    padding-top: 150px;
  }
  .padding-md-155t {
    padding-top: 155px;
  }
  .padding-md-160t {
    padding-top: 160px;
  }
  .padding-md-165t {
    padding-top: 165px;
  }
  .padding-md-170t {
    padding-top: 170px;
  }
  .padding-md-175t {
    padding-top: 175px;
  }
  .padding-md-180t {
    padding-top: 180px;
  }
  .padding-md-185t {
    padding-top: 185px;
  }
  .padding-md-190t {
    padding-top: 190px;
  }
  .padding-md-195t {
    padding-top: 195px;
  }
  .padding-md-200t {
    padding-top: 200px;
  }
  .padding-md-0b {
    padding-bottom: 0px;
  }
  .padding-md-5b {
    padding-bottom: 5px;
  }
  .padding-md-10b {
    padding-bottom: 10px;
  }
  .padding-md-15b {
    padding-bottom: 15px;
  }
  .padding-md-20b {
    padding-bottom: 20px;
  }
  .padding-md-25b {
    padding-bottom: 25px;
  }
  .padding-md-30b {
    padding-bottom: 30px;
  }
  .padding-md-35b {
    padding-bottom: 35px;
  }
  .padding-md-40b {
    padding-bottom: 40px;
  }
  .padding-md-45b {
    padding-bottom: 45px;
  }
  .padding-md-50b {
    padding-bottom: 50px;
  }
  .padding-md-55b {
    padding-bottom: 55px;
  }
  .padding-md-60b {
    padding-bottom: 60px;
  }
  .padding-md-65b {
    padding-bottom: 65px;
  }
  .padding-md-70b {
    padding-bottom: 70px;
  }
  .padding-md-75b {
    padding-bottom: 75px;
  }
  .padding-md-80b {
    padding-bottom: 80px;
  }
  .padding-md-85b {
    padding-bottom: 85px;
  }
  .padding-md-90b {
    padding-bottom: 90px;
  }
  .padding-md-95b {
    padding-bottom: 95px;
  }
  .padding-md-100b {
    padding-bottom: 100px;
  }
  .padding-md-105b {
    padding-bottom: 105px;
  }
  .padding-md-110b {
    padding-bottom: 110px;
  }
  .padding-md-115b {
    padding-bottom: 115px;
  }
  .padding-md-120b {
    padding-bottom: 120px;
  }
  .padding-md-125b {
    padding-bottom: 125px;
  }
  .padding-md-130b {
    padding-bottom: 130px;
  }
  .padding-md-135b {
    padding-bottom: 135px;
  }
  .padding-md-140b {
    padding-bottom: 140px;
  }
  .padding-md-145b {
    padding-bottom: 145px;
  }
  .padding-md-150b {
    padding-bottom: 150px;
  }
  .padding-md-155b {
    padding-bottom: 155px;
  }
  .padding-md-160b {
    padding-bottom: 160px;
  }
  .padding-md-165b {
    padding-bottom: 165px;
  }
  .padding-md-170b {
    padding-bottom: 170px;
  }
  .padding-md-175b {
    padding-bottom: 175px;
  }
  .padding-md-180b {
    padding-bottom: 180px;
  }
  .padding-md-185b {
    padding-bottom: 185px;
  }
  .padding-md-190b {
    padding-bottom: 190px;
  }
  .padding-md-195b {
    padding-bottom: 195px;
  }
  .padding-md-200b {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 991px) {
  .margin-sm-0t {
    margin-top: 0px;
  }
  .margin-sm-5t {
    margin-top: 5px;
  }
  .margin-sm-10t {
    margin-top: 10px;
  }
  .margin-sm-15t {
    margin-top: 15px;
  }
  .margin-sm-20t {
    margin-top: 20px;
  }
  .margin-sm-25t {
    margin-top: 25px;
  }
  .margin-sm-30t {
    margin-top: 30px;
  }
  .margin-sm-35t {
    margin-top: 35px;
  }
  .margin-sm-40t {
    margin-top: 40px;
  }
  .margin-sm-45t {
    margin-top: 45px;
  }
  .margin-sm-50t {
    margin-top: 50px;
  }
  .margin-sm-55t {
    margin-top: 55px;
  }
  .margin-sm-60t {
    margin-top: 60px;
  }
  .margin-sm-65t {
    margin-top: 65px;
  }
  .margin-sm-70t {
    margin-top: 70px;
  }
  .margin-sm-75t {
    margin-top: 75px;
  }
  .margin-sm-80t {
    margin-top: 80px;
  }
  .margin-sm-85t {
    margin-top: 85px;
  }
  .margin-sm-90t {
    margin-top: 90px;
  }
  .margin-sm-95t {
    margin-top: 95px;
  }
  .margin-sm-100t {
    margin-top: 100px;
  }
  .margin-sm-105t {
    margin-top: 105px;
  }
  .margin-sm-110t {
    margin-top: 110px;
  }
  .margin-sm-115t {
    margin-top: 115px;
  }
  .margin-sm-120t {
    margin-top: 120px;
  }
  .margin-sm-125t {
    margin-top: 125px;
  }
  .margin-sm-130t {
    margin-top: 130px;
  }
  .margin-sm-135t {
    margin-top: 135px;
  }
  .margin-sm-140t {
    margin-top: 140px;
  }
  .margin-sm-145t {
    margin-top: 145px;
  }
  .margin-sm-150t {
    margin-top: 150px;
  }
  .margin-sm-155t {
    margin-top: 155px;
  }
  .margin-sm-160t {
    margin-top: 160px;
  }
  .margin-sm-165t {
    margin-top: 165px;
  }
  .margin-sm-170t {
    margin-top: 170px;
  }
  .margin-sm-175t {
    margin-top: 175px;
  }
  .margin-sm-180t {
    margin-top: 180px;
  }
  .margin-sm-185t {
    margin-top: 185px;
  }
  .margin-sm-190t {
    margin-top: 190px;
  }
  .margin-sm-195t {
    margin-top: 195px;
  }
  .margin-sm-200t {
    margin-top: 200px;
  }
  .margin-sm-0b {
    margin-bottom: 0px;
  }
  .margin-sm-5b {
    margin-bottom: 5px;
  }
  .margin-sm-10b {
    margin-bottom: 10px;
  }
  .margin-sm-15b {
    margin-bottom: 15px;
  }
  .margin-sm-20b {
    margin-bottom: 20px;
  }
  .margin-sm-25b {
    margin-bottom: 25px;
  }
  .margin-sm-30b {
    margin-bottom: 30px;
  }
  .margin-sm-35b {
    margin-bottom: 35px;
  }
  .margin-sm-40b {
    margin-bottom: 40px;
  }
  .margin-sm-45b {
    margin-bottom: 45px;
  }
  .margin-sm-50b {
    margin-bottom: 50px;
  }
  .margin-sm-55b {
    margin-bottom: 55px;
  }
  .margin-sm-60b {
    margin-bottom: 60px;
  }
  .margin-sm-65b {
    margin-bottom: 65px;
  }
  .margin-sm-70b {
    margin-bottom: 70px;
  }
  .margin-sm-75b {
    margin-bottom: 75px;
  }
  .margin-sm-80b {
    margin-bottom: 80px;
  }
  .margin-sm-85b {
    margin-bottom: 85px;
  }
  .margin-sm-90b {
    margin-bottom: 90px;
  }
  .margin-sm-95b {
    margin-bottom: 95px;
  }
  .margin-sm-100b {
    margin-bottom: 100px;
  }
  .margin-sm-105b {
    margin-bottom: 105px;
  }
  .margin-sm-110b {
    margin-bottom: 110px;
  }
  .margin-sm-115b {
    margin-bottom: 115px;
  }
  .margin-sm-120b {
    margin-bottom: 120px;
  }
  .margin-sm-125b {
    margin-bottom: 125px;
  }
  .margin-sm-130b {
    margin-bottom: 130px;
  }
  .margin-sm-135b {
    margin-bottom: 135px;
  }
  .margin-sm-140b {
    margin-bottom: 140px;
  }
  .margin-sm-145b {
    margin-bottom: 145px;
  }
  .margin-sm-150b {
    margin-bottom: 150px;
  }
  .margin-sm-155b {
    margin-bottom: 155px;
  }
  .margin-sm-160b {
    margin-bottom: 160px;
  }
  .margin-sm-165b {
    margin-bottom: 165px;
  }
  .margin-sm-170b {
    margin-bottom: 170px;
  }
  .margin-sm-175b {
    margin-bottom: 175px;
  }
  .margin-sm-180b {
    margin-bottom: 180px;
  }
  .margin-sm-185b {
    margin-bottom: 185px;
  }
  .margin-sm-190b {
    margin-bottom: 190px;
  }
  .margin-sm-195b {
    margin-bottom: 195px;
  }
  .margin-sm-200b {
    margin-bottom: 200px;
  }
  .padding-sm-0t {
    padding-top: 0px;
  }
  .padding-sm-5t {
    padding-top: 5px;
  }
  .padding-sm-10t {
    padding-top: 10px;
  }
  .padding-sm-15t {
    padding-top: 15px;
  }
  .padding-sm-20t {
    padding-top: 20px;
  }
  .padding-sm-25t {
    padding-top: 25px;
  }
  .padding-sm-30t {
    padding-top: 30px;
  }
  .padding-sm-35t {
    padding-top: 35px;
  }
  .padding-sm-40t {
    padding-top: 40px;
  }
  .padding-sm-45t {
    padding-top: 45px;
  }
  .padding-sm-50t {
    padding-top: 50px;
  }
  .padding-sm-55t {
    padding-top: 55px;
  }
  .padding-sm-60t {
    padding-top: 60px;
  }
  .padding-sm-65t {
    padding-top: 65px;
  }
  .padding-sm-70t {
    padding-top: 70px;
  }
  .padding-sm-75t {
    padding-top: 75px;
  }
  .padding-sm-80t {
    padding-top: 80px;
  }
  .padding-sm-85t {
    padding-top: 85px;
  }
  .padding-sm-90t {
    padding-top: 90px;
  }
  .padding-sm-95t {
    padding-top: 95px;
  }
  .padding-sm-100t {
    padding-top: 100px;
  }
  .padding-sm-105t {
    padding-top: 105px;
  }
  .padding-sm-110t {
    padding-top: 110px;
  }
  .padding-sm-115t {
    padding-top: 115px;
  }
  .padding-sm-120t {
    padding-top: 120px;
  }
  .padding-sm-125t {
    padding-top: 125px;
  }
  .padding-sm-130t {
    padding-top: 130px;
  }
  .padding-sm-135t {
    padding-top: 135px;
  }
  .padding-sm-140t {
    padding-top: 140px;
  }
  .padding-sm-145t {
    padding-top: 145px;
  }
  .padding-sm-150t {
    padding-top: 150px;
  }
  .padding-sm-155t {
    padding-top: 155px;
  }
  .padding-sm-160t {
    padding-top: 160px;
  }
  .padding-sm-165t {
    padding-top: 165px;
  }
  .padding-sm-170t {
    padding-top: 170px;
  }
  .padding-sm-175t {
    padding-top: 175px;
  }
  .padding-sm-180t {
    padding-top: 180px;
  }
  .padding-sm-185t {
    padding-top: 185px;
  }
  .padding-sm-190t {
    padding-top: 190px;
  }
  .padding-sm-195t {
    padding-top: 195px;
  }
  .padding-sm-200t {
    padding-top: 200px;
  }
  .padding-sm-0b {
    padding-bottom: 0px;
  }
  .padding-sm-5b {
    padding-bottom: 5px;
  }
  .padding-sm-10b {
    padding-bottom: 10px;
  }
  .padding-sm-15b {
    padding-bottom: 15px;
  }
  .padding-sm-20b {
    padding-bottom: 20px;
  }
  .padding-sm-25b {
    padding-bottom: 25px;
  }
  .padding-sm-30b {
    padding-bottom: 30px;
  }
  .padding-sm-35b {
    padding-bottom: 35px;
  }
  .padding-sm-40b {
    padding-bottom: 40px;
  }
  .padding-sm-45b {
    padding-bottom: 45px;
  }
  .padding-sm-50b {
    padding-bottom: 50px;
  }
  .padding-sm-55b {
    padding-bottom: 55px;
  }
  .padding-sm-60b {
    padding-bottom: 60px;
  }
  .padding-sm-65b {
    padding-bottom: 65px;
  }
  .padding-sm-70b {
    padding-bottom: 70px;
  }
  .padding-sm-75b {
    padding-bottom: 75px;
  }
  .padding-sm-80b {
    padding-bottom: 80px;
  }
  .padding-sm-85b {
    padding-bottom: 85px;
  }
  .padding-sm-90b {
    padding-bottom: 90px;
  }
  .padding-sm-95b {
    padding-bottom: 95px;
  }
  .padding-sm-100b {
    padding-bottom: 100px;
  }
  .padding-sm-105b {
    padding-bottom: 105px;
  }
  .padding-sm-110b {
    padding-bottom: 110px;
  }
  .padding-sm-115b {
    padding-bottom: 115px;
  }
  .padding-sm-120b {
    padding-bottom: 120px;
  }
  .padding-sm-125b {
    padding-bottom: 125px;
  }
  .padding-sm-130b {
    padding-bottom: 130px;
  }
  .padding-sm-135b {
    padding-bottom: 135px;
  }
  .padding-sm-140b {
    padding-bottom: 140px;
  }
  .padding-sm-145b {
    padding-bottom: 145px;
  }
  .padding-sm-150b {
    padding-bottom: 150px;
  }
  .padding-sm-155b {
    padding-bottom: 155px;
  }
  .padding-sm-160b {
    padding-bottom: 160px;
  }
  .padding-sm-165b {
    padding-bottom: 165px;
  }
  .padding-sm-170b {
    padding-bottom: 170px;
  }
  .padding-sm-175b {
    padding-bottom: 175px;
  }
  .padding-sm-180b {
    padding-bottom: 180px;
  }
  .padding-sm-185b {
    padding-bottom: 185px;
  }
  .padding-sm-190b {
    padding-bottom: 190px;
  }
  .padding-sm-195b {
    padding-bottom: 195px;
  }
  .padding-sm-200b {
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 767px) {
  .margin-xs-0t {
    margin-top: 0px;
  }
  .margin-xs-5t {
    margin-top: 5px;
  }
  .margin-xs-10t {
    margin-top: 10px;
  }
  .margin-xs-15t {
    margin-top: 15px;
  }
  .margin-xs-20t {
    margin-top: 20px;
  }
  .margin-xs-25t {
    margin-top: 25px;
  }
  .margin-xs-30t {
    margin-top: 30px;
  }
  .margin-xs-35t {
    margin-top: 35px;
  }
  .margin-xs-40t {
    margin-top: 40px;
  }
  .margin-xs-45t {
    margin-top: 45px;
  }
  .margin-xs-50t {
    margin-top: 50px;
  }
  .margin-xs-55t {
    margin-top: 55px;
  }
  .margin-xs-60t {
    margin-top: 60px;
  }
  .margin-xs-65t {
    margin-top: 65px;
  }
  .margin-xs-70t {
    margin-top: 70px;
  }
  .margin-xs-75t {
    margin-top: 75px;
  }
  .margin-xs-80t {
    margin-top: 80px;
  }
  .margin-xs-85t {
    margin-top: 85px;
  }
  .margin-xs-90t {
    margin-top: 90px;
  }
  .margin-xs-95t {
    margin-top: 95px;
  }
  .margin-xs-100t {
    margin-top: 100px;
  }
  .margin-xs-105t {
    margin-top: 105px;
  }
  .margin-xs-110t {
    margin-top: 110px;
  }
  .margin-xs-115t {
    margin-top: 115px;
  }
  .margin-xs-120t {
    margin-top: 120px;
  }
  .margin-xs-125t {
    margin-top: 125px;
  }
  .margin-xs-130t {
    margin-top: 130px;
  }
  .margin-xs-135t {
    margin-top: 135px;
  }
  .margin-xs-140t {
    margin-top: 140px;
  }
  .margin-xs-145t {
    margin-top: 145px;
  }
  .margin-xs-150t {
    margin-top: 150px;
  }
  .margin-xs-155t {
    margin-top: 155px;
  }
  .margin-xs-160t {
    margin-top: 160px;
  }
  .margin-xs-165t {
    margin-top: 165px;
  }
  .margin-xs-170t {
    margin-top: 170px;
  }
  .margin-xs-175t {
    margin-top: 175px;
  }
  .margin-xs-180t {
    margin-top: 180px;
  }
  .margin-xs-185t {
    margin-top: 185px;
  }
  .margin-xs-190t {
    margin-top: 190px;
  }
  .margin-xs-195t {
    margin-top: 195px;
  }
  .margin-xs-200t {
    margin-top: 200px;
  }
  .margin-xs-0b {
    margin-bottom: 0px;
  }
  .margin-xs-5b {
    margin-bottom: 5px;
  }
  .margin-xs-10b {
    margin-bottom: 10px;
  }
  .margin-xs-15b {
    margin-bottom: 15px;
  }
  .margin-xs-20b {
    margin-bottom: 20px;
  }
  .margin-xs-25b {
    margin-bottom: 25px;
  }
  .margin-xs-30b {
    margin-bottom: 30px;
  }
  .margin-xs-35b {
    margin-bottom: 35px;
  }
  .margin-xs-40b {
    margin-bottom: 40px;
  }
  .margin-xs-45b {
    margin-bottom: 45px;
  }
  .margin-xs-50b {
    margin-bottom: 50px;
  }
  .margin-xs-55b {
    margin-bottom: 55px;
  }
  .margin-xs-60b {
    margin-bottom: 60px;
  }
  .margin-xs-65b {
    margin-bottom: 65px;
  }
  .margin-xs-70b {
    margin-bottom: 70px;
  }
  .margin-xs-75b {
    margin-bottom: 75px;
  }
  .margin-xs-80b {
    margin-bottom: 80px;
  }
  .margin-xs-85b {
    margin-bottom: 85px;
  }
  .margin-xs-90b {
    margin-bottom: 90px;
  }
  .margin-xs-95b {
    margin-bottom: 95px;
  }
  .margin-xs-100b {
    margin-bottom: 100px;
  }
  .margin-xs-105b {
    margin-bottom: 105px;
  }
  .margin-xs-110b {
    margin-bottom: 110px;
  }
  .margin-xs-115b {
    margin-bottom: 115px;
  }
  .margin-xs-120b {
    margin-bottom: 120px;
  }
  .margin-xs-125b {
    margin-bottom: 125px;
  }
  .margin-xs-130b {
    margin-bottom: 130px;
  }
  .margin-xs-135b {
    margin-bottom: 135px;
  }
  .margin-xs-140b {
    margin-bottom: 140px;
  }
  .margin-xs-145b {
    margin-bottom: 145px;
  }
  .margin-xs-150b {
    margin-bottom: 150px;
  }
  .margin-xs-155b {
    margin-bottom: 155px;
  }
  .margin-xs-160b {
    margin-bottom: 160px;
  }
  .margin-xs-165b {
    margin-bottom: 165px;
  }
  .margin-xs-170b {
    margin-bottom: 170px;
  }
  .margin-xs-175b {
    margin-bottom: 175px;
  }
  .margin-xs-180b {
    margin-bottom: 180px;
  }
  .margin-xs-185b {
    margin-bottom: 185px;
  }
  .margin-xs-190b {
    margin-bottom: 190px;
  }
  .margin-xs-195b {
    margin-bottom: 195px;
  }
  .margin-xs-200b {
    margin-bottom: 200px;
  }
  .padding-xs-0t {
    padding-top: 0px;
  }
  .padding-xs-5t {
    padding-top: 5px;
  }
  .padding-xs-10t {
    padding-top: 10px;
  }
  .padding-xs-15t {
    padding-top: 15px;
  }
  .padding-xs-20t {
    padding-top: 20px;
  }
  .padding-xs-25t {
    padding-top: 25px;
  }
  .padding-xs-30t {
    padding-top: 30px;
  }
  .padding-xs-35t {
    padding-top: 35px;
  }
  .padding-xs-40t {
    padding-top: 40px;
  }
  .padding-xs-45t {
    padding-top: 45px;
  }
  .padding-xs-50t {
    padding-top: 50px;
  }
  .padding-xs-55t {
    padding-top: 55px;
  }
  .padding-xs-60t {
    padding-top: 60px;
  }
  .padding-xs-65t {
    padding-top: 65px;
  }
  .padding-xs-70t {
    padding-top: 70px;
  }
  .padding-xs-75t {
    padding-top: 75px;
  }
  .padding-xs-80t {
    padding-top: 80px;
  }
  .padding-xs-85t {
    padding-top: 85px;
  }
  .padding-xs-90t {
    padding-top: 90px;
  }
  .padding-xs-95t {
    padding-top: 95px;
  }
  .padding-xs-100t {
    padding-top: 100px;
  }
  .padding-xs-105t {
    padding-top: 105px;
  }
  .padding-xs-110t {
    padding-top: 110px;
  }
  .padding-xs-115t {
    padding-top: 115px;
  }
  .padding-xs-120t {
    padding-top: 120px;
  }
  .padding-xs-125t {
    padding-top: 125px;
  }
  .padding-xs-130t {
    padding-top: 130px;
  }
  .padding-xs-135t {
    padding-top: 135px;
  }
  .padding-xs-140t {
    padding-top: 140px;
  }
  .padding-xs-145t {
    padding-top: 145px;
  }
  .padding-xs-150t {
    padding-top: 150px;
  }
  .padding-xs-155t {
    padding-top: 155px;
  }
  .padding-xs-160t {
    padding-top: 160px;
  }
  .padding-xs-165t {
    padding-top: 165px;
  }
  .padding-xs-170t {
    padding-top: 170px;
  }
  .padding-xs-175t {
    padding-top: 175px;
  }
  .padding-xs-180t {
    padding-top: 180px;
  }
  .padding-xs-185t {
    padding-top: 185px;
  }
  .padding-xs-190t {
    padding-top: 190px;
  }
  .padding-xs-195t {
    padding-top: 195px;
  }
  .padding-xs-200t {
    padding-top: 200px;
  }
  .padding-xs-0b {
    padding-bottom: 0px;
  }
  .padding-xs-5b {
    padding-bottom: 5px;
  }
  .padding-xs-10b {
    padding-bottom: 10px;
  }
  .padding-xs-15b {
    padding-bottom: 15px;
  }
  .padding-xs-20b {
    padding-bottom: 20px;
  }
  .padding-xs-25b {
    padding-bottom: 25px;
  }
  .padding-xs-30b {
    padding-bottom: 30px;
  }
  .padding-xs-35b {
    padding-bottom: 35px;
  }
  .padding-xs-40b {
    padding-bottom: 40px;
  }
  .padding-xs-45b {
    padding-bottom: 45px;
  }
  .padding-xs-50b {
    padding-bottom: 50px;
  }
  .padding-xs-55b {
    padding-bottom: 55px;
  }
  .padding-xs-60b {
    padding-bottom: 60px;
  }
  .padding-xs-65b {
    padding-bottom: 65px;
  }
  .padding-xs-70b {
    padding-bottom: 70px;
  }
  .padding-xs-75b {
    padding-bottom: 75px;
  }
  .padding-xs-80b {
    padding-bottom: 80px;
  }
  .padding-xs-85b {
    padding-bottom: 85px;
  }
  .padding-xs-90b {
    padding-bottom: 90px;
  }
  .padding-xs-95b {
    padding-bottom: 95px;
  }
  .padding-xs-100b {
    padding-bottom: 100px;
  }
  .padding-xs-105b {
    padding-bottom: 105px;
  }
  .padding-xs-110b {
    padding-bottom: 110px;
  }
  .padding-xs-115b {
    padding-bottom: 115px;
  }
  .padding-xs-120b {
    padding-bottom: 120px;
  }
  .padding-xs-125b {
    padding-bottom: 125px;
  }
  .padding-xs-130b {
    padding-bottom: 130px;
  }
  .padding-xs-135b {
    padding-bottom: 135px;
  }
  .padding-xs-140b {
    padding-bottom: 140px;
  }
  .padding-xs-145b {
    padding-bottom: 145px;
  }
  .padding-xs-150b {
    padding-bottom: 150px;
  }
  .padding-xs-155b {
    padding-bottom: 155px;
  }
  .padding-xs-160b {
    padding-bottom: 160px;
  }
  .padding-xs-165b {
    padding-bottom: 165px;
  }
  .padding-xs-170b {
    padding-bottom: 170px;
  }
  .padding-xs-175b {
    padding-bottom: 175px;
  }
  .padding-xs-180b {
    padding-bottom: 180px;
  }
  .padding-xs-185b {
    padding-bottom: 185px;
  }
  .padding-xs-190b {
    padding-bottom: 190px;
  }
  .padding-xs-195b {
    padding-bottom: 195px;
  }
  .padding-xs-200b {
    padding-bottom: 200px;
  }
}

@media (max-width: 768px) {
  .vc_row[data-vc-full-width] {
    padding: 0;
  }
}

/*---------------------------------------------------------------*/
