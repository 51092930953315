.enable_overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20;
}

.top-banner {
  position: relative;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  transition: none;
  -webkit-transform: none;
  -ms-transform: none;
      transform: none;
  background-attachment: scroll;
  background-position: center top;
  padding-left: 0;
  padding-right: 0;
}

.top-banner .fix-inner {
  height: 690px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  background-repeat: no-repeat;
  background-position: center;
  z-index: -2;
}

.top-banner .overlay {
  position: absolute;
  top: 0; 
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 20;
}

@media (max-width: 992px) {
  .top-banner .fix-inner {
    height: 100vh;
  }
}

@media (max-width: 480px) {
  .top-banner {
    text-align: center;
  }
}

.top-banner .subtitle {
  font-size: 12px;
  line-height: 20px;
  font-family: "Montserrat", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 2.4px;
  margin-bottom: 40px;
  padding-top: 150px;
  -webkit-font-smoothing: antialiased;
  @extend %base-color;
}

.top-banner.left_content .subtitle {
  color: #c0af94;
  @extend %subtitle-color;
}

.top-banner.center_content .subtitle {
  margin-bottom: 31px;
  color: #c0af94;
  @extend %subtitle-color;
}
@media (max-width: 480px) {
  .top-banner .subtitle {
    padding-top: 30px;
  }
}

.top-banner .title {
  font-size: 70px;
  font-family: "BebasNeueBook";
  color: #ffffff;
  font-weight: 300;
  letter-spacing: 5.6px;
  margin-bottom: 40px;
  @extend %base-color;
}

.top-banner.center_content .title {
  margin-bottom: 25px;
  color: #131313;
  @extend %base-color;
}

@media (max-width: 767px) {
  .top-banner .title {
    font-size: 40px;
    line-height: 50px;
  }
}

.top-banner .descr {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.12px;
  max-width: 510px;
  @extend %base-color;
}
.top-banner.center_content .descr {
  margin-left: auto;
  margin-right: auto;
  max-width: 970px;
  color: #888888;
  @extend %desc-color;
}
.vertical-align {
  position: absolute;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
      transform: translateY(-50%);
}

body .top-banner .a-btn {
  margin-top: 30px ;
}

/*Top banner full height*/
.top-banner.full-height .container .subtitle {
  padding-top: 0;
}

/* 09. BANNER WITH GALLERY
------------------------*/
.banner-gallery {
  position: relative;
}

.banner-gallery .content-wrap {
  position: absolute;
  background-color: #ffffff;
  @extend %main-bg-color;
}

.banner-gallery .content-wrap .content {
  left: 0;
  padding: 15px 70px;
}

.banner-gallery .content-wrap .title {
  font-size: 60px;
  font-family: "BebasNeueBook", sans-serif;
  color: #131313;
  font-weight: 400;
  line-height: 80px;
  letter-spacing: 5.6px;
  margin-bottom: 20px;
  @extend %base-color;
}

.banner-gallery .content-wrap .description {
  font-size: 14px;
  line-height: 28px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  max-width: 430px;
  margin: auto;
  letter-spacing: 1.12px;
  @extend %desc-color;
}

.banner-gallery .content-wrap .button {
  margin-left: auto;
  margin-top: 31px;
}

body .banner-list {
  display: block;
  width: 100%;
  margin: 0  ;
}

.banner-list:before,
.banner-list:after {
  content: '';
  display: table;
}

.banner-list li {
  -webkit-perspective: 400px;
  -o-perspective: 400px;
  -ms-perspective: 400px;
  perspective: 400px;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
  @extend %main-bg-color;
}

.banner-list li span {
  display: block;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-size: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  box-sizing: content-box;
}