/*Gridrotator - Fallback css*/

.ri-grid {
  width: 600px;
}

.ri-grid ul li,
.ri-grid ul li a {
  width: 100px;
  height: 100px;
}

.ri-grid ul li a img {
  width: 100%;
}

.ri-grid {
  margin: 30px auto 30px;
  position: relative;
  height: auto;
}

.ri-grid ul {
  list-style: none;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
}


/* Clear floats by Nicolas Gallagher: http://nicolasgallagher.com/micro-clearfix-hack/ */

.ri-grid ul:before,
.ri-grid ul:after {
  content: '';
  display: table;
}

.ri-grid ul:after {
  clear: both;
}

.ri-grid ul {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */
}

.ri-grid ul li {
  -webkit-perspective: 400px;
  -o-perspective: 400px;
  -ms-perspective: 400px;
  perspective: 400px;
  margin: 0;
  padding: 0;
  float: left;
  position: relative;
  display: block;
  overflow: hidden;
  background: #000;
}

.ri-grid ul li a {
  display: block;
  outline: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #333;
  box-sizing: content-box;
}


/* Grid wrapper sizes */

.ri-grid-size-1 {
  width: 55%;
}

.ri-grid-size-2 {
  width: 100%;
}

.ri-grid-size-3 {
  width: 100%;
  margin-top: 0px;
}


/* Shadow style */

.ri-shadow:after,
.ri-shadow:before {
  content: "";
  position: absolute;
  z-index: -2;
  bottom: 15px;
  left: 10px;
  width: 50%;
  height: 20%;
  max-width: 300px;
  max-height: 100px;
  box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
  -webkit-transform: rotate(-3deg);
  -ms-transform: rotate(-3deg);
  transform: rotate(-3deg);
}

.ri-shadow:after {
  right: 10px;
  left: auto;
  -webkit-transform: rotate(3deg);
  -ms-transform: rotate(3deg);
  transform: rotate(3deg);
}

.ri-grid-loading:after,
.ri-grid-loading:before {
  display: none;
}

.ri-loading-image {
  display: none;
}

.ri-grid-loading .ri-loading-image {
  position: relative;
  width: 30px;
  height: 30px;
  left: 50%;
  margin: 100px 0 0 -15px;
  display: block;
}

.gallery-item {
  display: block;
  height: 180px;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

.gallery9{
  .gallery-item{
    width: 100px;
    height: 100px;
    display: inline-block;
  }
}

.gallery-item:hover {
  opacity: 1;
}

.gallery-item .info-content {
  background-color: rgba(19, 19, 19, 0.3);
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: all 500ms ease;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.gallery-item .info-content h5 {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.92px;
  @extend %base-color;
}

.gallery-item:hover .info-content {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.modern .gallery-item {
  height: auto;
  text-align: center;
}

.modern .gallery-item img {
  width: 100%;
}

.modern .gallery-item .info-content {
  background-color: rgba(19, 19, 19, 0.3);
}

.modern .gallery-item .info-content h5 {
  font-family: "BebasNeueRegular";
  font-size: 24px;
  line-height: 30px;
  color: #ffffff;
  font-weight: 400;
  letter-spacing: 1.92px;
  @extend %base-color;
}


/* Full with gallery */

.fullwidth.full-single {
  width: 20%;
  display: block;
  float: left;
}

.fullwidth .gallery-item {
  margin: 0 5px 10px 5px;
  height: auto;
  width: calc(100% - 10px);
  box-sizing: border-box;
}

.fullwidth .gallery-item img {
  width: auto;
}

.fullwidth .gallery-item .info-content {
  height: 100%;
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
  background-color: rgba(255, 255, 255, 0.9);
}

.fullwidth .gallery-item .info-content h5 {
  letter-spacing: 1.1px;
  margin-bottom: 6px;
  color: #131313;
  @extend %base-color;
}

.fullwidth .gallery-item .info-content .subtitle {
  font-size: 10px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  text-transform: uppercase;
  line-height: 2;
  letter-spacing: 2.6px;
  @extend %subtitle-color;
}

.fullwidth .gallery-item:hover .info-content {
  -webkit-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
}

.full-single:nth-child(14n+1) {
  width: 20%;
}

.full-single:nth-child(14n+1) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+2) {
  width: 40%;
}

.full-single:nth-child(14n+2) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+3) {
  width: 40%;
}

.full-single:nth-child(14n+3) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+4) {
  width: 20%;
}

.full-single:nth-child(14n+4) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+5) {
  width: 40%;
}

.full-single:nth-child(14n+5) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+6) {
  width: 20%;
}

.full-single:nth-child(14n+6) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+7) {
  width: 20%;
}

.full-single:nth-child(14n+7) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+8) {
  width: 20%;
}

.full-single:nth-child(14n+8) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+9) {
  width: 20%;
}

.full-single:nth-child(14n+9) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+10) {
  width: 40%;
}

.full-single:nth-child(14n+10) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+11) {
  width: 40%;
}

.full-single:nth-child(14n+11) .gallery-item {
  height: 464px;
}

.full-single:nth-child(14n+12) {
  width: 20%;
}

.full-single:nth-child(14n+12) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+13) {
  width: 20%;
}

.full-single:nth-child(14n+13) .gallery-item {
  height: 227px;
}

.full-single:nth-child(14n+14) {
  width: 40%;
}

.full-single:nth-child(14n+14) .gallery-item {
  height: 227px;
}

.portfolio-wrapper {
  margin-bottom: 0px;
  padding: 0;
}

@media (max-width: 991px) {
  .full-single:nth-child(14n+1) {
    width: 20%;
  }
  .full-single:nth-child(14n+1) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+2) {
    width: 40%;
  }
  .full-single:nth-child(14n+2) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+3) {
    width: 40%;
  }
  .full-single:nth-child(14n+3) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+4) {
    width: 20%;
  }
  .full-single:nth-child(14n+4) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+5) {
    width: 40%;
  }
  .full-single:nth-child(14n+5) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+6) {
    width: 20%;
  }
  .full-single:nth-child(14n+6) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+7) {
    width: 20%;
  }
  .full-single:nth-child(14n+7) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+8) {
    width: 20%;
  }
  .full-single:nth-child(14n+8) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+9) {
    width: 20%;
  }
  .full-single:nth-child(14n+9) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+10) {
    width: 40%;
  }
  .full-single:nth-child(14n+10) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+11) {
    width: 40%;
  }
  .full-single:nth-child(14n+11) .gallery-item {
    height: 294px;
  }
  .full-single:nth-child(14n+12) {
    width: 20%;
  }
  .full-single:nth-child(14n+12) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+13) {
    width: 20%;
  }
  .full-single:nth-child(14n+13) .gallery-item {
    height: 142px;
  }
  .full-single:nth-child(14n+14) {
    width: 40%;
  }
  .full-single:nth-child(14n+14) .gallery-item {
    height: 142px;
  }
}

@media (max-width: 767px) {
  .full-single:nth-child(14n+1),
  .full-single:nth-child(14n+2),
  .full-single:nth-child(14n+3),
  .full-single:nth-child(14n+4),
  .full-single:nth-child(14n+5),
  .full-single:nth-child(14n+6),
  .full-single:nth-child(14n+7),
  .full-single:nth-child(14n+8),
  .full-single:nth-child(14n+9),
  .full-single:nth-child(14n+10),
  .full-single:nth-child(14n+11),
  .full-single:nth-child(14n+12),
  .full-single:nth-child(14n+13),
  .full-single:nth-child(14n+14) {
    width: 50%;
  }
  .full-single:nth-child(14n+1) .gallery-item,
  .full-single:nth-child(14n+2) .gallery-item,
  .full-single:nth-child(14n+3) .gallery-item,
  .full-single:nth-child(14n+4) .gallery-item,
  .full-single:nth-child(14n+5) .gallery-item,
  .full-single:nth-child(14n+6) .gallery-item,
  .full-single:nth-child(14n+7) .gallery-item,
  .full-single:nth-child(14n+8) .gallery-item,
  .full-single:nth-child(14n+9) .gallery-item,
  .full-single:nth-child(14n+10) .gallery-item,
  .full-single:nth-child(14n+11) .gallery-item,
  .full-single:nth-child(14n+12) .gallery-item,
  .full-single:nth-child(14n+13) .gallery-item,
  .full-single:nth-child(14n+14) .gallery-item {
    height: 370px;
  }
}

@media (max-width: 480px) {
  .full-single:nth-child(14n+1),
  .full-single:nth-child(14n+2),
  .full-single:nth-child(14n+3),
  .full-single:nth-child(14n+4),
  .full-single:nth-child(14n+5),
  .full-single:nth-child(14n+6),
  .full-single:nth-child(14n+7),
  .full-single:nth-child(14n+8),
  .full-single:nth-child(14n+9),
  .full-single:nth-child(14n+10),
  .full-single:nth-child(14n+11),
  .full-single:nth-child(14n+12),
  .full-single:nth-child(14n+13),
  .full-single:nth-child(14n+14) {
    width: 100%;
  }
}


/*Portfolio simple*/

body .vc_row-fluid[data-vc-full-width=true] .portfolio {
  margin-left: 0px ;
  margin-right: 0px ;
  padding: 0px 20px;
}

@media (max-width: 991px) {
  .portfolio {
    padding: 0px 10px;
  }
}

@media (max-width: 767px) {
  .portfolio {
    padding: 0px 15px;
  }
}

.item {
  float: left;
  display: block;
  position: relative;
}

.portfolio .item {
  height: 250px;
}

.item .item-img {
  margin-left: 0;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.item:after {
  padding-top: 0%;
  display: block;
  content: "";
}


/* 2;4 column items */

.col-2 .item {
  width: 50%;
}

.col-4 .item {
  width: 25%;
}

.portfolio-gallery.gallery-single {
  margin: 0 -15px;
}

.item.item-singe {
  min-height: 270px;
}

body .portfolio .item {
  max-height: 270px ;
}

.portfolio.col-3 .item{
  width: 33%;
}

.portfolio.col-4 .item{
  width: 25%;
}

.portfolio.col-6 .item{
  width: 50%;
}

@media (max-width: 991px) {
.portfolio.col-6 .item,
.portfolio.col-4 .item,
.portfolio.col-3 .item {
    width: 50% ;
  }
  body [data-space] .item-img {
    margin-top: 0px ;
  }
}

@media (max-width: 767px) {
.portfolio.col-6 .item,
.portfolio.col-4 .item,
.portfolio.col-3 .item {
    width: 100% ;
  }
}

.item-link {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow: hidden;
  transition: all 0.5s ease;
}

body .portfolio .item-link {
  margin: 0px 10px 20px 10px ;
}

@media (max-width: 767px) {
  body .portfolio .item-link {
    margin: 0px ;
    margin-bottom: 30px ;
  }
}

body .portfolio.classic .item-link {
  margin: 0px 15px 30px 15px ;
}

@media (max-width: 767px) {
  body .portfolio.classic .item-link {
    margin: 0px ;
    margin-bottom: 30px ;
  }
}

.item-link:hover {
  opacity: 1;
}

.item-img {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: 0;
}

.item-img .images {
  width: 33.33%;
  height: 33.33%;
  float: left;
  position: relative;
  display: block;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.item-img .images-one {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.item-overlay {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.3);
  transition: all .5s ease;
}

.item-overlay>h5 {
  position: absolute;
  left: 0;
  width: 100%;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  font-family: "BebasNeueRegular";
  color: #ffffff;
  text-align: center;
  @extend %base-color;
}


/*Potfolio classic */

.classic .item-overlay {
  transition: all 0.5s ease;
}

.classic .item-overlay h5 {
  color: #ffffff;
  font-weight: 500;
  letter-spacing: 1.6px;
  @extend %base-color;
}

body .portfolio.classic .item {
  height: 400px;
  max-height: 400px;
}

.portfolio.classic .item-link {
  min-height: 370px;
  height: 370px;
}


/*Potfolio classic BIG*/

body .portfolio.classic.big .item {
  height: 523px;
  max-height: 523px;
}

.portfolio.classic.big .item-link {
  min-height: 493px;
  height: 493px;
}

.portfolio.classic.big .item-overlay {
  background-color: #f2f2f2;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.classic.big .item-link:hover .item-overlay {
  opacity: 0.9;
}

.portfolio.classic.big .portfolio-title {
  position: absolute;
  top: auto;
  left: 45px;
  bottom: 50px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 1.6px;
  opacity: 0;
  transition: opacity 0.5s ease;
  color: #131313;
  @extend %base-color;
}

.portfolio.classic.big .item-link:hover .portfolio-title {
  opacity: 1;
}

.portfolio.classic.big .categories {
  position: absolute;
  top: auto;
  left: 45px;
  bottom: 30px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  opacity: 0;
  transition: opacity 0.5s ease;
  font-family: "Montserrat", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 0;
  text-decoration: none;
  color: #c0af94;
  @extend %subtitle-color;
}

.portfolio.classic.big .item-link:hover .categories {
  opacity: 1;
}

@media (max-width: 1199px) {
 body  .portfolio.classic.big .item {
    height: 400px;
    max-height: 400px;
  }
  .portfolio.classic.big .item-link {
    min-height: 370px;
    height: 370px;
  }
}


/*Potfolio Big Gap */

body .portfolio.big_gap .item {
  height: 575px;
  max-height: 575px;
}

body .portfolio.big_gap .item-link {
  min-height: 495px;
  height: 495px;
  margin: 0px 40px 80px 40px;
}

body .portfolio.big_gap .item.block_item_0 {
  height: 905px;
  max-height: 905px;
}

.portfolio.big_gap .item.block_item_0 .item-link {
  height: 825px;
  min-height: 825px;
}

body .portfolio.big_gap .item.block_item_1 {
  height: 445px;
  max-height: 445px;
}

.portfolio.big_gap .item.block_item_1 .item-link {
  height: 365px;
  min-height: 365px;
}

body .portfolio.big_gap .item.block_item_2 {
  height: 850px;
  max-height: 850px;
}

.portfolio.big_gap .item.block_item_2 .item-link {
  height: 770px;
  min-height: 770px;
}

body .portfolio.big_gap .item.block_item_4 {
  height: 625px;
  max-height: 625px;
}

.portfolio.big_gap .item.block_item_4 .item-link {
  height: 545px;
  min-height: 545px;
}

body .portfolio.big_gap .item.block_item_5 {
  height: 900px;
  max-height: 900px;
}

.portfolio.big_gap .item.block_item_5 .item-link {
  height: 820px;
  min-height: 820px;
}

body .portfolio.big_gap .item.block_item_6 {
  height: 460px;
  max-height: 460px;
}

.portfolio.big_gap .item.block_item_6 .item-link {
  height: 380px;
  min-height: 380px;
}

.portfolio.big_gap .portfolio-title {
  position: absolute;
  top: auto;
  left: 70px;
  bottom: 108px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
  text-align: left;
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 1.6px;
  opacity: 0;
  transition: opacity 0.5s ease;
  color: #ffffff;
  @extend %base-color;
}

.portfolio.big_gap .item-link:hover .portfolio-title {
  opacity: 1;
}

.portfolio.big_gap .big_gap_share {
  position: absolute;
  bottom: 52px;
  left: 60px;
  margin: 0;
  list-style-type: none;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.big_gap .big_gap_share li {
  display: inline-block;
  margin: 10px 3px;
}

.portfolio.big_gap .big_gap_share li:first-child {
  margin-left: 0;
}

.portfolio.big_gap .big_gap_share li i,
.portfolio.big_gap .big_gap_share li button {
  font-family: 'FontAwesome';
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #ffffff;
  text-decoration: none;
  padding: 0 10px;
  background-color: transparent;
  border: none;
  border-color: transparent;
  transition: opacity 0.3 s ease;
}

.portfolio.big_gap .big_gap_share:hover li button {
  opacity: 0.7;
}

.portfolio.big_gap .big_gap_share:hover li button:hover {
  opacity: 1;
}

.portfolio.big_gap .item-link:hover .big_gap_share {
  opacity: 1;
}

@media (max-width: 991px) {
  .portfolio.big_gap .portfolio-title {
    left: 30px;
    bottom: 100px;
  }
  .portfolio.big_gap .big_gap_share {
    left: 20px;
    bottom: 30px;
  }
}

@media (max-width: 767px) {
 body .portfolio.big_gap .item-link {
    margin: 0px 40px 30px 40px  ;
    height: 370px;
    min-height: 370px;
  }
 body .portfolio.big_gap .item {
    height: 400px;
    max-height: 400px  ;
  }
  body .portfolio.big_gap .item.block_item_0,
  body .portfolio.big_gap .item.block_item_1,
  body .portfolio.big_gap .item.block_item_2,
  body .portfolio.big_gap .item.block_item_3,
  body .portfolio.big_gap .item.block_item_4,
  body .portfolio.big_gap .item.block_item_5,
  body .portfolio.big_gap .item.block_item_6 {
    height: 400px;
    max-height: 400px  ;
  }
  .portfolio.big_gap .item.block_item_0 .item-link,
  .portfolio.big_gap .item.block_item_1 .item-link,
  .portfolio.big_gap .item.block_item_2 .item-link,
  .portfolio.big_gap .item.block_item_3 .item-link,
  .portfolio.big_gap .item.block_item_4 .item-link,
  .portfolio.big_gap .item.block_item_5 .item-link,
  .portfolio.big_gap .item.block_item_6 .item-link {
    height: 370px;
    min-height: 370px;
  }
}


/*Potfolio Masonry */

body .portfolio.masonry .item {
  max-height: none ;
  height: auto;
}

body .portfolio.masonry .item-link {
  position: relative;
  display: block;
  margin: 0 15px 7px 15px ;
}

.portfolio.masonry .item-link img {
  width: 100%;
}

.portfolio.masonry .item-overlay {
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.masonry .item-link:hover .item-overlay {
  background-color: #000000;
  opacity: 0.3;
}

.portfolio.masonry .item-portfolio-content {
  margin: 0 15px 24px 15px;
  text-align: center;
  max-height: 56px;
  overflow: hidden;
}

.portfolio.masonry .item-portfolio-content .portfolio-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #131313;
  margin-bottom: 0;
  @extend %base-color;
}

.portfolio.masonry .item-portfolio-content .portfolio-title a {
  text-decoration: none;
  color: inherit;
}


/*Portfolio Grid */

body .portfolio.grid .item {
  padding-top: 305px;
  max-height: none ;
  height: auto;
}

.portfolio.grid .item-link {
  height: 280px;
}

.portfolio.grid .item-overlay {
  background-color: #000000;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.portfolio.grid .item-link:hover .item-overlay {
  background-color: #000000;
  opacity: 0.3;
}

.portfolio.grid .item-portfolio-content {
  display: block;
  margin: 25px 10px 15px ;
  text-align: center;
  overflow: hidden;
  max-height: 56px;
}

.portfolio.grid .item-portfolio-content .portfolio-title {
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  color: #131313;
  margin-bottom: 0;
  @extend %base-color;
}

.portfolio.grid .item-portfolio-content .portfolio-title a {
  text-decoration: none;
}

.portfolio.grid .item-portfolio-content .category {
  color: #c0af94;
  @extend %subtitle-color;
}

.portfolio.grid .item-portfolio-content .category a {
  font-family: "Montserrat", sans-serif;
  color: #c0af94;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  margin-top: 0;
  letter-spacing: 2.4px;
  margin-bottom: 30px;
  text-decoration: none;
  @extend %subtitle-color;
}


/*Hover styles*/


/*Default hover*/

.portfolio.simple .item-link .item-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease;
}

.item-link:hover .item-overlay {
  opacity: 0;
  transition: all 0.5s ease;
}


/* .gallery-single .gallery-item:hover .item-img, */

.portfolio.simple .item-link:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: all 0.5s ease;
}


/*Hover 1 - Zoom out*/

.gallery-single .gallery-item.hover1 .item-img,
.portfolio .item-link.hover1 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  width: 120%;
  height: 100%;
  -webkit-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  left: -10%;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover1:hover .item-img,
.portfolio .item-link.hover1:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.5s ease-in-out;
}


/*Hover 2 - Slide*/

body .gallery-single .gallery-item.hover2 .item-img,
body .portfolio .item-link.hover2 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  width: 120%  ;
  left: -20%;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover2:hover .item-img,
.portfolio .item-link.hover2:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  left: 0%;
  width: 120%;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover2 .info-content {
  left: -7%;
}


/*Hover 3 - Rotate (+ zoom out)*/

.gallery-single .gallery-item.hover3 .item-img,
.portfolio .item-link.hover3 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: rotate(15deg) scale(1.4);
  -ms-transform: rotate(15deg) scale(1.4);
  transform: rotate(15deg) scale(1.4);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover3:hover .item-img,
.portfolio .item-link.hover3:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: rotate(0deg) scale(1);
  -ms-transform: rotate(0deg) scale(1);
  transform: rotate(0deg) scale(1);
  transition: all 0.5s ease-in-out;
}


/*Hover 4 - Blur*/

.gallery-single .gallery-item.hover4 .item-img,
.portfolio .item-link.hover4 .item-img {
  -webkit-filter: grayscale(0%) blur(3px);
  filter: grayscale(0%) blur(3px);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover4:hover .item-img,
.portfolio .item-link.hover4:hover .item-img {
  -webkit-filter: grayscale(0%) blur(0px);
  filter: grayscale(0%) blur(0px);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}


/*Hover 5 - Greyscale*/

.gallery-single .gallery-item.hover5 .item-img,
.portfolio .item-link.hover5 .item-img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover5:hover .item-img,
.portfolio .item-link.hover5:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}


/*Hover 6 - Sepia*/

.gallery-single .gallery-item.hover6 .item-img,
.portfolio .item-link.hover6 .item-img {
  -webkit-filter: sepia(100%) grayscale(0%);
  filter: sepia(100%) grayscale(0%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover6:hover .item-img,
.portfolio .item-link.hover6:hover .item-img {
  -webkit-filter: sepia(0%) grayscale(0%);
  filter: sepia(0%) grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}


/*Hover 7 - Blur + Greyscale*/

.gallery-single .gallery-item.hover7 .item-img,
.portfolio .item-link.hover7 .item-img {
  -webkit-filter: blur(0px) grayscale(0%);
  filter: blur(0px) grayscale(0%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover7:hover .item-img,
.portfolio .item-link.hover7:hover .item-img {
  -webkit-filter: blur(3px) grayscale(100%);
  filter: blur(3px) grayscale(100%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}


/*Hover 8 - Opacity*/

.gallery-single .gallery-item.hover8 .item-img,
.portfolio .item-link.hover8 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover8:hover .item-img,
.portfolio .item-link.hover8:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 0.5;
  transition: all 0.5s ease-in-out;
}


/*Hover 9 - Shine*/

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

.gallery-single .gallery-item.hover9 .item-img,
.portfolio .item-link.hover9 .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover9 .item-img::before,
.portfolio .item-link.hover9 .item-img::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 2;
  display: block;
  content: '';
  width: 50%;
  height: 100%;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.gallery-single .gallery-item.hover9:hover .item-img,
.portfolio .item-link.hover9:hover .item-img {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  transition: all 0.5s ease-in-out;
}

.gallery-single .gallery-item.hover9:hover .item-img::before,
.portfolio .item-link.hover9:hover .item-img::before {
  -webkit-animation: shine .75s;
  animation: shine .75s;
}


/*Gallery protected page*/

.single-proof_gallery .single-content>.title,
.single-proof_gallery .title {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  font-weight: 400;
  font-family: "BebasNeueRegular";
  color: #131313;
  margin: 0;
  padding-top: 35px;
  @extend %base-color;
}

@media (max-width: 767px) {
  .single-proof_gallery .single-content>.title,
  .single-proof_gallery .title {
    padding-top: 20px;
    text-align: center;
  }
}

.single-proof_gallery .date-post {
  display: none;
}

.single-proof_gallery .single-content .post-info {
  display: none;
}

.single-proof_gallery .pixproof-data {
  margin-bottom: 75px;
}

@media (max-width: 1199px) {
  .single-proof_gallery .pixproof-data {
    margin-bottom: 60px;
  }
}

@media (max-width: 991px) {
  .single-proof_gallery .pixproof-data {
    margin-bottom: 50px;
  }
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data {
    text-align: center;
  }
}

.single-proof_gallery .pixproof-data .grid {
  font-size: 0px;
}

.single-proof_gallery .pixproof-data .grid__item {
  display: inline-block;
  vertical-align: top;
  width: auto;
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data .grid__item {
    display: block;
  }
}

.single-proof_gallery .pixproof-data .grid__item .entry__meta-box {
  display: inline-block;
  vertical-align: bottom;
  line-height: 20px;
}

.single-proof_gallery .pixproof-data .grid__item .entry__meta-box span {
  font-size: 14px;
  line-height: 20px;
  font-family: "Libre Baskerville";
  letter-spacing: 1.12px;
  color: #888888;
  display: inline-block;
  vertical-align: top;
  padding-top: 25px;
  padding-right: 40px;
  @extend %desc-color;
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data .grid__item .entry__meta-box span {
    padding-right: 0px;
  }
}

.single-proof_gallery .pixproof-data .grid__item .entry__meta-box button:not(:last-child) {
  margin-right: 15px;
}

body .single-proof_gallery .pixproof-data .grid__item .entry__meta-box .meta-box__title {
  font-size: 12px ;
  line-height: 20px ;
  vertical-align: top ;
  font-family: "Montserrat" ;
  color: #c0af94;
  text-transform: uppercase ;
  letter-spacing: 2.4px ;
  display: inline-block ;
  padding-top: 26px ;
  padding-right: 10px ;
  @extend %subtitle-color;
}

.single-proof_gallery .pixproof-data .grid__item .a-btn-2 {
  background: transparent;
  border-left: none;
  border-right: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  color: #131313;
  @extend %base-light-bottom-color;
  @extend %base-color;
}

.single-proof_gallery .pixproof-data .grid__item .a-btn-2:hover {
  background: #131313;
  color: #ffffff;
  @extend %base-border-color;
  @extend %base-bg-color;
  @extend %font-color;
}

.single-proof_gallery .pixproof-data .grid__item:last-child {
  float: right;
}

@media (max-width: 767px) {
  .single-proof_gallery .pixproof-data .grid__item:last-child {
    padding-top: 20px;
    float: none;
  }
}

.single-proof_gallery #pixproof_gallery {
  margin-bottom: 50px;
}

@media (max-width: 767px) {
  .single-proof_gallery #pixproof_gallery {
    margin-bottom: 40px;
  }
}

.single-proof_gallery #pixproof_gallery .proof-photo {
  cursor: pointer;
  margin-bottom: 30px;
  /*Image*/
  /*Hover effects on non-selected photo*/
  /*Hover effects on selected photo*/
}

.single-proof_gallery #pixproof_gallery .proof-photo .img-wrap {
  height: 180px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.single-proof_gallery #pixproof_gallery .proof-photo .img-wrap:before {
  position: absolute;
  display: block;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__container {
  position: relative;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__meta {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__status {
  display: none;
}

body .single-proof_gallery #pixproof_gallery .proof-photo .meta__action.zoom-action {
  font-size: 0px;
  padding: 0;
  position: absolute;
  background-color: transparent  ;
  width: 100%;
  height: 180px;
  top: 0;
  z-index: 49;
}

.single-proof_gallery #pixproof_gallery .proof-photo .actions-nav {
  margin: 0;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action {
  font-size: 0px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  white-space: nowrap;
  padding: 0;
  width: 24px;
  height: 24px;
  box-sizing: border-box;
  position: relative;
  text-decoration: none;
  opacity: 0;
  z-index: 50;
  margin-left: 15px;
  margin-top: 15px;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action:hover {
  opacity: 1;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action .button-text {
  font-size: 0px;
  white-space: nowrap;
}

.single-proof_gallery #pixproof_gallery .proof-photo .meta__action.select-action:before {
  content: '\f067';
  font-family: "FontAwesome";
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: all 0.5s ease;
  @extend %base-color;
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__bg {
  position: relative;
  /*Title*/
}

.single-proof_gallery #pixproof_gallery .proof-photo .proof-photo__bg .proof-photo__id {
  position: absolute;
  display: block;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  width: 100%;
  font-size: 24px;
  font-weight: 400;
  font-family: "BebasNeueRegular";
  color: #ffffff;
  text-align: center;
  opacity: 0;
  transition: all 0.5s ease;
  @extend %base-color;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .proof-photo__id {
  opacity: 1;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .img-wrap:before {
  opacity: 1;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .meta__action.select-action {
  opacity: 1;
  background-color: transparent;
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo:hover .meta__action.select-action:hover:before {
  -webkit-transform: translate(-50%, -50%) rotate(90deg);
  -ms-transform: translate(-50%, -50%) rotate(90deg);
  transform: translate(-50%, -50%) rotate(90deg);
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected .meta__action.select-action {
  opacity: 1;
  border: 1px solid #c0af94;
  background-color: #c0af94;
  @extend %subtitle-border-color;
  @extend %subtitle-bg-color;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected .meta__action.select-action:before {
  content: '\f00c';
  transition: all 0.5s ease;
}

.single-proof_gallery #pixproof_gallery .proof-photo.selected:hover .meta__action.select-action:hover:before {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  content: '\f068';
  transition: all 0.5s ease;
}


/*Justified gallery*/

.justified-gallery .dgwt-jg-caption {
  background: rgba(0, 0, 0, 0.5);
}

body .dgwt-jg-gallery.justified-gallery .dgwt-jg-caption span {
  font-family: "BebasNeueRegular";
  font-size: 24px ;
  line-height: 30px ;
  color: #ffffff;
  letter-spacing: 1.92px ;
  font-weight: 400 ;
  opacity: 1 ;
  @extend %base-color;
}

body .lg .lg-sub-html h4 {
  font-family: "BebasNeueRegular";
  font-size: 24px ;
  line-height: 30px ;
  color: #ffffff;
  font-weight: 400 ;
  letter-spacing: 1.92px;
  @extend %base-color;
}

body .lg .lg-sub-html .dgwt-jg-item-desc {
  letter-spacing: .8px;
  font-size: 12px;
  line-height: 20px ;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
  color: #888888;
  text-align: center;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  max-width: 900px;
  margin-left: auto ;
  margin-right: auto ;
  @extend %desc-color;
}

body .single-proof_gallery .mfp-arrow-left {
  left: 5px ;
}

body .single-proof_gallery .mfp-arrow-right {
  right: 100px ;
}


/*Izotop container portfolio*/

@media (max-width:767px) {
  body .izotope-container .item-single {
    margin-bottom: 5px;
  }
 body  .izotope-container .item-single:last-child {
    margin-bottom: 30px;
  }
}


/* 13. PORTFOLIO FILTER
-------------------------*/

.filter {
  text-align: center;
}

.filter.grid {
  text-align: left;
}

.filter.grid ul,
.filter.sim ul,
.filter.cla ul {
  margin: 30px 0;
}

.filter.masonry ul {
  margin: 63px 0;
}

.filter ul li {
  font-family: "Libre Baskerville", sans-serif;
  font-size: 14px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: lowercase;
  display: inline-block;
  margin: 8px;
  cursor: pointer;
  opacity: 1;
  color: #b2b2b2;
  transition: all 300ms ease;
  @extend %desc-color;
}

.filter ul li:hover {
  opacity: 0.7;
}

.filter ul li.active {
  color: #131313;
  opacity: 1;
  @extend %base-color;
}

.filter.grid ul li:first-child {
  margin-left: 0;
}

@media (max-width: 991px) {
  .filter.masonry ul {
    margin: 30px 0;
  }
}

@media (max-width: 767px) {
  .filter,
  .filter.grid {
    text-align: center;
    padding: 0 15px;
  }
  .filter.grid ul li:first-child {
    margin-left: 8px;
  }
}


/* 14. BLOCKQUOTE
------------------*/

blockquote {
  border: none;
  font-size: 15px;
  padding: 10px 0;
}

blockquote footer {
  font-size: 85%;
}

.single-proof_gallery {
  .proof_gallery .pixproof-data {
    position: relative;
    margin-bottom: 24px
  }
  .proof_gallery .meta-box__title {
    display: block;
    font-weight: bold
  }
  @media screen and (min-width: 899px) {
    .proof_gallery .button-download {
      position: absolute;
      right: 0
    }
  }
  .proof_gallery .proof-photo {
    padding-bottom: 0;
    -moz-transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    -o-transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    -webkit-transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    transition: all 0.3s cubic-bezier(0.86, 0, 0.07, 1);
    text-align: center
  }
  .proof_gallery .proof-photo:hover .proof-photo__bg {
    background: #EEE9E9
  }
  .proof_gallery .proof-photo:hover .proof-photo__meta {
    filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
    opacity: 1;
    background: rgba(0, 0, 0, 0.5)
  }
  .proof_gallery .proof-photo:hover .separator {
    max-width: 80%;
    margin-top: 12px;
    margin-bottom: 12px
  }
  .proof_gallery .proof-photo.selected .proof-photo__status {
    opacity: 1
  }
  .proof_gallery .proof-photo.selected .proof-photo__status span.ticker {
    opacity: 1
  }
  .proof_gallery .proof-photo.selecting .proof-photo__status span.ticker {
    opacity: 0
  }
  .proof_gallery .proof-photo.selecting .proof-photo__status span.spinner {
    opacity: 1
  }
  .proof_gallery .proof-photo.scrooled_from_comments {
    -moz-transform: scale(1.2, 1.2);
    -ms-transform: scale(1.2, 1.2);
    -o-transform: scale(1.2, 1.2);
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
    z-index: 50
  }
  .proof_gallery .proof-photo a {
    color: white
  }
  .proof_gallery .proof-photo .actions-nav {
    text-align: center;
    margin: 12px 0;
    padding: 0
  }
 body .proof_gallery .proof-photo .nav {
    list-style: none;
    margin-left: 0
  }
  .proof_gallery .proof-photo .nav:after {
    content: "";
    display: table;
    clear: both
  }
  .proof_gallery .proof-photo .nav>li {
    margin: 0
  }
  .proof_gallery .proof-photo .nav>li,
  .proof_gallery .proof-photo .nav>li>a {
    display: inline-block;
    *display: inline;
    zoom: 1
  }
  .proof_gallery .proof-photo .nav--stacked>li {
    display: list-item
  }
  .proof_gallery .proof-photo .nav--stacked>li>a {
    display: block;
    width: 100%
  }
  .proof_gallery .proof-photo__bg {
    display: inline-block;
    margin: 0 auto
  }
  .proof_gallery .proof-photo__container {
    position: relative
  }
  .proof_gallery .proof-photo__meta {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear
  }
  .proof_gallery .proof-photo__meta .flexbox {
    height: 100%
  }
  .proof_gallery .proof-photo__meta .separator {
    max-width: 0;
    margin: 0 auto;
    height: 1px;
    background: white;
    -moz-transition: all 0.1s linear;
    -o-transition: all 0.1s linear;
    -webkit-transition: all 0.1s linear;
    transition: all 0.1s linear
  }
  .proof_gallery .proof-photo__status {
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    opacity: 0
  }
  .proof_gallery .proof-photo__status span.ticker {
    display: block;
    font-size: 18px;
    color: white;
    line-height: 32px;
    width: 32px;
    text-align: center;
    opacity: 0;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear
  }
  .proof_gallery .proof-photo__status span.spinner {
    position: absolute;
    right: 0;
    bottom: 0;
    border: 3px solid transparent;
    border-radius: 100%;
    opacity: 0;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear
  }
  .proof_gallery .proof-photo__id {
    display: block;
    padding: 6px 0;
    text-align: center
  }
   body .proof_gallery .meta__action {
    margin: 0;
    color: black;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center
  }
  .proof_gallery .meta__action--popup {
    color: white
  }
  .proof_gallery .pixproof_photo_ref {
    padding: 3px 6px;
    margin: 0 3px;
    color: white;
    background: black;
    cursor: pointer
  }
  .proof_gallery .gallery {
    margin-bottom: 20px
  }
  .proof_gallery .gallery-item {
    float: left;
    margin: 0 4px 4px 0;
    overflow: hidden;
    position: relative
  }
  .proof_gallery .gallery-columns-1 .gallery-item {
    max-width: 100%;
    width: 100%
  }
  .proof_gallery .gallery-columns-2 .gallery-item {
    max-width: 48%;
    width: 48%;
    max-width: -webkit-calc(50% - 4px);
    max-width: calc(50% - 4px);
    width: calc(50% - 4px)
  }
  .proof_gallery .gallery-columns-3 .gallery-item {
    max-width: 32%;
    width: 32%;
    max-width: -webkit-calc(33.3% - 4px);
    max-width: calc(33.3% - 4px);
    width: calc(33.3% - 4px)
  }
  .proof_gallery .gallery-columns-4 .gallery-item {
    max-width: 23%;
    width: 23%;
    max-width: -webkit-calc(25% - 4px);
    max-width: calc(25% - 4px);
    width: calc(25% - 4px)
  }
  .proof_gallery .gallery-columns-5 .gallery-item {
    max-width: 19%;
    width: 19%;
    max-width: -webkit-calc(20% - 4px);
    max-width: calc(20% - 4px);
    width: calc(20% - 4px)
  }
  .proof_gallery .gallery-columns-6 .gallery-item {
    max-width: 15%;
    width: 15%;
    max-width: -webkit-calc(16.7% - 4px);
    max-width: calc(16.7% - 4px);
    width: calc(16.7% - 4px)
  }
  .proof_gallery .gallery-columns-7 .gallery-item {
    max-width: 13%;
    width: 13%;
    max-width: -webkit-calc(14.28% - 4px);
    max-width: calc(14.28% - 4px);
    width: calc(14.28% - 4px)
  }
  .proof_gallery .gallery-columns-8 .gallery-item {
    max-width: 11%;
    width: 11%;
    max-width: -webkit-calc(12.5% - 4px);
    max-width: calc(12.5% - 4px);
    width: calc(12.5% - 4px)
  }
  .proof_gallery .gallery-columns-9 .gallery-item {
    max-width: 9%;
    width: 9%;
    max-width: -webkit-calc(11.1% - 4px);
    max-width: calc(11.1% - 4px);
    width: calc(11.1% - 4px)
  }
  .proof_gallery .gallery-columns-1 .gallery-item:nth-of-type(1n),
  .proof_gallery .gallery-columns-2 .gallery-item:nth-of-type(2n),
  .proof_gallery .gallery-columns-3 .gallery-item:nth-of-type(3n),
  .proof_gallery .gallery-columns-4 .gallery-item:nth-of-type(4n),
  .proof_gallery .gallery-columns-5 .gallery-item:nth-of-type(5n),
  .proof_gallery .gallery-columns-6 .gallery-item:nth-of-type(6n),
  .proof_gallery .gallery-columns-7 .gallery-item:nth-of-type(7n),
  .proof_gallery .gallery-columns-8 .gallery-item:nth-of-type(8n),
  .proof_gallery .gallery-columns-9 .gallery-item:nth-of-type(9n) {
    margin-right: 0
  }
  .proof_gallery .gallery-columns-1.gallery-size-medium figure.gallery-item:nth-of-type(1n+1),
  .proof_gallery .gallery-columns-1.gallery-size-thumbnail figure.gallery-item:nth-of-type(1n+1),
  .proof_gallery .gallery-columns-2.gallery-size-thumbnail figure.gallery-item:nth-of-type(2n+1),
  .proof_gallery .gallery-columns-3.gallery-size-thumbnail figure.gallery-item:nth-of-type(3n+1) {
    clear: left
  }
  .proof_gallery .gallery-caption {
    background-color: rgba(0, 0, 0, 0.7);
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: #fff;
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
    max-height: 50%;
    opacity: 0;
    padding: 6px 8px;
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: left;
    width: 100%
  }
  .proof_gallery .gallery-caption:before {
    content: "";
    height: 100%;
    min-height: 49px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%
  }
  .proof_gallery .gallery-item:hover .gallery-caption {
    opacity: 1
  }
  .proof_gallery .gallery-columns-7 .gallery-caption,
  .proof_gallery .gallery-columns-8 .gallery-caption,
  .proof_gallery .gallery-columns-9 .gallery-caption {
    display: none
  }
  .proof_gallery button[disabled],
  .proof_gallery btn.disabled {
    pointer-events: none;
    cursor: not-allowed;
    filter: alpha(opacity=30);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .30
  }
  .mfp-arrow {
    position: absolute;
    top: 50%;
    display: block;
    outline: none;
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%)
  }
  .mfp-arrow:hover,
  .mfp-arrow:active,
  .mfp-arrow:focus {
    background-color: transparent
  }
  .mfp-arrow:before,
  .mfp-arrow:after {
    font-size: 7em
  }
  .mfp-close {
    outline: none;
    padding: 0 24px
  }
  .mfp-close:hover,
  .mfp-close:active,
  .mfp-close:focus {
    background-color: transparent
  }
  .mfp-arrow-left {
    left: 2%;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-left: 30px solid transparent;
    border-right: 30px solid white
  }
  .mfp-arrow-right {
    right: 2%;
    width: 0;
    height: 0;
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
    border-right: 30px solid transparent;
    border-left: 30px solid white
  }
  .mfp-counter {
    font-size: 1em;
    color: white
  }
  div.mfp-bottom-bar {
    margin-top: -50px
  }
  div.mfp-bottom-bar .mfp-title {
    margin-top: 10px
  }
  img.mfp-img {
    padding: 80px 0
  }
  div.mfp-figure:after {
    content: none
  }
  .proof_gallery .proof-photo__status span.spinner {
    height: 20px;
    width: 20px;
    margin: 0 6px 6px 0;
    border-left: 3px solid rgba(255, 255, 255, 0.15);
    border-right: 3px solid rgba(255, 255, 255, 0.15);
    border-bottom: 3px solid rgba(255, 255, 255, 0.15);
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    border-radius: 100%;
    -webkit-animation: rotation .6s infinite linear;
    -moz-animation: rotation .6s infinite linear;
    -o-animation: rotation .6s infinite linear;
    animation: rotation .6s infinite linear
  }
  @-webkit-keyframes rotation {
    from {
      -webkit-transform: rotate(0deg)
    }
    to {
      -webkit-transform: rotate(359deg)
    }
  }
  @-moz-keyframes rotation {
    from {
      -moz-transform: rotate(0deg)
    }
    to {
      -moz-transform: rotate(359deg)
    }
  }
  @-o-keyframes rotation {
    from {
      -o-transform: rotate(0deg)
    }
    to {
      -o-transform: rotate(359deg)
    }
  }
  @keyframes rotation {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(359deg)
    }
  }
}