.toggle {
  margin-bottom: 10px; }

.toggle-title {
  position: relative;
  display: inline-block;
  font-family: 'Montserrat';
  letter-spacing: .1em;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 15px;
  -webkit-font-smoothing: antialiased; }

.toggle-title:after {
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 1px;
  background-color: #000;
  bottom: -3px;
  transition: all .5s ease; }

.toggle.active .toggle-title:after {
  width: 100%; }

.toggle-content {
  padding-top: 10px;
  font-size: 14px;
  display: none; }

.toggle.active .toggle-content {
  display: block; }

/* 18. SERVICES
------------------------*/
.services {
  background-color: #F4F4F4;
  padding: 30px 15px;
  min-height: 295px;
  margin-bottom: 30px;
  @extend %dark-black-bg-color;
}

.services .img-wrap {
  width: 100%;
  height: 110px;
  margin: auto;
  position: relative;
}

.services .img-wrap img {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 110px;
}

.services .content {
  padding-top: 3px; }

.services .content .title {
  font-size: 26px;
  line-height: 36px;
  letter-spacing: 2.08px;
  font-family: "BebasNeueRegular", sans-serif;
  color: #131313;
  font-weight: 400;
  text-align: center;
  margin-bottom: 10px;
  @extend %base-color;
}

.services .content .text {
  font-size: 12px;
  font-family: "Libre Baskerville", sans-serif;
  color: #888888;
  line-height: 22px;
  text-align: center;
  letter-spacing: 0.96px;
  font-weight: 400;
  @extend %desc-color;
}

/* 19. IMAGE SLIDER
--------------------*/
.img-slider {
  position: relative; }

.img-slider img {
  width: 100%; }

.img-slider ul {
  list-style: none; }

.post-slider-img {
  width: 100%;
  height: 247px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat; }

.img-slider .flex-direction-nav {
  margin-left: 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  text-align: center;
  opacity: .5;
  transition: all .3s ease; }

.img-slider:hover .flex-direction-nav {
  opacity: 1; }

.img-slider .flex-direction-nav li {
  display: inline-block; }

.img-slider .flex-next,
.img-slider .flex-prev {
  background: #ffffff;
  display: block;
  padding: 5px;
  font-size: 40px;
  color: #555; }

.post .img-slider .flex-next,
.post .img-slider .flex-prev {
  padding: 0; }
