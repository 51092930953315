body #google-map {
    width: 68%;
    float: left;
    height: 480px;
}

#google-map img {
    max-width: none;
}


/* 22. CONTACT INFO
---------------------*/

body .contact-info {
    float: left;
    width: 32%;
    color: #000;
    z-index: 888;
    display: block;
    padding: 60px 70px 20px 70px;
    background-color: #f2f2f2;
    height: 480px;
    @extend %dark-black-bg-color;
    @extend %desc-color;
}

@media (max-width: 991px) {
    .contact-info {
        padding: 40px 20px 20px 20px;
    }
}

.contact-info .info-box {
    margin-bottom: 24px;
}

.contact-info .info-box:last-child {
    margin-bottom: 0;
}

.contact-info i {
    float: left;
    width: 50px;
}

.contact-info .details {
    display: inline-block;
}

.contact-info .details h5 {
    font-size: 36px;
    line-height: 40px;
    font-family: "BebasNeueRegular", sans-serif;
    color: #131313;
    font-weight: 400;
    letter-spacing: 2.88px;
    margin-bottom: 8px;
    @extend %base-color;
}

.contact-info h5 {
    margin-bottom: 10px;
}

.contact-info .details a,
.contact-info .details h6 {
    font-size: 14px;
    font-family: "Libre Baskerville", sans-serif;
    text-decoration: none;
    line-height: 28px;
    letter-spacing: 1.12px;
    font-style: normal;
    display: block;
    margin: 0px;
    font-weight: 400;
    color: #888888;
    @extend %desc-color;
}

.contact-info .details a:hover {
    opacity: 1;
}


/* 23. CONTACT FORM
----------------------*/

.contact-form {
    margin-top: 30px;
    margin-bottom: 50px;
}

@media (max-width: 767px) {
    .contact-form {
        text-align: center;
        margin-top: 0px;
        margin-bottom: 30px;
    }
}

.contact-form .title {
    font-size: 36px;
    line-height: 40px;
    font-family: "BebasNeueRegular", sans-serif;
    color: #131313;
    font-weight: 400;
    letter-spacing: 2.88px;
    margin-bottom: 16px;
    @extend %base-color;
}

.wpcf7-form,
.wpcf7 {
    font-family: "Libre Baskerville", sans-serif;
}

.wpcf7 form label {
    font-size: 14px;
    font-family: "Libre Baskerville", sans-serif;
    width: 100%;
}

form.wpcf7-form input,
form.wpcf7-form textarea {
    font-size: 14px;
    font-family: "Libre Baskerville", sans-serif;
    background: none;
    width: 100%;
    border: 0;
    border-radius: 0;
    color: #131313;
    border-bottom: 2px solid #f2f2f2;
    padding: 10px 0px;
    letter-spacing: 1.04px;
    margin-bottom: 22px;
    transition: all .3s ease;
    @extend %desc-color;
    @extend %form-border-color;
}

.wpcf7 form textarea {
    min-height: 50px;
    max-height: 50px;
    resize: none;
    margin-bottom: 20px;
}

form.wpcf7-form input::-moz-placeholder {
    color: #888888;
}

.wpcf7 form input::-moz-placeholder {
    color: #888888;
}

form.wpcf7-form input::-webkit-input-placeholder {
    color: #888888;
}

.wpcf7 form input::-webkit-input-placeholder {
    color: #888888;
}

form.wpcf7-form input:-moz-placeholder {
    color: #888888;
}

.wpcf7 form input:-moz-placeholder {
    color: #888888;
}

form.wpcf7-form input:-ms-input-placeholder {
    color: #888888;
}

.wpcf7 form input:-ms-input-placeholder {
    color: #888888;
}

form.wpcf7-form textarea::-moz-placeholder {
    color: #888888;
}

.wpcf7 form textarea::-moz-placeholder {
    color: #888888;
}

form.wpcf7-form textarea::-webkit-input-placeholder {
    color: #888888;
}

.wpcf7 form textarea::-webkit-input-placeholder {
    color: #888888;
}

form.wpcf7-form textarea:-moz-placeholder {
    color: #888888;
}

.wpcf7 form textarea:-moz-placeholder {
    color: #888888;
}

form.wpcf7-form textarea:-ms-input-placeholder {
    color: #888888;
}

.wpcf7 form textarea:-ms-input-placeholder {
    color: #888888;
}

form.wpcf7-form textarea {
    min-height: 50px;
    max-height: 50px;
    resize: none;
    margin-bottom: 24px;
}

.wpcf7 form input:focus,
form.wpcf7-form input:focus,
form.wpcf7-form textarea:focus {
    color: #131313;
    border-bottom: 2px solid #c0af94;
    @extend %base-color;
    @extend %subtitle-border-color;
}

.wpcf7 form input[type="submit"],
form.wpcf7-form #submit {
    font-size: 11px;
    line-height: 20px;
    display: inline-block;
    letter-spacing: 1.1px;
    font-family: "Montserrat";
    font-weight: bold;
    box-sizing: border-box;
    padding: 8px 20px;
    text-transform: uppercase;
    text-decoration: none;
    border-top: 2px solid rgba(19, 19, 19, 0.5);
    border-bottom: 2px solid rgba(19, 19, 19, 0.5);
    border-left: none;
    border-right: none;
    color: #131313;
    width: auto;
    margin-top: 7px;
    margin-bottom: 0px;
    -webkit-font-smoothing: antialiased;
    border-radius: 0px;
    @extend %base-light-bottom-color;
    @extend %base-color;
}

@media (max-width: 767px) {
    .wpcf7 form input[type="submit"],
    form.wpcf7-form #submit {
        margin-bottom: 30px;
    }
}

.wpcf7 form input[type="submit"]:hover,
form.wpcf7-form #submit:hover {
    border-top: 2px solid #131313;
    border-bottom: 2px solid #131313;
    border-left: none;
    border-right: none;
    color: #ffffff;
    background-color: #131313;
    opacity: 1;
    @extend %base-border-color;
    @extend %base-bg-color;
    @extend %font-color;
}

.contact-message {
    display: none;
    text-transform: capitalize;
    text-align: center;
    margin: 50px 0;
}