/*------------------------------------------------------------------

  Project:  Napoli
  Version:  1.6

  [Table of contents]

   Color SETTINGS
   DEFAULT STYLES
   STYLE GALLERY
   HEADER
   MENU
   ARROW LINKS
   HERO
   HERO SLIDER
   BANNER
   BANNER WITH GALLERY
   CALL TO ACTION
   ABOUT SECTION
   PORTFOLIO
   PORTFOLIO FILTER
   BLOCKQUOTE
   TEAM
   POSTS LIST
   TOGGLES
   SERVICES
   IMAGE SLIDER
   BLOG
   GOOGLE MAP
   CONTACT INFO
   CONTACT FORM
   PAGINATION
   FOOTER
   SIDEBAR
   PROTECTED PAGE
   SLIDER
   COMMENTS
   
  

/*-----------------------------------------------------------------*/


/* Color SETTINGS */


/*-----------------------------------------------------------------*/

@import "_colors.scss";

/*---------------------------------------------------------------*/


/*  DEFAULT STYLES
/*---------------------------------------------------------------*/

@import "_default.scss";

/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/


/*  STYLE GALLERY
/*---------------------------------------------------------------*/

@import "_style_gallery.scss";

/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/


/*  PRELOADER
/*---------------------------------------------------------------*/

@import "_style_gallery.scss";

/*---------------------------------------------------------------*/


/*  HEADER
/*---------------------------------------------------------------*/

@import "_header.scss";

/*---------------------------------------------------------------*/


/* MENU
/*---------------------------------------------------------------*/

@import "_menu.scss";

/*---------------------------------------------------------------*/


/*  ARROW LINKS
/*---------------------------------------------------------------*/

@import "_arrow.scss";

/*---------------------------------------------------------------*/


/*  HERO &&  HERO SLIDER
------------------------*/


/*---------------------------------------------------------------*/

@import "_hero.scss";

/*---------------------------------------------------------------*/


/*  BANNER &&  BANNER WITH GALLERY
--------------------------*/


/*---------------------------------------------------------------*/

@import "_banner.scss";

/*---------------------------------------------------------------*/


/*  CALL TO ACTION
/*---------------------------------------------------------------*/

@import "_call.scss";

/*---------------------------------------------------------------*/


/*  ABOUT SECTION
/*---------------------------------------------------------------*/

@import "_about.scss";

/*---------------------------------------------------------------*/


/*  PORTFOLIO &&  PORTFOLIO FILTER &&  BLOCKQUOTE
/*---------------------------------------------------------------*/

@import "_portfolio.scss";

/*---------------------------------------------------------------*/


/*  TEAM
/*---------------------------------------------------------------*/

@import "_team.scss";

/*---------------------------------------------------------------*/


/*  POSTS LIST
/*---------------------------------------------------------------*/

@import "_posts.scss";

/*---------------------------------------------------------------*/


/*  TOGGLES &&  IMAGE SLIDER &&   SERVICES
------------------------*/


/*---------------------------------------------------------------*/

@import "_toggle.scss";

/*---------------------------------------------------------------*/


/*  BLOG
/*---------------------------------------------------------------*/

@import "_blog.scss";

/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/


/*  GOOGLE MAP && CONTACT INFO  && CONTACT FORM
/*---------------------------------------------------------------*/

@import "_contact.scss";

/*---------------------------------------------------------------*/


/*  FOOTER &&  PAGINATION
/*---------------------------------------------------------------*/

@import "_footer.scss";

/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/


/*  SIDEBAR
/*---------------------------------------------------------------*/

@import "_sidebar.scss";

/*---------------------------------------------------------------*/


/*   PROTECTED PAGE
/*---------------------------------------------------------------*/

@import "_protected.scss";

/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/


/*  SLIDER
/*---------------------------------------------------------------*/

@import "_slider.scss";


/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/


/* /*Else 1 versions css && COMMENTS
/*---------------------------------------------------------------*/

@import "_else.scss";

/*---------------------------------------------------------------*/

//* For version 1.3.0 */

/*---------------------------------------------------------------*/

@import "_version1.3.scss";

/*---------------------------------------------------------------*/

@import "_else.scss";

/*---------------------------------------------------------------*/

//* Calendar plagin monthly */

/*---------------------------------------------------------------*/

@import "_monthly.scss";

/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/

//* Price*/

/*---------------------------------------------------------------*/

@import "_price.scss";

/*---------------------------------------------------------------*/


/*---------------------------------------------------------------*/

//* MARGINs and PADDINGs */

/*---------------------------------------------------------------*/

@import "_margins.scss";

/*---------------------------------------------------------------*/