/* 27. PROTECTED PAGE
-----------------*/
.protected-page .protected-title {
  font-size: 36px;
  line-height: 40px;
  letter-spacing: 2.88px;
  color: #131313;
  font-family: "BebasNeueRegular";
  font-weight: 400;
  text-align: center;
  padding: 280px 15px 16px 15px;
  @extend %base-color;
}

@media (max-width: 767px) {
  .protected-page .protected-title {
    padding-top: 40px;
    padding-bottom: 20px;
  }
}

.protected-page form {
  font-family: "Montserrat";
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 2.4px;
  font-weight: 400;
  color: #c0af94;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  @extend %subtitle-color;
}

@media (max-width: 767px) {
  .protected-page form {
    padding: 0px 15px;
  }
}

.protected-page input[type="password"] {
  margin-top: 35px;
  display: block;
  max-width: 370px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 14px;
  line-height: 28px;
  padding: 10px 0px;
  text-align: center;
  font-family: "Libre Baskerville";
  letter-spacing: 0.39px;
  font-weight: 400;
  border: none;
  background-color: transparent;
  outline: none;
  border-bottom: 2px solid #f2f2f2;
  transition: all 0.5s ease;
  color: #888888;
  @extend %desc-color;
  @extend %form-border-color;
}

@media (max-width: 767px) {
  .protected-page input[type="password"] {
    margin-top: 10px;
  }
}

.protected-page input[type="password"]:focus {
  color: #131313;
  border-bottom: 2px solid #c0af94;
  transition: all 0.5s ease;
  @extend %base-color;
  @extend %subtitle-border-color;
}

.protected-page input[type="password"]::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #888888;
}

.protected-page input[type="password"]::-moz-placeholder {
  /* Firefox 19+ */
  color: #888888;
}

.protected-page input[type="password"]:-ms-input-placeholder {
  /* IE 10+ */
  color: #888888;
}

.protected-page input[type="password"]:-moz-placeholder {
  /* Firefox 18- */
  color: #888888;
}

@media (max-width: 767px) {
  .protected-page input[type="password"] {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.protected-page input[type="submit"] {
  font-size: 11px;
  line-height: 20px;
  display: inline-block;
  letter-spacing: 1.1px;
  font-family: "Montserrat";
  font-weight: bold;
  box-sizing: border-box;
  padding: 8px 20px;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
  border-top: 2px solid rgba(19, 19, 19, 0.5);
  border-bottom: 2px solid rgba(19, 19, 19, 0.5);
  border-left: none;
  border-right: none;
  color: #131313;
  background-color: transparent;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-font-smoothing: antialiased;
  border-radius: 0px;
  @extend %base-light-bottom-color;
  @extend %base-color;
}

@media (max-width: 767px) {
  .protected-page input[type="submit"] {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.protected-page input[type="submit"]:hover {
  border-top: 2px solid #131313;
  border-bottom: 2px solid #131313;
  border-left: none;
  border-right: none;
  color: #ffffff;
  background-color: #131313;
  opacity: 1;
  @extend %base-border-color;
  @extend %base-bg-color;
  @extend %font-color;
}

.single-content.protected-page .post-info {
  display: none;
}

.single-proof_gallery .recent-post-single {
  display: none;
}

.single-proof_gallery .comments {
  display: none;
}