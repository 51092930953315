#topmenu {
  width: 100%;
  text-align: center;
  background: #ffffff;
  @extend %main-bg-color;
}

#topmenu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: inline-block;
}

#topmenu ul li {
  display: inline-block;
  position: relative;
}

#topmenu ul li a {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
  color: #131313;
  font-weight: 400;
  display: block;
  text-align: left;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0 20px 5px;
  line-height: 45px;
  letter-spacing: 0.96px;
  transition: all .3s ease;
  -webkit-font-smoothing: antialiased;
  @extend %base-color;
}

.header_trans-fixed.header_top_bg #topmenu ul li a {
  color: #fff;
  @extend %base-color;
}
.header_trans-fixed.header_top_bg.open #topmenu ul li a {
  color: #131313;
  @extend %base-color;
}

.top-menu #topmenu ul li a {
  padding: 0;
  margin-bottom: 5px;
}

#topmenu .social .fa {
  font-size: 15px;
}

/*Sub menu*/
#topmenu ul ul {
  position: absolute;
  z-index: 999;
  left: 0px;
  top: 50px;
  min-width: 215px;
  display: none;
  box-shadow: 0px 5px 18px 0px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

@media (min-width: 768px) {
  #topmenu ul ul {
    background-color: rgba(19, 19, 19, 0.8);
    padding: 10px 0px;
  }
}
@media (min-width: 768px) {
  .top-menu #topmenu ul ul {
    left: -20px;
  }
}

@media (max-width: 767px) {
  #topmenu ul li ul {
    box-shadow: none;
    font-style: normal;
  }
  #topmenu ul {
    box-shadow: none;
    font-style: normal;
  }

}

@media (min-width: 768px) {
  #topmenu > ul > li > ul:before {
    position: absolute;
    content: '';
    top: -5px;
    left: 20px;
    width: 0px;
    height: 0px;
    border-top: none;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid rgba(19, 19, 19, 0.8);
  }
}

@media (min-width: 768px) {
  #topmenu ul ul li {
    display: block;
    margin-bottom: 5px;
  }
  #topmenu ul ul li:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .top-menu #topmenu ul li {
    margin: 0px 10px 5px 10px;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .top-menu #topmenu ul li {
    margin: 0px 20px 5px 20px;
    padding: 0;
  }
}

#topmenu ul ul li::before {
  content: '';
  display: table;
  clear: both;
}

#topmenu ul ul li a {
  font-size: 12px;
  line-height: 26px;
  padding: 0px 20px;
  font-family: "Montserrat";
  letter-spacing: 0.72px;
  display: block;
  width: 100%;
  position: relative;
  -webkit-font-smoothing: antialiased;
}

@media (min-width: 768px) {
 body  #topmenu ul ul li a {
    color: #ffffff;
    text-transform: none  ;
  }
}

@media (min-width: 768px) {
  #topmenu ul ul li.current-menu-item > a {
    color: #c0af94;
  } 
  .header_trans-fixed.header_top_bg #topmenu ul ul li.current-menu-item > a {
    color: #c0af94;
  }
}

#topmenu ul ul li a::before {
  content: none;
}

body #topmenu > ul > li > ul > li:hover ul {
  display: block  ;
}

#topmenu > ul > li > ul > li > ul {
  left: 101%;
  top: -15px;
}

.mob-nav {
  display: none;
  width: 20px;
  height: 20px;
  margin: 0 auto 12px;
  font-size: 14px;
  color: #131313;
  opacity: 1;
  @extend %base-color;
}
.mob-nav:hover {
  opacity: 0.7;
}
.header_trans-fixed .mob-nav i {
  color: #fff;
  @extend %base-color;
}
.header_trans-fixed.open .mob-nav i {
  color: #131313;
  @extend %base-color;
}

@media (max-width: 767px) {
  .top-menu .logo {
    margin-bottom: 0;
    margin-top: 0;
  }
 body  .no-padd-mob {
    padding: 0  ;
  }
  .right-menu #topmenu .menu li.menu-item-has-children > a,
  #topmenu .menu li.menu-item-has-children > a {
    position: relative;
  }
  #topmenu ul li a:hover {
    opacity: 1;
  }
  .mob-nav {
    display: block;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
        transform: translateY(-50%);
  }
  #topmenu {
    display: none;
    overflow-y: auto;
    height: 100vh;
    position: absolute;
    padding-bottom: 55px;
    padding-left: 15px;padding-right: 15px;
    margin-bottom: 50px;
    text-align: left;
    padding-top: 20px;
  }
  #topmenu ul ul {
    display: block;
    position: static;
  }
 body  #topmenu ul.menu > li > ul > li > ul {
    display: block  ;
  }
  #topmenu ul.menu {
    width: 100%;
  }
 body  #topmenu ul.menu li {
    display: block  ;
    float: none;
    margin-bottom: 0px;
  }
 body  #topmenu ul.menu li a {
    padding: 5px 10px;
    line-height: 25px;
    display: block;
    width: 100%  ;
    float: none;
    transition: all 0.5s ease;
  }
  #topmenu ul.menu li a:hover {
    opacity: 0.7;
    transition: all 0.5s ease;
  }
  /*1 level menu*/
  #topmenu > ul.menu > li {
    margin-bottom: 15px;
  }
  #topmenu > ul.menu > li > a {
    font-size: 14px;
  }
  /*2 level menu*/
  #topmenu > ul.menu > li > ul > li {
    margin: 0px 15px;
    border: 1px solid #f1f2f3;
    margin-top: -1px;
  }
  /*3 level menu*/
  #topmenu > ul.menu > li > ul > li > ul > li {
    margin: 0px 15px;
    border: 1px solid #f1f2f3;
    margin-top: -1px;
  }
  #topmenu > ul.menu > li > ul > li > ul > li:last-child {
    margin-bottom: 20px;
  }
 body  #topmenu .social li a {
    padding: 0px 10px;
    line-height: 25px ;
  }
}

@media (min-width: 768px) {
  #topmenu > ul > li:hover ul {
    display: block;
  }
 body   #topmenu {
    display: block ;
  }
  #topmenu > ul > li > ul > li > ul {
    left: -100%;
    top: -15px;
  }
  .sub-menu li a {
    z-index: 1999;
  }
 body  .pl30md {
    padding-left: 30px ;
    padding-right: 0  ;
  }
}

@media (min-width: 768px) {
  /*Top menu*/
  .right-menu {
    max-width: 1170px;
    width: 100%;
    margin: auto;
    display: table;
    padding: 22px 0 13px;
  }
  .right-menu #topmenu {
    text-align: right;
  }
  .right-menu #topmenu ul ul {
    left: 10px;
    top: 50px;
  }
  .top-menu #topmenu ul ul {
    left: -20px;
    top: 50px;
  }
  .right-menu #topmenu > ul > li > ul > li > ul {
    left: 100%;
    top: -10px;
  }
  .top-menu #topmenu > ul > li > ul > li > ul {
    left: 100%;
    top: -10px;
  }
  .right-menu #topmenu .social {
    text-align: right;
    margin-left: 40px;
    vertical-align: top;
  }
  .right-menu #topmenu .social li a {
    padding: 0;
    margin-left: 18px;
  }
  .right-menu #topmenu .social li a:hover {
    opacity: 0.5;
    transition: all 0.5s ease;
  }
  .right-menu #topmenu .social li a::after,
  .right-menu #topmenu .social li a::before {
    content: none;
  }
  .right-menu #topmenu > ul > li > a {
    position: relative;
    padding: 0 0 5px 0px;
    margin-left: 30px;
  }
  .right-menu #topmenu > ul > li > a:hover,
  .top-menu #topmenu > ul > li > a:hover {
    opacity: 1;
  }
  .right-menu #topmenu > ul > li > a::before,
  .top-menu #topmenu > ul > li > a::before {
    position: absolute;
    content: '';
    width: 0%;
    height: 4px;
    bottom: 10px;
    left: 0;
    background-image: url("../images/menu-border.svg");
    background-repeat-x: repeat;
    z-index: 10;
    transition: all 0.5s ease;
  }
  .right-menu #topmenu > ul > li > a:hover::before,
  .top-menu #topmenu > ul > li > a:hover::before {
    width: 100%;
    transition: all 0.5s ease;
  }
  .right-menu #topmenu > ul > li.current-menu-item > a::before,
  .top-menu #topmenu > ul > li.current-menu-item > a::before,
  .right-menu #topmenu > ul > li.current-menu-parent > a::before,
  .top-menu #topmenu > ul > li.current-menu-parent > a::before {
    width: 0%;
  }
  .right-menu #topmenu > ul > li.current-menu-item > a,
  .top-menu #topmenu > ul > li.current-menu-item > a,
  .right-menu #topmenu > ul > li.current-menu-parent > a,
  .top-menu #topmenu > ul > li.current-menu-parent > a {
    opacity: 0.7;
    transition: all 0.5s ease;
  }
  .right-menu .logo img {
    margin-bottom: 12px;
    max-height: 75px;
  }
}

@media (max-width: 767px) {
  .right-menu #topmenu .social {
    display: block;
  }
  .right-menu #topmenu .social li {
    display: inline-block;
  }
  .right-menu #topmenu .social li a {
    padding: 0 10px 5px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .right-menu {
    text-align: left;
  }
  .right-menu .logo {
    display: inline-block;
    margin-top:9px;
    margin-bottom: 0;
  }
body   .right-menu #topmenu {
    display: inline-block  ;
    width: auto;
    float: right;
  }
  .right-menu #topmenu ul ul {
    left: -5px;
  }
  .right-menu #topmenu .menu > li > a {
    margin-left: 15px;
  }
  .right-menu #topmenu .social {
    margin-left: 0px;
    float: right;
  }
}

/*================================================*/
/* TOP SOCIALS */
/*================================================*/
.napoli-top-social {
  display: inline-block;
  margin-left: 30px;
  position: relative;
  vertical-align: top;
}
.napoli-top-social .social-icon {
  display: none;
  font-size: 12px;
  line-height: 45px;
  color: #131313;
  opacity: 1;
  padding: 0 10px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  position: relative;
  z-index: 30;
  @extend %base-color;
}
.header_trans-fixed .napoli-top-social .social-icon {
  color: #fff;
  @extend %base-color;
}
.napoli-top-social .social-icon:hover {
  opacity: 0.7;
}
.right-menu #topmenu .napoli-top-social .social {
  margin-left: 0;
}
#topmenu .social li {
  display: inline-block;
  margin-left: 12px;
}
.right-menu #topmenu .napoli-top-social .social li a {
  margin-left: 0;
  color: #131313;
  opacity: 1;
  transition: opacity 0.3s ease;
  @extend %base-color;
}
.header_trans-fixed .right-menu #topmenu .napoli-top-social .social li a {
  color: #fff;
  @extend %base-color;
}
.right-menu #topmenu .napoli-top-social .social:hover a {
  opacity: 0.7;
}
.right-menu #topmenu .napoli-top-social .social li a:hover {
  opacity: 1;
}

@media only screen and (max-width: 1199px) {
  .napoli-top-social .social-icon {
    display: inline-block;
  }
  .right-menu #topmenu .napoli-top-social .social {
    background-color: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 120px;
    z-index: 25;
    visibility: hidden;
    opacity: 0;
    text-align: left;
    transition: visibility 0.3s ease, opacity 0.3s ease;
  }
  .header_trans-fixed .right-menu #topmenu .napoli-top-social .social {
    background-color: transparent;
  }
  .right-menu #topmenu .napoli-top-social .social li {
    margin-left: 10px;
  }
  .right-menu #topmenu .napoli-top-social .social.active {
    visibility: visible;
    opacity: 1;
  }
  .right-menu #topmenu .napoli-top-social .social li a {
    line-height: 40px;
  }
}

@media only screen and (max-width: 991px) {
  .napoli-top-social {
    margin-left: 10px;
  }
  .right-menu #topmenu .napoli-top-social .social {
    right: -10px;
    left: auto;
  }
}

@media (max-width: 991px) and (min-width: 768px) {
 body .right-menu #topmenu .menu > li > a {
    margin-left: 7px  ;
  }
}

@media only screen and (max-width: 767px) {
  .napoli-top-social .social-icon {
    display: none;
  }
  .right-menu #topmenu .napoli-top-social .social {
    position: static;
    visibility: visible;
    opacity: 1;
  }
  .right-menu #topmenu .napoli-top-social .social li {
    margin-left: 7px;
  }
  .header_trans-fixed.open .right-menu #topmenu .napoli-top-social .social li a {
    color: #131313;
  }
}